import React, { PureComponent } from 'react';
import ContentLoader from 'react-content-loader';
import { Spin } from 'antd';

import StyledDiv from './index.styled';

import withModal from '../../common/modal/base';
import FormHeader from '../../form/header-placeholder';

class LoadingReport extends withModal(PureComponent) {
  render() {
    const { sidebarCollapsed, showSpin = false } = this.props;

    return (
      <div className="bdm-form">
        <FormHeader sidebarCollapsed={sidebarCollapsed}>
          <div className="header-info">
            <ContentLoader
              className="svg-loading-animation is-form-tabs"
              height={57}
              width={350}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <circle cx="60" cy="20" r="5" />
              <rect x="20" y="45" rx="5" ry="5" width="80" height="8" />

              <rect x="66" y="19" rx="5" ry="5" width="150" height="2" />

              <circle cx="223" cy="20" r="5" />
              <rect x="185" y="45" rx="5" ry="5" width="80" height="8" />

              <rect x="228" y="19" rx="5" ry="5" width="100" height="2" />
            </ContentLoader>
          </div>
        </FormHeader>
        {showSpin && (
          <StyledDiv>
            <Spin size="large" />
          </StyledDiv>
        )}
      </div>
    );
  }
}

export default LoadingReport;
