import 'react-app-polyfill/stable';
import 'whatwg-fetch';
import React from 'react';
import { render } from 'react-dom';
import './styles/index.less';

import registerServiceWorker from './registerServiceWorker';
import exposeAppVersion from './appVersion';
import enableDebugMode from './enableDebugMode';
import googleAnalytics from './googleAnalytics';
import initializeDatadogRum from './datadogRUM';
import renderIfNotTesting from './utils/renderIfNotTesting';
import { i18nextConfig } from './i18next';
import App from './App';

export const AppRoot = document.getElementById('root');

googleAnalytics();
exposeAppVersion();
initializeDatadogRum();
i18nextConfig();
renderIfNotTesting(() => {
  enableDebugMode();

  render(<App />, AppRoot);
});
registerServiceWorker();
