import FieldSwitch from '../../components/FieldSwitch/FieldSwitch';
import { DEFAULT_VALUE, DEFAULT_VALUE_LABEL } from '../../constants/constants';

export default class {
  constructor() {
    this.name = DEFAULT_VALUE;
    this.label = DEFAULT_VALUE_LABEL;
    this.component = FieldSwitch;
    this.initialValue = false;
  }

  onChange = (value, values) => ({
    ...values,
    [this.name]: value,
  });

  onSave = values => {
    const value = values[this.name];
    const { options = {} } = values;

    return {
      ...values,
      [this.name]: undefined,
      options: {
        ...options,
        [DEFAULT_VALUE]: value ? value : undefined,
      },
    };
  };

  getInitialValue = values => {
    const { options = {} } = values;

    return options[DEFAULT_VALUE] !== undefined
      ? options[DEFAULT_VALUE]
      : this.initialValue;
  };
}
