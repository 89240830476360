import React from 'react';
import { isArray, isEmpty } from 'lodash';
import { Dropdown, Icon } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';
import { Container, MenuItem, DropdownMenu } from './EllipsisMenu.styled';

const EllipsisMenu = ({ elements, testId = '' }) => {
  const formattedElements = isArray(elements) ? elements : [elements];
  const firstElement =
    formattedElements && !isEmpty(formattedElements)
      ? formattedElements[0]
      : '';
  const menuElements = formattedElements.filter(
    element => element !== firstElement
  );

  const menu = (
    <DropdownMenu>
      {menuElements.map((element, index) => (
        <MenuItem
          key={`ellipsis-menu-item-${index}-${element}`}
          selectable={false}
          onClick={e => e.preventDefault()}
        >
          <span>{htmlUnescape(element)}</span>
        </MenuItem>
      ))}
    </DropdownMenu>
  );

  return (
    <Container data-testid={testId}>
      {firstElement && <span>{htmlUnescape(firstElement)}</span>}
      {menuElements && !isEmpty(menuElements) && (
        <Dropdown overlay={menu} trigger={['click']}>
          <Icon type="down-circle" theme="twoTone" />
        </Dropdown>
      )}
    </Container>
  );
};

export default EllipsisMenu;
