import React, { Component } from 'react';
import { Row, Col, Input as InputField, Alert } from 'antd';
import { Field } from 'redux-form';

import Title from '../../../../components/form-viewer/styled/section-title';
import Input from '../../components/input';
import { PhoneNumberField as PhoneNumberInput } from '../../../../components/form-viewer/field-types/input-masked';
import If from '../../../../components/utils/ConditionalRender';
import FormSkeleton from './FormSkeleton';
import {
  StyledCollapse,
  StyledPanel,
  StyledCheckbox,
  StyledPanelTitle,
} from '../../../../components/custom-sections/sections/Sections.styled';
import TextAreaTooltip from './TextAreaTooltip';
import { translate } from '../../../../i18next';

const TextField = Input(InputField);
const AddressField = Input(TextAreaTooltip);
const PhoneNumberField = Input(PhoneNumberInput);
const commonFieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
  inputCol: 'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
};

class UserContactInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMailingAddressSame: false,
    };
  }

  buildRows = (fieldNames, shouldSeeAnyField) => {
    const allowedfieldNames = fieldNames.filter(field =>
      shouldSeeAnyField(field)
    );
    const rows = [];
    let i = 0;
    const length = allowedfieldNames.length;
    while (i < length) {
      let count = 2;
      let cols = [];
      while (count > 0 && i < length) {
        cols.push(allowedfieldNames[i]);
        i++;
        count--;
      }
      rows.push(cols);
    }
    return rows;
  };

  onMailingCheckboxChange = e => {
    const {
      profileForm: {
        values: { address },
      },
      updateField,
    } = this.props;
    const { checked } = e.target;
    this.setState({ isMailingAddressSame: checked });
    if (checked) {
      updateField('mailingAddress', address);
    } else {
      updateField('mailingAddress', '');
    }
  };

  onAddressChange = e => {
    const { updateField } = this.props;
    const { value } = e.target;
    const { isMailingAddressSame } = this.state;
    if (isMailingAddressSame) {
      updateField('mailingAddress', value);
    }
  };

  onEmailChange = e => {
    const {
      profileForm: {
        values: { username = '' },
      },
    } = this.props;

    if (username.indexOf('@') !== -1) {
      const {
        target: { value },
      } = e;
      this.setState({ showEmailWarning: username !== value });
    }
  };

  render = () => {
    const {
      fetchingAgency = false,
      shouldSeeAnyField,
      shouldBeReadOnlyField,
      getFieldLabel,
    } = this.props;
    const { isMailingAddressSame, showEmailWarning } = this.state;
    const toolTipTitle = translate('containers.profile.addressFormat');
    const emailWarning = 'You must logon with this new email.';
    const contactInfofields = new Map();
    contactInfofields.set(
      'homePhone',
      <Col key={`col-homePhone`} xs={24} sm={12} md={12} lg={12}>
        <Field
          type="text"
          name="homePhone"
          data-test="homePhone"
          label={getFieldLabel('homePhone')}
          component={PhoneNumberField}
          options={commonFieldOptions}
          disabled={fetchingAgency}
          plainText={shouldBeReadOnlyField(['homePhone'])}
        />
      </Col>
    );
    contactInfofields.set(
      'email',
      <Col key={`col-email`} xs={24} sm={12} md={12} lg={12}>
        <Field
          type="email"
          name="email"
          data-test="email"
          label={getFieldLabel('email')}
          component={TextField}
          options={commonFieldOptions}
          disabled={fetchingAgency}
          plainText={shouldBeReadOnlyField(['email'])}
          onChange={this.onEmailChange}
        />
        {showEmailWarning && (
          <Alert message={emailWarning} type="warning" closable />
        )}
      </Col>
    );
    contactInfofields.set(
      'mobilePhone',
      <Col key={`col-mobilePhone`} xs={24} sm={12} md={12} lg={12}>
        <Field
          type="text"
          name="mobilePhone"
          data-test="mobilePhone"
          label={getFieldLabel('mobilePhone')}
          component={PhoneNumberField}
          options={commonFieldOptions}
          disabled={fetchingAgency}
          plainText={shouldBeReadOnlyField(['mobilePhone'])}
        />
      </Col>
    );
    contactInfofields.set(
      'address',
      <Col key={`col-address`} xs={24} sm={12} md={12} lg={12}>
        <Field
          type="text"
          name="address"
          data-test="address"
          label={getFieldLabel('address')}
          component={AddressField}
          options={commonFieldOptions}
          disabled={fetchingAgency}
          inputProps={{
            rows: 4,
            style: { resize: 'none' },
            title: toolTipTitle,
          }}
          plainText={shouldBeReadOnlyField(['address'])}
          onChange={this.onAddressChange}
        />
      </Col>
    );

    const mailingFields = new Map();
    mailingFields.set(
      'mailingAddress',
      <Col key={`col-mailingAddress`} xs={24} sm={12} md={12} lg={12}>
        <Field
          type="text"
          name="mailingAddress"
          data-test="mailingAddress"
          label={getFieldLabel('mailingAddress')}
          component={AddressField}
          options={commonFieldOptions}
          disabled={fetchingAgency || isMailingAddressSame}
          inputProps={{
            rows: 4,
            style: { resize: 'none' },
            title: toolTipTitle,
          }}
          plainText={shouldBeReadOnlyField(['mailingAddress'])}
        />
      </Col>
    );

    const emergencyFields = new Map();

    emergencyFields.set(
      'emergencyName',
      <Col key={`col-emergencyName`} xs={24} sm={12} md={12} lg={12}>
        <Field
          type="text"
          name="emergencyName"
          data-test="emergencyName"
          label={getFieldLabel('emergencyName')}
          component={TextField}
          options={commonFieldOptions}
          disabled={fetchingAgency}
          plainText={shouldBeReadOnlyField(['emergencyName'])}
        />
      </Col>
    );
    emergencyFields.set(
      'relationshipOfficer',
      <Col key={`col-relationshipOfficer`} xs={24} sm={12} md={12} lg={12}>
        <Field
          type="text"
          name="relationshipOfficer"
          data-test="relationshipOfficer"
          label={getFieldLabel('relationshipOfficer')}
          component={TextField}
          options={commonFieldOptions}
          disabled={fetchingAgency}
          inputProps={{ rows: 4, style: { resize: 'none' } }}
          plainText={shouldBeReadOnlyField(['relationshipOfficer'])}
        />
      </Col>
    );
    emergencyFields.set(
      'emergencyHomePhone',
      <Col key={`col-emergencyHomePhone`} xs={24} sm={12} md={12} lg={12}>
        <Field
          type="text"
          name="emergencyHomePhone"
          data-test="emergencyHomePhone"
          label={getFieldLabel('emergencyHomePhone')}
          component={PhoneNumberField}
          options={commonFieldOptions}
          disabled={fetchingAgency}
          plainText={shouldBeReadOnlyField(['emergencyHomePhone'])}
        />
      </Col>
    );
    emergencyFields.set(
      'emergencyMobilePhone',
      <Col key={`col-emergencyMobilePhone`} xs={24} sm={12} md={12} lg={12}>
        <Field
          type="text"
          name="emergencyMobilePhone"
          data-test="emergencyMobilePhone"
          label={getFieldLabel('emergencyMobilePhone')}
          component={PhoneNumberField}
          options={commonFieldOptions}
          disabled={fetchingAgency}
          plainText={shouldBeReadOnlyField(['emergencyMobilePhone'])}
        />
      </Col>
    );
    emergencyFields.set(
      'emergencyAddress',
      <Col key={`col-emergencyAddress`} xs={24} sm={12} md={12} lg={12}>
        <Field
          type="text"
          name="emergencyAddress"
          data-test="emergencyAddress"
          label={getFieldLabel('emergencyAddress')}
          component={AddressField}
          options={commonFieldOptions}
          disabled={fetchingAgency}
          inputProps={{
            rows: 4,
            style: { resize: 'none' },
            title: toolTipTitle,
          }}
          plainText={shouldBeReadOnlyField(['emergencyAddress'])}
        />
      </Col>
    );

    const contactInfoArray = ['homePhone', 'mobilePhone', 'email', 'address'];
    const emergencyArray = [
      'emergencyName',
      'relationshipOfficer',
      'emergencyHomePhone',
      'emergencyMobilePhone',
      'emergencyAddress',
    ];

    const contactInfoRows = this.buildRows(contactInfoArray, shouldSeeAnyField);

    const mailingRows = this.buildRows(['mailingAddress'], shouldSeeAnyField);

    const emergencyRows = this.buildRows(emergencyArray, shouldSeeAnyField);

    return (
      <If
        condition={shouldSeeAnyField(contactInfoArray.concat(emergencyArray))}
      >
        <StyledCollapse
          bordered={false}
          defaultActiveKey="contact-Info"
          expandIconPosition="right"
        >
          <StyledPanel
            header={
              <StyledPanelTitle size="large">
                {translate('containers.profile.contactInfo')}
              </StyledPanelTitle>
            }
            key="contact-Info"
            className="section-panel"
          >
            <hr></hr>
            <If condition={fetchingAgency}>
              <FormSkeleton small />
            </If>
            <If condition={!fetchingAgency}>
              <div>
                {contactInfoRows.map((row, i) => (
                  <Row key={`row-user-contact-${i}`}>
                    {contactInfofields.get(row[0])}{' '}
                    {contactInfofields.get(row[1])}
                  </Row>
                ))}
                <If condition={shouldSeeAnyField(['mailingAddress'])}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <StyledCheckbox
                        onChange={this.onMailingCheckboxChange}
                        disabled={shouldBeReadOnlyField(['mailingAddress'])}
                      >
                        {translate('containers.profile.sameAddress')}
                      </StyledCheckbox>
                    </Col>
                  </Row>
                </If>
                {mailingRows.map((row, i) => (
                  <Row key={`row-mailing-info-${i}`}>
                    {mailingFields.get(row[0])}
                  </Row>
                ))}
                <If condition={shouldSeeAnyField(emergencyArray)}>
                  <Row>
                    <Col className="gutter-row" span={24}>
                      <Title size="small">
                        {translate('containers.profile.emergencyContactInfo')}
                      </Title>
                    </Col>
                  </Row>
                </If>
                {emergencyRows.map((row, i) => (
                  <Row key={`row-emergency-info-${i}`}>
                    {emergencyFields.get(row[0])} {emergencyFields.get(row[1])}
                  </Row>
                ))}
              </div>
            </If>
          </StyledPanel>
        </StyledCollapse>
      </If>
    );
  };
}

export default UserContactInformation;
