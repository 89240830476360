import React, { Component } from 'react';

import { Row, Col, Input, Button, Upload, Checkbox } from 'antd';
import { omit, isEmpty, fill, some, every } from 'lodash';
import { save } from 'save-file';

import RowWrapper from '../OptionsModalBody/OptionsModalBody.styled';
import StyledCheckbox from './UpdateSelectOptions.styled';

const ADD_OPTION_ERROR = 'Please fill out all fields before add more.';
const MAX_STRING_LENGTH = 50;
export default class UpdateSelectOptions extends Component {
  constructor(props) {
    super(props);

    const defaultValue = props.defaultValue;
    const options = props.options;
    const newDefaultValue = this.hasDuplicateDefaultValue(options, defaultValue)
      ? undefined
      : defaultValue;

    this.state = {
      options: options,
      checkboxValues: this.createCheckboxValues(props.options),
      selectAll: false,
      defaultValue: newDefaultValue,
    };
  }

  createCheckboxValues = (options = []) =>
    options.map(o => ({
      value: false,
      enums: o.enums ? this.createCheckboxValues(o.enums) : undefined,
    }));

  render() {
    const { options, checkboxValues, selectAll, defaultValue } = this.state;
    const deleteDisabled = !some(
      checkboxValues,
      v => v.value || (v.enums && some(v.enums, { value: true }))
    );

    return (
      <RowWrapper className="select-values" key="select">
        <Row key="select-title">
          <span className="title">Options</span>
        </Row>
        <Row key="select-content">
          <Col span={10}>Label</Col>
          <Col span={9}>Value</Col>
          <Col span={3} className="select-all" style={{ textAlign: 'center' }}>
            <label>
              Select all&nbsp;
              <Checkbox
                checked={selectAll}
                indeterminate={this.getIndeterminateSelectAll()}
                onChange={this.onChangeSelectAll}
                disabled={options.length < 2}
              />
            </label>
          </Col>
          <Col span={2}>Default value</Col>
        </Row>
        <Row className="scrollable">
          {this.renderRow(options, this.updateOptions, defaultValue)}
        </Row>

        <Row className="select-footer" key="select-footer">
          <Col span={6}>
            <Button
              data-test="button-add"
              type="dashed"
              size="small"
              icon="plus"
              onClick={this.addSelectOption}
            >
              Add option
            </Button>
          </Col>
          <Col span={6}>
            <Upload
              accept=".csv"
              showUploadList={false}
              beforeUpload={this.uploadOptions}
            >
              <Button data-test="button-upload" size="small" icon="upload">
                Upload
              </Button>
            </Upload>
          </Col>
          <Col span={6}>
            <Button
              data-test="button-download"
              type="default"
              size="small"
              icon="download"
              onClick={this.downloadOptions}
            >
              Download
            </Button>
          </Col>
          <Col span={6}>
            <Button
              data-test="button-delete-selected"
              type="default"
              className="delete-selected"
              size="small"
              icon="delete"
              onClick={this.deleteSelected}
              danger="true"
              disabled={deleteDisabled}
            >
              Delete selected
            </Button>
          </Col>
        </Row>
      </RowWrapper>
    );
  }

  deleteSelected = () => {
    const { options, checkboxValues, selectAll, defaultValue } = this.state;
    let newOptions;
    let values;
    if (selectAll) {
      newOptions = [{ label: '', value: '' }];
      values = [{ value: false }];
    } else {
      newOptions = options.reduce((newOp, value, idx) => {
        if (!checkboxValues[idx].value) {
          newOp.push({
            ...value,
            enums: value.enums
              ? value.enums.filter(
                  (v, i) => !checkboxValues[idx].enums[i].value
                )
              : undefined,
          });
        }
        return newOp;
      }, []);
      values = this.createCheckboxValues(newOptions);
    }

    if (
      !newOptions.some(option =>
        this.containsDefaultValue(option, defaultValue)
      )
    ) {
      this.updateDefaultValue(undefined);
    }

    this.setState({
      options: newOptions,
      checkboxValues: values,
      selectAll: false,
    });
    this.updateOptions(newOptions);
  };

  uploadOptions = (file, fileList) => {
    const { showMessage } = this.props;
    const reader = new FileReader();
    const name = (file.name || '').split('.');

    if (name.length < 2 || name.pop() !== 'csv') {
      showMessage('error', `${file.name} is not a csv file.`);
      return false;
    }

    reader.onload = this.readerOnload(file.name);
    reader.readAsText(file);

    // Prevent upload
    return false;
  };

  readerOnload = fileName => e => {
    const { showMessage } = this.props;
    const file = e.target;

    if (file.error) {
      showMessage('error', `${fileName} file upload failed`);
    } else {
      const { options, newDefaultValue } = this.csvToJson(file.result);
      showMessage('success', `${fileName} file uploaded successfully`);
      const checkboxValues = this.createCheckboxValues(options);
      // if the same file is uploaded again with label changes, UI is
      // not refreshed correctly because it is using the same keys
      // this way keys are refreshed
      this.setState({ options: [], checkboxValues: [], selectAll: false }, () =>
        this.setState({
          options,
          checkboxValues,
        })
      );
      this.updateOptions(options);
      this.updateDefaultValue(newDefaultValue);
    }
  };

  // Parse a CSV row, accounting for commas inside quotes
  splitValues = line => {
    let insideQuote = false;
    let entries = [];
    let entry = [];
    line.split('').forEach(character => {
      if (character === '"') {
        insideQuote = !insideQuote;
      } else {
        if (character === ',' && !insideQuote) {
          entries.push(entry.join(''));
          entry = [];
        } else {
          entry.push(character);
        }
      }
    });
    entries.push(entry.join(''));
    return entries;
  };

  getStringValue = (str = '') => str.substring(0, MAX_STRING_LENGTH).trim();

  sanitizeLines = lines => {
    return lines.map(line => (line.endsWith(',') ? line.slice(0, -1) : line));
  };

  csvToJson = (data = '') => {
    // Ensure to use the correct line break
    let lines = data.split(/\r\n|\n/);
    lines = this.sanitizeLines(lines);

    let newDefaultValue;
    let seenDefaultValue = false;

    const options = lines.reduce((acc, line) => {
      const values = this.splitValues(line);
      switch (values.length) {
        case 4:
          const label1 = this.getStringValue(values[0]);
          const option = acc.find(o => o.label === label1 && o.enums);
          if (option) {
            option.enums.push({
              label: this.getStringValue(values[1]),
              value: this.getStringValue(values[2]),
              order: option.enums.length + 1,
            });
          } else {
            acc.push({
              label: label1,
              enums: [
                {
                  label: this.getStringValue(values[1]),
                  value: this.getStringValue(values[2]),
                  order: 1,
                },
              ],
              order: acc.length + 1,
            });
          }

          if (
            this.getStringValue(values[3])
              .trim()
              .toLowerCase() === 'true'
          ) {
            if (!seenDefaultValue) {
              newDefaultValue = this.getStringValue(values[2]);
              seenDefaultValue = true;
            } else {
              newDefaultValue = undefined;
            }
          }

          break;

        case 3:
          if (
            this.isSkipLine(
              this.getStringValue(values[0]),
              this.getStringValue(values[1])
            )
          ) {
            break;
          }

          // Groups should have 3 values (group, label, value)
          // or new template (label, value, defaultValue)
          const label = this.getStringValue(values[0]);

          const stringValue = this.getStringValue(values[2]);

          if (
            stringValue.trim().toLowerCase() === 'false' ||
            stringValue.trim().toLowerCase() === 'true'
          ) {
            // upload with defaultValue boolean keys

            acc.push({
              label: this.getStringValue(values[0]),
              value: this.getStringValue(values[1]),
              order: acc.length + 1,
            });

            if (stringValue.trim().toLowerCase() === 'true') {
              if (!seenDefaultValue) {
                newDefaultValue = this.getStringValue(values[1]);
                seenDefaultValue = true;
              } else {
                newDefaultValue = undefined;
              }
            }
          } else {
            const option = acc.find(o => o.label === label && o.enums);
            if (option) {
              option.enums.push({
                label: this.getStringValue(values[1]),
                value: this.getStringValue(values[2]),
                order: option.enums.length + 1,
              });
            } else {
              acc.push({
                label: label,
                enums: [
                  {
                    label: this.getStringValue(values[1]),
                    value: this.getStringValue(values[2]),
                    order: 1,
                  },
                ],
                order: acc.length + 1,
              });
            }
          }
          break;

        case 2:
          if (
            this.isSkipLine(
              this.getStringValue(values[0]),
              this.getStringValue(values[1])
            )
          ) {
            break;
          }

          // Regular option should have 2 values (label, value)
          acc.push({
            label: this.getStringValue(values[0]),
            value: this.getStringValue(values[1]),
            order: acc.length + 1,
          });
          break;

        default:
          // For everything else, assuming 1 value (same for label and value)
          const value = this.getStringValue(values[0]);
          if (value) {
            acc.push({
              label: value,
              value: value,
              order: acc.length + 1,
            });
          }
          break;
      }
      return acc;
    }, []);

    return { options, newDefaultValue };
  };

  getCsvValue = (value = '') =>
    value.indexOf(',') !== -1 ? `"${value}"` : value;

  jsonToCsv = (options = [], defaultValue, group) => {
    const csv = options.reduce((lines, { label, value, enums }) => {
      const line = group ? [this.getCsvValue(group)] : [];
      if (enums) {
        return lines.concat(this.jsonToCsv(enums, defaultValue, label));
      } else {
        line.push(this.getCsvValue(label));
        line.push(this.getCsvValue(value));
        line.push(
          defaultValue !== undefined && value === defaultValue
            ? this.getCsvValue('true')
            : this.getCsvValue('false')
        );
        lines.push(line.join(', '));
        return lines;
      }
    }, []);
    return 'Label,Value,defaultValue\r\n' + csv.join('\r\n');
  };

  downloadOptions = async () => {
    const { showMessage } = this.props;
    const { options = [], defaultValue } = this.state;

    if (options.length === 0) {
      showMessage('info', 'No options to download');
    } else {
      await save(this.jsonToCsv(options, defaultValue), 'SelectOptions.csv');
    }
  };

  addSelectOption = () => {
    const { showMessage } = this.props;
    const { options, checkboxValues } = this.state;
    if (this.canAddOptions(options)) {
      const newOptions = options.concat([{ label: '', value: '' }]);
      this.setState({
        checkboxValues: checkboxValues.concat([{ value: false }]),
        selectAll: false,
      });
      this.updateOptions(newOptions);
    } else {
      showMessage('error', ADD_OPTION_ERROR);
    }
  };

  addOption = (array, index, enums, update) => () => {
    const { showMessage } = this.props;
    const { checkboxValues } = this.state;
    if (this.canAddOptions(array)) {
      const newArray = this.optionsUpdater(
        array,
        index,
        'enums',
        enums.concat([{ label: '', value: '' }])
      );
      let newValues = this.optionsUpdater(
        checkboxValues,
        index,
        'value',
        false
      );
      newValues = this.optionsUpdater(
        newValues,
        index,
        'enums',
        newValues[index].enums.concat([{ value: false }])
      );
      this.setState({
        checkboxValues: newValues,
        selectAll: false,
      });
      update(newArray);
    } else {
      showMessage('error', ADD_OPTION_ERROR);
    }
  };

  onBlur = (index, name, array, update, parentIndex = undefined) => e => {
    const newOptions = this.optionsUpdater(array, index, name, e.target.value);
    update(newOptions);

    const changedValue = e.target.value;

    if (name === 'value') {
      this.handleValueChange(index, newOptions, parentIndex, changedValue);
    }
  };

  updateOptions = newOptions => {
    const { updateParent } = this.props;
    this.setState({ options: newOptions, message: '' });
    updateParent && updateParent({ select: newOptions });
  };

  optionsUpdater = (options, index, name, value) => {
    return options.map((o, i) => {
      if (i === index) {
        return value === undefined ? omit(o, [name]) : { ...o, [name]: value };
      } else {
        return o;
      }
    });
  };

  canAddOptions = options =>
    !options.some(
      o =>
        isEmpty(o.label) ||
        (o.enums ? !this.canAddOptions(o.enums) : isEmpty(o.value))
    );

  updateCheckbox = (options, index, parentIndex, value) =>
    options.map((o, i) => {
      if (parentIndex === undefined) {
        if (i === index) {
          const enums = o.enums
            ? fill(Array(o.enums.length), { value })
            : undefined;

          return { value, enums };
        }
        return o;
      } else {
        return i === parentIndex
          ? {
              value: o.value,
              enums: this.updateCheckbox(o.enums, index, undefined, value),
            }
          : o;
      }
    });

  getCheckboxValue = (index, parentIndex) => {
    const { checkboxValues } = this.state;
    const values =
      parentIndex !== undefined
        ? checkboxValues[parentIndex].enums
        : checkboxValues;
    return values[index].value;
  };

  getIndeterminate = (index, parentIndex) => {
    const { checkboxValues } = this.state;
    const { value, enums = [] } = checkboxValues[
      parentIndex === undefined ? index : parentIndex
    ];

    if (value || every(enums, { value: true })) {
      return false;
    } else {
      return parentIndex === undefined && some(enums, { value: true });
    }
  };

  onChangeCheckbox = (index, parentIndex) => e => {
    const { checkboxValues } = this.state;
    const { target: { checked = false } = {} } = e;
    const values = this.updateCheckbox(
      checkboxValues,
      index,
      parentIndex,
      checked
    );

    if (parentIndex !== undefined) {
      values[parentIndex].value = every(values[parentIndex].enums, {
        value: true,
      });
    }
    const selectAll = every(values, { value: true });
    this.setState({ checkboxValues: values, selectAll });
  };

  getIndeterminateSelectAll = () => {
    const { checkboxValues, selectAll } = this.state;
    return selectAll
      ? false
      : some(
          checkboxValues,
          v => v.value || (v.enums && some(v.enums, { value: true }))
        );
  };

  onChangeSelectAll = e => {
    const { checkboxValues } = this.state;
    const { target: { checked = false } = {} } = e;
    const values = checkboxValues.map(c => ({
      value: checked,
      enums: c.enums
        ? fill(Array(c.enums.length), { value: checked })
        : undefined,
    }));
    this.setState({ checkboxValues: values, selectAll: checked });
  };

  onClickGroup = (enums, index, array, update) => () => {
    const { checkboxValues, defaultValue } = this.state;
    let newArray;
    let newValues;
    let newDefaultValue = defaultValue;

    if (enums) {
      const isDefaultInEnums = array[index]?.enums?.some(
        item => item.value === defaultValue
      );
      if (isDefaultInEnums) {
        newDefaultValue = undefined;
      }

      newArray = this.optionsUpdater(array, index, 'enums', undefined);
      newArray = this.optionsUpdater(newArray, index, 'value', '');
      newValues = this.optionsUpdater(
        checkboxValues,
        index,
        'enums',
        undefined
      );
    } else {
      if (array[index]?.value === defaultValue) {
        newDefaultValue = undefined;
      }

      newArray = this.optionsUpdater(array, index, 'value', undefined);
      newArray = this.optionsUpdater(newArray, index, 'enums', [
        { label: '', value: '' },
      ]);
      newValues = this.optionsUpdater(checkboxValues, index, 'enums', [
        { value: false },
      ]);
    }

    if (newDefaultValue === undefined) {
      this.updateDefaultValue(newDefaultValue);
    }

    this.setState({ checkboxValues: newValues });
    update(newArray);
  };

  renderRow = (
    data,
    update,
    defaultValue,
    { parentIndex, key = 'select', maxNestedGroup = 1 } = {}
  ) => {
    return data.map(({ label, value, enums }, index, array) => (
      <Row className="select-values-row" key={`${key}-${value}-${index}`}>
        <Col sm={10}>
          <Input
            className="edit-input"
            defaultValue={label}
            maxLength={MAX_STRING_LENGTH}
            onBlur={this.onBlur(index, 'label', array, update)}
          />
        </Col>
        <Col sm={10}>
          {value === undefined ? (
            <Button
              className="button-add ant-btn-circle"
              type="default"
              size="small"
              icon="plus"
              onClick={this.addOption(array, index, enums, update)}
            />
          ) : (
            <Input
              className="edit-input"
              defaultValue={value}
              maxLength={MAX_STRING_LENGTH}
              onBlur={this.onBlur(index, 'value', array, update, parentIndex)}
            />
          )}
        </Col>
        <Col sm={3}>
          {maxNestedGroup > 0 && (
            <Button
              className="button-group ant-btn-circle"
              icon={enums ? 'tags' : 'tag-o'}
              onClick={this.onClickGroup(enums, index, array, update)}
            />
          )}
          &nbsp;
          {array.length > 1 && (
            <Checkbox
              className="checkbox-delete"
              checked={this.getCheckboxValue(index, parentIndex)}
              indeterminate={this.getIndeterminate(index, parentIndex)}
              onChange={this.onChangeCheckbox(index, parentIndex)}
            />
          )}
        </Col>

        <Col sm={1}>
          {value !== undefined && (
            <StyledCheckbox
              className="checkbox-default-value"
              checked={defaultValue !== undefined && value === defaultValue}
              disabled={
                value === undefined ||
                (defaultValue !== undefined && value !== defaultValue)
              }
              onChange={() => this.onChangeDefaultValueCheckbox(value)}
            />
          )}
        </Col>

        {enums &&
          this.renderRow(
            enums,
            newEnums => {
              const newArray = this.optionsUpdater(
                array,
                index,
                'enums',
                newEnums
              );
              update(newArray);
            },
            defaultValue,
            {
              parentIndex: index,
              key: `${key}-${label}-${index}`,
              maxNestedGroup: maxNestedGroup - 1,
            }
          )}
      </Row>
    ));
  };

  onChangeDefaultValueCheckbox = (value, isUpload = false) => {
    const { defaultValue } = this.state;
    const { updateParent, options } = this.props;

    // If no checkbox is selected, or the same checkbox is being unselected
    const newDefaultValue =
      isUpload || defaultValue !== value
        ? this.hasDuplicateDefaultValue(options, value)
          ? undefined
          : value
        : undefined;

    this.updateDefaultValue(newDefaultValue);
  };

  isSkipLine = (label, value) => {
    return label.trim() === 'Label' && value.trim() === 'Value' ? true : false;
  };

  containsDefaultValue = (option, defaultValue) => {
    if (option.value === defaultValue) {
      return true;
    }

    if (option.enums) {
      return option.enums.some(enumItem => enumItem.value === defaultValue);
    }

    return false;
  };

  hasDuplicateDefaultValue = (options = [], defaultValue) => {
    const seenValues = new Set();

    const checkForDuplicates = options => {
      for (const { value, enums } of options) {
        if (value === defaultValue) {
          if (seenValues.has(value)) {
            return true;
          }
          seenValues.add(value);
        }

        if (enums && checkForDuplicates(enums)) {
          return true; // Found a duplicate in nested enums
        }
      }

      return false; // No duplicates found
    };
    return checkForDuplicates(options);
  };

  handleValueChange = (index, newOptions, parentIndex, changedValue) => {
    const { options, defaultValue } = this.state;
    let oldValue;

    if (parentIndex === undefined) {
      oldValue = options[index]?.value;
    } else {
      oldValue = options[parentIndex]?.enums?.[index]?.value;
    }

    if (oldValue === defaultValue || changedValue === defaultValue) {
      let updatedOptions = [...options];

      if (parentIndex !== undefined) {
        updatedOptions[parentIndex] = {
          ...updatedOptions[parentIndex],
          enums: newOptions,
        };
      } else {
        updatedOptions = newOptions;
      }

      const hasDuplicate = this.hasDuplicateDefaultValue(
        updatedOptions,
        changedValue
      );

      const newDefaultValue = hasDuplicate ? undefined : changedValue;
      this.updateDefaultValue(newDefaultValue);
    }
  };

  updateDefaultValue = newDefaultValue => {
    const { updateParent } = this.props;
    this.setState({ defaultValue: newDefaultValue });
    updateParent && updateParent({ defaultValue: newDefaultValue });
  };
}
