import React from 'react';
import { Button, Modal, Input } from 'antd';
import { translate } from '../../../i18next';
const { TextArea } = Input;

const OrganizationCommentModal = ({
  addFlag,
  comment,
  loading,
  manageOuPermission,
  onCancel,
  onChange,
  onDelete,
  onSave,
  ROW_SIZE = 15,
  title,
  visible,
}) => {
  let classes, footer;
  const showDeleteCommentFlag = addFlag == false && visible;

  showDeleteCommentFlag
    ? (classes = 'pull-left')
    : (classes = 'pull-left bdm-no-display-field');

  const confirmationModal = () => {
    const deleteModalMessage = translate(
      'containers.organizationProfile.deleteModalMessage'
    );
    Modal.confirm({
      title: translate('containers.organizationProfile.removeComment'),
      content: deleteModalMessage,
      okText: translate('containers.organizationProfile.yes'),
      cancelText: 'No',
      onOk: () => onDelete(),
    });
  };

  manageOuPermission
    ? (footer = [
        <Button
          key="deleteComment"
          className={classes}
          type="danger"
          onClick={() => confirmationModal()}
          loading={loading}
          ghost
        >
          {translate('containers.organizationProfile.deleteComment')}
        </Button>,
        <Button key="Cancel" type="secondary" onClick={() => onCancel()}>
          {translate('containers.organizationProfile.cancel')}
        </Button>,
        <Button
          key="Done"
          type="primary"
          loading={loading}
          onClick={() => onSave()}
        >
          {translate('containers.organizationProfile.save')}
        </Button>,
      ])
    : (footer = [
        <Button key="Cancel" type="secondary" onClick={() => onCancel()}>
          Cancel
        </Button>,
        null,
      ]);

  return (
    <Modal
      title={title}
      visible={visible}
      width={1000}
      className="ou-comment-modal"
      centered
      closable={false}
      footer={footer}
    >
      <TextArea
        value={comment}
        rows={ROW_SIZE}
        onChange={e => onChange(e)}
      ></TextArea>
    </Modal>
  );
};

export default OrganizationCommentModal;
