import React, { Component } from 'react';
import moment from 'moment';
import {
  get,
  isEmpty,
  capitalize,
  upperFirst,
  toLower,
  startCase,
  snakeCase,
} from 'lodash';
import { Badge, Icon, Tooltip, Table, notification } from 'antd';
import ContentEditable from 'react-contenteditable';

import {
  CaseFileListSection,
  CaseFileStatusSection,
  FlexContainer,
} from './CaseFileList.style';
import caseFileEndpoints from '../../api/caseFileEndpoints/caseFileEndpoints';
import fetchReadableStream from './utils/fetchReadableStream';
import CreateCaseFileButton from './components/createCaseFileButton';
import CaseFileAdvancedFilters from './components/advancedFilters/CaseFileAdvancedFilters';

import { getCurrentUser } from '../../selectors/session';
import PageHeader from 'APP_COMPONENTS/PageHeader/PageHeader';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import getRoute from 'APP_ROOT/utils/get-route';
import { unEscapeInput } from '../../utils/request';
import { unescape as htmlUnescape } from 'html-escaper';

// Containers
import DashboardPage from '../../components/dashboard';

import parseDate, { BENCHMARK_DATE_FORMAT } from '../../utils/parse-date';
import { hasPermissions, PERMISSIONS } from '../../utils/admin';
import { FEATURES, hasFeatures } from '../../utils/features';
import EllipsisMenu from './components/EllipsisMenu';
import StatusCard from './components/StatusCard';
import ColumnsPreferences from './components/columnsPreferences/ColumnsPreferences';
import {
  PAGE_SIZE,
  TABLE_ASC,
  ASC_PARAM,
  DESC_PARAM,
  CASEFILE_STATUSES,
  STATUS_OPEN,
  STATUS_PAUSED,
  STATUS_CLOSED,
  STATUS_OVERDUE,
  STATUS_WITHOUT,
} from './constants/caseFileList';
import columnsPreferences from './preferences/columns';
import { translate } from '../../i18next';
import getUserSuccess from '../../actions/get-user-success';
import DynamicEllipsisText from './components/DynamicEllipsisText';
import DraggableHeaderColumn from './DraggableHeaderColumn';

const MENUS = {
  COLUMNS_PREFERENCES: 'columnsPreferencesVisible',
  ADVANCED_FILTERS: 'advancedFiltersVisible',
};

class CaseFileList extends Component {
  constructor(props) {
    super(props);
    const { currentUser: { featureFlags, preferences } = {} } = props;
    const { caseFiles: { columnsPreferences } = {} } = preferences;
    const enableCasefileKeyDetails = hasFeatures(
      featureFlags,
      FEATURES.enableCasefileKeyDetails
    );

    this.state = {
      loading: true,
      data: [],
      pagination: { current: 1 },
      enableCasefileKeyDetails,
      casefileStatuses: CASEFILE_STATUSES,
      filters: {},
      filterCount: 0,
      sortedInfo: null,
      columns: columnsPreferences,
      loadingColumnsPreferences: false,
      dragOver: '',
      columnOrderLoaded: false,
      columnsOrderReseted: false,
      columnsPreferencesVisible: false,
      advancedFiltersVisible: false,
    };

    this.COLUMNS = [
      {
        title: 'ID',
        dataIndex: 'casefileId',
        sorter: true,
        render: this.renderLink,
        width: '150px',
        fixed: 'left',
        onHeaderCell: ({ dataIndex }) => ({
          dataIndex,
        }),
      },
      {
        title: (
          <span id="name" className="ant-table-column-title">
            {translate('containers.caseFiles.title')}
          </span>
        ),
        dataIndex: 'name',
        sorter: true,
        render: this.renderStringEllipsis,
        width: '250px',
        onHeaderCell: this.handleOnHeaderCell,
      },
      {
        title: (
          <span id="description" className="ant-table-column-title">
            {translate('containers.caseFiles.description')}
          </span>
        ),
        dataIndex: 'description',
        sorter: true,
        render: this.renderStringEllipsis,
        width: '400px',
        onHeaderCell: this.handleOnHeaderCell,
      },
      {
        title: (
          <span id="createdAt" className="ant-table-column-title">
            {translate('containers.caseFiles.creationDate')}
          </span>
        ),
        dataIndex: 'createdAt',
        sorter: true,
        render: this.renderDate,
        width: '200px',
        onHeaderCell: this.handleOnHeaderCell,
      },
      {
        title: (
          <span id="createdByFullName" className="ant-table-column-title">
            {translate('containers.caseFiles.owner')}
          </span>
        ),
        dataIndex: 'createdByFullName',
        sorter: true,
        width: '200px',
        render: this.renderOwner,
        onHeaderCell: this.handleOnHeaderCell,
      },
    ];

    this.COLUMNS_ORDER = [];
  }

  getVisibleOrderedColumns = (columnOrderLoaded, hasBeenReset = false) => {
    const { currentUser: { preferences } = {} } = this.props;
    const { caseFiles: { initialColumnsOrder } = {} } = preferences;

    const visibleColumns = [...this.getVisibleColumns()];

    let order = [];
    if (hasBeenReset) {
      order = this.COLUMNS.map(column => column.dataIndex);
    } else if (!columnOrderLoaded) {
      order = !initialColumnsOrder
        ? this.COLUMNS.map(column => column.dataIndex)
        : [...initialColumnsOrder];
    } else {
      order = this.COLUMNS_ORDER.map(column => column.dataIndex);
    }

    if (!hasBeenReset) {
      visibleColumns.forEach(({ dataIndex }) => {
        const columnFound = order.find(column => column === dataIndex);
        if (!columnFound) {
          order.push(dataIndex);
        }
      });
    }

    const orderedColumns = [];
    order.forEach(orderedColumn => {
      const index = visibleColumns.findIndex(
        ({ dataIndex }) => orderedColumn === dataIndex
      );
      if (index !== -1) {
        orderedColumns.push(visibleColumns[index]);
      }
    });

    //sync the internal order array
    this.COLUMNS_ORDER = [...orderedColumns];
    return orderedColumns;
  };

  handleDragStart = e => {
    const { columnOrderLoaded } = this.state;
    const { id } = e.target;
    const index = [
      ...this.getVisibleOrderedColumns(columnOrderLoaded),
    ].findIndex(col => col.dataIndex === id);
    e.dataTransfer.setData('colIdx', index);
    if (!columnOrderLoaded) {
      this.setState({ columnOrderLoaded: true });
    }
  };

  handleDragEnter = e => {
    const { id } = e.target;
    this.setState({ dragOver: id });
  };

  handleOnDrop = e => {
    const { id } = e.target;
    const columns = [
      ...this.getVisibleOrderedColumns(this.state.columnOrderLoaded),
    ];

    if (!id) {
      this.setState({ dragOver: '' });
      return;
    }

    const droppedColIndex = columns.findIndex(
      column => column.dataIndex === id
    );
    const draggedColIndex = e.dataTransfer.getData('colIdx');

    this.COLUMNS_ORDER[draggedColIndex] = columns[droppedColIndex];
    this.COLUMNS_ORDER[droppedColIndex] = columns[draggedColIndex];

    this.setState({ dragOver: '', columnsOrderReseted: false });

    let visibleColumns = {};
    if (!this.state.columns || Object.keys(this.state.columns).length === 0) {
      this.COLUMNS.forEach(({ dataIndex }) => {
        visibleColumns[dataIndex] = { checked: true };
      });
    } else {
      visibleColumns = { ...this.state.columns };
    }

    /* 
      format of this array's object : { FIELD: { checked: BOOLEAN } }
      e.i: { name: { checked: false } } 
    */
    const columnsRefomatted = { casefileId: { checked: true } };

    this.COLUMNS_ORDER.forEach(({ dataIndex }) => {
      let visibilityValue = visibleColumns[dataIndex];

      if (visibilityValue) {
        columnsRefomatted[dataIndex] = visibilityValue;
      }
    });

    this.handleSaveColumnsPreferences(columnsRefomatted);
  };

  handleOnHeaderCell = ({ dataIndex }) => ({
    dataIndex,
    onDragEnter: this.handleDragEnter,
    onDragStart: this.handleDragStart,
    onDrop: this.handleOnDrop,
    dragOver: dataIndex === this.state.dragOver,
  });

  renderArray = array => {
    if (array && array.length > 1) {
      return <EllipsisMenu elements={array} />;
    }
    return htmlUnescape(array);
  };

  renderLink = (name, record) => {
    const { id } = record;
    const route = getRoute('openCaseFile', { id });
    const label = name || 'CF';
    return <a href={route}>{label}</a>;
  };

  renderDate = date => {
    const { timezone } = this.props;
    return parseDate(date, timezone, BENCHMARK_DATE_FORMAT, false);
  };

  renderOwner = (value, data) => {
    return (
      <span
        className="ant-form-text ant-form-text-field"
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {data && !data.ownerHasManageCasefilesPermission ? (
          <>
            {this.renderString(value)}
            <Tooltip
              title={translate('containers.caseFiles.permissionsMessage')}
            >
              <Icon type="warning" style={{ fontSize: '16px' }} />
            </Tooltip>
          </>
        ) : (
          this.renderString(value)
        )}
      </span>
    );
  };

  renderString = str => <ContentEditable html={str} disabled={true} />;

  renderStringEllipsis = str => <DynamicEllipsisText text={str} />;

  renderDeadline = (deadline, record) => {
    if (!deadline) {
      return '';
    }

    const { overdue } = record;

    if (overdue) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="due-date-error-color">{deadline}</span>
          <Icon
            type="exclamation-circle"
            theme="filled"
            className="casefile-icon-due-date due-date-error-color"
          />
        </div>
      );
    } else {
      return deadline;
    }
  };

  renderStatus = status =>
    !status || !status.label
      ? ''
      : htmlUnescape(translate(`containers.caseFiles.${status.label}`));

  setSortingTrackingToColumns = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    for (const column in this.COLUMNS) {
      this.COLUMNS[column].sortOrder =
        sortedInfo.columnKey === this.COLUMNS[column].dataIndex &&
        sortedInfo.order;
    }
  };

  componentDidMount() {
    this.enableKeyDetailsColumns();
    this.fetchCasefileStatuses();
    this.fetchData();
  }

  fetchCasefileStatuses = filters => {
    const {
      currentUser: { userIntegrationId },
    } = this.props;
    caseFileEndpoints
      .getCasefileStatistics(userIntegrationId, filters)
      .then(response => {
        const {
          open = {},
          paused = {},
          closed = 0,
          overdue = 0,
          without_status = 0,
          ...rest
        } = response;
        const casefileStatuses = {
          open,
          paused,
          closed,
          overdue,
          without_status,
          ...rest,
        };
        this.setState({ casefileStatuses });
      })
      .catch(error => this.handleError(error));
  };

  enableKeyDetailsColumns = () => {
    const { enableCasefileKeyDetails } = this.state;
    if (enableCasefileKeyDetails) {
      const keyDetailsColumns = [
        {
          title: (
            <span id="individuals" className="ant-table-column-title">
              {translate('containers.caseFiles.individuals')}
            </span>
          ),
          dataIndex: 'individuals',
          sorter: false,
          render: this.renderArray,
          width: '200px',
          onHeaderCell: this.handleOnHeaderCell,
        },
        {
          title: (
            <span id="outcomes" className="ant-table-column-title">
              {translate('containers.caseFiles.outcomesOthers')}
            </span>
          ),
          dataIndex: 'outcomes',
          render: this.renderArray,
          width: '200px',
          onHeaderCell: this.handleOnHeaderCell,
        },
        {
          title: (
            <span id="reasons" className="ant-table-column-title">
              {translate('containers.caseFiles.reasonsOthers')}
            </span>
          ),
          dataIndex: 'reasons',
          render: this.renderArray,
          width: '200px',
          onHeaderCell: this.handleOnHeaderCell,
        },
        {
          title: (
            <span id="deadline" className="ant-table-column-title">
              {translate('containers.caseFiles.deadline')}
            </span>
          ),
          dataIndex: 'deadline',
          sorter: true,
          render: this.renderDeadline,
          width: '200px',
          onHeaderCell: this.handleOnHeaderCell,
        },
        {
          title: (
            <span id="status" className="ant-table-column-title">
              {translate('containers.caseFiles.status')}
            </span>
          ),
          dataIndex: 'status',
          sorter: true,
          render: this.renderStatus,
          width: '150px',
          onHeaderCell: this.handleOnHeaderCell,
        },
      ];
      keyDetailsColumns.forEach(column => this.COLUMNS.push(column));
    }
  };

  handleTableChange = (pagination, _, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState({
      pagination: pager,
      sortedInfo: sorter,
    });
    this.fetchData(
      pagination.current,
      sorter.field,
      sorter.order,
      this.state.filters
    );
  };

  fetchData = (currentPage = 1, sortBy, sortDirection, filters) => {
    const {
      currentUser: { userIntegrationId },
    } = this.props;
    caseFileEndpoints
      .getCasefileList(
        userIntegrationId,
        currentPage,
        PAGE_SIZE,
        sortBy,
        sortDirection === TABLE_ASC ? ASC_PARAM : DESC_PARAM,
        filters
      )
      .then(data => {
        this.loadData(unEscapeInput(data));
      })
      .catch(error => this.handleError(error));
  };

  handleError = error => {
    if (error?.response?.body instanceof ReadableStream) {
      fetchReadableStream(error.response.body, this.loadData);
    } else {
      this.showNotification(
        translate('containers.caseFiles.error'),
        translate('containers.caseFiles.somethingWentWrong'),
        error?.error || error?.message || ''
      );
    }
  };

  loadData = ({ success, content, errors, error, message }) => {
    if (success) {
      const { data, page } = content;
      const pagination = {
        total: page.totalRecords,
        currentPage: page.current,
      };
      const formattedData = data.map(caseFile => {
        if (!caseFile.deadline) return caseFile;
        const transformedDeadline = moment
          .utc(caseFile.deadline)
          .format(BENCHMARK_DATE_FORMAT);
        return {
          ...caseFile,
          deadline: transformedDeadline,
        };
      });
      this.setState({ data: formattedData, pagination });
    } else {
      const errorMessage = isEmpty(errors)
        ? error || message
        : errors.map(e => capitalize(e.message)).join(', ');
      this.showNotification(
        translate('containers.caseFiles.error'),
        translate('containers.caseFiles.somethingWentWrongLoadingCaseFiles'),
        errorMessage
      );
    }
    this.setDoneLoading();
  };

  setDoneLoading = () => {
    this.setState({ loading: false });
  };

  showNotification = (type, message, description) =>
    notification[type]({ message, description });

  getFormattedStatuses = () => {
    const { casefileStatuses } = this.state;

    const subOpen = casefileStatuses
      ? Object.entries(casefileStatuses[STATUS_OPEN]).map(entry => ({
          name: decodeURIComponent(entry[0]),
          value: entry[1],
        }))
      : [];

    const subPaused = casefileStatuses
      ? Object.entries(casefileStatuses[STATUS_PAUSED]).map(entry => ({
          name: decodeURIComponent(entry[0]),
          value: entry[1],
        }))
      : [];

    const open = {
      name: upperFirst(STATUS_OPEN),
      value: subOpen?.reduce((acc, curr) => {
        return acc + curr.value;
      }, 0),
      subStatuses: subOpen,
    };

    const paused = {
      name: upperFirst(STATUS_PAUSED),
      value: subPaused?.reduce((acc, curr) => {
        return acc + curr.value;
      }, 0),
      subStatuses: subPaused,
    };

    const statuses = [
      open,
      paused,
      {
        name: upperFirst(STATUS_CLOSED),
        value: casefileStatuses[STATUS_CLOSED],
        subStatuses: [],
      },
      {
        name: upperFirst(STATUS_OVERDUE),
        value: casefileStatuses[STATUS_OVERDUE],
        subStatuses: [],
      },
    ];

    if (casefileStatuses[STATUS_WITHOUT] > 0) {
      statuses.push({
        name: startCase(toLower(STATUS_WITHOUT)),
        value: casefileStatuses[STATUS_WITHOUT],
        subStatuses: [],
      });
    }

    return statuses;
  };

  get headerActions() {
    const { history, currentUser: { permissions } = {} } = this.props;
    let actions = [];
    if (
      hasPermissions(permissions, [
        PERMISSIONS.manageAllCasefiles,
        PERMISSIONS.manageMyCasefiles,
      ])
    ) {
      actions.push(CreateCaseFileButton(history));
    }
    return actions;
  }

  resetAdvancedFilters = () => {
    this.setState({
      filters: {},
      filterCount: 0,
      sortedInfo: null,
      pagination: { current: 1 },
    });
    this.fetchCasefileStatuses();
    this.fetchData();
  };

  updateTableAndCardData = filters => {
    // `Creation Date` and `Deadline` has two filters each, so we need to remove one of the dates from the count
    const filterCount = filters
      ? Object.keys(filters).filter(
          f => f !== 'createdAtEnd' && f !== 'deadlineEnd'
        ).length
      : 0;
    this.setState({
      filters,
      filterCount,
      sortedInfo: null,
      pagination: { current: 1 },
    });
    // eslint-disable-next-line no-undef
    this.fetchData(1, 'casefileId', _, filters);
    this.fetchCasefileStatuses(filters);
  };

  handleSaveColumnsPreferences = columns => {
    const { saveColumns } = columnsPreferences;
    this.setState({
      loadingColumnsPreferences: true,
      columnsOrderReseted: false,
    });
    saveColumns(columns).then(newColumns => {
      this.updateUserColumnsPreferences(newColumns);
      this.setState({ columns, loadingColumnsPreferences: false });
    });
  };

  handleResetColumnsPreferences = () => {
    const { resetColumns } = columnsPreferences;
    this.setState({ loadingColumnsPreferences: true });
    resetColumns().then(newColumns => {
      const columns = {};
      this.COLUMNS_ORDER = [];

      this.COLUMNS.forEach(col => {
        columns[col.dataIndex] = { checked: true };
        this.COLUMNS_ORDER.push(col);
      });

      this.updateUserColumnsPreferences(columns);
      this.setState({
        columns,
        loadingColumnsPreferences: false,
        columnsOrderReseted: true,
      });
    });
  };

  updateUserColumnsPreferences = newColumns => {
    const { dispatch, currentUser } = this.props;
    if (newColumns) {
      dispatch(
        getUserSuccess(currentUser.userId, {
          ...currentUser,
          preferences: {
            ...currentUser.preferences,
            caseFiles: {
              ...currentUser.preferences.caseFiles,
              columnsPreferences: { ...newColumns },
              initialColumnsOrder: Object.keys(newColumns),
            },
          },
        })
      );
    }
  };

  getVisibleColumns = () => {
    const { columns } = this.state;
    if (!columns || isEmpty(columns)) {
      return this.COLUMNS;
    }

    const checkedColumns = Object.entries(columns)?.filter(
      ([_, value]) => value.checked === true
    );
    const visibleColumns = this.COLUMNS.filter(col => {
      const { dataIndex } = col;
      const columnsObject = checkedColumns.reduce((prev, curr) => {
        const [key, value] = curr;
        return {
          ...prev,
          [key]: value,
        };
      }, {});
      const currentCol = columnsObject[dataIndex];
      if (currentCol && currentCol.checked) {
        return true;
      }
      return false;
    });

    return visibleColumns;
  };

  handleToggleMenu = (menuName, flag) => {
    const otherMenus = Object.values(MENUS)
      .filter(key => key !== menuName)
      .reduce((prev, curr) => {
        return { ...prev, [curr]: false };
      }, {});
    this.setState({
      ...otherMenus,
      [menuName]: flag,
    });
  };

  render() {
    const {
      loading,
      data,
      pagination,
      enableCasefileKeyDetails,
      filterCount,
      columns,
      loadingColumnsPreferences,
      columnOrderLoaded,
      columnsOrderReseted,
      columnsPreferencesVisible,
      advancedFiltersVisible,
    } = this.state;
    const {
      currentUser: { organizationUnit: { tenantId } = {}, agency } = {},
    } = this.props;
    const formattedStatuses = this.getFormattedStatuses();

    this.setSortingTrackingToColumns();

    const visibleColumns = this.getVisibleOrderedColumns(
      columnOrderLoaded,
      columnsOrderReseted
    );

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          marginBottom: '45px',
        }}
      >
        <PageHeader
          title={translate('containers.caseFiles.caseFiles')}
          history={history}
          actions={this.headerActions}
        />
        <CaseFileStatusSection className="case-file-status-section">
          {enableCasefileKeyDetails &&
            formattedStatuses.map((status, index) => (
              <StatusCard
                key={`status-card-${index}-${status.name}-${status.value}`}
                title={translate(`containers.caseFiles.${status.name}`)}
                count={status.value}
                loading={loading}
                theme={snakeCase(status.name)}
                subStatuses={status.subStatuses}
              />
            ))}
          <FlexContainer>
            <ColumnsPreferences
              loading={loadingColumnsPreferences}
              visible={columnsPreferencesVisible}
              enableCasefileKeyDetails={enableCasefileKeyDetails}
              selectedColumns={columns}
              onReset={this.handleResetColumnsPreferences}
              onApply={this.handleSaveColumnsPreferences}
              onClick={flag =>
                this.handleToggleMenu(MENUS.COLUMNS_PREFERENCES, flag)
              }
            />
            <Badge
              count={filterCount}
              style={{
                backgroundColor: '#fff',
                color: '#0012DD',
                borderColor: '#0012DD',
                lineHeight: '22px',
              }}
            >
              <CaseFileAdvancedFilters
                agencyId={agency.id}
                tenantId={tenantId}
                visible={advancedFiltersVisible}
                setTableData={this.updateTableAndCardData}
                enableCasefileKeyDetails={enableCasefileKeyDetails}
                onClick={flag =>
                  this.handleToggleMenu(MENUS.ADVANCED_FILTERS, flag)
                }
                onReset={this.resetAdvancedFilters}
              />
            </Badge>
          </FlexContainer>
        </CaseFileStatusSection>
        <CaseFileListSection className="case-file-list-section">
          <Table
            columns={visibleColumns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={this.handleTableChange}
            scroll={{ x: '100%' }}
            components={{ header: { cell: DraggableHeaderColumn } }}
          />
        </CaseFileListSection>
      </div>
    );
  }
}

const mapState = (state, props) => {
  const timezone = get(props, 'agency.timezone');
  return {
    timezone,
    currentUser: getCurrentUser(state),
  };
};

export default withoutClutter(DashboardPage(mapState)(CaseFileList));
