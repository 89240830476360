import React from 'react';
import openModal from '../utils/onShareButtonClick';
import { ButtonActionsWrapper } from '../CaseFile.styled';
import { translate } from '../../../i18next';

const ShareButton = props => {
  const { isCasefileClosed } = props;
  return (
    <ButtonActionsWrapper
      icon="user-add"
      type="icon"
      shape="circle"
      title={translate('containers.caseFiles.share')}
      tooltipMessage={translate('containers.caseFiles.shareFile')}
      onClick={() => openModal(isCasefileClosed)}
    />
  );
};

export default ShareButton;
