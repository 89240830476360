import React, { Component } from 'react';
import { Row, Col, Tag } from 'antd';
import { castArray, get, omit } from 'lodash';
import { connect } from 'react-redux';
import { DragSource } from 'react-dnd';

import withModal from 'APP_COMPONENTS/common/modal/base';
import IconButton from 'APP_COMPONENTS/common/buttons/icon-button';
import { COLUMN } from 'APP_ROOT/constants/layoutComponentTypes';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';

import PickableZone from '../PickableZone';
import Styled from './FieldGroupEditor.styled';
import buildOptionsModalContent from '../../utils/buildOptionsModalContent';
import componentsManager from '../../services/componentsManager';
import {
  TAB_VALUES,
  TAB_CONDITIONAL,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
  TAB_DATE_BOUNDARY,
} from '../OptionsModalBody/OptionsModalBody';

class FieldGroupEditor extends withModal(Component) {
  MAX_SUPPORTED_FIELDS = 2;

  constructor(props) {
    super(props);

    this.state = {
      field: props.field,
      values: props.field,
    };
  }

  componentDidMount() {
    this.createModal();
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(this.props, nextProps);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      field: props.field,
      values: props.field,
    };
  }

  showOptionsModal = () => {
    const { values } = this.state;
    const { field } = this.props;

    const fieldsOptions = {
      type: values.type,
      title: {
        type: 'text',
        label: 'Title',
        value: values.title || '',
        maxLength: 100,
        placeholder: 'Title',
      },
      disableTab: [
        TAB_VALUES,
        TAB_CONDITIONAL,
        TAB_SHARE_KEY,
        TAB_INFORMATION,
        TAB_DATE_BOUNDARY,
      ],
    };

    const modalData = buildOptionsModalContent({
      ...fieldsOptions,
      onCancel: this.deleteModal,
      onDelete: () => {
        this.remove();
        this.deleteModal();
      },

      onSave: params => {
        const modifiedValues = {
          ...values,
          ...omit(params, ['title']),
          title: params.title.value,
        };

        this.setState({
          values: modifiedValues,
        });

        componentsManager.editComponent(field.id, {
          ...omit(modifiedValues, ['type']),
        });

        this.deleteModal();
      },
    });

    this.updateModal(modalData);

    this.showModal();
  };

  getValue = (name, defaultValue) =>
    get(this, `state.values.${name}`, defaultValue) || defaultValue;

  remove = () => {
    const { field } = this.props;

    componentsManager.removeComponent(field.id);
  };

  render() {
    const {
      children,
      id,
      connectDragSource,
      connectDragPreview,
      isDragging,
    } = this.props;
    const childs = castArray(children);
    const fillChild = Array.from(
      Array(this.MAX_SUPPORTED_FIELDS - childs.length),
      (_, idx) => idx
    ).map(() => null);

    return connectDragPreview(
      <div>
        <Styled className={`${isDragging ? 'is-dragging' : ''}`}>
          {connectDragSource(
            <div className="field-group--header">
              <Row className="field-group--type">
                <Tag>FIELD GROUP</Tag>
              </Row>
              <Row>
                <span className="field-group--actions">
                  <IconButton
                    icon="setting"
                    onClick={this.showOptionsModal}
                    className="is-big is-focusable"
                  />
                </span>
                <span className="field-group--title">
                  <span>{this.getValue('title', 'Untitled')}</span>
                </span>
              </Row>
              <hr />
            </div>
          )}
          <Row className="field-group--detail">
            {childs.concat(fillChild).map((child, idx) => (
              <Col span={12} key={idx}>
                <PickableZone type={COLUMN} id={id} allowSingleChildrenOnly>
                  {child
                    ? React.cloneElement(child, {
                        compact: true,
                        fieldGroup: true,
                      })
                    : child}
                </PickableZone>
              </Col>
            ))}
          </Row>
        </Styled>
      </div>
    );
  }
}

export default connect()(
  DragSource(
    params => params.type,
    {
      beginDrag: param => param.field,
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    })
  )(FieldGroupEditor)
);
