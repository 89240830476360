import styled from 'styled-components';

const Badge = styled.div`
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 18px;
  border-radius: 18px;
  color: #121212;
  font-size: 16px;
  font-weight: 600;
  background-color: ${props => props.bgColor};
`;

export default Badge;
