import React, { useEffect, useState } from 'react';
import { Badge, Icon, Menu, Spin } from 'antd';
import { isEmpty, isNumber, debounce } from 'lodash';
import { translate } from '../../../../i18next';

import {
  Wrapper,
  Select,
  Selection,
  Selected,
  SearchWrapper,
  Clear,
  Placeholder,
  MultiselectMenu,
  MenuContainer,
  MenuContent,
} from './Multiselect.styled';

const Multiselect = ({
  identifier = 'multiselect',
  loading = false,
  selectedItems = [],
  options = [],
  placeholder = '',
  onBlur = () => {},
  onFocus = () => {},
  onChange = () => {},
  onSearch = () => {},
  notFoundContent = 'Not found',
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selection, setSelection] = useState([]);
  const [focused, setFocused] = useState(false);
  const [componentDidMount, setComponentDidMount] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [mouseInMenuRange, setMouseInMenuRange] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [applyingChange, setApplyingChange] = useState(false);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);

  useEffect(() => {
    setComponentDidMount(true);
  }, []);

  useEffect(() => {
    if (selectedItems && !isEmpty(selectedItems)) {
      setSelection(selectedItems);
    }
  }, [selectedItems]);

  useEffect(
    debounce(() => {
      if (componentDidMount) {
        setApplyingChange(true);
        onSearch(search, () => setApplyingChange(false));
      }
    }, 800),
    [search]
  );

  const scrollToElement = element => {
    element.scrollIntoView({
      behavior: 'instant',
      block: 'end',
      inline: 'nearest',
    });
  };

  const toggleMenu = () => {
    setOpen(prevState => !prevState);
    document.getElementById(`${identifier}-searchInput`).focus();
    setFocused(true);
    if ((selection && isEmpty(selection)) || (options && isEmpty(options))) {
      setCurrentMenuItem(null);
      onFocus();
    }
  };

  const handleBlur = () => {
    if (!mouseInMenuRange) {
      setCurrentMenuItem(null);
      setFocused(false);
      setOpen(false);
      onBlur();
    } else {
      document.getElementById(`${identifier}-searchInput`).focus();
    }
  };

  const handleClear = () => {
    if (
      showIcon &&
      !loading &&
      ((selection && !isEmpty(selection)) || search)
    ) {
      setCurrentMenuItem(null);
      setSearch('');
      setSelection([]);
      handleChange([]);
      handleBlur();
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Escape' && open) {
      setCurrentMenuItem(null);
      setFocused(false);
      setOpen(false);
      onBlur();
      return;
    }

    if (applyingChange || loading) return;

    switch (e.key) {
      case 'Backspace':
        if (!search || (isEmpty(search) && selection && !isEmpty(selection))) {
          const newSelection = selection;
          newSelection.pop();
          setSelection(newSelection);
          handleChange(newSelection);
        }
        break;
      case 'Enter':
        if (isNumber(currentMenuItem)) {
          e.preventDefault();
          const { id, label } = options[currentMenuItem];
          const newItem = { id, label };
          const newSelection = selection;
          const newItemIndex = selection.findIndex(
            item => item.id === newItem.id
          );
          if (newItemIndex === -1) {
            newSelection.push(newItem);
          } else {
            newSelection.splice(newItemIndex, 1);
          }
          setSelection(newSelection);
          handleChange(newSelection);
        }
        break;
      case 'ArrowDown':
        if (options && !isEmpty(options)) {
          let index = 0;
          if (isNumber(currentMenuItem)) {
            if (currentMenuItem < options.length - 1) {
              index = currentMenuItem + 1;
            } else {
              index = 0;
            }
          }
          setCurrentMenuItem(index);
          scrollToElement(
            document.getElementById(`${identifier}-option-${index}`)
          );
        }
        break;
      case 'ArrowUp':
        if (options && !isEmpty(options)) {
          let index = options.length - 1;
          if (isNumber(currentMenuItem)) {
            if (currentMenuItem > 0) {
              index = currentMenuItem - 1;
            } else {
              index = options.length - 1;
            }
          }
          setCurrentMenuItem(index);
          scrollToElement(
            document.getElementById(`${identifier}-option-${index}`)
          );
        }
        break;
    }
  };

  const handleChange = newSelection => {
    setApplyingChange(true);
    onChange(newSelection, () => {
      if (search && !isEmpty(search)) {
        setSearch('');
      } else {
        setApplyingChange(false);
      }
    });
  };

  const handleClick = e => {
    const newItem = { id: e.key, label: e.item.props.title };
    const newSelection = selection;
    const newItemIndex = selection.findIndex(item => item.id === newItem.id);
    if (newItemIndex === -1) {
      newSelection.push(newItem);
    } else {
      newSelection.splice(newItemIndex, 1);
    }
    setSelection(newSelection);
    handleChange(newSelection);
  };

  const handleDeselect = deselected => {
    const newSelection = selection;
    const itemIndex = selection.findIndex(item => item.id === deselected);
    newSelection.splice(itemIndex, 1);
    setSelection(newSelection);
    handleChange(newSelection);
  };

  const handleSearch = event => {
    if (!open) {
      toggleMenu();
    }
    const newSearch = event.target.value;
    setCurrentMenuItem(null);
    setSearch(newSearch);
  };

  const renderOptions = () => {
    return options.map(({ id, label }, index) => {
      let classes = '';

      if (hoveredItem === id || currentMenuItem === index) {
        classes += 'menu-item-hover ';
      } else if (selection.findIndex(item => item.id === id) > -1) {
        classes += 'ant-menu-item-selected ';
      }

      if (selection.findIndex(item => item.id === id) > -1) {
        classes += 'font-weight-bold';
      }
      const opacity =
        hoveredItem === id || selection.map(item => item.id).includes(id)
          ? '1'
          : '0';
      return (
        <Menu.Item
          id={`${identifier}-option-${index}`}
          key={id}
          title={label}
          onMouseEnter={() => setHoveredItem(id)}
          onMouseLeave={() => setHoveredItem(null)}
          className={classes}
          disabled={applyingChange || loading}
        >
          <MenuContainer>
            <span>{translate(`containers.caseFiles.${label}`)}</span>
            <Icon type="check" style={{ opacity }} />
          </MenuContainer>
        </Menu.Item>
      );
    });
  };

  const renderBadges = () => {
    if (selection && selection.length > 3) {
      const firstThree = selection.slice(0, 3);
      const plusCount = selection.length - 3;
      return (
        <>
          {firstThree.map(item => (
            <Selected
              key={`${item.id}-badge`}
              title={item.label}
              unselectable="on"
              role="presentation"
            >
              <Badge title={item.label}>
                <span>{item.label}</span>
                <Icon type="close" onClick={() => handleDeselect(item.id)} />
              </Badge>
            </Selected>
          ))}
          <Selected
            title={`+${plusCount} selected items`}
            unselectable="on"
            role="presentation"
          >
            <Badge className="plusBadge" title={`+${plusCount}`}>
              <span>{`+${plusCount}`}</span>
            </Badge>
          </Selected>
        </>
      );
    } else {
      return selection.map(item => (
        <Selected
          key={`${item.id}-badge`}
          title={item.label}
          unselectable="on"
          role="presentation"
        >
          <Badge title={item.label}>
            <span>{item.label}</span>
            <Icon type="close" onClick={() => handleDeselect(item.id)} />
          </Badge>
        </Selected>
      ));
    }
  };

  const renderSearch = () => (
    <SearchWrapper>
      <input
        id={`${identifier}-searchInput`}
        type="text"
        value={search}
        onChange={handleSearch}
        onBlur={() => {
          if (!mouseInMenuRange) {
            setSearch('');
          }
        }}
        onKeyDown={e => handleKeyPress(e)}
        autoComplete="off"
      />
    </SearchWrapper>
  );

  return (
    <Wrapper
      id={`${identifier}-wrapper`}
      className={focused ? 'focused' : ''}
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      onBlur={handleBlur}
    >
      <Select onClick={toggleMenu}>
        <Selection className={focused ? 'focused' : ''}>
          {!search && isEmpty(selection) && (
            <Placeholder data-testid="placeholder" unselectable="on">
              {placeholder}
            </Placeholder>
          )}
          <ul>
            {selection && !isEmpty(selection) && renderBadges()}
            {renderSearch()}
          </ul>
        </Selection>
        <Clear
          style={{
            opacity: showIcon || loading ? '1' : '0',
            cursor:
              showIcon &&
              !loading &&
              ((selection && !isEmpty(selection)) || search)
                ? 'pointer'
                : 'text',
          }}
          onClick={handleClear}
        >
          {loading && <Icon type="loading" />}
          {showIcon &&
            !loading &&
            ((selection && !isEmpty(selection)) || search) && (
              <Icon type="close-circle" theme="filled" />
            )}
        </Clear>
      </Select>
      <MultiselectMenu
        id={`${identifier}-menu`}
        multiple
        style={{ display: open ? 'block' : 'none' }}
        selectedKeys={selection}
        onClick={e => handleClick(e)}
        onMouseEnter={() => setMouseInMenuRange(true)}
        onMouseLeave={() => setMouseInMenuRange(false)}
      >
        {options && !isEmpty(options) ? (
          renderOptions()
        ) : (
          <MenuContent>
            <span className="not-found-content">
              {loading ? <Spin size="small" /> : notFoundContent}
            </span>
          </MenuContent>
        )}
      </MultiselectMenu>
    </Wrapper>
  );
};

export default Multiselect;
