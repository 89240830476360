/**
 * Contains all the functions to build the timeline items for all the contribute to report actions
 */
import React from 'react';

import { parseHtmlInput } from 'APP_ROOT/utils/request';

import parsedDate from '../../utils/parse-date';
import {
  SECTION_ASSIGN_ACTION,
  SECTION_UNASSIGN_ACTION,
  SECTION_COMPLETE_ACTION,
  SECTION_INCOMPLETE_ACTION,
  CONTRIBUTOR_ASSIGNMENT_SECTION,
} from '../../constants/contributeToReport';

/**
 * Returns the appropriate wording action based on timeline action
 * @param {String} action
 * @returns {String}
 */
const getContributeAction = (action, customTimeline) => {
  const custom = customTimeline === CONTRIBUTOR_ASSIGNMENT_SECTION;
  switch (action) {
    case SECTION_ASSIGN_ACTION:
      return `assigned ${custom ? 'this' : 'a'} section to`;
    case SECTION_UNASSIGN_ACTION:
      return `unassigned ${custom ? 'this' : 'a'} section from`;
    case SECTION_COMPLETE_ACTION:
      return `marked ${custom ? 'this' : 'their assigned'} section complete`;
    case SECTION_INCOMPLETE_ACTION:
      return `marked ${custom ? 'this' : 'their assigned'} section incomplete`;
    default:
      return '';
  }
};

const getSubmissionMessage = (action, actor, subject, customTimeline) => {
  const messageBaseText = getContributeAction(action, customTimeline);
  let message = '';
  switch (action) {
    case SECTION_ASSIGN_ACTION:
      message = `${actor.rankName} ${actor.fullName} ${messageBaseText} ${subject.rankName} ${subject.fullName}`;
      break;
    case SECTION_UNASSIGN_ACTION:
      message = `${actor.rankName} ${actor.fullName} ${messageBaseText} ${subject.rankName} ${subject.fullName}`;
      break;
    case SECTION_COMPLETE_ACTION:
      message = `${subject.rankName} ${subject.fullName} ${messageBaseText}`;
      break;
    case SECTION_INCOMPLETE_ACTION:
      message = `${subject.rankName} ${subject.fullName} ${messageBaseText}`;
      break;
    default:
      message = '';
  }
  return message.trim();
};

const getParticipantDetails = (participants, id) => {
  const participant = participants[id] || {};
  return {
    fullName: participant.fullName || '',
    rankName: participant.rank?.name || '',
  };
};

const contributeReportItem = ({
  item,
  index,
  timezone,
  participants,
  customTimeline,
}) => {
  const actor = getParticipantDetails(participants, item.assignatId);
  const subject = getParticipantDetails(participants, item.assignedId);
  const submissionMessage = getSubmissionMessage(
    item.action,
    actor,
    subject,
    customTimeline
  );
  return (
    <div>
      <p className={`timeline-item-date-${index}`}>
        {parsedDate(item.createdAt, timezone)}
      </p>
      <p>
        <b>{` ${item.formType || ''} ${item.formNumber || ''} `}</b>
      </p>
      <p className={`timeline-item-message-${index}`}>
        {parseHtmlInput(submissionMessage)}
      </p>
    </div>
  );
};

export default contributeReportItem;
