import React, { Component } from 'react';

import { Col, Pagination } from 'antd';
import {
  has,
  kebabCase,
  uniqBy,
  find,
  reverse,
  get,
  castArray,
  uniq,
} from 'lodash';

import { FEATURES, hasFeatures } from 'APP_ROOT/utils/features';

// Actions
import getReports from '../../actions/get-reports';
// Containers
import DashboardPage from '../../components/dashboard';
// Components
import ReportCard from '../../components/report-card';
import FormFilter from '../../components/common/form-filter';
import setPageRetention from './utils/setPageRetention';
import getPageRetention from './utils/getPageRetention';
import ReportsHeaderUtilities from './ReportsHeaderUtilities';

import { getAgencyTZ } from '../../selectors/session';
import emitter, { EventTypes } from '../../utils/eventEmitter';
import retrieveAgencyTemplates from '../../actions/utils/get-form/retrieveAgencyTemplates';
import getReportsTemplates from '../../actions/get-reports-templates';
import { compileFilterForIntakeStatus } from 'APP_ROOT/components/common/form-filter/filters/intake-status';
import resetOnBehalf from '../../actions/reset-onBehalf';

import {
  StyledNoResults,
  StyledRow,
  StyledRowPagination,
} from './Reports.styled';
import setReportListFilters from '../../actions/set-report-list-filters';
import withoutClutter from '../../components/without-clutter';
import { translate } from '../../i18next';

import ReportsTable from './ReportsTable';

// IMPORTANT:
// This page code is TRIPLICATED, any change here should be
// done in this replicated in the 3 pages:
// src/containers/reports/index.js
// src/containers/administrator/agency-reports/Reports/ListOfReports.js
// src/containers/reports/uiWithRefactoredEndpoint.js

class Reports extends Component {
  state = {
    formType: '',
    mainFilter: [],
    dataFilter: [],
    keywords: '',
    view: '',
    delayCompleted: false,
    loadingTemplates: true,
    isActivateTenantReport: false,
  };

  constructor(props) {
    super(props);

    const {
      dispatch,
      history: {
        location: { state: { fromReport = false } = {} },
      },
    } = props;

    if (!fromReport) {
      dispatch(setReportListFilters({ fromReport: false }));
    }
  }

  static getPageConfig() {
    return {
      title: 'Reports',
    };
  }

  async componentDidMount() {
    const { dispatch, templates } = this.props;

    const templatesFilter = {
      where: {
        hasWorkflow: { neq: false },
        hasReports: { neq: false },
        isNote: { neq: true },
        isPresentation: { neq: true },
      },
    };

    await retrieveAgencyTemplates({
      dispatch,
      fallback: templates,
      shouldFetch: () => true,
      filter: templatesFilter,
    });

    this.setState({ loadingTemplates: false });

    await this.waitAtLeastPromise();
    this.setState({ delayCompleted: true });
  }

  waitAtLeastPromise = (ms = 1000) =>
    new Promise(resolve => {
      global.setTimeout(resolve, ms);
    });

  onNewReportClick = () => {
    const { dispatch } = this.props;
    dispatch(resetOnBehalf());
    emitter.emit(EventTypes.CREATE_REPORT);
  };

  getReportsTemplate = (error, reports) => {
    const { templates, dispatch } = this.props;
    const templatesArray = Object.values(templates);

    if (!error) {
      const templateList = reports.map(
        ({ formType, templateId, templateVersionId }) => ({
          formType,
          templateId,
          templateVersionId,
          exists:
            has(templates, kebabCase(formType)) &&
            find(templatesArray, item => item.versionId === templateVersionId),
        })
      );

      const nonExistentTemplates = templateList
        .filter(templateItem => !templateItem.exists)
        .map(({ templateId }) => templateId);

      if (nonExistentTemplates.length) {
        const filteredTemplates = templatesArray.filter(({ id: templateId }) =>
          nonExistentTemplates.includes(templateId)
        );

        dispatch(
          getReportsTemplates({
            getSchemaFor: filteredTemplates,
            withTemplates: templatesArray,
          })
        );
      }
    }
  };

  //function which update Report Component state without calling formSubmissions endpoint
  updateOnlyStateFilters = props => {
    this.setState(props);
  };

  updateStateFilters = (
    mainFilters = [],
    dataFilters = [],
    keywords,
    clearDataFilters = false,
    view
  ) => {
    const {
      mainFilter,
      dataFilter,
      keywords: keywordsFilter,
      view: viewFilter,
    } = this.state;

    const uniqMainFilter = uniqBy(
      reverse([...mainFilter, ...mainFilters]),
      'column'
    );
    const uniqDataFilter = uniqBy(
      reverse([...dataFilter, ...dataFilters]),
      'column'
    );
    const { type: formTypeValue = '' } =
      find(uniqMainFilter, ['column', 'formType']) || {};

    this.setState(
      {
        formType: formTypeValue,
        mainFilter: uniqMainFilter,
        dataFilter: clearDataFilters
          ? uniqDataFilter.filter(x => x.column === 'submitted_at')
          : uniqDataFilter,
        keywords: keywords !== undefined ? keywords : keywordsFilter,
        view: view !== undefined ? view : viewFilter,
      },
      this.updateFilters
    );
  };

  clearFilterColumn = (filterName, columnNames) => {
    const { formType } = this.state;

    const columns = castArray(columnNames);
    const currentFilter = castArray(get(this.state, filterName, []));

    const cleanedFilter = currentFilter.filter(
      filter => !columns.includes(filter.column)
    );

    this.setState(
      {
        [filterName]: cleanedFilter,
        formType: columns.includes('formType') ? '' : formType,
      },
      this.updateFilters
    );
  };

  clearKeywords = () => {
    this.setState(
      {
        keywords: '',
      },
      this.updateFilters
    );
  };

  updateFilters = async () => {
    const { dispatch, history } = this.props;
    const {
      mainFilter: uncompileMainFilter,
      dataFilter,
      keywords = '',
      view = '',
    } = this.state;

    const currentPage = getPageRetention(history);
    const mainFilter = compileFilterForIntakeStatus(uncompileMainFilter);
    dispatch(
      setReportListFilters({
        mainFilter: mainFilter,
        dataFilter: dataFilter,
        keywords: keywords,
        view: view,
        page: currentPage,
      })
    );
    this.setState({ delayCompleted: false });
    dispatch(
      getReports(
        currentPage,
        mainFilter,
        dataFilter,
        keywords,
        '',
        async () => {
          await this.waitAtLeastPromise(500);
          this.setState({ delayCompleted: true });
        },
        view
      )
    );
  };

  //This function return the report cards to be shown in the UI.
  getReportCards = () => {
    const {
      reportList = {},
      session: {
        currentUser: {
          userId,
          agency: { timezone },
        },
      } = {},
      total = 0,
      history,
      templates,
      officers = [],
      reportOrderedList = [],
      loadingReports,
      reports,
    } = this.props;
    const { delayCompleted, isActivateTenantReport } = this.state;

    if (loadingReports || !delayCompleted) {
      return <ReportCard loading showSecondLoadingLine />;
    }
    return total > 0 ? (
      reportOrderedList.map(reportId => {
        //here is manipulated the reports to show between normal reports and tenant child reports,
        //depends of the state of the switch, currently this just showing mock data in the case
        //that the switch to be true because of the missing of finall endpoint.
        const report = !isActivateTenantReport
          ? get(reportList, reportId, null)
          : 'tenantReportsList';

        if (!report) {
          return null;
        }

        return (
          <ReportCard
            key={reportId}
            history={history}
            report={report}
            currentUser={userId}
            timezone={timezone}
            templates={templates}
            officers={officers}
            reports={reports}
          />
        );
      })
    ) : (
      <StyledNoResults>
        {translate('containers.reports.zeroResults')}
      </StyledNoResults>
    );
  };

  createReport = e => {
    const {
      history: { push },
    } = this.props;

    push('/use-of-force/new');
  };

  changePage = page => {
    const { dispatch, history } = this.props;
    const { mainFilter, dataFilter, keywords, view } = this.state;

    setPageRetention(page, history);
    window.scrollTo(0, 0);

    dispatch(setReportListFilters({ page: page }));
    dispatch(
      getReports(
        page,
        mainFilter,
        dataFilter,
        keywords,
        '',
        this.getReportsTemplate,
        view
      )
    );
  };

  render() {
    const {
      session: {
        currentUser: { permissions = [] },
      },
      total = 0,
      per_page = 8,
      page = 1,
      dispatch,
      templates,
      loadingReports,
      timezone,
      history,
      currentUser: { featureFlags = [] } = {},
    } = this.props;

    const {
      formType,
      mainFilter,
      dataFilter,
      loadingTemplates,
      delayCompleted,
    } = this.state;
    const enableReportsTable = hasFeatures(
      featureFlags,
      FEATURES.enableReportsTable
    );

    //This function manage the state of the switch.
    const activeChildTenantReports = () => {
      const { isActivateTenantReport } = this.state;
      this.setState({ isActivateTenantReport: !isActivateTenantReport });
    };

    return (
      <div>
        <ReportsHeaderUtilities
          permissions={permissions}
          isLoadingTemplates={loadingTemplates}
          isDelayCompleted={delayCompleted}
          isloadingReports={loadingReports}
          onViewChildTenantReports={activeChildTenantReports}
          onNewReportClick={this.onNewReportClick}
        />

        <div className="page-content">
          <FormFilter
            action={dispatch}
            mainFilter={mainFilter}
            dataFilter={dataFilter}
            formType={formType}
            formTemplates={templates}
            onChangeFilter={this.updateStateFilters}
            clearFilterColumn={this.clearFilterColumn}
            clearKeywords={this.clearKeywords}
            timezone={timezone}
            loadingReports={loadingReports}
            updateOnlyStateFilters={this.updateOnlyStateFilters}
            history={history}
          />
          <StyledRow>
            {enableReportsTable && <ReportsTable loading={!delayCompleted} />}
            {!enableReportsTable && (
              <Col span={24}>{this.getReportCards()}</Col>
            )}
          </StyledRow>
          {delayCompleted && total > 0 && (
            <StyledRowPagination>
              <Col span={24} className="text-right">
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  total={total}
                  pageSize={per_page}
                  onChange={this.changePage}
                  itemRender={(page, type, originalElement) => {
                    const id = type === 'page' ? page : type;
                    return React.cloneElement(originalElement, {
                      'data-testid': `reports-pagination-page-${id}`,
                    });
                  }}
                />
              </Col>
            </StyledRowPagination>
          )}
        </div>
      </div>
    );
  }
}

const mapState = state => {
  const {
    report: {
      data: reportList,
      fetched,
      total,
      per_page,
      page,
      officers,
      list = [],
      loading,
    },
    form,
    reportListFilters,
  } = state;
  const selectedFormType = reportListFilters?.mainFilter?.find(
    entry => entry.column === 'formType'
  )?.type;

  const templatesFetched = get(form, 'templatesMeta.fetched', false);
  return {
    reports: {
      data: get(state, 'report.data', null),
    },
    fetched,
    reportList,
    reportOrderedList: uniq(list),
    total,
    per_page,
    page,
    officers,
    timezone: getAgencyTZ(state),
    loadingReports: loading,
    templatesFetched,
    reportListFilters,
    selectedFormType,
  };
};

export default withoutClutter(DashboardPage(mapState, null)(Reports));
