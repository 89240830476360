import React, { Component } from 'react';
import { Button, Icon, Layout, Modal } from 'antd';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import keys from 'lodash/keys';
import get from 'lodash/get';
import { findDOMNode } from 'react-dom';
import IdleTimer from 'react-idle-timer';
import { change } from 'redux-form';

import { config as appConfig } from '../../config';
import storage from '../../utils/storage';
import callbacks from '../../utils/callback';
import getAgencyTemplates from '../../actions/get-agency-templates';
import toggleSidebar from '../../actions/toggle-sidebar';
import collapseSidebar from '../../actions/collapse-sidebar';
import hideModal from '../../actions/hide-modal';
import createModal from '../../actions/create-modal';
import updateModal from '../../actions/update-modal';
import loadUserAgencyConfigurations from '../../actions/load-user-agency-configurations';
import showModal from '../../actions/show-modal';
import logout from '../../actions/logout-direct';
import addOnbehalf from '../../actions/add-onBehalf';
import resetOnBehalf from 'APP_ROOT/actions/reset-onBehalf';
import FormPicker from '../common/form-picker';
import emitter, { EventTypes } from 'APP_ROOT/utils/eventEmitter';
import getLmsUrl from 'APP_ROOT/utils/getLmsUrl';
import PageLoader from '../PageLoader';
import organizationEndpoints from '../../api/organization/organizationEndpoints';
import { getEnvironmentVariable } from '../../appVersion';
import { defaultTemplatesFilter } from '../../constants/filters';
import SideBar from './side-bar';
import postWebsiteLogs from '../../actions/post-website-logs';
import websiteLogsMessage from '../../utils/websiteLogsMessage';
import {
  getSideBarOptions,
  getSidebarHrefObject,
} from '../../utils/sidebarOptions.constants';
import { getAgencyConfigurations } from '../../api/session';
import isMobileDevice from '../../utils/isMobileDevice';
import { translate } from '../../i18next';
import {
  NEW_REPORT_ON_CASE_FILE_REDUX_PROP,
  NEW_REPORT_ON_CASE_FILE_REDUX_VALUE,
} from '../../containers/caseFile/components/newReportButton/constants';

const NEW_REPORT_QUERY_PARAM = 'create_report';

export const NEW_REPORT_PICKER_ID = 'form-picker';
const USER_IDLE_TIMEOUT = getEnvironmentVariable('USER_IDLE_TIMEOUT', '900');
const STATS_WEBSITE_URL = getEnvironmentVariable(
  'REACT_APP_STATS_WEBSITE_URI',
  ''
);
const leftNavMobileMenu = require('../../icons/ic_left_nav_open_mobile.svg');

const isMobile = isMobileDevice();
class PageWrapper extends Component {
  lastWidth = window.innerWidth;
  idleTimer = null;
  openFormPickerFn;

  state = {
    sidebarHeight: document.body.clientHeight,
    isTimedOut: false,
    token: localStorage.getItem('__storejs_bdm_token'),
    showMobileBanner: false,
  };

  onLeftNavMobileMenuClick = value => {
    const { showMobileBanner } = this.state;
    this.setState({ showMobileBanner: !showMobileBanner });
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const isCollapsed = storage.get('sidebar_collapsed', false);

    this.getSidebarMenuHeight();

    if (window.clientWidth < 992) {
      dispatch(collapseSidebar(true));
    } else if (isCollapsed) {
      dispatch(collapseSidebar(isCollapsed));
    }

    window.addEventListener('resize', this.syncResize);
    window.addEventListener('resize', this.getSidebarMenuHeight);
    /**
     * PageWrapper is a global component used in multiple places
     * the event is emitted from multiple places also
     */
    this.openFormPickerFn = this.openFormPicker(
      this.getPickerOptions(NEW_REPORT_PICKER_ID)
    );
    emitter.on(EventTypes.CREATE_REPORT, this.openFormPickerFn);

    const params = new URLSearchParams(location.search);
    if (params.get(NEW_REPORT_QUERY_PARAM) === 'true') {
      emitter.emit(EventTypes.CREATE_REPORT);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getSidebarMenuHeight);
    window.removeEventListener('resize', this.syncResize);
    emitter.off(EventTypes.CREATE_REPORT, this.openFormPickerFn);
  }

  componentDidUpdate(prevProps) {
    const { pauseIdleTimer } = prevProps.app;
    if (pauseIdleTimer) {
      this.idleTimer.pause();
    } else {
      this.idleTimer.resume();
    }
  }

  onSearchOnBehalf = async value => {
    const { session } = this.props;
    const { agencyId } = session.currentUser;
    const officersFilter = {
      where: { and: [{ includeNoSworn: true }] },
      include: 'rank',
    };

    const filters = {
      page: 0,
      includeDisabled: false,
      includeNoSworn: true,
      pageSize: 100,
      sortBy: 'lastName',
      lastName: '',
      projection: 'IdAndNameAndLastNameOnly',
      filter: officersFilter,
    };
    filters.fullName = value;
    return await organizationEndpoints.getUsersSearch(agencyId, filters);
  };

  onSelectOnBehalf = value => {
    const { dispatch } = this.props;
    const formName = 'form';
    const source = 'usersOnBehalf';
    dispatch(addOnbehalf(value, source, formName));
  };

  onChangeCheckboxOnBehalf = value => {
    const { dispatch } = this.props;
    if (value === false) dispatch(resetOnBehalf());
    return value ? false : true;
  };

  onChangeOnBehalf = value => {
    const { dispatch } = this.props;
    if (value === undefined) dispatch(resetOnBehalf());
  };

  getPickerOptions = pickerId => {
    const NEW_REPORT_PICKER_OPTIONS = {
      id: NEW_REPORT_PICKER_ID,
      identifierName: 'pickerId',
      title: translate('containers.reports.newReport'),
      callback: this.onTemplatesLoaded,
    };

    const pickerOptions = {
      [NEW_REPORT_PICKER_ID]: NEW_REPORT_PICKER_OPTIONS,
    };

    return pickerOptions[pickerId] || {};
  };

  onBehalfPickerOptions = {
    onSearchOnBehalf: this.onSearchOnBehalf,
    onSelectOnBehalf: this.onSelectOnBehalf,
    onChangeCheckboxOnBehalf: this.onChangeCheckboxOnBehalf,
    onChangeOnBehalf: this.onChangeOnBehalf,
  };

  onTemplatesLoaded = async (error, templates) => {
    const {
      dispatch,
      permissions,
      session,
      history: { push },
    } = this.props;

    if (error) {
      throw new Error('Form template request error.');
    }

    // Loads newest configuration to session, only if they don't exist
    const sessionConfig = session.currentUser.agency.configurations;
    if (!sessionConfig || Object.keys(sessionConfig).length < 1) {
      const configurations = await getAgencyConfigurations(
        session.currentUser.agencyId
      );
      dispatch(
        loadUserAgencyConfigurations(Number(session.currentUser.userId), {
          configurations,
        })
      );
      this.props.session.currentUser.agency.configurations = configurations;
    }

    const modalOptions = {
      id: NEW_REPORT_PICKER_ID,
      title: translate('containers.reports.newReport'),
      children: (
        <FormPicker
          templates={templates}
          permissions={permissions}
          push={push}
          closeModal={() => dispatch(hideModal(NEW_REPORT_PICKER_ID))}
          user={session.currentUser}
          onBehalfPickerOptions={this.onBehalfPickerOptions}
          dispatch={dispatch}
        />
      ),
    };
    dispatch(resetOnBehalf());
    dispatch(updateModal(modalOptions));
  };

  clearNewReportFromCasefile = () => {
    const { dispatch } = this.props;
    dispatch(
      change(
        NEW_REPORT_ON_CASE_FILE_REDUX_PROP,
        NEW_REPORT_ON_CASE_FILE_REDUX_VALUE,
        null
      )
    );
  };

  openFormPicker = ({
    id,
    identifierName,
    title,
    callback,
    requiresTemplates = true,
  }) => async e => {
    const { dispatch, templatesLoading, session } = this.props;
    this[identifierName] = id;
    // Loads newest configuration to session, only if they don't exist
    const sessionConfig = session.currentUser.agency.configurations;
    if (!sessionConfig || Object.keys(sessionConfig).length < 1) {
      const configurations = await getAgencyConfigurations(
        session.currentUser.agencyId
      );
      dispatch(
        loadUserAgencyConfigurations(Number(session.currentUser.userId), {
          configurations,
        })
      );
    }

    const modalOptions = {
      id,
      title: (
        <span>
          {title} <Icon type="loading" />
        </span>
      ),
      children: (
        <FormPicker
          templates={[]}
          closeModal={() => dispatch(hideModal(id))}
          isLoading
          onBehalfPickerOptions={this.onBehalfPickerOptions}
        />
      ),
    };

    dispatch(createModal(modalOptions));
    dispatch(showModal(modalOptions.id));

    if (requiresTemplates) {
      if (!templatesLoading) {
        const canCreate = true;
        dispatch(
          getAgencyTemplates(
            defaultTemplatesFilter,
            callback,
            undefined,
            canCreate
          )
        );
      }
    } else {
      callback(null);
    }
  };

  get currentYear() {
    return new Date().getFullYear();
  }

  syncResize = e => {
    this.lastWidth = window.innerWidth;
  };

  toggleCollapse = e => {
    const { dispatch } = this.props;

    dispatch(toggleSidebar());
  };

  onCollapse = (sidebarCollapsed, type) => {
    const { dispatch } = this.props;

    if (window.innerWidth !== this.lastWidth) {
      dispatch(collapseSidebar(sidebarCollapsed));
    }
  };

  hideModal = (id, hideAll = false) => {
    const { dispatch } = this.props;
    this.clearNewReportFromCasefile();
    dispatch(hideModal(id, hideAll));
  };

  logoutTrigger = e => {
    const { dispatch } = this.props;
    const token = storage.get('token');
    const ssoPreLogoutURI = getEnvironmentVariable('SSO_PRE_LOGOUT_URI');
    const ssoLogoutURI = getEnvironmentVariable('SSO_LOGOUT_URI');
    const securityLogoutURI = getEnvironmentVariable('SECURITY_SERVICE_URI');
    const execLogoutUrl = `${ssoPreLogoutURI}?token=${token}&sso_logout_uri=${ssoLogoutURI}&client_id=benchmark&scope=openid+email+agency+roles+rank+permissions&kmi_logout=true&security_host=${securityLogoutURI}`;

    dispatch(
      logout(
        callbacks({
          onComplete: () => {
            window.location.href = execLogoutUrl;
          },
        })
      )
    );
  };

  saveRef = name => el => {
    this[name] = el;
  };

  getSidebarMenuHeight = () => {
    const sidebarFooter = findDOMNode(this.sidebarFooter);
    const offset = isMobile ? 37 : 21;
    let sidebarFooterHeight = 0;

    if (sidebarFooter) {
      sidebarFooterHeight = sidebarFooter.clientHeight;
    }

    this.setState({
      sidebarHeight: document.body.clientHeight - sidebarFooterHeight - offset,
    });
  };

  showIdleConfirmation = () => {
    const { dispatch } = this.props;
    const id = 'idle-confirmation';
    const modalOptions = {
      id,
      title: (
        <span>
          {' '}
          <Icon type="info-circle" /> You have been idle for a while!
        </span>
      ),
      children: (
        <span>
          You have been inactive for a while, so you will be logged out for
          security reasons. Click Continue to stay signed in.
        </span>
      ),
      footer: (
        <div className="text-right text-xs-left">
          <Button
            onClick={e => {
              dispatch(hideModal(id));
            }}
          >
            Continue
          </Button>
        </div>
      ),
    };

    dispatch(createModal(modalOptions));
    dispatch(showModal(modalOptions.id));
  };

  handleOnAction = () => {
    this.setState({ isTimedOut: false });
  };

  handleOnActive = () => {
    this.setState({ isTimedOut: false });
  };

  handleOnIdle = () => {
    const { isTimedOut } = this.state;
    const { dispatch } = this.props;

    if (isTimedOut) {
      //This dispatch is sending information to an enpoint on the node api to get information about
      //forced logout - for more information about the issue review SW-1059
      dispatch(
        postWebsiteLogs(
          websiteLogsMessage(
            'Logout executed because of Idle time',
            'page-wrapper'
          )
        )
      );
      setTimeout(() => this.logoutTrigger(), 500);
    } else {
      this.showIdleConfirmation();
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  };

  render() {
    const {
      children = null,
      config = {},
      match = {},
      location = {},
      session,
      app = {
        sidebarCollapsed: isMobile ? true : false,
      },
      modals = {},
      className = '',
      templatesLoading,
      history,
      dispatch,
      supportedAgency = {},
    } = this.props;
    const { sidebarCollapsed = false } = app;
    const { sidebarHeight, showMobileBanner } = this.state;
    const shouldBeCollapsed = isMobile ? !showMobileBanner : sidebarCollapsed;

    const { currentUser } = session;

    const { permissions = [] } = currentUser || {};

    const pageTitle = config && config.title ? config.title : '';

    const sidebarCollapseButtonClassNames = classNames({
      'is-collapsed': shouldBeCollapsed,
    });

    const sidebarHrefObject = getSidebarHrefObject(
      session,
      { STATS_WEBSITE_URL, token: this.state.token, getLmsUrl },
      supportedAgency
    );

    const menuOptions = getSideBarOptions(sidebarHrefObject);

    const systemVersion = `V${global.VERSION.VERSION}`
      .toLowerCase()
      .replace('vunknown', '')
      .toUpperCase();

    return (
      <Layout>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          timeout={1000 * parseInt(USER_IDLE_TIMEOUT)}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <Helmet>
          {pageTitle && (
            <title>
              {pageTitle} | {appConfig.name}
            </title>
          )}
          {!pageTitle && <title>{appConfig.name}</title>}
        </Helmet>
        <PageLoader intermitent loading={app.loading} />
        <SideBar
          sidebarCollapsed={shouldBeCollapsed}
          onCollapse={this.onCollapse}
          saveRef={this.saveRef}
          sidebarHeight={sidebarHeight}
          currentUser={currentUser}
          onOpenNewReport={this.openFormPicker(
            this.getPickerOptions(NEW_REPORT_PICKER_ID)
          ).bind(this)}
          permissions={permissions}
          optionsHistory={history}
          location={location}
          match={match}
          onLogOut={this.logoutTrigger}
          isNewReportLoading={templatesLoading}
          menuOptions={menuOptions}
          sidebarCollapseButtonClassNames={sidebarCollapseButtonClassNames}
          onToggleCollapse={this.toggleCollapse}
          appConfig={appConfig}
          currentYear={this.currentYear}
          systemVersion={systemVersion}
          className={className}
          config={config}
          history={history}
          dispatch={dispatch}
        >
          {children}
        </SideBar>
        {isMobile && (
          <Button className="menu-fab" onClick={this.onLeftNavMobileMenuClick}>
            <img src={leftNavMobileMenu} alt="Open Menu" />
          </Button>
        )}
        {keys(modals).map((modal, index) => {
          const modalId = get(modals, [modal, 'id']);
          const modalCancel = get(modals, [modal, 'onCancel']);
          return (
            <Modal
              key={modalId || index}
              footer={null}
              {...get(modals, [modal], {})}
              maskClosable={false}
              onCancel={e => {
                modalCancel && modalCancel(e);
                this.hideModal(modalId, !modalId || modal.hideAll);
              }}
            />
          );
        })}
      </Layout>
    );
  }
}

export default PageWrapper;
