import React, { useState, useEffect } from 'react';
import { DatePicker, Select, Icon } from 'antd';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';

import { StyledFilterCol } from '../../audit-logs/audit-logs-table.styled';
import { BENCHMARK_DATE_FORMAT } from '../../../utils/parse-date';
import { getOfficersAndCivilians } from '../../../api/users';
import { ValidationLabel } from '../../audit-logs/audit-logs-table.styled';
import getReportTimelineAuditLogs, {
  formCleanReportTimelineLogs,
} from '../../../actions/organizations/get-report-timeline-audit-logs';

const fillInputsMessage = (
  <ValidationLabel>
    <Icon type="warning" theme="twoTone" />
    Select a date range and a user who performed the change to view audit logs.
  </ValidationLabel>
);

const AgencyAuditLogsReportTimelineSearchFilters = ({
  dispatch,
  agencyId,
  tenantId,
  pageNumber,
  pageSize,
  resetPage,
}) => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [executedBySelectOptions, setExecutedBySelectOptions] = useState([]);
  const [dateRangeOnChangedValue, setDateRangeOnChangedValue] = useState({});
  const [executedByOnChangedValue, setExecutedByOnChangedValue] = useState('');

  useEffect(() => {
    if (executedByOnChangedValue && !isEmpty(dateRangeOnChangedValue)) {
      const pageData = { pageNumber, pageSize };
      dispatch(
        getReportTimelineAuditLogs(
          tenantId,
          executedByOnChangedValue,
          dateRangeOnChangedValue,
          pageData
        )
      );
    }
  }, [executedByOnChangedValue, dateRangeOnChangedValue, pageNumber]);

  useEffect(() => {
    return () => {
      resetPage();
      dispatch(formCleanReportTimelineLogs());
    };
  }, []);

  const disabledDate = current =>
    current && current.valueOf() >= moment('2025-02-01');

  const selectUsersOptionsBuilder = users => {
    return users.map(({ integrationId, fullName }) => {
      return (
        <Option key={integrationId} value={integrationId}>
          {fullName}
        </Option>
      );
    });
  };
  const onExecutedBySearch = debounce(async value => {
    if (value) {
      const users = await getOfficersAndCivilians(agencyId, {
        fields: { fullName: value },
      });
      const userOptions = await selectUsersOptionsBuilder(users);
      setExecutedBySelectOptions(userOptions);
    }
  }, 800);

  const onExecutedByChange = value => {
    setExecutedByOnChangedValue(value);
  };

  const onChangeDatePickerChange = value => {
    const dateTranformed = {
      initialDate: moment(value[0]).format(BENCHMARK_DATE_FORMAT),
      endDate: moment(value[1])
        .add(1, 'days')
        .format(BENCHMARK_DATE_FORMAT),
    };
    if (isEmpty(value)) setDateRangeOnChangedValue({});
    else setDateRangeOnChangedValue(dateTranformed);
  };

  return (
    <>
      <StyledFilterCol md={14} sm={12} xs={24}>
        {fillInputsMessage}
      </StyledFilterCol>
      <StyledFilterCol md={8} xl={6} sm={8} xs={24}>
        <RangePicker
          format={BENCHMARK_DATE_FORMAT}
          size="default"
          className="full-width"
          disabledDate={disabledDate}
          onChange={onChangeDatePickerChange}
        />
      </StyledFilterCol>
      <StyledFilterCol md={4} sm={8} xs={24}>
        <Select
          showSearch
          placeholder="Executed By"
          style={{ width: '100%' }}
          size="default"
          filterOption={false}
          onSearch={onExecutedBySearch}
          onChange={onExecutedByChange}
          allowClear
        >
          {executedBySelectOptions}
        </Select>
      </StyledFilterCol>
    </>
  );
};

export default AgencyAuditLogsReportTimelineSearchFilters;
