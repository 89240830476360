import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import FormHeader from './styled/header';
import PageHeader from '../PageHeader/PageHeader';

export class FormHeaderActions extends PureComponent {
  handleBackButtonClick = () => {
    const {
      history = {},
      location: { pathname = '' },
    } = this.props;
    const { length: historyLength = 0 } = history;
    const expression = '/reports/';
    if (historyLength <= 2 || pathname.search(expression) !== -1) {
      history.push('/reports');
    } else {
      history.goBack();
    }
  };

  render() {
    const { children = [], sidebarCollapsed } = this.props;

    return (
      <PageHeader
        title={
          <ContentLoader
            className="svg-loading-animation is-form-name"
            height={65}
            width={250}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="5" y="2" rx="4" ry="4" width="150" height="25" />
            <rect x="165" y="2" rx="15" ry="15" width="80" height="25" />
            <rect x="5" y="38" rx="4" ry="4" width="85" height="15" />
          </ContentLoader>
        }
      >
        <FormHeader
          className="loading-placeholder"
          sidebarCollapsed={sidebarCollapsed}
        >
          <Row>
            <Col xs={16}>
              <div className="form-info">
                <div className="form-info__title">
                  <div></div>
                </div>
              </div>
            </Col>
            <Col className="text-right text-sm-center" xs={8}>
              <div className="form-actions">
                <div className="form-actions__meta">
                  <div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div className="form-actions__buttons" />
              </div>
            </Col>
          </Row>
          <Row>{children}</Row>
        </FormHeader>
      </PageHeader>
    );
  }
}

export default withRouter(FormHeaderActions);
