import React, { Component } from 'react';
import { Row, Col, Tag } from 'antd';
import { connect } from 'react-redux';
// import { DragSource } from 'react-dnd';

import IconButton from '../../../../components/common/buttons/icon-button';
import componentsManager from '../../services/componentsManager';
import Styled from './RowEditor.styled';
import withModal from 'APP_COMPONENTS/common/modal/base';
import buildOptionsModalContent from '../../utils/buildOptionsModalContent';
import {
  TAB_VALUES,
  TAB_CONDITIONAL,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
  TAB_DATE_BOUNDARY,
} from '../OptionsModalBody/OptionsModalBody';
class RowEditor extends withModal(Component) {
  componentDidMount() {
    this.createModal();
  }

  remove = () => {
    const { id } = this.props;

    componentsManager.removeComponent(id);
  };

  showOptionsModal = () => {
    const { type } = this.props;

    const fieldsOptions = {
      type,
      disableTab: [
        TAB_VALUES,
        TAB_CONDITIONAL,
        TAB_SHARE_KEY,
        TAB_INFORMATION,
        TAB_DATE_BOUNDARY,
      ],
    };

    const modalData = buildOptionsModalContent({
      ...fieldsOptions,
      onCancel: this.deleteModal,
      onDelete: () => {
        this.remove();
        this.deleteModal();
      },
    });

    this.updateModal(modalData);

    this.showModal();
  };

  render() {
    const {
      children,
      total,
      // connectDragSource,
      // connectDragPreview,
      // isDragging,
    } = this.props;

    // the commented code is to make the row draggable, but for
    // later, right now we'll keep it as it is (not moving)

    // return connectDragPreview(
    return (
      <div className="fb-row">
        <Styled type="flex" gutter={16} data-test="field row">
          <Col className="row-editor--actions" span={24}>
            {total > 1 && (
              // connectDragSource(
              <div>
                <Row
                  className="row-editor--actions-row"
                  type="flex"
                  align="middle"
                >
                  <Col span={12}>
                    <Tag>Row</Tag>
                  </Col>
                  <Col
                    className="text-right row-editor--action-buttons"
                    span={12}
                  >
                    <IconButton
                      icon="setting"
                      className="is-big is-focusable"
                      onClick={this.showOptionsModal}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Col>

          {children}
        </Styled>
      </div>
    );
  }
}

RowEditor.displayName = 'FieldEditor.RowEditor';

export default connect()(RowEditor);
// export default connect()(
//   DragSource(
//     params => params.type,
//     {
//       beginDrag: param => param.field,
//     },
//     (connect, monitor) => ({
//       connectDragSource: connect.dragSource(),
//       connectDragPreview: connect.dragPreview(),
//       isDragging: monitor.isDragging(),
//     })
//   )(RowEditor)
// );
