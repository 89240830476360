import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Row, Col, Input, Table, Pagination } from 'antd';
import { StyledRow, StyledRowPagination } from '../people/People.styled';
import { orderBy } from 'lodash';

import DashboardPage from '../../components/dashboard';

import savePreviousPath from 'APP_ROOT/utils/savePreviousPath';
import getRoute from 'APP_ROOT/utils/get-route';
import getOuList from './actions/get-ou-list';

import CsvExportLink from '../../components/csv-export-link';
import withoutClutter from '../../components/without-clutter';
import PageHeader from '../../components/PageHeader/PageHeader';

import setOuListSearchKeyword from './actions/set-ou-list-search-keyword';
import setOuListFromOrgProfile from './actions/set-ou-list-from-org-profile';
import { translate } from '../../i18next';

const { Column } = Table;
const PAGE_SIZE = 100;

// CSV config
const CSV_HEADERS = [
  { label: 'Id', key: 'id' },
  { label: 'Name', key: 'name' },
  { label: 'Phone No.', key: 'phone' },
  { label: 'Website', key: 'website' },
  { label: 'Parent ID', key: 'parentId' },
  { label: 'Parent Name', key: 'parentName' },
];

const CSV_PAGE_SIZE = 1000;

class Organizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ouList: [],
      loading: true,
      total: 100,
      page: 0,
      search: '',
      isSearchClearActive: false,
      csvData: [],
    };
  }

  searchInput = null;

  componentDidMount() {
    const {
      ouListSearchKeyword,
      isSearchFilterKeywordKept,
      dispatch,
    } = this.props;
    this.searchInput.focus();
    this.keepSearchFilterData(
      ouListSearchKeyword,
      isSearchFilterKeywordKept,
      dispatch
    );
    this.fetchDataForCsv();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setOuListFromOrgProfile(false));
  }

  setSearchKeywordAction = (value = '') => {
    const { dispatch } = this.props;
    dispatch(setOuListSearchKeyword(value));
  };

  keepSearchFilterData = (ouListSearchKeyword, isSearchFilterKeywordKept) => {
    if (isSearchFilterKeywordKept) {
      this.fetchData(ouListSearchKeyword);
      this.setState({
        search: ouListSearchKeyword,
        isSearchClearActive: !!ouListSearchKeyword,
      });
    } else {
      this.fetchData();
      this.setSearchKeywordAction();
    }
  };

  changePage = page => {
    window.scrollTo(0, 0);
    this.setState({ page: page - 1 }, () => this.fetchData());
  };

  handleSearchChange = ({ target: { value } }) => {
    this.setState({ search: value, isSearchClearActive: !!value });
  };

  handleOnPressEnter = ({ target: { value } }) => {
    this.fetchData(value);
    this.setSearchKeywordAction(value);
  };

  handleOnSearchClear = () => {
    this.setState({ search: '', isSearchClearActive: false });
    this.fetchData();
    this.setSearchKeywordAction();
  };

  fetchData = async (searchKey = '') => {
    const pageData = {
      pageNumber: this.state.page,
      pageSize: PAGE_SIZE,
      ouName: searchKey,
    };
    const { data, total, error } = await getOuList(pageData);
    const sortedOrglist = data => orderBy(data, ['name'], ['asc']);
    if (error) return;
    this.setState({ total, ouList: sortedOrglist(data), loading: false });
  };

  fetchDataForCsv = async conclude => {
    const { data, error } = await getOuList({
      pageNumber: 0,
      pageSize: CSV_PAGE_SIZE,
    });
    if (!error) {
      this.setState({ csvData: data }, conclude);
    }
  };

  processCsvData = () => {
    return this.state.csvData;
  };

  searchSuffix = isSearchClearActive => {
    if (isSearchClearActive)
      return <Icon type="close-circle" onClick={this.handleOnSearchClear} />;
    return <Icon type="search" />;
  };

  renderSearchInput = () => {
    const { isSearchClearActive } = this.state;
    return (
      <Input
        data-test="inputSearch"
        placeholder={translate('containers.organizations.search')}
        size="default"
        value={this.state.search}
        onChange={this.handleSearchChange}
        onPressEnter={this.handleOnPressEnter}
        suffix={this.searchSuffix(isSearchClearActive)}
        ref={input => {
          this.searchInput = input;
        }}
      />
    );
  };

  renderParentOULink = (_text, record) => {
    const { parentName, parentId } = record;

    if (parentName && parentId) {
      return (
        <Link
          to={savePreviousPath(
            getRoute('organizationProfile', {
              organizationalUnitId: parentId,
            })
          )}
        >
          {parentName}
        </Link>
      );
    }

    return (
      <span>{translate('containers.organizations.table.noParentOU')}</span>
    );
  };

  renderPaginationControl = () => {
    const { page, total } = this.state;
    if (total > PAGE_SIZE) {
      return (
        <StyledRowPagination>
          <Col span={24} className="text-right">
            <Pagination
              defaultCurrent={1}
              current={page + 1}
              total={total}
              pageSize={PAGE_SIZE}
              onChange={this.changePage}
            />
          </Col>
        </StyledRowPagination>
      );
    }
  };

  render() {
    const { ouList, loading } = this.state;

    return (
      <>
        <PageHeader
          className="admin-container"
          title={translate('containers.organizations.title')}
        />

        <div className="section-container">
          <Row style={{ paddingTop: 20 }}>
            <Col span={18} className="text-left">
              <CsvExportLink
                useAsync
                headers={CSV_HEADERS}
                asyncFetchData={this.fetchDataForCsv}
                getProcessedData={this.processCsvData}
                buttonText="Download CSV"
                filename="org_list_export.csv"
              />
            </Col>
            <Col span={6} className="text-right">
              {this.renderSearchInput()}
            </Col>
          </Row>
          <StyledRow>
            <Col span={24}>
              <div className="responsive-table">
                <Table dataSource={ouList} loading={loading} pagination={false}>
                  <Column
                    title={translate('containers.organizations.table.name')}
                    key="name"
                    render={(_text, record) => (
                      <Link
                        to={savePreviousPath(
                          getRoute('organizationProfile', {
                            organizationalUnitId: record.id,
                          })
                        )}
                      >
                        {record.name}
                      </Link>
                    )}
                  />
                  <Column
                    title={translate('containers.organizations.table.phone')}
                    dataIndex="phone"
                    key="phone"
                  />
                  <Column
                    title={translate('containers.organizations.table.website')}
                    dataIndex="website"
                    key="website"
                  />
                  <Column
                    title={translate('containers.organizations.table.parentOU')}
                    key="parentOU"
                    render={this.renderParentOULink}
                  />
                </Table>
              </div>
            </Col>
          </StyledRow>
          {this.renderPaginationControl()}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    organization: {
      ouList: { searchKeyword = '', fromOuOrgProfile = false } = {},
    } = {},
  } = state;
  return {
    ouListSearchKeyword: searchKeyword,
    isSearchFilterKeywordKept: fromOuOrgProfile,
  };
};

export default withoutClutter(DashboardPage(mapStateToProps)(Organizations));
