import React from 'react';
import { Switch } from 'antd';

class FieldSwitch extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = checked => {
    const { onChange } = this.props;
    onChange && onChange(checked);
  };

  render() {
    const { value, initialValue } = this.props;
    return (
      <Switch
        checked={value !== undefined ? value : initialValue}
        checkedChildren="Yes"
        unCheckedChildren="No"
        onChange={this.onChange}
      />
    );
  }
}

export default FieldSwitch;
