import { get, first } from 'lodash';
import REPORT_STATUS from 'APP_ROOT/constants/status';

const getActiveParticipant = report => {
  const activeStateReviewers = get(
    report,
    'workFlowData.activeStateReviewers',
    {}
  );
  const state = get(report, 'workFlowData.state', '');
  const status = get(report, 'workFlowData.status', {});
  const reviewers = get(activeStateReviewers, state, []);
  const reviewer = first(reviewers);
  const review = get(status, reviewer, {});

  return review.label === REPORT_STATUS.closed ? null : reviewer;
};

export default getActiveParticipant;
