import React, { Component, Fragment } from 'react';
import { DatePicker, TimePicker } from 'antd';
import { get, kebabCase, isNil, isEmpty } from 'lodash';
import moment from 'moment-timezone';

import ReviewerField from '../reviewer-field';
import parseDate, {
  momentWithTZ,
  BENCHMARK_TIME_FORMAT,
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_DATE_TIMEZ_FORMAT,
  BENCHMARK_DATE_TIME_FORMAT,
} from '../../../utils/parse-date';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import isMobileDevice from '../../../utils/isMobileDevice';
import StyledInput, { StyledDiv } from './input-date.styled';
import classNames from 'classnames';
import {
  CONTRIBUTOR_ASSIGNMENT_DATE,
  CONTRIBUTOR_SECTION_COMPLETE_DATE,
} from '../../../constants/contributeToReport.js';
import emitter, { EventTypes } from '../../../utils/eventEmitter.js';
import {
  EXPLICIT_VALUE,
  RANGE_VALUE,
  PAST_LOWER_BOUNDARY_VALUE,
  PAST_UPPER_BOUNDARY_VALUE,
  FUTURE_LOWER_BOUNDARY_VALUE,
  FUTURE_UPPER_BOUNDARY_VALUE,
  DAY_VALUE,
} from '../../../modules/FormBuilder/components/DateBoundary/constants.js';

const DATE_FORMAT = 'YYYY-MM-DD';
const DATETIME_FORMAT = `${DATE_FORMAT}THH:mm`;

const isMobile = isMobileDevice();

const setCurrentDate = (props, format, timezone) => {
  const { onChange, value } = props;
  const today = isEmpty(value) ? '' : parseDate(value, timezone, format);
  onChange(today);
};

const shouldUseTimezoneOffset = (options, defaultValue = true) =>
  get(options, 'useTimezoneOffset', defaultValue);

const onDateChange = (props, value) => {
  const { onChange, options, timezone } = props;
  const useTimezoneOffset = shouldUseTimezoneOffset(options);
  // value is a string, need to call .tz to make sure
  // timezone is applied
  const momentifiedValue = useTimezoneOffset
    ? moment.tz(value, timezone)
    : momentWithTZ(value, timezone, null, false);
  onChange && onChange(momentifiedValue);
};

const disabledDateChecker = (dateBoundary, submittedAt) => {
  if (!dateBoundary) return () => false;

  switch (dateBoundary.type) {
    case EXPLICIT_VALUE:
      return disabledDateCheckerExplicitDate(dateBoundary);

    case RANGE_VALUE:
      return disabledDateCheckerRangeDate(dateBoundary, submittedAt);

    default:
      return () => false;
  }
};

const disabledDateCheckerExplicitDate = dateBoundary => {
  const lowerBoundary = dateBoundary.explicitLowerBoundary
    ? moment(dateBoundary.explicitLowerBoundary).startOf('day')
    : undefined;
  const upperBoundary = dateBoundary.explicitUpperBoundary
    ? moment(dateBoundary.explicitUpperBoundary).endOf('day')
    : undefined;

  return currentDate => {
    if (!currentDate) return false;

    const isCrossed =
      lowerBoundary && upperBoundary && lowerBoundary.isAfter(upperBoundary);

    if (isCrossed) {
      // Exclude dates between the crossed boundaries
      const isBetween =
        currentDate.isSameOrAfter(upperBoundary, 'day') &&
        currentDate.isSameOrBefore(lowerBoundary, 'day');
      return isBetween;
    } else {
      // Normal boundary behavior
      const isBeforeLower =
        lowerBoundary && currentDate.isBefore(lowerBoundary, 'second');
      const isAfterUpper =
        upperBoundary && currentDate.isAfter(upperBoundary, 'second');
      return isBeforeLower || isAfterUpper;
    }
  };
};

const disabledDateCheckerRangeDate = (dateBoundary, submittedAt) => {
  const boundaries = dateBoundary.rangeBoundary || [];
  const referenceDate = isNil(submittedAt) ? moment() : moment(submittedAt);
  let minBoundary = null,
    maxBoundary = null;

  // Iterate over boundaries and properly assign the minimum and maximum limits
  boundaries.forEach(boundary => {
    const boundaryDate = calculateBoundaryDate(boundary, referenceDate);
    const { minBoundary: newMin, maxBoundary: newMax } = assignSingleBoundary(
      boundaryDate
    );

    // Update minBoundary if newMin exists and is earlier
    if (newMin && (!minBoundary || newMin.isBefore(minBoundary))) {
      minBoundary = newMin;
    }

    // Update maxBoundary if newMax exists and is later
    if (newMax && (!maxBoundary || newMax.isAfter(maxBoundary))) {
      maxBoundary = newMax;
    }
  });

  return currentDate => {
    if (!currentDate) return false;

    // If both min and max boundaries exist, block dates outside the range
    if (minBoundary && maxBoundary) {
      return (
        currentDate.isBefore(minBoundary, DAY_VALUE) ||
        currentDate.isAfter(maxBoundary, DAY_VALUE)
      );
    }

    // If only minBoundary exists, block dates before it
    if (minBoundary) {
      return currentDate.isBefore(minBoundary, DAY_VALUE);
    }

    // If only maxBoundary exists, block dates after it
    if (maxBoundary) {
      return currentDate.isAfter(maxBoundary, DAY_VALUE);
    }

    return false;
  };
};

const assignSingleBoundary = boundaryDate => {
  const boundaryType = boundaryDate.type.toLowerCase();
  let minBoundary, maxBoundary;

  if (boundaryType.includes('lower')) {
    minBoundary = boundaryDate.boundaryDate;
  } else if (boundaryType.includes('upper')) {
    maxBoundary = boundaryDate.boundaryDate;
  }
  return { minBoundary, maxBoundary };
};

const calculateBoundaryDate = (boundary, referenceDate) => {
  const { type, limit, unit } = boundary;
  const pastBoundary = referenceDate.clone().subtract(limit, unit);
  const futureBoundary = referenceDate.clone().add(limit, unit);
  let boundaryDate;

  switch (type) {
    case PAST_LOWER_BOUNDARY_VALUE:
      boundaryDate = pastBoundary.startOf(DAY_VALUE);
      break;
    case PAST_UPPER_BOUNDARY_VALUE:
      boundaryDate = pastBoundary.endOf(DAY_VALUE);
      break;
    case FUTURE_LOWER_BOUNDARY_VALUE:
      boundaryDate = futureBoundary.startOf(DAY_VALUE);
      break;
    case FUTURE_UPPER_BOUNDARY_VALUE:
      boundaryDate = futureBoundary.endOf(DAY_VALUE);
      break;
    default:
      break;
  }
  return { boundaryDate, type };
};

class WrappedDatePicker extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  onChange = event => {
    const value = event.currentTarget.value;
    onDateChange(this.props, value);
  };

  render() {
    const {
      enums,
      options,
      isReviewer,
      isDraft,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      calculatedValue,
      field_type,
      data: { meta: { submittedAt = undefined } = {} } = {},
      ...props
    } = this.props;
    const evaluateValue = calculatedValue || this.props.value;
    const useTimezoneOffset = shouldUseTimezoneOffset(options);
    const momentifiedValue = momentWithTZ(
      evaluateValue,
      timezone,
      null,
      useTimezoneOffset
    );
    let overrideEditRights = false;
    let overrideReviewer = isReviewer;
    if (this.props.isContributeReport) {
      if (this.props.contributorAssignmentCanEdit) {
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        overrideReviewer = true;
      } else {
        <ReviewerField />;
      }
    }
    if (overrideReviewer && !overrideEditRights) {
      const todayMoment = moment().tz(timezone);

      let formattedValue = evaluateValue
        ? momentifiedValue.format(BENCHMARK_DATE_FORMAT)
        : ' ';

      if (formattedValue && this.props.title.split(' ').includes('Age')) {
        formattedValue = todayMoment.diff(formattedValue, 'years');
        if (formattedValue < 0) {
          formattedValue = 'N/A';
        }
      }

      return (
        <div className="reviewer-field text-field">{formattedValue}&nbsp;</div>
      );
    }

    return isMobile ? (
      <StyledInput
        type="date"
        {...props}
        onChange={this.onChange}
        value={momentifiedValue.format(DATE_FORMAT)}
      />
    ) : (
      <DatePicker
        style={{ width: '100%' }}
        size="default"
        {...props}
        format={BENCHMARK_DATE_FORMAT}
        ref={ref => (this.input = ref)}
        value={evaluateValue ? momentifiedValue : null}
        onOk={() => setCurrentDate(this.props, BENCHMARK_DATE_FORMAT, timezone)}
        disabledDate={disabledDateChecker(options.dateBoundary, submittedAt)}
      />
    );
  }
}

class DateTimePicker extends Component {
  ctrDateTimeUpdate_bound = this.ctrDateTimeUpdate.bind(this);
  constructor(props) {
    super();
    this.state = {
      reportingKey: props.reportingKey,
      key: props.id,
    };
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidMount() {
    emitter.on(
      EventTypes.CTR_SECTION_TIMESTAMP_UPDATE,
      this.ctrDateTimeUpdate_bound
    );
  }

  componentWillUnmount() {
    emitter.off(
      EventTypes.CTR_SECTION_TIMESTAMP_UPDATE,
      this.ctrDateTimeUpdate_bound
    );
  }

  ctrDateTimeUpdate({ detail }) {
    if (this.props.id === detail) {
      const today = parseDate(new Date(), null, BENCHMARK_DATE_TIMEZ_FORMAT);

      this.onChange({ currentTarget: { value: today } });
    }
  }

  onChange = event => {
    const value = event.currentTarget.value;
    onDateChange(this.props, value);
  };

  renderContributorDate = (canRender, label, formattedValue) => {
    const { shouldRenderHeaderDate } = this.props;
    return shouldRenderHeaderDate && canRender ? (
      <StyledDiv className={kebabCase(`${label}-date`)}>
        <div className="label">{label}</div>
        <div>{formattedValue}</div>
      </StyledDiv>
    ) : (
      <Fragment />
    );
  };

  render() {
    const {
      enums,
      options,
      isReviewer,
      isDraft,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      calculatedValue,
      field_type,
      data: { meta: { submittedAt = undefined } = {} } = {},
      ...props
    } = this.props;
    const useTimezoneOffset = shouldUseTimezoneOffset(options);
    const evaluateValue = calculatedValue || this.props.value;
    const momentifiedValue = momentWithTZ(
      evaluateValue,
      timezone,
      null,
      useTimezoneOffset
    );
    let overrideReviewer = isReviewer;
    let overrideEditRights = false;
    const isContributorAssignmentDate =
      this.state.reportingKey === CONTRIBUTOR_ASSIGNMENT_DATE;
    const isContributorSectionCompleteDate =
      this.state.reportingKey === CONTRIBUTOR_SECTION_COMPLETE_DATE;
    const contributorAssignmentClass = classNames({
      'assigned-date': isContributorAssignmentDate,
    });
    // Performance review assigned date should never be editable
    if (isContributorAssignmentDate || isContributorSectionCompleteDate) {
      overrideReviewer = true;
    } else if (this.props.isContributeReport) {
      if (this.props.contributorAssignmentCanEdit) {
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        overrideReviewer = true;
      }
    }
    if (overrideReviewer && !overrideEditRights) {
      let formattedValue = evaluateValue
        ? momentifiedValue.format(BENCHMARK_DATE_TIMEZ_FORMAT)
        : undefined; // TODO: this might render undefined
      if (isContributorAssignmentDate && formattedValue) {
        return this.renderContributorDate(
          this.props.contributorAssignmentIsAssigned,
          'Assigned',
          formattedValue
        );
      }
      if (isContributorSectionCompleteDate) {
        return this.renderContributorDate(
          this.props.contributorAssignmentIsComplete,
          'Completed',
          formattedValue
        );
      }
      return <ReviewerField value={formattedValue} />;
    }

    return isMobile ? (
      <StyledInput
        type="datetime-local"
        {...props}
        onChange={this.onChange}
        value={momentifiedValue.format(DATETIME_FORMAT)}
      />
    ) : (
      <DatePicker
        size="default"
        className={contributorAssignmentClass}
        showTime
        style={{ width: '100%', minWidth: '180px' }}
        format={BENCHMARK_DATE_TIME_FORMAT}
        {...props}
        ref={ref => (this.input = ref)}
        value={evaluateValue ? momentifiedValue : null}
        onChange={value => {
          const { onChange } = this.props;
          setCurrentDate(
            { onChange, value },
            BENCHMARK_DATE_TIMEZ_FORMAT,
            timezone
          );
        }}
        onOk={() =>
          setCurrentDate(this.props, BENCHMARK_DATE_TIMEZ_FORMAT, timezone)
        }
        disabledDate={disabledDateChecker(options.dateBoundary, submittedAt)}
      />
    );
  }
}

class TimePickerInput extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  render() {
    const {
      enums,
      options,
      isReviewer,
      isDraft,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      value,
      calculatedValue,
      field_type,
      ...props
    } = this.props;
    const useTimezoneOffset = shouldUseTimezoneOffset(options, false);
    const momentifiedValue = momentWithTZ(
      isReviewer ? calculatedValue || value : value,
      timezone,
      null,
      useTimezoneOffset
    );
    let overrideReviewer = isReviewer;
    let overrideEditRights = false;
    if (this.props.isContributeReport) {
      if (this.props.contributorAssignmentCanEdit) {
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        overrideReviewer = true;
      } else {
        <ReviewerField></ReviewerField>;
      }
    }
    if (overrideReviewer && !overrideEditRights) {
      return (
        <ReviewerField
          value={
            calculatedValue || value
              ? momentifiedValue.format(BENCHMARK_TIME_FORMAT)
              : ''
          }
        />
      );
    }

    return (
      <TimePicker
        size="default"
        style={{ width: '100%' }}
        {...props}
        format={BENCHMARK_TIME_FORMAT}
        ref={ref => (this.input = ref)}
        value={value ? momentifiedValue : null}
        allowClear={false}
        onOk={() => setCurrentDate(this.props, BENCHMARK_TIME_FORMAT, timezone)}
      />
    );
  }
}

export default WrappedDatePicker;
export const DateTime = DateTimePicker;
export const TimePickerField = TimePickerInput;
