import React from 'react';
import ModalBody from 'APP_ROOT/components/common/modal/body';
import { isEmpty } from 'lodash';
import { translate } from '../../../i18next';
export default props => {
  const { namesToShare, namesToUnshare, title } = props;

  const shares = [];
  const hasNamesToShare = !isEmpty(namesToShare);
  const hasNamesToUnshare = !isEmpty(namesToUnshare);
  if (hasNamesToShare) {
    shares.push(
      <strong key="shared">{translate('containers.caseFiles.shared')}</strong>,
      ` ${translate('containers.caseFiles.with')} ${namesToShare}`
    );
    if (hasNamesToUnshare) {
      shares.push(` ${translate('containers.caseFiles.and')} `);
    }
  }

  if (hasNamesToUnshare) {
    shares.push(
      <strong key="unshared">
        {translate('containers.caseFiles.unshared')}
      </strong>,
      ` ${translate('containers.caseFiles.with')} ${namesToUnshare}`
    );
  }

  return (
    <ModalBody>
      <p>
        {translate('containers.caseFiles.caseFile')} {title}{' '}
        {translate('containers.caseFiles.hasBeen')} {shares.map(s => s)}
      </p>
    </ModalBody>
  );
};
