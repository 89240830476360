import React from 'react';
import openModal from '../utils/onDeleteButtonClick';
import { ButtonActionsWrapper } from '../CaseFile.styled';
import { translate } from '../../../i18next';

const ShareButton = props => {
  const { isCasefileClosed } = props;
  return (
    <ButtonActionsWrapper
      icon="delete"
      type="icon"
      shape="circle"
      title={translate('containers.caseFiles.delete')}
      tooltipMessage={translate('containers.caseFiles.deleteFile')}
      onClick={() => openModal(isCasefileClosed)}
    />
  );
};

export default ShareButton;
