import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Select } from 'antd';

import PageHeader from 'APP_COMPONENTS/PageHeader';
import AdministratorWrapper from '../Administrator.styled';
import getRoute from '../../../utils/get-route';
import withoutClutter from '../../../components/without-clutter';
import DashboardPage from '../../../components/dashboard';
import withOwnershipGuard from '../components/agency-ownership-guard';
import { AuditLogWrapper } from '../agency-audit-logs/AgencyAuditLogs.styled';
import ReportTimelineAuditLogsPage from './pages/report-timeline-audit-logs-page';
import { formCleanReportTimelineLogs } from '../../../actions/organizations/get-report-timeline-audit-logs';
import { LAYOUTS } from './constants';

const { Option } = Select;

const AgencyAuditLogsReportTimeline = ({
  dispatch,
  reportTimelineLogs = [],
  agencyId,
  tenantId,
  timezone,
}) => {
  const [currentLayout, setCurrentLayout] = useState(LAYOUTS.NEW);

  useEffect(() => {
    return () => dispatch(formCleanReportTimelineLogs());
  }, []);

  const handleChangeLayout = newLayout => setCurrentLayout(newLayout);

  return (
    <AdministratorWrapper>
      <PageHeader
        title="Reports Audit Logs"
        goBackTo={getRoute('administratorAgency', { agencyId })}
        actions={[
          <Select
            key="reports-audit-logs-actions"
            defaultValue={LAYOUTS.NEW}
            onChange={handleChangeLayout}
            style={{ width: 325 }}
          >
            <Option value={LAYOUTS.NEW}>
              Report Activity After February 2025
            </Option>
            <Option value={LAYOUTS.OLD}>
              Report Activity Before February 2025
            </Option>
          </Select>,
        ]}
      />
      <AuditLogWrapper>
        <ReportTimelineAuditLogsPage
          dispatch={dispatch}
          agencyId={agencyId}
          tenantId={tenantId}
          reportTimelineLogs={reportTimelineLogs}
          timezone={timezone}
          currentLayout={currentLayout}
        />
      </AuditLogWrapper>
    </AdministratorWrapper>
  );
};

const mapStateToProps = (state, props) => ({
  agencyId: get(props, 'match.params.agencyId'),
  tenantId: get(props, 'match.params.tenantId'),
  reportTimelineLogs: get(state, 'organization.reportTimelineLogs'),
  timezone: get(state, 'session.currentUser.agency.timezone'),
});

export default withoutClutter(
  DashboardPage(
    mapStateToProps,
    null,
    {}
  )(
    withOwnershipGuard(
      AgencyAuditLogsReportTimeline,
      'administratorAgencyReportTimelineAuditLogs'
    )
  )
);
