import React, { useState } from 'react';
import { change, arrayPush, arrayRemove } from 'redux-form';

//PPL-566 Replaced the route to use the same component and logic on User Profile as in organization profile for upload documents.
//The use of the previous component was the root cause of the issue due the logic was oriented to a different functionality for other sections.
import SectionAddButton from 'APP_ROOT/components/custom-sections/sections/SectionAddButton';
import CustomSectionTable from '../../../../../components/custom-sections/tables/CustomSectionTable';
import UserDocumentModal from './UserDocumentModal';
import { genOptions, fetchRequest } from 'APP_ROOT/utils/request';
import storage from 'APP_ROOT/utils/storage';
import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from '../../../../../components/custom-sections/sections/Sections.styled';
import { get } from 'lodash';

import {
  ATTRIBUTE_TYPE_STRING,
  ATTRIBUTE_TYPE_LINK,
} from '../UserProfile.constants';
import { translate } from '../../../../../i18next';

const UserDocument = props => {
  const [recordId, setRecordId] = useState(0);
  const [data, setData] = useState({});

  const _sectionAttributes = [
    {
      attributeName: 'description',
      type: ATTRIBUTE_TYPE_STRING,
      title: translate('containers.profile.documents.description'),
      settings: { tableColumn: 1, unique: false },
    },
    {
      attributeName: 'fileName',
      type: ATTRIBUTE_TYPE_LINK,
      title: translate('containers.profile.documents.file'),
      settings: { tableColumn: 2 },
    },
    {
      attributeName: 'documentType',
      type: ATTRIBUTE_TYPE_STRING,
      title: translate('containers.profile.documents.documentType'),
      settings: { tableColumn: 3 },
    },
    {
      attributeName: 'uploaded',
      type: ATTRIBUTE_TYPE_STRING,
      title: translate('containers.profile.documents.uploaded'),
      settings: { tableColumn: 4, defaultValue: null },
    },
  ];

  const {
    profileForm,
    editSection: pEditSection,
    updateSectionField: pUpdateSectionField,
    addSectionFieldValue: pAddSectionFieldValue,
    removeSectionFieldValue: pRemoveSectionFieldValue,
    setEditSectionTable: pSetEditSectionTable,
    userProfile,
    isSectionReadOnly = false,
    parentContent,
    showDocumentType,
    showSaveWarning,
    modalOkLabel,
    sectionAttributes = _sectionAttributes,
  } = props;

  const _updateSectionField = (field, value) => {
    const { dispatch } = parentContent.props;
    dispatch(change(parentContent.formName, field, value));
  };

  const _addSectionFieldValue = (field, value) => {
    const { dispatch } = parentContent.props;
    dispatch(arrayPush(parentContent.formName, field, value));
  };

  const _removeSectionFieldValue = (field, index) => {
    const { dispatch } = parentContent.props;
    dispatch(arrayRemove(parentContent.formName, field, index));
    parentContent.setState({ editUserSection: null });
  };

  const _setEditSectionTable = (sectionId, key) => {
    if (sectionId) {
      parentContent.setState({ editUserSection: { sectionId, key } });
    } else {
      parentContent.setState({ editUserSection: null });
    }
  };

  const editSection =
    pEditSection || get(parentContent, 'state.editUserSection');
  const updateSectionField = pUpdateSectionField || _updateSectionField;
  const addSectionFieldValue = pAddSectionFieldValue || _addSectionFieldValue;
  const removeSectionFieldValue =
    pRemoveSectionFieldValue || _removeSectionFieldValue;
  const setEditSectionTable = pSetEditSectionTable || _setEditSectionTable;

  const onActionCallback = (record, _, __, callback) => {
    const { key } = record;
    const data = get(profileForm, `values.userDocumentsData.documents[${key}]`);

    setRecordId(record.key);
    setData(data);
    callback();
  };

  const onCancelCallback = () => {
    updateSectionField(`userDocumentsData[${recordId}]`, data);
  };

  const downloadDocument = (e, index) => {
    e.preventDefault();

    const getAttachmentURL = get(
      profileForm,
      `values.userDocumentsData.documents.locationUrl`
    )[index];
    // S3 attachments do not have a constant URL, it must be requested every time.
    if (getAttachmentURL.includes('/request-url')) {
      const options = genOptions('GET', null, storage.get('token'));
      fetchRequest(getAttachmentURL, options).then(response => {
        const element = document.createElement('a');
        element.href = response.url;
        element.download = '';
        element.click();
      });
    } else {
      const url = getAttachmentURL + '?access_token=' + storage.get('token');
      const element = document.createElement('a');
      element.href = url;
      element.download = '';
      element.click();
    }
  };

  const buildDocumentsModal = (
    readOnly,
    showModal,
    sectionId,
    rowIndex,
    handleModalCancel,
    handleModalOk,
    isNew
  ) => {
    return (
      <UserDocumentModal
        title={
          isNew
            ? translate('containers.profile.documents.addDocument')
            : translate('containers.profile.documents.documentDetails')
        }
        attributes={sectionAttributes}
        profileForm={profileForm}
        readOnly={readOnly}
        visible={showModal}
        sectionId={sectionId}
        rowIndex={rowIndex}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        updateSectionField={updateSectionField}
        userProfile={userProfile}
        isNew={isNew}
        showDocumentType={showDocumentType}
        showSaveWarning={showSaveWarning}
        okLabel={modalOkLabel}
      />
    );
  };

  return (
    <StyledCollapse
      bordered={false}
      defaultActiveKey="documents"
      expandIconPosition="right"
    >
      <StyledPanel
        header={
          <StyledPanelTitle size="large">
            {translate('containers.profile.documents.documents')}
          </StyledPanelTitle>
        }
        key="documents"
        className="section-panel"
        extra={
          <SectionAddButton
            sectionId="documents"
            attributeId="id"
            prefix="userDocumentsData"
            attributes={sectionAttributes}
            mainForm={profileForm}
            addSectionFieldValue={addSectionFieldValue}
            setEditSectionTable={setEditSectionTable}
            buttonTitle={translate('containers.profile.documents.addDocument')}
            disabled={isSectionReadOnly}
          ></SectionAddButton>
        }
      >
        <hr></hr>
        <CustomSectionTable
          modalTitle="Add Document"
          sectionId="documents"
          prefix="userDocumentsData"
          attributeId="id"
          attributes={sectionAttributes}
          mainForm={profileForm}
          editSection={editSection}
          addSectionFieldValue={addSectionFieldValue}
          updateSectionField={updateSectionField}
          removeSectionFieldValue={removeSectionFieldValue}
          setEditSectionTable={setEditSectionTable}
          modalFor="documents"
          onAction={onActionCallback}
          onCancel={onCancelCallback}
          linkAction={downloadDocument}
          isSectionReadOnly={isSectionReadOnly}
          buildModal={buildDocumentsModal}
        />
      </StyledPanel>
    </StyledCollapse>
  );
};

export default UserDocument;
