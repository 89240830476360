import React from 'react';
import parsedDate from '../../utils/parse-date';
import { translate } from '../../i18next.js';
import { parseHtmlInput } from 'APP_ROOT/utils/request';

const AdminEditItem = ({ item, index, timezone }) => {
  const submissionMessage = translate(
    'containers.reports.timeline.editedThisReport',
    {
      actor: item.actor || '',
    }
  );

  return (
    <div>
      <p className={`timeline-item-date-${index}`}>
        {parsedDate(item.created, timezone)}
      </p>
      <p>
        <b>
          {' '}
          {item.formType} {item.formNumber}{' '}
        </b>
      </p>
      <p className={`timeline-item-message-${index}`}>
        {parseHtmlInput(submissionMessage)}
      </p>
    </div>
  );
};

export default AdminEditItem;
