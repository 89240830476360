import React, { Fragment } from 'react';
import { Tooltip } from 'antd';

import IconButton from '../../../components/common/buttons/icon-button';
import { REPORT_HISTORY } from './constants';

import viewHistory from './viewHistory';

import { translate } from '../../../i18next';

const renderTimelineButton = record => {
  const { [REPORT_HISTORY]: history, report } = record;

  if (!history?.length) return <Fragment />;
  const historyIcon = require('APP_ROOT/icons/ic_history.svg');
  const TIMELINE = translate('containers.reports.timelineIcon');

  return (
    <Tooltip title={TIMELINE}>
      <IconButton
        key="timeline"
        shape="circle"
        onClick={viewHistory({ report })}
        className="timeline"
        data-idtest="reports-timeline-icon"
      >
        <img src={historyIcon} alt={TIMELINE} />
      </IconButton>
    </Tooltip>
  );
};

export default renderTimelineButton;
