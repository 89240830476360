import React from 'react';
import ModalBody from 'APP_ROOT/components/common/modal/body';
import { translate } from '../../../i18next.js';

export default props => {
  const { reassignedTo, reportName } = props;

  return (
    <ModalBody>
      <p>
        {`${reportName} ${translate(
          'containers.reports.notifications.reassignedReport'
        )} ${reassignedTo}`}
      </p>
    </ModalBody>
  );
};
