import { camelCase } from 'lodash';

import AgencyBranding from './AgencyBranding';
import { PERMISSIONS } from '../../../utils/admin';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/agency-branding/:tenantId`,
    name: camelCase(`${name}Branding`),
    component: AgencyBranding,
    protectedWith: [PERMISSIONS.manageAgencyAttributes],
    childRoutes: [],
  },
];
