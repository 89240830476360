import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Button } from 'antd';

import store from 'APP_ROOT/store';
import { getCurrentUser } from 'APP_ROOT/selectors/session';
import { hasPermissions, PERMISSIONS } from 'APP_ROOT/utils/admin';

import hideModal from '../../../actions/hide-modal';
import { DELETE_MODAL_ID } from '../constants/delete';
import ChangesCaseFileDelete from '../services/changesCaseFileDelete';
import { translate } from '../../../i18next';

class ModalFooter extends Component {
  state = { loading: false };
  componentDidMount() {
    this.closeModalSuscription = ChangesCaseFileDelete.getSubmit().subscribe(
      data => data && this.closeModal()
    );
  }

  componentWillUnmount() {
    this.closeModalSuscription.unsubscribe();
  }

  closeModal = () => {
    const { dispatch } = store;
    this.setState({ loading: false });
    dispatch(hideModal(DELETE_MODAL_ID));
    ChangesCaseFileDelete.setIsOpenModal(false);
  };

  submit = () => {
    this.setState({ loading: true });
    ChangesCaseFileDelete.setSubmit(true);
  };

  checkIfItsOwnerOrAdmin = () => {
    const {
      user: { permissions } = {},
      isCasefileClosed: { isOwner } = {},
    } = this.props;
    return (
      isOwner || hasPermissions(permissions, PERMISSIONS.manageAllCasefiles)
    );
  };

  render() {
    const { loading } = this.state;
    const { isCasefileClosed: { closed } = {} } = this.props;
    return (
      <div>
        <Button onClick={this.closeModal} disabled={loading}>
          {translate('containers.caseFiles.cancel')}
        </Button>
        <Button
          type="primary"
          onClick={this.submit}
          loading={loading}
          disabled={closed ? !this.checkIfItsOwnerOrAdmin() : false}
        >
          {translate('containers.caseFiles.delete')}
        </Button>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    user: getCurrentUser(state),
    isCasefileClosed: get(
      state,
      'forms.manageCaseFile.values.isCasefileClosed',
      ''
    ),
  };
};

export default connect(mapState)(ModalFooter);
