import { DATE, DATE_TIME, TIME } from '../../../constants/fieldTypes';

export const FORM_HEADER = 'fromHeader';

export const SOURCE_FIELD_DEFAULT_VALUE = ['none'];

export const TIME_UNITS_YEARS = 'YY';
export const TIME_UNITS_MINUTES = 'mm';

// to be used in duration field units
export const TIME_UNITS = [
  { label: 'Years', value: TIME_UNITS_YEARS },
  // this will be needed later
  // { label: 'Months', value: 'MO' },
  // { label: 'Days', value: 'DD' },
  // { label: 'Hours', value: 'hh' },
  { label: 'Minutes', value: TIME_UNITS_MINUTES },
  // { label: 'Seconds', value: 'ss' },
];

// to be used to compute diffence dates units
export const TIME_UNITS_CONVERSION = {
  [TIME_UNITS_YEARS]: 'years',
  [TIME_UNITS_MINUTES]: 'minutes',
};

export const DURATION_FIELDS_YEARS = [DATE, DATE_TIME];
export const DURATION_FIELDS_MINUTES = [DATE_TIME, TIME];

export const DURATION_FIELDS_RELATED = [
  ...DURATION_FIELDS_YEARS,
  ...DURATION_FIELDS_MINUTES,
];

export const CASE_NUMBER = 'caseNumber';
export const CASE_NUMBER_LABEL = 'Case Number';
export const SEARCHABLE_BY = 'searchable_by';

export const EVENT_DATE = 'eventDate';
export const EVENT_DATE_LABEL = 'Event Date';

export const DEFAULT_VALUE = 'defaultValue';
export const DEFAULT_VALUE_LABEL = 'Default Value';

// Math field
export const MATH_OPERATION_SUM = 'sum';
export const MATH_OPERATIONS = [
  { label: 'Sum', value: MATH_OPERATION_SUM },
  { label: 'Average', value: 'avg' },
];

export const MATH_ROUND = '2';
export const MATH_ROUNDING = [
  { label: 'Nearest Whole Number', value: '0' },
  { label: 'Nearest 2 Decimals', value: MATH_ROUND },
];
