import React from 'react';
import ModalBody from 'APP_ROOT/components/common/modal/body';
import { isEmpty } from 'lodash';
import { translate } from '../../../i18next';
export default props => {
  const { namesToShare, reportName, usersToUnshare } = props;

  const shares = [];
  if (!isEmpty(namesToShare)) {
    shares.push(
      <strong key="shared">
        {translate('containers.reports.notifications.shared')}
      </strong>,
      ` ${translate('containers.reports.notifications.with')}  ${namesToShare}`
    );
    if (!isEmpty(usersToUnshare)) {
      shares.push(` ${translate('containers.reports.notifications.and')} `);
    }
  }
  if (!isEmpty(usersToUnshare)) {
    shares.push(
      <strong key="unshared">
        {translate('containers.reports.notifications.unshared')}
      </strong>,
      ` ${translate('containers.reports.notifications.with')} ${usersToUnshare}`
    );
  }
  return (
    <ModalBody>
      <p>
        {reportName} {translate('containers.reports.notifications.hasBeen')}{' '}
        {shares.map(s => s)}
      </p>
    </ModalBody>
  );
};
