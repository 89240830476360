import { Menu } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  min-height: 34px;
  height: auto;
  margin-top: 3px;

  display: block;
  position: relative;

  border: solid 1px rgba(78, 78, 78, 0.5);
  color: #4e4e4e;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0;

  &:hover {
    border-color: #0040dd;
  }

  &.focused {
    outline: 1px solid #0040dd39;
  }
`;

const Select = styled.div`
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  outline: 0;
`;

const Selection = styled.div`
  width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 0;
  outline: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  min-height: 32px;
  cursor: text;
  zoom: 1;
  display: block;
  clear: both;
`;

const Selected = styled.li`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  max-width: 99%;
  float: left;
  margin: 3px 0 3px 5px;
  padding: 3px 5px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 0;
  cursor: default;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;

  .ant-badge {
    display: flex;
    justify-content: space-between;
    gap: 3px;
    height: 16px;
    line-height: 16px;
    max-width: 160px;
    overflow: hidden;
    font-size: 14px;

    span {
      overflow: hidden;
      width: 88%;
      white-space: nowrap;
      text-overflow: clip;
    }

    .anticon {
      font-size: 10px;
      line-height: 20px;
      opacity: 0.6;
      cursor: pointer;
    }
  }

  .plusBadge span {
    width: 100%;
  }
`;

const SearchWrapper = styled.div`
  input {
    width: 95%;
    padding: 1px;
    margin-left: 4px;
    margin-top: 5px;

    height: 100%;
    font-size: 100%;
    max-width: 100%;

    line-height: 1;
    background: transparent;
    border: none;
    border-width: 0;
    border-radius: 0;
    outline: 0;
    color: rgba(0, 0, 0, 0.65);
  }
`;

const Clear = styled.span`
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1000;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #fff;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  -o-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;

  .anticon:hover {
    color: rgba(0, 0, 0, 0.45);
  }
`;

const Placeholder = styled.div`
  display: block;
  user-select: none;
  font-weight: 400;
  color: rgba(78, 78, 78, 0.8) !important;
  line-height: 24px;
  margin-left: 8px;
  font-size: 14px;

  position: absolute;
  top: 55%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
`;

const MultiselectMenu = styled(Menu)`
  width: 100%;
  padding: 0;
  margin-top: 5px;
  position: absolute;
  z-index: 1001;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(155, 155, 155, 0.5),
    0 1px 2px 0 rgba(155, 155, 155, 0.49) !important;
  max-height: 255px;
  overflow-y: scroll;

  .ant-menu-item {
    width: 100%;
    display: inline-block;
    margin: 0;
    font-size: 12px;
    line-height: 12px;
    color: #4e4e4e;
    height: 32px;

    -webkit-transition: color 0.3s ease, opacity 0.15s ease;
    -o-transition: color 0.3s ease, opacity 0.15s ease;
    transition: color 0.3s ease, opacity 0.15s ease;

    &.font-weight-bold {
      font-weight: 600;
    }

    &:focus {
      background-color: #e6f1ff !important;
    }
  }

  .ant-menu-item-selected {
    background-color: #dcebfc !important;
  }

  .menu-item-hover {
    background-color: #e6f1ff !important;
  }

  .anticon {
    line-height: 35px;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  span {
    max-width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const MenuContent = styled.div`
  padding: 10px;

  .not-found-content {
    display: block;
    user-select: none;
    font-weight: 400;
    color: #4e4e4e39;
    line-height: 20px;
    font-size: 12px;
  }
`;

export {
  Wrapper,
  Select,
  Selection,
  Selected,
  SearchWrapper,
  Clear,
  Placeholder,
  MultiselectMenu,
  MenuContainer,
  MenuContent,
};
