import styled from 'styled-components';

const StyledRepNumDiv = styled.div`
  display: flex;
  flex-direction: column;

  a {
    cursor: pointer;
  }

  .report {
    display: flex;
    justify-content: space-between;

    // Hate to use important, but it is already
    // defined like that in the project
    .ant-btn-circle {
      padding: 2px !important;
      width: 20px !important;
      height: 19px !important;
    }
  }

  .linked-report {
    font-size: 12px;

    &:hover {
      font-size: 15px;
    }
  }
`;

export default StyledRepNumDiv;
