import { SET_LAST_SAVE_DATE, SET_ACTIVE_SECTION } from '../../actions';

const events = {
  [SET_LAST_SAVE_DATE]: (state, { payload: lastSaveDate }) => {
    const selectedForm = {
      ...state.selectedForm,
      lastUpdate: {
        ...state.selectedForm.lastUpdate,
        lastSaveDate,
      },
    };
    return {
      ...state,
      selectedForm,
    };
  },
  [SET_ACTIVE_SECTION]: (state, { payload: activeSection }) => {
    const selectedForm = {
      ...state.selectedForm,
      lastUpdate: {
        ...state.selectedForm.lastUpdate,
        activeSection,
      },
    };
    return {
      ...state,
      selectedForm,
    };
  },
};

export default events;
