export const STYLE_OPTIONS = { width: 260 };
export const ITEM_LAYOUT = {
  colon: false,
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const DATE_BOUNDARY_TYPE_LABEL = 'Date Boundary Type';
export const NONE_LABEL = 'None';
export const NONE_VALUE = 'none';
export const EXPLICIT_LABEL = 'Explicit Date';
export const EXPLICIT_VALUE = 'explicit';
export const RANGE_LABEL = 'Range';
export const RANGE_VALUE = 'range';
export const EXPLICIT_LOWER_BOUNDARY_LABEL = 'Explicit Lower Boundary';
export const EXPLICIT_LOWER_BOUNDARY_VALUE = 'explicitLowerBoundary';
export const EXPLICIT_UPPER_BOUNDARY_LABEL = 'Explicit Upper Boundary';
export const EXPLICIT_UPPER_BOUNDARY_VALUE = 'explicitUpperBoundary';

//Range date boundary
export const RANGE_BOUNDARY_KEY = 'rangeBoundary';
export const DEFAULT_RANGE_BOUNDARY_TYPE_LABEL = 'Select Boundary';
export const PAST_LOWER_BOUNDARY_LABEL = 'Past Lower Boundary';
export const PAST_LOWER_BOUNDARY_VALUE = 'pastLowerBoundary';
export const FUTURE_LOWER_BOUNDARY_LABEL = 'Future Lower Boundary';
export const FUTURE_LOWER_BOUNDARY_VALUE = 'futureLowerBoundary';
export const PAST_UPPER_BOUNDARY_LABEL = 'Past Upper Boundary';
export const PAST_UPPER_BOUNDARY_VALUE = 'pastUpperBoundary';
export const FUTURE_UPPER_BOUNDARY_LABEL = 'Future Upper Boundary';
export const FUTURE_UPPER_BOUNDARY_VALUE = 'futureUpperBoundary';
export const DECIMAL_REGEX = /\D/g;
export const DAY_LABEL = 'Day(s)';
export const DAY_VALUE = 'day';
export const YEAR_LABEL = 'Year(s)';
export const YEAR_VALUE = 'year';
export const RANGE_BOUNDARY_DEFAULT_ROW = {
  type: undefined,
  limit: undefined,
  unit: undefined,
};
export const TYPE_OPTIONS = [
  { label: PAST_LOWER_BOUNDARY_LABEL, value: PAST_LOWER_BOUNDARY_VALUE },
  { label: FUTURE_LOWER_BOUNDARY_LABEL, value: FUTURE_LOWER_BOUNDARY_VALUE },
  { label: PAST_UPPER_BOUNDARY_LABEL, value: PAST_UPPER_BOUNDARY_VALUE },
  { label: FUTURE_UPPER_BOUNDARY_LABEL, value: FUTURE_UPPER_BOUNDARY_VALUE },
];
export const SUPPORTED_COMBINATIONS = {
  [PAST_LOWER_BOUNDARY_VALUE]: [
    { label: PAST_UPPER_BOUNDARY_LABEL, value: PAST_UPPER_BOUNDARY_VALUE },
    { label: FUTURE_UPPER_BOUNDARY_LABEL, value: FUTURE_UPPER_BOUNDARY_VALUE },
  ],
  [FUTURE_LOWER_BOUNDARY_VALUE]: [
    { label: FUTURE_UPPER_BOUNDARY_LABEL, value: FUTURE_UPPER_BOUNDARY_VALUE },
  ],
};
