import React, { Component } from 'react';
import { Button, notification, Input, Modal } from 'antd';
import { get, isEmpty } from 'lodash';
import { unescape as htmlUnescape } from 'html-escaper';
import { StyledModal, RemoveButton } from './AddNoteModal.styled';
import { translate } from '../../../i18next';

const { TextArea } = Input;
const { confirm } = Modal;
const ATTRIBUTE_DETAILS = 'details';

class AddNoteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: '',
      prevDetails: undefined,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { visible, isNew } = nextProps;
    const { prevDetails } = prevState;
    if (visible && !isNew && prevDetails === undefined) {
      const { prefix, sectionId, rowIndex } = nextProps;
      const nextDetails = get(nextProps, [
        'profileForm',
        'values',
        prefix,
        sectionId,
        'details',
        rowIndex,
      ]);

      return { details: nextDetails, prevDetails: nextDetails };
    }
    return null;
  }

  showNotification = (type, message, description) =>
    notification[type]({ message, description });

  initData = () =>
    this.setState({
      details: '',
      prevDetails: undefined,
    });

  onModalOk = e => {
    const {
      onOk,
      isNew,
      rowIndex,
      profileForm,
      prefix,
      sectionId,
    } = this.props;
    const { details } = this.state;
    const rowId = get(profileForm, [
      'values',
      prefix,
      sectionId,
      'objectValueId',
      rowIndex,
    ]);
    const methodType = 'add';

    onOk && onOk(e, { details, isNew, rowIndex, rowId, methodType });
    this.initData();
  };

  onModalRemove = e => {
    const {
      onOk,
      isNew,
      rowIndex,
      profileForm,
      prefix,
      sectionId,
    } = this.props;
    const { details } = this.state;
    const rowId = get(profileForm, [
      'values',
      prefix,
      sectionId,
      'objectValueId',
      rowIndex,
    ]);
    const methodType = 'remove';
    confirm({
      title: 'Are you sure you want to remove this note from this file?',
      content: htmlUnescape(details),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        onOk && onOk(e, { details, isNew, rowIndex, rowId, methodType });
        this.initData();
      },
    });
  };

  onModalCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
    this.initData();
  };

  onChangeDetails = e => {
    const { value } = e.target;
    this.setState({ details: value });
  };

  isOwner = () => {
    const { userId, profileForm, prefix, sectionId, rowIndex } = this.props;
    const createdBy = get(profileForm, [
      'values',
      prefix,
      sectionId,
      'createdBy',
      rowIndex,
    ]);
    return userId === createdBy;
  };

  render() {
    const { title, visible, readOnly, isNew, attributes = [] } = this.props;
    const { details = '' } = this.state;
    const { settings: { maxLength = 2500 } = {} } =
      attributes.find(a => a.attributeName === ATTRIBUTE_DETAILS) || {};

    const canEdit = isNew ? true : readOnly ? false : this.isOwner();
    const disableSave = !canEdit || isEmpty(details.trim());
    const disableRemove = isNew || readOnly || !this.isOwner();
    return (
      <StyledModal
        title={title}
        width={1000}
        className="add-note-modal"
        centered
        visible={visible}
        onOk={this.onModalOk}
        onCancel={this.onModalCancel}
        footer={[
          <RemoveButton
            type="danger"
            key="Remove"
            onClick={this.onModalRemove}
            disabled={disableRemove}
          >
            {translate('containers.caseFiles.remove')}
          </RemoveButton>,
          <Button key="cancel" onClick={this.onModalCancel}>
            {translate('containers.caseFiles.cancel')}
          </Button>,
          <Button
            key="Save"
            type="primary"
            onClick={this.onModalOk}
            disabled={disableSave}
          >
            {translate('containers.caseFiles.save')}
          </Button>,
        ]}
      >
        <TextArea
          rows={10}
          maxLength={maxLength}
          readOnly={!canEdit}
          autoFocus
          value={htmlUnescape(details)}
          onChange={this.onChangeDetails}
        />
      </StyledModal>
    );
  }
}

export default AddNoteModal;
