import React, { useState } from 'react';
import If from 'APP_COMPONENTS/utils/ConditionalRender';
import SectionAddButton from '../../custom-sections/sections/SectionAddButton';
import CustomSectionTable from '../../custom-sections/tables/CustomSectionTable';
import OrganizationDocumentModal from './OrganizationDocumentModal';
import OrganizationDocumentSkeleton from './OrganizationDocumentSkeleton';

import { genOptions, fetchRequest } from 'APP_ROOT/utils/request';
import storage from 'APP_ROOT/utils/storage';
import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from '../../custom-sections/sections/Sections.styled';
import { get } from 'lodash';
import {
  ATTRIBUTE_TYPE_STRING,
  ATTRIBUTE_TYPE_LINK,
} from '../../custom-sections/sections/constants';
import { translate } from '../../../i18next';

const OrganizationDocument = props => {
  const [recordId, setRecordId] = useState(0);
  const [data, setData] = useState({});

  const sectionAttributes = [
    {
      attributeName: 'description',
      type: ATTRIBUTE_TYPE_STRING,
      title: translate('containers.organizationProfile.description'),
      settings: { tableColumn: 1, unique: false },
    },
    {
      attributeName: 'fileName',
      type: ATTRIBUTE_TYPE_LINK,
      title: translate('containers.organizationProfile.file'),
      settings: { tableColumn: 2 },
    },
    {
      attributeName: 'documentType',
      type: ATTRIBUTE_TYPE_STRING,
      title: translate('containers.organizationProfile.documentType'),
      settings: {
        tableColumn: 3,
        defaultValue: translate('containers.organizationProfile.other'),
      },
    },
    {
      attributeName: 'uploaded',
      type: ATTRIBUTE_TYPE_STRING,
      title: translate('containers.organizationProfile.uploaded'),
      settings: { tableColumn: 4, defaultValue: null },
    },
  ];

  const {
    organizationProfileForm,
    isLoading,
    editSection,
    updateSectionField,
    addSectionFieldValue,
    removeSectionFieldValue,
    setEditSectionTable,
    isSectionReadOnly = false,
  } = props;

  const onActionCallback = (record, _, __, callback) => {
    const { key } = record;
    const data = get(
      organizationProfileForm,
      `values.documentsData.documents[${key}]`
    );

    setRecordId(record.key);
    setData(data);
    callback();
  };

  const onCancelCallback = () => {
    updateSectionField(`documentsData[${recordId}]`, data);
  };

  const downloadDocument = (e, index) => {
    e.preventDefault();

    const getAttachmentURL = get(
      organizationProfileForm,
      `values.documentsData.documents.locationUrl`
    )[index];
    // S3 attachments do not have a constant URL, it must be requested every time.
    if (getAttachmentURL.includes('/request-url')) {
      const options = genOptions('GET', null, storage.get('token'));
      fetchRequest(getAttachmentURL, options).then(response => {
        const element = document.createElement('a');
        element.href = response.url;
        element.download = '';
        element.click();
      });
    } else {
      const url = getAttachmentURL + '?access_token=' + storage.get('token');
      const element = document.createElement('a');
      element.href = url;
      element.download = '';
      element.click();
    }
  };

  const buildDocumentModal = (
    readOnly,
    showModal,
    sectionId,
    rowIndex,
    handleModalCancel,
    handleModalOk,
    isNew
  ) => {
    return (
      <OrganizationDocumentModal
        title={translate('containers.organizationProfile.addDocument')}
        attributes={sectionAttributes}
        organizationProfileForm={organizationProfileForm}
        readOnly={readOnly}
        visible={showModal}
        sectionId={sectionId}
        rowIndex={rowIndex}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        addSectionFieldValue={addSectionFieldValue}
        updateSectionField={updateSectionField}
        removeSectionFieldValue={removeSectionFieldValue}
        setEditSectionTable={setEditSectionTable}
        editSection={editSection}
        isNew={isNew}
      />
    );
  };

  return (
    <StyledCollapse
      bordered={false}
      defaultActiveKey="documents"
      expandIconPosition="right"
    >
      <StyledPanel
        header={
          <StyledPanelTitle size="large">
            {translate('containers.organizationProfile.documents')}
          </StyledPanelTitle>
        }
        key="documents"
        className="section-panel"
        extra={
          <SectionAddButton
            sectionId="documents"
            attributeId="id"
            prefix="documentsData"
            attributes={sectionAttributes}
            mainForm={organizationProfileForm}
            addSectionFieldValue={addSectionFieldValue}
            setEditSectionTable={setEditSectionTable}
            buttonTitle={translate(
              'containers.organizationProfile.addDocument'
            )}
            disabled={isSectionReadOnly}
          ></SectionAddButton>
        }
      >
        <hr></hr>
        <If condition={isLoading}>
          <OrganizationDocumentSkeleton />
        </If>
        <If condition={!isLoading}>
          <CustomSectionTable
            key="documents"
            modalTitle={translate('containers.organizationProfile.addDocument')}
            sectionId="documents"
            prefix="documentsData"
            attributeId="id"
            attributes={sectionAttributes}
            mainForm={organizationProfileForm}
            editSection={editSection}
            addSectionFieldValue={addSectionFieldValue}
            updateSectionField={updateSectionField}
            removeSectionFieldValue={removeSectionFieldValue}
            setEditSectionTable={setEditSectionTable}
            modalFor="documents"
            onAction={onActionCallback}
            onCancel={onCancelCallback}
            linkAction={downloadDocument}
            isSectionReadOnly={isSectionReadOnly}
            buildModal={buildDocumentModal}
          />
        </If>
      </StyledPanel>
    </StyledCollapse>
  );
};

export default OrganizationDocument;
