import styled from 'styled-components';

const StyledLastSaveDate = styled.div`
  color: #ddd;
  font-size: 14px;
  text-align: right;
  padding-bottom: 10px;
`;

export default StyledLastSaveDate;
