import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Pagination, Icon } from 'antd';
import styled from 'styled-components';
import {
  has,
  kebabCase,
  uniqBy,
  find,
  reverse,
  get,
  castArray,
  uniq,
} from 'lodash';

import { FEATURES, hasFeatures } from 'APP_ROOT/utils/features';

// Actions
import getReports from '../../../../actions/get-reports';

import ReportCard from '../../../../components/report-card';
import FormFilter from '../../../../components/common/form-filter';
import setPageRetention from '../../../reports/utils/setPageRetention';
import getPageRetention from '../../../reports/utils/getPageRetention';

import PageHeader from 'APP_COMPONENTS/PageHeader';

import { getAgencyTZ } from '../../../../selectors/session';
import retrieveAgencyTemplates from '../../../../actions/utils/get-form/retrieveAgencyTemplates';
import getReportsTemplates from '../../../../actions/get-reports-templates';
import { compileFilterForIntakeStatus } from 'APP_ROOT/components/common/form-filter/filters/intake-status';
import getRoute from 'APP_ROOT/utils/get-route';
import { getTemplatesLoading, getTemplates } from '../../../../selectors/form';
import { getCurrentUser } from '../../../../selectors/session';
import setReportListFilters from '../../../../actions/set-report-list-filters';

import ReportsTable from '../../../reports/ReportsTable';
import { translate } from '../../../../i18next';

// IMPORTANT:
// This page code is TRIPLICATED, any change here should be
// done in this replicated in the 3 pages:
// src/containers/reports/index.js
// src/containers/administrator/agency-reports/Reports/ListOfReports.js
// src/containers/reports/uiWithRefactoredEndpoint.js

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: '',
      mainFilter: [],
      dataFilter: [],
      keywords: '',
      view: '',
      delayCompleted: false,
      loadingTemplates: true,
    };
  }

  async componentDidMount() {
    const { dispatch, templates, agencyId } = this.props;

    await retrieveAgencyTemplates({
      dispatch,
      fallback: templates,
      shouldFetch: () => true,
      agencyId,
    });
    this.setState({ loadingTemplates: false });

    await this.waitAtLeastPromise();
    this.setState({ delayCompleted: true });
  }

  waitAtLeastPromise = (ms = 1000) =>
    new Promise(resolve => {
      global.setTimeout(resolve, ms);
    });

  getReportsTemplate = (error, reports) => {
    const { templates, dispatch, agencyId } = this.props;
    const templatesArray = Object.values(templates);

    if (!error) {
      const templateList = reports.map(
        ({ formType, templateId, templateVersionId }) => ({
          formType,
          templateId,
          templateVersionId,
          exists:
            has(templates, kebabCase(formType)) &&
            find(templatesArray, item => item.versionId === templateVersionId),
        })
      );

      const nonExistentTemplates = templateList
        .filter(templateItem => !templateItem.exists)
        .map(({ templateId }) => templateId);

      if (nonExistentTemplates.length) {
        const filteredTemplates = templatesArray.filter(({ id: templateId }) =>
          nonExistentTemplates.includes(templateId)
        );

        dispatch(
          getReportsTemplates({
            getSchemaFor: filteredTemplates,
            withTemplates: templatesArray,
            agencyIdFromOptions: agencyId,
          })
        );
      }
    }
  };

  //function which update Report Component state without calling formSubmissions endpoint
  updateOnlyStateFilters = props => {
    this.setState(props);
  };

  updateStateFilters = (
    mainFilters = [],
    dataFilters = [],
    keywords,
    clearDataFilters = false,
    view
  ) => {
    const {
      mainFilter,
      dataFilter,
      keywords: keywordsFilter,
      view: viewFilter,
    } = this.state;

    const uniqMainFilter = uniqBy(
      reverse([...mainFilter, ...mainFilters]),
      'column'
    );
    const uniqDataFilter = uniqBy(
      reverse([...dataFilter, ...dataFilters]),
      'column'
    );
    const { type: formTypeValue = '' } =
      find(uniqMainFilter, ['column', 'formType']) || {};

    this.setState(
      {
        formType: formTypeValue,
        mainFilter: uniqMainFilter,
        dataFilter: clearDataFilters
          ? uniqDataFilter.filter(x => x.column === 'incidentDate')
          : uniqDataFilter,
        keywords: keywords !== undefined ? keywords : keywordsFilter,
        view: view !== undefined ? view : viewFilter,
      },
      this.updateFilters
    );
  };

  clearFilterColumn = (filterName, columnNames) => {
    const { formType } = this.state;

    const columns = castArray(columnNames);
    const currentFilter = castArray(get(this.state, filterName, []));

    const cleanedFilter = currentFilter.filter(
      filter => !columns.includes(filter.column)
    );

    this.setState(
      {
        [filterName]: cleanedFilter,
        formType: columns.includes('formType') ? '' : formType,
      },
      this.updateFilters
    );
  };

  clearKeywords = () => {
    this.setState(
      {
        keywords: '',
      },
      this.updateFilters
    );
  };

  updateFilters = async () => {
    const { dispatch, history, agencyId } = this.props;
    const {
      mainFilter: uncompileMainFilter,
      dataFilter,
      keywords = '',
      view = '',
    } = this.state;
    const currentPage = getPageRetention(history);
    const mainFilter = compileFilterForIntakeStatus(uncompileMainFilter);
    dispatch(
      setReportListFilters({
        mainFilter: mainFilter,
        dataFilter: dataFilter,
        keywords: keywords,
        view: view,
        page: currentPage,
      })
    );
    this.setState({ delayCompleted: false });
    dispatch(
      getReports(
        currentPage,
        mainFilter,
        dataFilter,
        keywords,
        agencyId,
        async () => {
          await this.waitAtLeastPromise(500);
          this.setState({ delayCompleted: true });
        },
        view
      )
    );
  };

  getReportCards = () => {
    const {
      reportList = {},
      session: {
        currentUser: {
          userId,
          agency: { timezone },
        },
      } = {},
      total = 0,
      history,
      templates,
      officers = [],
      reportOrderedList = [],
      loadingReports,
      agencyId,
    } = this.props;
    const { delayCompleted } = this.state;
    if (loadingReports || !delayCompleted) {
      return <ReportCard loading showSecondLoadingLine />;
    }

    return total > 0 ? (
      reportOrderedList.map(reportId => {
        const report = get(reportList, reportId, null);

        if (!report) {
          return null;
        }

        return (
          <ReportCard
            key={reportId}
            history={history}
            report={report}
            currentUser={userId}
            timezone={timezone}
            templates={templates}
            officers={officers}
            agencyId={agencyId}
          />
        );
      })
    ) : (
      <StyledNoResults>0 results</StyledNoResults>
    );
  };

  changePage = page => {
    const { dispatch, history, agencyId } = this.props;
    const { mainFilter, dataFilter, keywords = '', view = '' } = this.state;

    setPageRetention(page, history);
    window.scrollTo(0, 0);

    dispatch(setReportListFilters({ page: page }));
    dispatch(
      getReports(
        page,
        mainFilter,
        dataFilter,
        keywords,
        agencyId,
        this.getReportsTemplate,
        view
      )
    );
  };

  render() {
    const {
      total = 0,
      per_page = 8,
      page = 1,
      dispatch,
      templates,
      loadingReports,
      agencyId,
      timezone,
      history,
      currentUser: { featureFlags = [] } = {},
    } = this.props;
    const { formType, mainFilter, dataFilter, delayCompleted } = this.state;
    const enableReportsTable = hasFeatures(
      featureFlags,
      FEATURES.enableReportsTable
    );

    return (
      <div>
        <PageHeader
          title={translate('containers.reports.reports')}
          goBackTo={getRoute('administratorAgency', { agencyId })}
        >
          {(loadingReports || !delayCompleted) && <Icon type="loading" />}
        </PageHeader>
        <div className="administrator-content">
          <FormFilter
            action={dispatch}
            mainFilter={mainFilter}
            dataFilter={dataFilter}
            formType={formType}
            formTemplates={templates}
            onChangeFilter={this.updateStateFilters}
            clearFilterColumn={this.clearFilterColumn}
            clearKeywords={this.clearKeywords}
            timezone={timezone}
            agencyId={agencyId}
            loadingReports={loadingReports}
            updateOnlyStateFilters={this.updateOnlyStateFilters}
            history={history}
          />
          <StyledRow>
            {enableReportsTable && (
              <ReportsTable loading={!delayCompleted} adminPage />
            )}
            {!enableReportsTable && (
              <Col span={24}>{this.getReportCards()}</Col>
            )}
          </StyledRow>
          {delayCompleted && total > 0 && (
            <StyledRowPagination>
              <Col span={24} className="text-right">
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  total={total}
                  pageSize={per_page}
                  onChange={this.changePage}
                  itemRender={(page, type, originalElement) => {
                    const id = type === 'page' ? page : type;
                    return React.cloneElement(originalElement, {
                      'data-testid': `reports-pagination-page-${id}`,
                    });
                  }}
                />
              </Col>
            </StyledRowPagination>
          )}
        </div>
      </div>
    );
  }
}

const StyledNoResults = styled.div`
  text-align: center;
  padding: 50px 0;
  color: #c7c7c7;
  font-size: 2em;
`;
const StyledRow = styled(Row)`
  margin-top: 16px;
`;
const StyledRowPagination = styled(Row)`
  margin-top: 16px;
`;

const mapStateToProps = state => {
  const {
    report: {
      data: reportList,
      fetched,
      total,
      per_page,
      page,
      officers,
      list = [],
      loading,
    },
    form,
  } = state;
  const templatesFetched = get(form, 'templatesMeta.fetched', false);
  return {
    fetched,
    reportList,
    reportOrderedList: uniq(list),
    total,
    per_page,
    page,
    officers,
    timezone: getAgencyTZ(state),
    loadingReports: loading,
    templatesFetched,
    currentUser: getCurrentUser(state),
    templatesLoading: getTemplatesLoading(state),
    templates: getTemplates(state),
  };
};
export default connect(mapStateToProps)(Reports);
