import { get, pick, isEmpty } from 'lodash';

import getRoute from 'APP_ROOT/utils/get-route';

import {
  COLUMN_REPORT_NUMBER,
  COLUMN_REPORT_TYPE,
  COLUMN_SUBMISSION_DATE,
  COLUMN_SUBMITTED_BY,
  COLUMN_EVENT_DATE,
  COLUMN_CASE_NUMBER,
  COLUMN_MENTIONED_USERS,
  COLUMN_STATUS,
  COLUMN_ACTIVE_PARTICIPANT,
  REPORT_UNIT_DISPLAY_NAME,
  REPORT_LINKED_FORMS,
  REPORT_HISTORY,
} from './constants';

import getActiveParticipant from './getActiveParticipant';
import parseDate, { BENCHMARK_DATE_FORMAT } from '../../../utils/parse-date';

import getReportStatus from '../../../utils/get-report-status';
import getReportUsers from '../../../components/utils/getReportUsers';

const getReportPath = (adminPage, id, agencyId) => {
  const type = adminPage ? 'administratorAgencyReport' : 'report';
  return getRoute(type, { agencyId, id });
};
const getDate = (report, column, timezone) => {
  const date = report[column];
  return date ? parseDate(date, timezone, BENCHMARK_DATE_FORMAT) : '';
};

const getReportsTableData = ({
  reportOrderedList,
  reportList,
  currentUser,
  timezone,
  adminPage,
}) => {
  const data = reportOrderedList
    .map(id => {
      const report = get(reportList, id, null);
      if (!report) return null;

      const status = getReportStatus(report, currentUser.id);
      const reportParticipants = getReportUsers(report);
      const activeParticipant = getActiveParticipant(report);
      const linkedForms = get(report, REPORT_LINKED_FORMS, []);

      return {
        id,
        reportParticipants,
        report,
        ...pick(report, [
          COLUMN_REPORT_NUMBER,
          COLUMN_REPORT_TYPE,
          COLUMN_SUBMITTED_BY,
          COLUMN_CASE_NUMBER,
          REPORT_UNIT_DISPLAY_NAME,
          REPORT_LINKED_FORMS,
          REPORT_HISTORY,
        ]),
        [REPORT_LINKED_FORMS]: linkedForms,
        [COLUMN_SUBMISSION_DATE]: getDate(
          report,
          COLUMN_SUBMISSION_DATE,
          timezone
        ),
        [COLUMN_EVENT_DATE]: getDate(report, COLUMN_EVENT_DATE, timezone),
        [COLUMN_MENTIONED_USERS]: ['Not', 'Implemented', 'Yet'],
        [COLUMN_STATUS]: status.label,
        [COLUMN_ACTIVE_PARTICIPANT]: activeParticipant,
        key: id,
        reportPath: getReportPath(adminPage, report.id, report.agencyId),
        linkedReportsPath: linkedForms.map(r => ({
          path: getReportPath(adminPage, r.id, report.agencyId),
          text: r[COLUMN_REPORT_NUMBER],
        })),
      };
    })
    .filter(report => report !== null);

  return isEmpty(data) ? null : data;
};

export default getReportsTableData;
