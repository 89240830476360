import i18next from 'i18next';
import es from './locales/es.json';
import en from './locales/en.json';
import enUS from 'antd/lib/locale-provider/en_US';
import esES from 'antd/lib/locale/es_ES';

export const detectedBrowserLang = navigator.language || 'en-US';

export const i18nextConfig = () => {
  i18next.init({
    debug: process.env.NODE_ENV !== 'production',
    resources: {
      en: {
        translation: en.keys,
      },
      es: {
        translation: es.keys,
      },
    },
    lng: detectedBrowserLang,
    interpolation: { escapeValue: false },
    fallbackLng: 'en',
  });
};

export const translate = (key, options) => {
  if (!i18next.isInitialized) {
    i18nextConfig();
  }
  // if options are passed could be using dynamic key resolution
  if (!i18next.exists(key) && key.includes('.') && !options) {
    return key.split('.').pop();
  }
  return i18next.t(key, options);
};

export const localizeDate = dateValue => {
  const parsedDate = new Date(dateValue);
  if (parsedDate) {
    const localizedDate = new Intl.DateTimeFormat(detectedBrowserLang, {
      dateStyle: 'short',
      timeStyle: 'long',
    }).format(parsedDate);
    return localizedDate;
  }
  return '';
};

const getNestedProperty = (obj, path) => {
  return path.split('.').reduce((acc, key) => acc && acc[key], obj);
};

/*
The following function takes the label received from the API reponse,
matches it to its corresponding key in the API response and based on that
run the translate value
*/

export const translateByLabel = (label, path) => {
  const formKeys = getNestedProperty(en.keys, path);
  const foundKey = Object.keys(formKeys).find(key => formKeys[key] === label);
  label = foundKey ? translate(`${path}.${foundKey}`) : label;
  return label;
};

/*

Translates by label matching the whole rest api request

HOW TO USE

INPUT:
inputArray -> [ { label: String }] (example: [ { label: 'Employee ID' }])
path: path to object in en.json where the key value pairs are located -> String (Example: containers.administrator.agencyUsers.userProfile.form)

*/

export const translateAPIRequest = (inputArray, path) => {
  let newArray = [...inputArray];
  newArray.map(element => {
    element.label = translateByLabel(element.label, path);
    return element;
  });
  return newArray;
};

const regexes = [
  /(?<translate>You submitted)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<translate>You reviewed)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<translate>Please add more information to)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<translate>Please review)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<translate>Please complete)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<name>.+)\s(?<translate>has reviewed)\s(?<form_number>.+)/,
  /(?<name>.+)\s(?<translate>submitted)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<name>.+)\s(?<translate>updated)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<name>.+)\s(?<translate>closed)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<name>.+)\s(?<translate>reviewed)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<name>.+)\s(?<translate>requested more information for)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<name>.+)\s(?<translate>assigned full name to)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<form_label>.+)\s(?<form_name>.+)\s(?<translate>with)\s(?<form_type>.+)\s(?<form_number>.+)\s(?<translate_1>is closed)/,
  /(?<name>.+)\s(?<translate>assigned you as a contributor of)\s(?<form_name>.+). (?<translate_1>Please complete your section)/,
  /(?<name>.+)\s(?<translate>unassigned you as a contributor of)\s(?<form_name>.+)/,
  /(?<name>.+)\s(?<translate>shared Case File)\s(?<casefile_name>.+)\s(?<translate_1>with you)/,
  /(?<translate>Case File)\s(?<casefile_name>.+)\s(?<translate_1>is due today)/,
  /(?<name>.+)\s(?<translate>completed their section of)\s(?<form_name>.+)\s(?<form_number>.+)/,
  /(?<translate>A task has been completed in Case File)\s(?<casefile_name>.+)/,
  /^:icon:\s+(?<translate_1>This)\s+<%.*?%>\s+(?<translate_2>involves a)\s+<%.*?%>\s+(?<translate_3>report)\s*:linkedFormLink:$/,
];

export function translateNotification(str) {
  const matchRegex = regexes.find(r => str.match(r) !== null);
  if (!matchRegex) return str;
  const match = str.match(matchRegex);
  if (!match) return str;
  for (const [key, value] of Object.entries(match.groups)) {
    if (key.includes('translate'))
      str = str.replace(
        value,
        translate(`containers.home.notifications.${value}`)
      );
  }
  return str;
}

export function translateExpression(str, path) {
  const matchRegex = regexes.find(r => str.match(r) !== null);
  if (!matchRegex) return str;
  const match = str.match(matchRegex);
  if (!match) return str;
  for (const [key, value] of Object.entries(match.groups)) {
    if (key.includes('translate'))
      str = str.replace(value, translate(`${path}.${value}`));
  }
  return str;
}

export const antdLocale = detectedBrowserLang.includes('es') ? esES : enUS;
