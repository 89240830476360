import React, { useEffect, useState /*, useMemo*/ } from 'react';
import RangeBoundaryRow from './RangeBoundaryRow';

import {
  DAY_VALUE,
  TYPE_OPTIONS,
  RANGE_BOUNDARY_DEFAULT_ROW,
  SUPPORTED_COMBINATIONS,
} from '../constants';

const RangeDateBoundary = ({ rangeBoundary, onRangeBoundaryChange }) => {
  const initRows = [RANGE_BOUNDARY_DEFAULT_ROW, RANGE_BOUNDARY_DEFAULT_ROW];

  const [rows, setRows] = useState(
    Array.isArray(rangeBoundary) && rangeBoundary.length
      ? rangeBoundary
      : initRows
  );

  const updateRowDefaultValues = (row, field, value) => ({
    ...row,
    [field]: value,
    ...(field === 'type' &&
      value && { limit: row.limit ?? 0, unit: row.unit ?? DAY_VALUE }),
  });

  const onFieldChange = (index, field, value) => {
    // Create a copy of the current rows state
    const updatedRows = [...rows];

    // If the changed field is 'type' and it's cleared, reset the current row,
    // otherwise, update the field while ensuring default values when necessary
    updatedRows[index] =
      field === 'type' && !value
        ? { ...RANGE_BOUNDARY_DEFAULT_ROW } // Reset row
        : updateRowDefaultValues(
            updatedRows[index] || RANGE_BOUNDARY_DEFAULT_ROW,
            field,
            value
          );

    //If type option changes in the first row (index 0), then clear the second row
    if (index === 0) {
      updatedRows[1] = RANGE_BOUNDARY_DEFAULT_ROW;
    }

    setRows(updatedRows);
    onRangeBoundaryChange?.(updatedRows);
  };

  useEffect(() => {
    onRangeBoundaryChange?.(rows);
  }, [rows, onRangeBoundaryChange]);

  const firstRowType = rows[0]?.type;
  const shouldShowSecondRow =
    firstRowType && SUPPORTED_COMBINATIONS[firstRowType];
  const renderRow = (rows, index) => (
    <RangeBoundaryRow
      key={index}
      typeValue={rows[index]?.type}
      onTypeChange={value => onFieldChange(index, 'type', value)}
      typeOptions={
        index === 0 ? TYPE_OPTIONS : SUPPORTED_COMBINATIONS[firstRowType]
      }
      limitValue={rows[index]?.limit ?? ''}
      onLimitChange={value => onFieldChange(index, 'limit', value)}
      unitValue={rows[index]?.unit}
      onUnitChange={value => onFieldChange(index, 'unit', value)}
    />
  );

  return (
    <>
      {renderRow(rows, 0)}
      {shouldShowSecondRow && renderRow(rows, 1)}
    </>
  );
};

export default RangeDateBoundary;
