import React, { Component } from 'react';
import { Select } from 'antd';
import { get, omit } from 'lodash';
import classNames from 'classnames';
import scrollToComponent from 'react-scroll-to-component';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';

import { mapProperties } from '../../../utils/form';
import NavigationSteps from '../styled/navigation-steps';
import NavigationSelect from '../styled/navigation-select';
import TabsNavigation from '../../form/tabs-navigation';
import emitter, { EventTypes } from 'APP_ROOT/utils/eventEmitter';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';
import { getFormValidationState } from 'APP_ROOT/selectors/form';
import {
  setTabsWithErrors,
  updateTabsWithError,
  validateCurrentTabFields,
} from '../../utils/tabs';
import store from '../../../store';
import { translate } from '../../../i18next';

const Option = Select.Option;
const DEFAULT_HEIGHT = 200;

class TabsHeader extends Component {
  constructor(props) {
    const { options = {}, isReviewer, properties = [] } = props;
    const { active: current = 0 } = options;
    super(props);
    const initialTabs = properties.map((_, index) => index);
    updateTabsWithError(initialTabs);
    this.state = {
      current: isReviewer ? 0 : current,
    };
  }

  componentDidMount() {
    const { isReviewer } = this.props;

    if (isReviewer) {
      window.addEventListener('scroll', this.spyScrolling);
    }

    emitter.on(EventTypes.SET_TAB, this.setTab);
  }

  componentWillUnmount() {
    const { isReviewer } = this.props;

    if (isReviewer) {
      window.removeEventListener('scroll', this.spyScrolling);
    }

    emitter.off(EventTypes.SET_TAB, this.setTab);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      propsHasChanged(nextProps, this.props) ||
      propsHasChanged(nextState, this.state)
    );
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  setTab = tab => this.setStep(null, tab);

  spyScrolling = () => {
    const { tabsRefs, formHeader } = this.props;
    const { current } = this.state;
    const sectionCount = tabsRefs.length;
    let currentScrolling = current;

    if (!sectionCount) return;

    const headerHeight =
      findDOMNode(formHeader)?.clientHeight || DEFAULT_HEIGHT;
    const topPos = window.pageYOffset + headerHeight;

    for (let i = 0; i < sectionCount; i++) {
      const section = findDOMNode(tabsRefs[i]);
      if (topPos > section.offsetTop) {
        currentScrolling = i;
      }
    }

    this.setState({ current: currentScrolling });
  };

  syncTab = current => {
    const {
      settings: { syncTab, validateFormFields },
      validationState: {
        form: { submitRequested },
      },
      tab = 0,
    } = this.props;

    if (tab === current) {
      return false;
    }

    window.scrollTo(0, 0);

    if (submitRequested) {
      syncTab(current);
      validateFormFields(errors => this.setTabsWithErrors(tab, errors));
    } else {
      syncTab(current);
      this.validateCurrentTabFields(tab);
    }
  };

  setStep = (e, current) => {
    const { isReviewer, tabsRefs, formHeader } = this.props;

    if (isReviewer) {
      const headerHeight =
        findDOMNode(formHeader)?.clientHeight || DEFAULT_HEIGHT;
      const offset = -headerHeight;
      scrollToComponent(tabsRefs[current], { align: 'top', offset });
    } else {
      this.syncTab(current);
    }
    this.setState({ current });
  };

  next = () => {
    const { tab = 0 } = this.props;
    const current = tab + 1;

    this.syncTab(current);
  };

  prev = () => {
    const { tab = 0 } = this.props;
    const current = tab - 1;

    this.syncTab(current);
  };

  validateCurrentTabFields = current => {
    const {
      form: { validateFields },
      validationState: { tabsWithErrors },
    } = this.props;
    validateCurrentTabFields(current, validateFields, tabsWithErrors);
  };

  setTabsWithErrors = (current, errors = []) => {
    const {
      validationState: { tabsWithErrors },
    } = this.props;
    setTabsWithErrors(current, errors, tabsWithErrors);
  };

  computeSubmitBottomLabel = (isReviewer, isEditor) => {
    let submitButtonLabel;
    if (isReviewer) {
      submitButtonLabel = translate(
        'containers.reports.reportViewer.addReview'
      );
    } else if (isEditor) {
      submitButtonLabel = translate('containers.reports.reportViewer.save');
    } else {
      submitButtonLabel = translate('containers.reports.reportViewer.submit');
    }

    const {
      form: { selectedForm },
    } = store.getState();
    if (selectedForm.isContributeReport) {
      const anyIncomplete = selectedForm.data.__assignedSections.find(
        section => !section.complete
      );
      if (anyIncomplete) {
        submitButtonLabel = 'Save draft';
      }
    }
    return submitButtonLabel;
  };

  render() {
    const {
      options = {},
      properties = [],
      tab = 0,
      validationState = {},
      loading = false, // eslint-disable-line
      timezone,
      isReviewer = false,
      isViewer = false,
      canSubmit = true,
      submitDisable,
      tabsRefs = [],
      formHeader = [],
      ...rest
    } = this.props;
    const { current } = this.state;
    const isEditor = get(rest, 'settings.isEditor', false);
    const isEditMode = get(rest, 'settings.isEditMode', false);

    const { form = {}, sections = {}, tabsWithErrors = [] } = validationState;

    const { submitRequested } = form;

    const tabsClasses = properties.map((tab, index) => {
      const isTouched = get(sections[index], 'touched', false);

      if (isReviewer) {
        return '';
      }
      return classNames({
        'has-errors':
          (isTouched && !submitRequested && tabsWithErrors.includes(index)) ||
          (submitRequested && tabsWithErrors.includes(index)),
        'no-errors': isTouched && !tabsWithErrors.includes(index),
        'is-inactive': !isTouched && !submitRequested,
      });
    });

    const { showNavigation = true } = options;

    const submitButtonLabel = this.computeSubmitBottomLabel(
      isReviewer,
      isEditor
    );

    return (
      <div className="steps-navigation">
        <div style={{ flexGrow: 1 }}>
          {showNavigation && (
            <NavigationSteps current={isReviewer ? current : tab} progressDot>
              {properties
                .map(step => omit(step, ['disable']))
                .map(
                  mapProperties({
                    ...omit(rest, [
                      'changedFields',
                      'canSubmit',
                      'dispatch',
                      'disable',
                    ]),
                    total: properties.length,
                    classNames: tabsClasses,
                    tabsRefs,
                    formHeader,
                  })
                )}
            </NavigationSteps>
          )}
          <NavigationSelect
            value={tab + []}
            onChange={value => this.setStep(value, +value)}
          >
            {properties.map((item, i) => (
              <Option key={i} value={i + []}>
                {item.title}
              </Option>
            ))}
          </NavigationSelect>
        </div>
        <div className="text-right">
          <TabsNavigation
            showSubmitButton
            showNavigation={false}
            submitDisable={submitDisable}
            submitButtonLabel={submitButtonLabel}
            current={tab}
            onNext={this.next}
            onPrev={this.prev}
            totalSteps={properties.length}
            loading={loading}
            isReviewer={isReviewer}
            isViewer={isViewer}
            canSubmit={canSubmit}
            isEditMode={isEditMode}
            isEditor={isEditor}
          />
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    validationState: getFormValidationState(state, props),
  };
};

export default connect(mapState)(TabsHeader);
