import React, { Component } from 'react';
import { AutoComplete, Spin } from 'antd';
import { get, map, isEmpty, uniq, castArray, find, some } from 'lodash';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';

import { getFormTemplates, getSelectedForm } from 'APP_ROOT/selectors/form';
import getFormLinkConfiguration from '../object-types/FormField/getFormLinkConfiguration';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import { normalizeString } from 'APP_ROOT/utils/filters';
import { FEATURES, hasFeatures } from 'APP_ROOT/utils/features';

import ReviewerField from '../reviewer-field';
import ContentEditable from 'react-contenteditable';

import StyledSelect from '../styled/input-select';
import StyledAutoComplete, { StyledStatus } from '../styled/input-autocomplete';

import {
  emitReviewerAssignment,
  getVisibilityOverridesContributor,
} from './utils/contribute-to-report-field-types.js';

import UnassignAndClearCRTSection from './utils/unassignAndClearCTRSection.js';
import { translate } from '../../../i18next.js';

import {
  CONTRIBUTOR_ASSIGNMENT,
  CONTRIBUTOR_SHARE_COMPLETED,
} from '../../../constants/contributeToReport.js';

const Option = AutoComplete.Option;

const getFormLinkOptions = (options = [], templates) => {
  return options
    .filter(item => templates[item].canCreate)
    .map(option => ({
      value: `create-new-${option}`,
      label: `Create New ${get(templates, [option, 'name'])} Report`,
    }));
};

let isActive = false; // use variable instead of state to avoid re-renders & faster response time
let lastResponse = { total: 0 }; // use variable instead of state to avoid re-renders & faster response time

class InputAutocomplete extends Component {
  constructor(props) {
    super();
    this.state = {
      value: props.value || null,
      filtering: false,
      shouldUpdateEvent: false,
      reportingKey: props.reportingKey,
      key: props.dataKey,
      update: false,
      noResults: false,
      mounted: false,
      initialLoad: false,
      loading: true,
    };
  }

  componentDidUpdate() {
    const {
      value,
      data,
      options: { sourceField = [] },
      onChange,
      parentIndex,
    } = this.props;
    const { shouldUpdateEvent } = this.state;
    let fieldValue = value;

    if (shouldUpdateEvent) {
      this.setState({
        shouldUpdateEvent: false,
      });
      if (!isEmpty(sourceField) && !sourceField.includes('none')) {
        const sourceValue = get(data, [
          sourceField[1],
          parentIndex,
          sourceField[2],
        ]);
        if (sourceValue) {
          onChange(sourceValue);
          fieldValue = sourceValue;
        }
      }
      this.setState({
        value: fieldValue,
      });
    }
  }

  componentDidMount() {
    window.addEventListener(
      CONTRIBUTOR_SHARE_COMPLETED,
      this.enableCTRAutocomplete
    );
    // Based on a timing issue, a library was triggering an internal validation issue
    // and the application breaks, since the information was not present during the run time
    // process, we decided to move the logic into componentDidUpdate() to obtain more time,
    // ticket SW-971
    this.setState({
      shouldUpdateEvent: true,
      mounted: true,
    });
  }

  componentWillUnmount() {
    window.removeEventListener(
      CONTRIBUTOR_SHARE_COMPLETED,
      this.enableCTRAutocomplete
    );
    this.setState({
      mounted: false,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value: nextValue } = nextProps;
    const { value } = this.props;

    if (nextValue !== value) {
      this.setState({
        value: nextValue,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      propsHasChanged(nextProps, this.props) ||
      propsHasChanged(nextState, this.state)
    );
  }

  enableCTRAutocomplete = () => {
    this.setState({ disabled: false });
  };

  onChange = value => {
    this.setState({
      value,
    });
  };

  onSelect = value => {
    this.disableCTRAutocomplete();
    emitReviewerAssignment(this.props, value);
    const { onChange, onSelect, value: prevValue, options } = this.props;
    const isMultiselect = get(options, 'multiselect', false);
    const currentValue = castArray(value);
    const nextValue = isMultiselect
      ? prevValue
        ? currentValue.concat(prevValue)
        : currentValue
      : value;

    onSelect(nextValue, newValue => onChange(newValue ? newValue : nextValue));
  };

  onDeselect = value => {
    this.disableCTRAutocomplete();
    // by default onDeselect is only called for multiple or tags
    const { onChange, onSelect, value: prevValue } = this.props;
    const nextValue = prevValue.filter(v => v !== value);

    onSelect(nextValue, newValue => onChange(newValue ? newValue : nextValue));
  };

  disableCTRAutocomplete = () => {
    const { isContributeReport, reportingKey } = this.props;
    // check if the field is to assign contributor
    if (isContributeReport && reportingKey === CONTRIBUTOR_ASSIGNMENT) {
      this.setState({ disabled: true });
    }
  };
  getDataSource = () => {
    const {
      enums,
      options: { defaultSource = [], linkableToType } = {},
      templates = {},
      data: { __users = {} },
      value,
    } = this.props;

    const formLinkConfigurations = getFormLinkConfiguration({
      scope: linkableToType,
    });
    const newFormLinkOptions = getFormLinkOptions(
      formLinkConfigurations,
      templates
    );

    /**
     * defaultSource contains the options that will be included by default into
     * the autocomplete component beside the options that are already into enums.
     */
    const values = [
      ...newFormLinkOptions,
      ...uniq(defaultSource),
      ...uniq(enums),
    ];

    // check if the selected value is already loaded in values
    const needValue = !isEmpty(value) && !values.some(v => v.value === value);
    // need the numeric value to access user collection
    const valueId = Number(value);
    if (needValue && __users[valueId]) {
      values.push({
        value,
        label: __users[valueId].fullName,
        data: __users[valueId],
      });
    }
    return map(values, (item, index) =>
      item && item.hasOwnProperty('label') && item.hasOwnProperty('value') ? (
        <Option key={index} value={item.value + []} data={item.data}>
          {item.label}
        </Option>
      ) : (
        <Option key={index} value={item + []}>
          {item}
        </Option>
      )
    );
  };

  showModal = () => this.setState({ showConfirm: true });

  hideModal = () => this.setState({ showConfirm: false });

  unassignUser = emitAssigment => {
    const { onChange } = this.props;

    this.setState({ value: '', noResults: false });
    onChange('');
    emitAssigment && emitReviewerAssignment(this.props);
    this.enableCTRAutocomplete();
  };

  cancelUnassignUser = () => {
    // rollback value and hide modal
    const { value } = this.props;
    this.onChange(value);
    this.hideModal();
    this.enableCTRAutocomplete();
  };

  onBlur = inputValue => {
    const { enums = [], value: oldValue } = this.props;
    const value = enums.find(e => e.value === inputValue) ? inputValue : '';

    if (isEmpty(value) && !isEmpty(oldValue)) {
      this.disableCTRAutocomplete();
      this.showModal();
    }

    isActive = false;
  };

  onFocus = () => {
    isActive = true;
    // Note: the problem with this approach is that the field gets re-mounted with the re-render
    // This causes the focus on the field to get lost, the user will need to click again to see the results.

    if (!this.state.initialLoad) {
      this.setState({ initialLoad: true });
      this.props.onSearch('', false, this.onSearchComplete);
    }
  };

  onSearchComplete = (response = []) => {
    const { mounted } = this.state;

    if (!mounted) return;

    const input = findDOMNode(this.input);
    const hasFocus = input.contains(document.activeElement);
    // check if the input has the focus to make sure we show
    // the no results status only when input has focus
    this.setState({ noResults: hasFocus && isEmpty(response) });
    if (
      (!response || (response.length > 0 && lastResponse.total === 0)) &&
      !isActive
    ) {
      /*
       * Statement: You may be asking why.
       * Reason: When we dont have results, and inmediately after we have,
       * the dropdown is autmatically re-opened, this is a natural behavior of the autocomplete
       * component however it could be treated as a bug.
       */
      Object.assign([], input.getElementsByClassName('ant-input')).forEach(
        i => {
          i.focus();
          i.blur();
        }
      );
    }
    lastResponse = { data: response, total: response.length };
  };

  saveRef = el => {
    if (!this.input) {
      this.input = el;
    }
  };

  filterOption = (input, { props = {} }) => {
    const { options: { orCriteria = [] } = {} } = this.props;
    const { data = {}, children } = props;
    const { currentUser: { featureFlags = [] } = {} } = this.props;
    const hasReportUserSelectionFuzzySearch = hasFeatures(
      featureFlags,
      FEATURES.reportUserSelectionFuzzySearch
    );

    const isFound = (text, context) => {
      if (hasReportUserSelectionFuzzySearch) {
        const normalizedText = normalizeString(text);
        const normalizedContext = normalizeString(String(context));
        return normalizedContext.indexOf(normalizedText) >= 0;
      }
      return (
        String(context)
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      );
    };

    const searchInData = some(orCriteria, criteria =>
      isFound(input, get(data, criteria, ''))
    );

    return isFound(input, children) || searchInData;
  };

  render() {
    const {
      options,
      placeholder,
      isReviewer,
      isDraft,
      onSearch,
      dataKey,
      parentKey,
      parentIndex,
      calculatedValue,
      timezone,
      field_type,
      enums,
      ...props
    } = this.props;

    const { value, noResults, showConfirm, loading } = this.state;

    const isMultiselect = get(options, 'multiselect', false);
    const fromSourceField = get(options, 'sourceField', []);

    const SelectComponent = isMultiselect ? StyledSelect : StyledAutoComplete;
    const dataSource = this.getDataSource();
    const extraProps = isMultiselect
      ? {
          mode: 'multiple',
          children: dataSource,
        }
      : { dataSource };

    const {
      overrideReviewer,
      overrideEditRights,
      isContributorAssignmentField,
    } = getVisibilityOverridesContributor(this.props);

    if (overrideReviewer && !overrideEditRights) {
      const readOnlyValue = calculatedValue || props.value || ' ';
      return (
        <ReviewerField
          addClass={
            isContributorAssignmentField
              ? 'contributor-assignment-select'
              : 'reviewer-field select-field'
          }
          value={readOnlyValue}
        />
      );
    }

    if (!isEmpty(fromSourceField) && !fromSourceField.includes('none')) {
      const value = calculatedValue || props.value || ' ';
      const sourceValue = find(enums, { value });
      const readOnlyValue = get(sourceValue, ['label']);

      return <ContentEditable html={readOnlyValue} disabled={true} />;
    }

    const disabled = props.disabled || this.state.disabled;

    return (
      <StyledStatus showStatus={noResults}>
        <SelectComponent
          {...props}
          {...extraProps}
          size="default"
          allowClear={true}
          disabled={disabled}
          filterOption={this.filterOption}
          placeholder={
            isContributorAssignmentField
              ? translate('containers.reports.reportViewer.selectContributor')
              : placeholder ||
                translate('containers.reports.reportViewer.pleaseType')
          }
          onSearch={keyworkds =>
            onSearch(keyworkds, false, this.onSearchComplete)
          }
          notFoundContent={loading && !noResults ? <Spin size="small" /> : null}
          ref={this.saveRef}
          value={isMultiselect ? (value ? castArray(value) : []) : value}
          onSelect={this.onSelect}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onDeselect={this.onDeselect}
        />

        <div className="status">No Results Found</div>
        {isContributorAssignmentField && (
          <UnassignAndClearCRTSection
            visible={showConfirm}
            hideModal={this.hideModal}
            unassignUser={this.unassignUser}
            cancelUnassignUser={this.cancelUnassignUser}
            {...this.props}
          />
        )}
      </StyledStatus>
    );
  }
}

const mapState = state => ({
  templates: getFormTemplates(state),
  selectedForm: getSelectedForm(state),
  currentUser: state.session.currentUser,
});

export default connect(mapState, null)(InputAutocomplete);
