import { get, isEmpty } from 'lodash';
import { change } from 'redux-form';

import { POST_DRAFT_REPORT_REQUEST } from './';
import { postDraftReport } from '../api/reports';
import postDraftReportSuccess from './post-draft-report-success';
import postDraftReportError from './post-draft-report-error';
import resetOnBehalf from './reset-onBehalf';
import {
  NEW_REPORT_ON_CASE_FILE_REDUX_PROP,
  NEW_REPORT_ON_CASE_FILE_REDUX_VALUE,
} from '../containers/caseFile/components/newReportButton/constants';
import caseFileEndpoints from '../api/caseFileEndpoints/caseFileEndpoints';
import showNotification from '../containers/administrator/agencies/agency-live-event-check-in/showNotification';

const getFormTypeName = (agency, formType) => {
  const templatesOptions = agency.configurations.find(
    ({ entity }) => entity === 'template'
  ).options;
  const templateTitle = templatesOptions
    .find(({ name }) => name === 'title')
    .values.find(({ scope }) => scope === formType);

  return templateTitle.value || '';
};

const resetBannerHeader = dispatch => {
  dispatch(
    change(
      NEW_REPORT_ON_CASE_FILE_REDUX_PROP,
      NEW_REPORT_ON_CASE_FILE_REDUX_VALUE,
      null
    )
  );
};

export default (data, next = () => {}) => async (dispatch, getState) => {
  const {
    session: {
      currentUser: { agencyId, organizationalUnitId, hierarchyKey, agency },
    },
    form: {
      selectedForm: { template },
      templates,
      __usersOnBehalf,
    },
    forms,
  } = getState();
  const templateId = templates[template].id;

  if (data && !data.organizationalUnitId) {
    data = {
      ...data,
      organizationalUnitId: organizationalUnitId,
      hierarchyKey: hierarchyKey,
    };
  }

  dispatch({ type: POST_DRAFT_REPORT_REQUEST });

  return new Promise(async (resolve, reject) => {
    try {
      const report = await postDraftReport({
        agencyId,
        templateId,
        data: {
          ...data,
          __usersOnBehalf: !isEmpty(__usersOnBehalf) ? __usersOnBehalf : {},
        },
      });
      dispatch(postDraftReportSuccess(report));

      /* 
         This part will call the endpoint linking this new report to an existing casefile IF
         the new port was called from a casefile 
      */
      let idToLinkReport = null;
      let casefileNumber = '';
      if (!isEmpty(forms)) {
        const { casefileId, casefileTitle } =
          forms[NEW_REPORT_ON_CASE_FILE_REDUX_PROP]?.values?.caseFile || {};
        idToLinkReport = casefileId;
        casefileNumber = casefileTitle;
      }

      if (idToLinkReport) {
        const payload = [
          {
            reportId: report.id,
            reportNumber: report.number,
            reportType:
              report.templateName || getFormTypeName(agency, report.formType),
          },
        ];

        caseFileEndpoints
          .addReportToCasefile(idToLinkReport, payload)
          .then(response => {
            const { success = false } = response;

            if (success) {
              showNotification(
                'info',
                'Report linked to a Case File',
                `This report has now been linked to case file ${casefileNumber}`
              );
            } else {
              showNotification(
                'error',
                'Linking Report Error',
                'This report could not been linked'
              );
            }
          })
          .catch(_ =>
            showNotification(
              'error',
              'Linking Report Error',
              'This report could not been linked'
            )
          )
          .finally(() => {
            resetBannerHeader(dispatch);
          });
      }

      dispatch(resetOnBehalf());
      next(false, report);
      resolve(report);
    } catch (e) {
      dispatch(postDraftReportError(get(e, ['response', 'status'])));
      next(e);
      reject(e);
    }
  });
};
