import React from 'react';
import { Icon, Button } from 'antd';

import ModalTitle from 'APP_ROOT/components/common/modal/title';
import ModalBody from 'APP_ROOT/components/common/modal/body';

export default (state = 'loading', optionalMessage) => {
  return {
    title: (
      <ModalTitle success style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Icon type="loading" />
        <span>{state === 'loading' && 'Exporting PDF'}{state === 'failed' && 'Export PDF failed!'}</span>
      </ModalTitle>
    ),
    text: (
      <ModalBody>
        <p>{state === 'loading' && 'We are now preparing your PDF.  This may take several minutes.  Please keep this window open -- you may continue using Benchmark in another window.'}{state === 'failed' && 'We are sorry but your PDF failed to export :((('}</p>
        {optionalMessage && <p>{optionalMessage}</p>}
      </ModalBody>
    ),
    /**
     * @param {function} onOkButtonClick
     * @return {function[]}
     */
    buildFooter: (onOkButtonClick, onCancelButtonClick) => [
      state === 'failed' && <Button
        key="export-report-button-accept"
        type="primary"
        onClick={onOkButtonClick}
      >
        {'OK'}
      </Button>,
      state === 'loading' && <Button
        key="export-report-button-cancel"
        // type="primary"
        onClick={onCancelButtonClick}
      >
        {'Cancel'}
      </Button>
    ],
  }
};
