import React, { Component } from 'react';
import { Tag, Row, Col, message } from 'antd';
import { isEmpty, get } from 'lodash';
import { connect } from 'react-redux';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import withModal from 'APP_COMPONENTS/common/modal/base';

import Styled from './RepeaterEditor.styled';
import PickableZone from '../PickableZone';
import IconButton from 'APP_ROOT/components/common/buttons/icon-button';
import componentsManager from '../../services/componentsManager';
import buildOptionsModalContent from '../../utils/buildOptionsModalContent';
import getCustomFields from '../../utils/getCustomFields';
import getFieldLabel from '../../utils/getFieldLabel';
import { REPEATER } from 'APP_ROOT/constants/layoutComponentTypes';
import getReviewerCssClass from '../../utils/getReviewerCssClass';

import {
  TAB_VALUES,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
  TAB_DATE_BOUNDARY,
} from '../OptionsModalBody/OptionsModalBody';
class RepeaterEditor extends withModal(Component) {
  constructor(props) {
    super(props);

    this.state = {
      values: props.field,
    };
  }

  componentDidMount() {
    this.createModal();
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(this.props, nextProps);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      values: props.field,
    };
  }

  remove = () => {
    const { id } = this.props;

    componentsManager.removeComponent(id);
  };

  // to check ciruclar reference in the syncTo property
  checkRepeaterReference = (params, repeaterKey, tabFields) => {
    const [repeaterItem] = componentsManager.findContainersByKey(
      tabFields.properties,
      repeaterKey
    );
    const syncTo = get(repeaterItem, 'options.syncTo', []);
    if (params.syncTo.includes(repeaterKey) && syncTo.includes(params.key)) {
      message.error(
        `Circular dependency between this repeater and ${repeaterItem.reportingKey} found`,
        3
      );
      return false;
    }
    return true;
  };

  // to check repeaters per tab
  checkRepeaterPerTab = (params, repeaters, fields) => tabIndex => {
    return repeaters[tabIndex].every(({ repeaterKey }) => {
      if (params.key !== repeaterKey) {
        return this.checkRepeaterReference(
          params,
          repeaterKey,
          fields[tabIndex]
        );
      }
      return true;
    });
  };

  validate = params => {
    const {
      reportingKey: { value: reportingKey },
    } = params;
    if (isEmpty(reportingKey) || isEmpty(reportingKey.trim())) {
      message.error('Reporting key must not be empty', 3);
      return false;
    }

    if (!new RegExp(/^[a-zA-Z0-9]+$/).test(reportingKey)) {
      message.error('Reporting key must not have special characters', 3);
      return false;
    }

    if (params.syncTo && params.syncTo.length > 0) {
      const fields = componentsManager.fields;
      const repeaters = componentsManager.repeatersReference;
      return Object.keys(repeaters).every(
        this.checkRepeaterPerTab(params, repeaters, fields)
      );
    }
    return true;
  };

  getValue = (name, defaultValue) =>
    get(this, `state.values.${name}`, defaultValue) || defaultValue;

  getFieldOptions = () => {
    const { values } = this.state;
    const { field } = this.props;
    const { options = {} } = values;
    const {
      showAddButton = true,
      addButtonLabel = 'Add More Actions',
    } = options;

    const customFields = getCustomFields(field.type, null, values);
    const customFieldsInitialValue = customFields.reduce(
      (allInitialValues, { initialValue, name }) => ({
        ...allInitialValues,
        [name]: initialValue,
      }),
      {}
    );

    return {
      ...customFieldsInitialValue,
      customFields,
      showAddButton,
      reportingKey: {
        type: 'text',
        label: 'Reporting Key',
        value: values.reportingKey,
        maxLength: 100,
        placeholder: 'Reporting key',
      },
      addButtonLabel: {
        type: 'text',
        label: 'Add button',
        value: addButtonLabel,
        maxLength: 100,
        placeholder: 'Add button label',
        isHidden: values =>
          values.showAddButton === undefined ? false : !values.showAddButton,
      },

      conditions: values.conditions,
      type: values.type,
      id: values.id,
      fieldKey: values.key,
      disableTab: [
        TAB_VALUES,
        TAB_SHARE_KEY,
        TAB_INFORMATION,
        TAB_DATE_BOUNDARY,
      ],
    };
  };

  onDelete = () => {
    this.remove();
    this.deleteModal();
  };

  onSave = params => {
    const { field } = this.props;
    const {
      reportingKey,
      showAddButton,
      addButtonLabel,
      syncTo,
      customFields,
      ...restParams
    } = params;
    if (!this.validate({ ...params, key: field.key })) return;

    const mutations = [];
    customFields.forEach(customField => {
      mutations.push(customField.onSave);
    });

    const modifiedValues = mutations.reduce(
      (allChanges, mutation) => mutation(allChanges),
      Object.assign({}, restParams, {
        reportingKey: reportingKey.value,
        options: {
          showAddButton,
          addButtonLabel: addButtonLabel.value,
          syncTo,
        },
      })
    );

    this.setState({
      values: modifiedValues,
    });

    componentsManager.editComponent(field.id, modifiedValues);

    this.deleteModal();
  };

  showOptionsModal = () => {
    const fieldsOptions = this.getFieldOptions();

    const modalData = buildOptionsModalContent({
      ...fieldsOptions,
      onCancel: this.deleteModal,
      onDelete: this.onDelete,
      onSave: this.onSave,
    });

    this.updateModal(modalData);

    this.showModal();
  };

  render() {
    const {
      children,
      type,
      id,
      field: { conditions, key, reportingKey },
    } = this.props;
    const [{ required = false }] = componentsManager.getValidationRules(key);
    const reviewerCss = getReviewerCssClass(conditions);
    return (
      <Styled className={reviewerCss}>
        <PickableZone type={type} id={id}>
          <Row type="flex" align="middle">
            <Col>
              <Tag className="uppercase">{getFieldLabel(REPEATER)}</Tag>
            </Col>
            <Col span={2}>{reportingKey}</Col>
            <Col span={4}>
              {required && <Tag color="red">Required</Tag>}
              {!isEmpty(conditions) && <Tag color="gold">Conditional</Tag>}
            </Col>
            <Col className="margin-left">
              <IconButton
                icon="setting"
                onClick={this.showOptionsModal}
                className="is-big is-focusable"
              />
            </Col>
          </Row>
          {children}
        </PickableZone>
      </Styled>
    );
  }
}

RepeaterEditor.displayName = 'FieldEditor.RepeaterEditor';

export default connect()(RepeaterEditor);
