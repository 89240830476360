import React from 'react';
import ModalTitle from 'APP_ROOT/components/common/modal/title';
import { Icon } from 'antd';
import { translate } from '../../../i18next';

export default () => (
  <ModalTitle warning>
    <Icon type="exclamation-circle" />{' '}
    <span>
      {translate('containers.caseFiles.doYouWantToDeleteThisCaseFile')}
    </span>
  </ModalTitle>
);
