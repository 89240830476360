import React, { Component } from 'react';
import { difference, get, isEmpty, findIndex } from 'lodash';
import moment from 'moment-timezone';
import ouAttributeFilter from './employment-history/ou-attribute-filter';
import ouAttributeActions from './employment-history/ou-attribute-actions';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import {
  formatDate,
  BENCHMARK_DATE_FORMAT,
} from '../../../../utils/parse-date';
import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from '../../../../components/custom-sections/sections/Sections.styled';
import SectionAddButton from '../../../../components/custom-sections/sections/SectionAddButton';
import CustomSectionTable from '../../../../components/custom-sections/tables/CustomSectionTable';

import UserEmploymentModal from './UserEmploymentModal';

import {
  EMPLOYMENT_PREFIX,
  EMPLOYMENT_ACTION_HIRE,
  EMPLOYMENT_ACTION_SEPARATION,
} from './userEmployment.constants';
import TransferEmploymentModal from './TransferEmploymentModal';

import { translate, translateByLabel } from '../../../../i18next.js';

class UserEmploymentHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      historyId: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      propsHasChanged(nextProps, this.props) ||
      propsHasChanged(nextState, this.state)
    );
  }

  componentDidUpdate(previousProps, previousState) {
    const { historyId } = this.state;
    const { profileForm } = this.props;
    const { employment, [historyId]: data } = get(
      profileForm,
      `values.${EMPLOYMENT_PREFIX}`,
      {}
    );
    if (data) {
      const { employmentAction, effectiveDate } = data;
      const rowIndex = findIndex(employment.id, id => id === historyId);
      this.setMainDates(employmentAction, effectiveDate, rowIndex);
    }
  }

  setMainDates = (employmentAction, effectiveDate, rowIndex) => {
    const { updateSectionField } = this.props;

    let index;
    index = findIndex(
      employmentAction,
      action => action === EMPLOYMENT_ACTION_HIRE
    );
    const hireDate = index === -1 ? undefined : effectiveDate[index];

    index = findIndex(
      employmentAction,
      action => action === EMPLOYMENT_ACTION_SEPARATION
    );
    const separationDate = index === -1 ? undefined : effectiveDate[index];

    /**
     * The effective date is wrapped as a moment object to create a consistent behavior, because the generic datepicker will
     * provide it's value as an object if the user doesn't change the datepicker's value, else, it will return it's value
     * as a string.
     */

    if (hireDate) {
      updateSectionField(
        `${EMPLOYMENT_PREFIX}.employment.appointmentDate[${rowIndex}]`,
        moment(hireDate).format(BENCHMARK_DATE_FORMAT)
      );
    }

    updateSectionField(
      `${EMPLOYMENT_PREFIX}.employment.separationDate[${rowIndex}]`,
      separationDate
        ? moment(separationDate).format(BENCHMARK_DATE_FORMAT)
        : null
    );
  };

  buildEmploymentModal = (
    readOnly,
    showModal,
    sectionId,
    rowIndex,
    handleModalCancel,
    handleModalOk,
    isNew
  ) => {
    const {
      profileForm,
      editSection,
      updateSectionField,
      addSectionFieldValue,
      removeSectionFieldValue,
      setEditSectionTable,
      storedInDatabase,
      employmentRankOptions,
      userEmploymentAttributes,
      organizationalUnitList,
      organizationalUnitTreeList,
      timezone,
    } = this.props;

    const activelyEmployedValues = this.getActivelyEmployedUnitIds(profileForm);

    return (
      <UserEmploymentModal
        title={translate('containers.profile.employment.employment')}
        attributes={userEmploymentAttributes}
        profileForm={profileForm}
        readOnly={readOnly}
        visible={showModal}
        sectionId={sectionId}
        rowIndex={rowIndex}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        addSectionFieldValue={addSectionFieldValue}
        updateSectionField={updateSectionField}
        removeSectionFieldValue={removeSectionFieldValue}
        setEditSectionTable={setEditSectionTable}
        storedInDatabase={storedInDatabase}
        editSection={editSection}
        employmentRankOptions={employmentRankOptions}
        isNew={isNew}
        treeDropdownOptions={organizationalUnitTreeList}
        plainValidValues={organizationalUnitList}
        disabledValues={activelyEmployedValues}
        timezone={timezone}
      />
    );
  };

  buildTransferModal = (
    showModal,
    sectionId,
    rowIndex,
    handleModalCancel,
    handleModalOk
  ) => {
    const {
      profileForm,
      userEmploymentAttributes,
      organizationalUnitTreeList,
      session,
      timezone,
    } = this.props;

    const activelyEmployedValues = this.getActivelyEmployedUnitIds(profileForm);

    return (
      <TransferEmploymentModal
        title={'Transfer Employment'}
        attributes={userEmploymentAttributes}
        profileForm={profileForm}
        visible={showModal}
        sectionId={sectionId}
        rowIndex={rowIndex}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        treeDropdownOptions={organizationalUnitTreeList}
        disabledValues={activelyEmployedValues}
        timezone={timezone}
        attributeId="id"
        prefix={EMPLOYMENT_PREFIX}
        session={session}
      />
    );
  };

  render = () => {
    const {
      profileForm,
      editSection,
      updateSectionField,
      addSectionFieldValue,
      removeSectionFieldValue,
      setEditSectionTable,
      storedInDatabase,
      loadEmploymentDetail,
      employmentRankOptions,
      isSectionReadOnly = false,
      organizationHistoryAttributes,
      organizationalUnitList,
      session,
      attributeActions,
    } = this.props;

    const onActionCallback = (record, _index, action, callback) => {
      const { key } = record;
      const data = get(profileForm, `values.${EMPLOYMENT_PREFIX}[${key}]`);
      this.setState({ historyId: record.key, data });

      const processedAction = attributeActions(action, record.key);

      if (processedAction) {
        processedAction(callback);
      } else {
        loadEmploymentDetail(record.key, callback);
      }
    };

    const onCancelCallback = (record, isNew) => {
      const { historyId, data = {} } = this.state;
      const refreshedData =
        !isNew && Object.keys(data).length == 0
          ? get(profileForm, `values.${EMPLOYMENT_PREFIX}[${record.key}]`)
          : data;

      updateSectionField(`${EMPLOYMENT_PREFIX}[${historyId}]`, refreshedData);
    };

    const onModalOkCallback = record => {
      const { historyId } = this.state;
      const { employment, [historyId]: data } = get(
        profileForm,
        `values.${EMPLOYMENT_PREFIX}`
      );
      if (data) {
        const { employmentAction, effectiveDate, titleRank } = data;
        const rowIndex = findIndex(employment.id, id => id === historyId);
        this.setMainDates(employmentAction, effectiveDate, rowIndex);

        // get the most recent effective date, to know the current rank
        const dateRank = effectiveDate.reduce((acc, date, i) => {
          const formatedDate = formatDate(date);
          if (isEmpty(acc) || formatedDate.isAfter(acc.date)) {
            return {
              date: formatedDate,
              dateTitleRank: titleRank[i],
              foundEmploymentAction: employmentAction[i],
            };
          }
          return acc;
        }, {});

        const { dateTitleRank } = dateRank;
        // update rank in the employment data
        updateSectionField(
          `${EMPLOYMENT_PREFIX}.employment.titleRank[${rowIndex}]`,
          dateTitleRank
        );
        updateSectionField(
          `${EMPLOYMENT_PREFIX}.employment.employmentAction[${rowIndex}]`,
          dateRank.foundEmploymentAction
        );
      }
    };

    const editSectionTable = (sectionId, key) => {
      const { setEditSectionTable } = this.props;

      // since we are adding a new sectino, we need to save the key
      // to calculate title/rank later
      this.setState({ historyId: key, data: {} });

      setEditSectionTable && setEditSectionTable(sectionId, key);
    };

    const finalOrganizationHistoryAttributes = ouAttributeFilter(
      organizationHistoryAttributes,
      organizationalUnitList
    );

    finalOrganizationHistoryAttributes.map(
      attr =>
        (attr.title = attr?.translateKey
          ? translate(attr.translateKey)
          : attr.title)
    );

    const finalOrganizationalHistoryActions = ouAttributeActions(session);

    return (
      <StyledCollapse
        bordered={false}
        defaultActiveKey="employment-history"
        expandIconPosition="right"
      >
        <StyledPanel
          header={
            <StyledPanelTitle size="large">
              {translate('containers.profile.employment.employment')}
            </StyledPanelTitle>
          }
          key="employment-history"
          className="section-panel"
          extra={
            <SectionAddButton
              sectionId="employment"
              attributeId="id"
              prefix={EMPLOYMENT_PREFIX}
              attributes={finalOrganizationHistoryAttributes}
              mainForm={profileForm}
              addSectionFieldValue={addSectionFieldValue}
              setEditSectionTable={editSectionTable}
              disabled={isSectionReadOnly}
            />
          }
        >
          <hr></hr>
          <CustomSectionTable
            key="employment"
            modalTitle="Employment"
            sectionId="employment"
            prefix={EMPLOYMENT_PREFIX}
            attributeId="id"
            attributes={finalOrganizationHistoryAttributes}
            mainForm={profileForm}
            editSection={editSection}
            addSectionFieldValue={addSectionFieldValue}
            updateSectionField={updateSectionField}
            removeSectionFieldValue={removeSectionFieldValue}
            setEditSectionTable={setEditSectionTable}
            modalFor="employment"
            onAction={onActionCallback}
            onCancel={onCancelCallback}
            onModalOkCallback={onModalOkCallback}
            employmentRankOptions={employmentRankOptions}
            isSectionReadOnly={isSectionReadOnly}
            storedInDatabase={storedInDatabase}
            buildModal={this.buildEmploymentModal}
            buildTransferModal={this.buildTransferModal}
            ellipsis={true}
            showIconAction={true}
            actionAttributes={finalOrganizationalHistoryActions}
          />
        </StyledPanel>
      </StyledCollapse>
    );
  };

  getActivelyEmployedUnitIds(profileForm) {
    // This key is hardcoded as it's meant to be a default in the user profile reusing the "custom section" feature.
    // It follows the construction of the user profile as-is jan 7 2021. Please update when the User Profile changes.
    const hardCodedKey = 'values.organizationalUnitHistoryData.employment';
    const unitIdKey = 'organizationalUnitId';
    const appointmentDateKey = 'appointmentDate';
    const sepeationDateKey = 'separationDate';

    const rows = get(profileForm, hardCodedKey, {});
    const unitIds = get(rows, unitIdKey, []);
    const appointments = this.fetchIndexOfNullValuesIn(
      get(rows, appointmentDateKey, [])
    );
    const separations = this.fetchIndexOfNullValuesIn(
      get(rows, sepeationDateKey, [])
    );
    const activeRelations = difference(separations, appointments);

    return activeRelations.map(value => unitIds[value]);
  }

  fetchIndexOfNullValuesIn(list) {
    return list
      .map((value, index) => {
        if (value === null) return index;
      })
      .filter(value => value !== undefined);
  }
}
export default UserEmploymentHistory;
