import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Tooltip } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';

const DynamicEllipsisText = React.memo(({ text }) => {
  const [dimensions, setDimensions] = useState(null);
  const elementRef = useRef(null);
  const invisibleRef = useRef(null);
  const shouldRenderTooltip = useMemo(
    () =>
      dimensions && dimensions.invisibleRefWidth > dimensions.elementRefWidth,
    [dimensions]
  );

  useEffect(() => {
    if (
      elementRef &&
      elementRef.current &&
      invisibleRef &&
      invisibleRef.current
    ) {
      const handleResize = () => {
        setDimensions({
          elementRefWidth: elementRef.current.offsetWidth,
          invisibleRefWidth: invisibleRef.current.offsetWidth,
        });
      };

      setDimensions({
        elementRefWidth: elementRef.current.offsetWidth,
        invisibleRefWidth: invisibleRef.current.offsetWidth,
      });

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [elementRef, invisibleRef]);

  if (text && shouldRenderTooltip) {
    return (
      <Tooltip title={text && htmlUnescape(text)}>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          ref={elementRef}
        >
          {text && htmlUnescape(text)}
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        ref={elementRef}
      >
        {text && htmlUnescape(text)}
      </div>
      <span style={{ position: 'absolute', opacity: 0 }} ref={invisibleRef}>
        {text && htmlUnescape(text)}
      </span>
    </>
  );
});

export default DynamicEllipsisText;
