import React, { Component } from 'react';

import { StyledModal } from './PointOfContactModal.styled';
import PointOfContactForm from './PointOfContactForm';
import { translate } from '../../../i18next';
class PointOfContactModal extends Component {
  render() {
    const {
      isModalVisible,
      handleCancel,
      agencyData,
      resetTableData,
      viewPointOfContact,
      managePointContact,
      contactData = {},
    } = this.props;
    return (
      <StyledModal
        title={translate('containers.organizationProfile.pointOfContact')}
        centered
        visible={isModalVisible}
        destroyOnClose={true}
        onCancel={() => handleCancel()}
        footer={null}
      >
        <PointOfContactForm
          agencyData={agencyData}
          viewPointOfContact={viewPointOfContact}
          managePointContact={managePointContact}
          contactData={contactData}
          handleCancel={handleCancel}
          resetTableData={resetTableData}
        />
      </StyledModal>
    );
  }
}

export default PointOfContactModal;
