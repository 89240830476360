import {
  FIELD,
  SECTION,
  SECTION_WRAPPER,
  REPEATER,
} from 'APP_ROOT/constants/layoutComponentTypes';
import {
  MASKED,
  AUTOCOMPLETE,
  TEXT,
  TEXTAREA,
  DATE,
  DATE_TIME,
  NUMBER,
  SWITCH,
  SELECT,
  HYPERLINK,
  STATIC_CONTENT,
  DURATION,
  MATH,
} from 'APP_ROOT/constants/fieldTypes';

import { FORM_HEADER } from '../../constants/constants';

import maskedField from './getMaskedField';
import sectionComponentOptions from './getSectionComponentOptions';
import calculatedField from './getCalculatedField';
import autocompleteField from './getAutocompleteField';
import resetKeys from './getResetKeys';
import setFieldsOnChange from './getSetFieldsOnChange';
import resetRepeaters from './getResetRepeaters';
import removeKeys from './getRemoveKeys';
import removeRefKeys from './getRemoveRefKeys';
import fullWidthOption from './getFullWidthOption';
import syncTo from './getSyncTo';
import sourceFieldCascader from './getSourceFieldCascader';
import sectionSourceFieldCheckBox from './getSectionSourceFieldCheckBox';
import sourceSectionFieldCascader from './getSectionSourceFieldCascader';
import hyperlinkField from './getHyperlinkField';
import repeaterDeleteModal from './getRepeaterDeleteModal';
import contentField from './getContentField';
import formHeader from './getFormHeader';
import durationField from './getDurationField';
import numberField from './getNumberField';
import caseNumberField from './getCaseNumberField';
import eventDateField from './getEventDateField';
import mathField from './getMathField';
import defaultValueSwitchField from './getDefaultValueSwitchField';
import defaultValueTextField from './getDefaultValueTextField';

const getMoreComponents = (field_type, values) => {
  switch (field_type) {
    case MASKED:
      return [new caseNumberField(), new maskedField()];
    case STATIC_CONTENT:
      return [new contentField(values)];
    case HYPERLINK:
      return [new hyperlinkField(values)];
    case DURATION:
      return [new durationField(values)];
    case MATH:
      return [new mathField(values)];
    case SWITCH:
      return [
        new resetKeys(values),
        new resetRepeaters(values),
        new removeKeys(values),
        new removeRefKeys(values),
        new defaultValueSwitchField(),
        new calculatedField(field_type),
      ];
    default:
      return [];
  }
};

const getFieldComponentOptions = (field_type, values) => {
  switch (field_type) {
    case TEXT:
      return [
        new caseNumberField(),
        new calculatedField(field_type),
        new sourceFieldCascader(values),
        new defaultValueTextField(values),
      ];
    case TEXTAREA:
      return [new fullWidthOption()];
    case DATE:
      return [new eventDateField(values), new calculatedField(field_type)];
    case DATE_TIME:
      return [new eventDateField(values)];
    case NUMBER:
      return [new numberField(), new calculatedField(field_type)];
    case AUTOCOMPLETE:
      return [
        new autocompleteField(),
        new sourceFieldCascader(values),
        new removeRefKeys(values),
      ];
    case SELECT:
      return [new setFieldsOnChange(values)];
    default:
      return getMoreComponents(field_type, values);
  }
};

const getComponentOptionsByType = (type, field_type, values) => {
  switch (type) {
    case FIELD:
      return getFieldComponentOptions(field_type, values);
    case SECTION:
      return [
        sectionComponentOptions,
        new sectionSourceFieldCheckBox(values),
        new sourceSectionFieldCascader(values),
      ];
    case SECTION_WRAPPER:
      return [new autocompleteField(true)];
    case REPEATER:
      return [new repeaterDeleteModal(), new syncTo(values)];
    case FORM_HEADER:
      return [new formHeader(values)];
    default:
      return [];
  }
};

export default getComponentOptionsByType;
