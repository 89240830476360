import React, { useState } from 'react';
import { Button } from 'antd';

import { UpdateModalContinueButtomWrapper } from './main-form.styled';
import postReportAdminEdit from '../../../actions/post-report-admin-edit';
import { translate } from '../../../i18next';

const confirmUpdateButton = ({
  dispatch,
  reportData,
  updateModal,
  callback,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtomLoading, setIsButtomLoading] = useState(false);

  const enableConfirmButton = () => {
    setIsButtonDisabled(false);
    setIsButtomLoading(false);
  };

  const dispatchUpdate = () => {
    setIsButtonDisabled(true);
    setIsButtomLoading(true);
    dispatch(
      postReportAdminEdit(
        reportData,
        updateModal,
        callback,
        enableConfirmButton
      )
    );
  };

  return (
    <UpdateModalContinueButtomWrapper>
      <Button
        type="primary"
        disabled={isButtonDisabled}
        onClick={dispatchUpdate}
        loading={isButtomLoading}
      >
        {translate('containers.reports.reportViewer.continue')}
      </Button>
    </UpdateModalContinueButtomWrapper>
  );
};

export default confirmUpdateButton;
