import React, { Component } from 'react';
import { Form } from 'antd';
import FieldSelect from '../../components/FieldSelect';
import moment from 'moment';
import {
  STYLE_OPTIONS,
  ITEM_LAYOUT,
  DATE_BOUNDARY_TYPE_LABEL,
  EXPLICIT_LABEL,
  EXPLICIT_VALUE,
  NONE_LABEL,
  NONE_VALUE,
  RANGE_LABEL,
  RANGE_VALUE,
  RANGE_BOUNDARY_KEY,
} from './constants';
import ExplicitDateBoundary from './ExplicitDateBoundary.js';
import RangeDateBoundary from './Range/RangeDateBoundary.js';

const { Item } = Form;
class DateBoundary extends Component {
  constructor(props) {
    super(props);

    // populates state based on dateBoundary from json.options
    this.state = {
      dateBoundary: {
        type: props.value?.type,
        explicitLowerBoundary: props.value?.explicitLowerBoundary
          ? moment(props.value.explicitLowerBoundary)
          : undefined,
        explicitUpperBoundary: props.value?.explicitUpperBoundary
          ? moment(props.value.explicitUpperBoundary)
          : undefined,
        rangeBoundary: props.value?.rangeBoundary
          ? props.value?.rangeBoundary
          : undefined,
      },
      dateBoundaryOptions: this.generateDateBoundaryOptions(),
    };
  }

  generateDateBoundaryOptions = () => {
    return [
      { label: NONE_LABEL, value: NONE_VALUE },
      { label: EXPLICIT_LABEL, value: EXPLICIT_VALUE },
      { label: RANGE_LABEL, value: RANGE_VALUE },
    ];
  };

  onTypeChange = value => {
    let updatedDateBoundary;

    // Fully reset object
    if (value === NONE_VALUE) {
      updatedDateBoundary = undefined;
    } else {
      updatedDateBoundary = { type: value };

      // Reset explicitLowerBoundary and explicitUpperBoundary when type changes
      if (value !== EXPLICIT_VALUE) {
        updatedDateBoundary.explicitLowerBoundary = undefined;
        updatedDateBoundary.explicitUpperBoundary = undefined;
      }

      //Reset rangeBoundary when type changes
      if (value !== RANGE_VALUE) {
        updatedDateBoundary.rangeBoundary = undefined;
      }
    }

    this.setState({ dateBoundary: updatedDateBoundary });

    if (this.props.onChange) {
      this.props.onChange(updatedDateBoundary); // Pass the entire object
    }
  };

  onFieldChange = (key, value) => {
    this.setState(prevState => {
      const updatedDateBoundary = {
        ...prevState.dateBoundary,
        [key]: value,
      };

      if (this.props.onChange) {
        this.props.onChange(updatedDateBoundary);
      }

      return { dateBoundary: updatedDateBoundary };
    });
  };

  onDateChange = (key, date) => {
    this.onFieldChange(key, date === null ? undefined : date.startOf('day'));
  };

  onRangeBoundaryChange = updatedRows => {
    const nonEmptyRows = updatedRows.filter(row => row.type);
    this.onFieldChange(RANGE_BOUNDARY_KEY, nonEmptyRows);
  };

  render() {
    const {
      dateBoundary: {
        type = NONE_VALUE,
        explicitLowerBoundary = undefined,
        explicitUpperBoundary = undefined,
        rangeBoundary = undefined,
      } = {},
      dateBoundaryOptions,
    } = this.state;

    return (
      <div>
        <Item label={DATE_BOUNDARY_TYPE_LABEL} {...ITEM_LAYOUT}>
          <FieldSelect
            value={type === undefined ? NONE_VALUE : type}
            style={STYLE_OPTIONS}
            onChange={this.onTypeChange}
            selectOptions={dateBoundaryOptions}
          />
        </Item>

        {type === EXPLICIT_VALUE && (
          <ExplicitDateBoundary
            lowerBoundary={explicitLowerBoundary}
            upperBoundary={explicitUpperBoundary}
            onDateChange={this.onDateChange}
          />
        )}

        {type === RANGE_VALUE && (
          <RangeDateBoundary
            rangeBoundary={rangeBoundary}
            onRangeBoundaryChange={this.onRangeBoundaryChange}
          />
        )}
      </div>
    );
  }
}

export default DateBoundary;
