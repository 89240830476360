import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import { isEmpty } from 'lodash';
import { Button, DatePicker, Form, notification, Icon } from 'antd';
import moment from 'moment';
import { unescape as htmlUnescape } from 'html-escaper';
import Multiselect from './Multiselect';
import caseFileEndpoints from '../../../../api/caseFileEndpoints/caseFileEndpoints';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';
import {
  setStatusesLoading,
  setStatusesLoadingSuccessful,
  setStatusesLoadingError,
  setOutcomesLoading,
  setOutcomesLoadingSuccessful,
  setOutcomesLoadingError,
  setReasonsLoading,
  setReasonsLoadingSuccessful,
  setReasonsLoadingError,
} from './actions';
import { initialState, reducer } from './reducer';
import {
  AdvanceFilterWrapper,
  FormButtons,
  StyledButton,
} from './CaseFileAdvancedFilters.styled';
import { translate, antdLocale } from '../../../../i18next';

const { RangePicker } = DatePicker;
const { Item } = Form;

const OWNERS = 'owners';
const STATUSES = 'statuses';
const REASONS = 'reasons';
const OUTCOMES = 'outcomes';
const INDIVIDUALS = 'individuals';
const CSF_TYPES = 'casefileTypes';
const DEADLINE = 'deadline';
const CREATED_DATE = 'createdDate';
const dateFields = [DEADLINE, CREATED_DATE];
const mapToIdFields = [CSF_TYPES, OWNERS, INDIVIDUALS];
const mapToLabelFields = [STATUSES, REASONS, OUTCOMES];

const CaseFileAdvancedFilters = ({
  setTableData,
  visible,
  tenantId,
  agencyId,
  enableCasefileKeyDetails,
  form,
  onClick = () => {},
  onReset = () => {},
}) => {
  const [filters, setFilters] = useState({});
  const [owners, setOwners] = useState([]);
  const [individuals, setIndividuals] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState({
    owners: false,
    individuals: false,
  });
  const [casefileTypes, setCasefileTypes] = useState({
    data: [],
    isLoading: false,
  });
  const [selectedUsers, setSelectedUsers] = useState({
    owners: { ids: [], labels: [] },
    individuals: { ids: [], labels: [] },
  });
  const [dateFieldsCache, setDateFieldsCache] = useState({
    deadline: [],
    createdDate: [],
  });
  const [multiselectOptions, setMultiselectOptions] = useState({
    owners: [],
    casefileTypes: [],
    statuses: [],
    reasons: [],
    outcomes: [],
    individuals: [],
  });
  const [keyDetails, dispatch] = useReducer(reducer, initialState);
  const [mouseInMenuRange, setMouseInMenuRange] = useState(false);
  const { getFieldDecorator } = form;
  const formValues = useMemo(() => form.getFieldsValue(), [form]);

  const USERS_MAP = {
    owners: {
      users: owners,
      updateStateFn: state => setOwners(state),
    },
    individuals: {
      users: individuals,
      updateStateFn: state => setIndividuals(state),
    },
  };

  const handleClickOutside = () => {
    if (!mouseInMenuRange && visible) {
      onClick(false);
    }
  };

  useEffect(() => {
    if (!visible) return;
    document.addEventListener('click', handleClickOutside, {
      passive: true,
    });
    return () =>
      document.removeEventListener('click', handleClickOutside, {
        passive: true,
      });
  }, [visible, mouseInMenuRange]);

  useEffect(() => {
    const { reasons, outcomes } = keyDetails;
    const statuses = getFilteredStatuses();
    setMultiselectOptions({
      owners,
      casefileTypes: casefileTypes.data,
      statuses: statuses.value,
      reasons: reasons.value,
      outcomes: outcomes.value,
      individuals,
    });
  }, [owners, casefileTypes, individuals, keyDetails]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const NOTIFICATION_ERROR = 'error';

  function showError(err) {
    const message = err.message || 'API response is not ok';
    showNotification(NOTIFICATION_ERROR, 'Error', message);
  }

  function showNotification(type, message, description) {
    return notification[type]({ message, description });
  }

  const disabledFutureDays = current => {
    return !(current && current < moment().endOf('day'));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const newFilters = form.getFieldsValue();
    normalizeFilters(newFilters);
    setFilters(form.getFieldsValue());
    setTableData(newFilters);
    onClick(false);
  };

  const normalizeFilters = newFilters => {
    for (let [key, value] of Object.entries(newFilters)) {
      if (
        !value ||
        (dateFields.includes(key) && value.length === 0) ||
        (typeof value === 'object' && value.length === 0)
      ) {
        setDateFieldsCache(prevState => ({ ...prevState, [key]: [] }));
        delete newFilters[key];
        continue;
      }

      if (key === DEADLINE) {
        const { startDate, endDate } = getStartAndEndDates(value);
        const deadline = [...newFilters[key]];
        setDateFieldsCache(prevState => ({ ...prevState, deadline }));
        delete newFilters[key];
        newFilters['deadlineStart'] = startDate;
        newFilters['deadlineEnd'] = endDate;
        continue;
      }

      if (key === CREATED_DATE) {
        const { startDate, endDate } = getStartAndEndDates(value);
        const createdDate = [...newFilters[key]];
        setDateFieldsCache(prevState => ({ ...prevState, createdDate }));
        delete newFilters[key];
        newFilters['createdAtStart'] = startDate;
        newFilters['createdAtEnd'] = endDate;
        continue;
      }

      if (mapToIdFields.includes(key)) {
        newFilters[key] = newFilters[key].map(filter => filter.id);
      }

      if (mapToLabelFields.includes(key)) {
        newFilters[key] = newFilters[key].map(filter => filter.label);
      }
    }
  };

  const handleResetFilters = () => {
    form.resetFields();
    setFilters({});
    onReset();
    onClick(false);
    setOwners([]);
    setIndividuals([]);
    setSelectedUsers({
      owners: { ids: [], labels: [] },
      individuals: { ids: [], labels: [] },
    });
    setDateFieldsCache({
      deadline: [],
      createdDate: [],
    });
  };

  const fetchStatuses = () => {
    if (keyDetails.statuses.length > 0) {
      return;
    }

    dispatch(setStatusesLoading());
    caseFileEndpoints
      .getStatusListDashboard(tenantId)
      .then(response => {
        const statuses = formatDataValuesStatuses(response);
        dispatch(setStatusesLoadingSuccessful(statuses));
      })
      .catch(e => {
        showError(e);
        dispatch(setStatusesLoadingError());
      });
  };

  const fetchReasons = () => {
    if (keyDetails.reasons.length > 0) {
      return;
    }

    dispatch(setReasonsLoading());
    caseFileEndpoints
      .getReasonsListDashboard(tenantId)
      .then(response => {
        const reasons = formatDataValuesLikeLabels(response);
        dispatch(setReasonsLoadingSuccessful(reasons));
      })
      .catch(e => {
        showError(e);
        dispatch(setReasonsLoadingError());
      });
  };

  const fetchOutcomes = () => {
    if (keyDetails.outcomes.length > 0) {
      return;
    }

    dispatch(setOutcomesLoading());
    caseFileEndpoints
      .getOutcomesListDashboard(tenantId)
      .then(response => {
        const outcomes = formatDataValuesLikeLabels(response);
        dispatch(setOutcomesLoadingSuccessful(outcomes));
      })
      .catch(e => {
        showError(e);
        dispatch(setOutcomesLoadingError());
      });
  };

  const fetchUsers = (
    searchValue = null,
    isOnFocus,
    users,
    updateStateFn,
    fieldName,
    newUsers = { owners: [], individuals: [] }
  ) => {
    if (
      users.length > 0 &&
      isOnFocus &&
      isEmpty(selectedUsers[fieldName].ids)
    ) {
      return;
    }
    setLoadingUsers(prevState => ({ ...prevState, [fieldName]: true }));
    organizationEndpoints
      .getUsersSearch(agencyId, { fullName: searchValue }, false)
      .then(response => {
        const data = response.map(({ integrationId, fullName }) => ({
          id: integrationId,
          label: fullName,
        }));
        if (newUsers[fieldName] && !isEmpty(newUsers[fieldName])) {
          const reducedUsers = reduceUsers(newUsers[fieldName]);
          reducedUsers.forEach(user => {
            const isUserInArray = data.findIndex(u => u.id === user.id);
            if (isUserInArray === -1) {
              data.unshift({ id: user.id, label: user.label });
            }
          });
        }
        updateStateFn(data);
      })
      .catch(e => showError(e))
      .finally(() =>
        setLoadingUsers(prevState => ({ ...prevState, [fieldName]: false }))
      );
  };

  const fetchCasefileTypes = () => {
    if (casefileTypes.data.length > 0) {
      return;
    }
    setCasefileTypes({ ...casefileTypes, isLoading: true });
    caseFileEndpoints
      .getCasefileTypes(tenantId)
      .then(response => {
        const data = response.content.map(({ id, name }) => ({
          id,
          label: htmlUnescape(name),
        }));
        setCasefileTypes({ ...casefileTypes, data });
      })
      .catch(e => showError(e))
      .finally(() =>
        setCasefileTypes(prevState => ({ ...prevState, isLoading: false }))
      );
  };

  const formatDataValuesStatuses = ({ content }) => {
    const newArray = content.map(({ id, label, casefileTypeId }) => ({
      id,
      casefileTypeId,
      label: htmlUnescape(label),
    }));
    return newArray;
  };

  const formatDataValuesLikeLabels = ({ content }) => {
    const uniqueValues = [];
    content.forEach(element => {
      const isAlreadyInArray = uniqueValues.includes(element.label);
      if (!isAlreadyInArray) {
        uniqueValues.push(htmlUnescape(element.label));
      }
    });

    const newArray = uniqueValues.map(el => ({ id: el, label: el }));
    return newArray;
  };

  const filtersIcon = visible ? 'minus' : 'plus';

  const handleSelectChange = (fieldName, e, callbackFn = () => {}) => {
    const newFilters = form.getFieldsValue();
    newFilters[fieldName] = e;
    updateFilters(newFilters);
    if (fieldName === OWNERS || fieldName === INDIVIDUALS) {
      const { [fieldName]: usersIds } = newFilters;
      const newUsers = mapFilterToSelectedUser(
        usersIds,
        USERS_MAP[fieldName].users
      );
      const _selectedUsers = { owners: [], individuals: [] };
      _selectedUsers[fieldName] = newUsers;
      const isNewUser = _selectedUsers[fieldName].ids.findIndex(id =>
        e.includes(id)
      );
      if (isNewUser === -1) {
        e.forEach(user => {
          _selectedUsers[fieldName].ids.push(user.id);
          _selectedUsers[fieldName].labels.push(user.label);
        });
      }
      fetchUsers(
        null,
        false,
        USERS_MAP[fieldName].users,
        USERS_MAP[fieldName].updateStateFn,
        fieldName,
        newUsers
      );
    }
    callbackFn();
  };

  const updateFilters = newFilters => {
    normalizeFilters(newFilters);
    setFilters(form.getFieldsValue());
  };

  const handleFieldOnBlur = fieldName => {
    switch (fieldName) {
      case OWNERS:
        const newOwners = reduceUsers(selectedUsers.owners);
        setOwners(newOwners);
        break;

      case INDIVIDUALS:
        const newIndividuals = reduceUsers(selectedUsers.individuals);
        setIndividuals(newIndividuals);
        break;
    }
  };

  const getStartAndEndDates = date => {
    const startDate = date[0].format('YYYY-MM-DD');
    const endDate = date[1].format('YYYY-MM-DD');
    return { startDate, endDate };
  };

  const reduceUsers = users => {
    if (!users || isEmpty(users)) return [];
    return users?.ids?.map((id, index) => ({
      id: id,
      label: users.labels[index],
    }));
  };

  const mapFilterToSelectedUser = (ids, users) => {
    if (!ids || isEmpty(ids)) {
      return { ids: [], labels: [] };
    }
    const filtered = users.filter(u => ids.includes(u.id));
    return filtered.reduce(
      (prev, curr) => {
        return {
          ids: [...prev.ids, curr.id],
          labels: [...prev.labels, curr.label],
        };
      },
      { ids: [], labels: [] }
    );
  };

  const updateDatesFromCache = filters => {
    if (
      dateFieldsCache &&
      dateFieldsCache.deadline &&
      !isEmpty(dateFieldsCache.deadline)
    ) {
      filters.deadline = dateFieldsCache.deadline;
    }
    if (
      dateFieldsCache &&
      dateFieldsCache.createdDate &&
      !isEmpty(dateFieldsCache.createdDate)
    ) {
      filters.createdDate = dateFieldsCache.createdDate;
    }
  };

  useEffect(() => {
    const newFilters = form.getFieldsValue();
    if (!newFilters || Object.keys(newFilters).length === 0) {
      return;
    }
    const { owners: ownersIds, individuals: individualsIds } = newFilters;
    const newOwners = mapFilterToSelectedUser(ownersIds, owners);
    const newIndividuals = mapFilterToSelectedUser(individualsIds, individuals);
    setSelectedUsers(prevState => {
      const ownersNotMapped = newOwners.ids.reduce(
        (prev, curr, index) => {
          const isNotMapped =
            prevState.owners.ids.findIndex(id => id === curr) === -1;
          if (isNotMapped) {
            return {
              ids: [...prev.ids, curr],
              labels: [...prev.labels, newOwners.labels[index]],
            };
          }
          return prev;
        },
        { ids: [], labels: [] }
      );

      const individualsNotMapped = newIndividuals.ids.reduce(
        (prev, curr, index) => {
          const isNotMapped =
            prevState.individuals.ids.findIndex(id => id === curr) === -1;
          if (isNotMapped) {
            return {
              ids: [...prev.ids, curr],
              labels: [...prev.labels, newIndividuals.labels[index]],
            };
          }
          return prev;
        },
        { ids: [], labels: [] }
      );

      return {
        owners: {
          ids: [...prevState.owners.ids, ...ownersNotMapped.ids],
          labels: [...prevState.owners.labels, ...ownersNotMapped.labels],
        },
        individuals: {
          ids: [...prevState.individuals.ids, ...individualsNotMapped.ids],
          labels: [
            ...prevState.individuals.labels,
            ...individualsNotMapped.labels,
          ],
        },
      };
    });
    setFilters(newFilters);
  }, [form]);

  useEffect(() => {
    if (!visible || Object.keys(filters).length === 0) {
      return;
    }
    const { owners: _owners, individuals: _individuals } = selectedUsers;
    const selectedOwners = reduceUsers(_owners);
    const selectedIndividuals = reduceUsers(_individuals);
    setOwners(selectedOwners);
    setIndividuals(selectedIndividuals);
    updateDatesFromCache(filters);
    form.setFieldsValue(filters);
  }, [visible]);

  const getFilteredStatuses = useCallback(() => {
    const filters = form.getFieldsValue();
    const casefileTypeIds = filters.casefileTypes?.map(type => type.id);
    if (
      casefileTypeIds &&
      !isEmpty(casefileTypeIds) &&
      statuses &&
      !isEmpty(statuses.value)
    ) {
      let filteredStatuses = [];
      casefileTypeIds.forEach(id => {
        const statusesToAdd = statuses?.value.filter(
          status => status.casefileTypeId === id
        );
        filteredStatuses = filteredStatuses.concat(statusesToAdd);
      });
      const uniqueFiltered = filterUniqueStatuses(filteredStatuses);
      return { loading: statuses.loading, value: uniqueFiltered };
    }
    const uniqueStatuses = filterUniqueStatuses(statuses.value);
    return { loading: statuses.loading, value: uniqueStatuses };
  }, [filters, keyDetails]);

  const filterUniqueStatuses = arr => {
    const uniqueValues = [];
    arr.forEach(element => {
      const isAlreadyInArray =
        uniqueValues.findIndex(el => el.label === element.label) > -1;
      if (!isAlreadyInArray) {
        uniqueValues.push({ ...element, label: htmlUnescape(element.label) });
      }
    });
    return uniqueValues;
  };

  const handleSearchFilter = (fieldName, search, callbackFn) => {
    let options = [];
    switch (fieldName) {
      case OWNERS:
        options = owners;
        break;
      case CSF_TYPES:
        options = casefileTypes.data;
        break;
      case STATUSES:
        options = getFilteredStatuses().value;
        break;
      case REASONS:
      case OUTCOMES:
        options = keyDetails[fieldName].value;
        break;
      case INDIVIDUALS:
        options = individuals;
        break;
    }
    if (!search || isEmpty(search)) {
      setMultiselectOptions(prevState => ({
        ...prevState,
        [fieldName]: options,
      }));
      callbackFn();
      return;
    }
    const searchWords = search.trim().split(' ');
    const filtered = options.filter(option => {
      let startsWithCount = 0;
      const optionWords = option.label.split(' ');
      optionWords.forEach(word => {
        searchWords.forEach(searchTerm => {
          if (word.toLowerCase().startsWith(searchTerm.toLowerCase())) {
            startsWithCount += 1;
          }
        });
      });
      return startsWithCount === searchWords.length;
    });
    setMultiselectOptions(prevState => ({
      ...prevState,
      [fieldName]: filtered,
    }));
    callbackFn();
  };

  const { statuses, reasons, outcomes } = keyDetails;
  const filteredStatuses = getFilteredStatuses();
  return (
    <div>
      <StyledButton
        type={visible ? 'secondary' : 'primary'}
        onClick={() => onClick(!visible)}
      >
        <Icon type={filtersIcon} />{' '}
        {translate('containers.caseFiles.advancedFilters')}
      </StyledButton>
      {visible && (
        <AdvanceFilterWrapper
          onMouseEnter={() => setMouseInMenuRange(true)}
          onMouseLeave={() => setMouseInMenuRange(false)}
        >
          <Form {...formItemLayout} onSubmit={handleSubmit}>
            <Item label={translate('containers.caseFiles.owner')}>
              {getFieldDecorator(OWNERS)(
                <Multiselect
                  identifier={OWNERS}
                  loading={loadingUsers[OWNERS]}
                  options={multiselectOptions[OWNERS]}
                  selectedItems={formValues.owners}
                  placeholder={translate('containers.caseFiles.owner')}
                  onFocus={() =>
                    fetchUsers(null, true, owners, setOwners, OWNERS)
                  }
                  onBlur={() => handleFieldOnBlur(OWNERS)}
                  onChange={(e, callbackFn) =>
                    handleSelectChange(OWNERS, e, callbackFn)
                  }
                  onSearch={(e, callbackFn) =>
                    handleSearchFilter(OWNERS, e, callbackFn)
                  }
                  notFoundContent={translate(
                    'containers.caseFiles.noUsersFound'
                  )}
                />
              )}
            </Item>
            <Item label={translate('containers.caseFiles.creationDate')}>
              {getFieldDecorator('createdDate', {
                rules: [{ required: false }],
              })(
                <RangePicker
                  locale={antdLocale}
                  allowClear
                  disabledDate={disabledFutureDays}
                  onChange={e => handleSelectChange('createdDate', e)}
                  style={{ width: '100%', maxWidth: '450px' }}
                />
              )}
            </Item>
            <Item label={translate('containers.caseFiles.caseFileTypes')}>
              {getFieldDecorator(CSF_TYPES)(
                <Multiselect
                  identifier={CSF_TYPES}
                  loading={casefileTypes.isLoading}
                  options={multiselectOptions[CSF_TYPES]}
                  selectedItems={formValues.casefileTypes}
                  placeholder={translate('containers.caseFiles.caseFileTypes')}
                  onFocus={fetchCasefileTypes}
                  onChange={(e, callbackFn) =>
                    handleSelectChange(CSF_TYPES, e, callbackFn)
                  }
                  onSearch={(e, callbackFn) =>
                    handleSearchFilter(CSF_TYPES, e, callbackFn)
                  }
                  notFoundContent={translate(
                    'containers.caseFiles.noCaseFileTypesFound'
                  )}
                />
              )}
            </Item>
            {enableCasefileKeyDetails && (
              <Item label={translate('containers.caseFiles.deadline')}>
                {getFieldDecorator(DEADLINE)(
                  <RangePicker
                    allowClear
                    onChange={e => handleSelectChange('deadline', e)}
                    style={{ width: '100%', maxWidth: '450px' }}
                  />
                )}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label={translate('containers.caseFiles.status')}>
                {getFieldDecorator(STATUSES)(
                  <Multiselect
                    identifier={STATUSES}
                    loading={filteredStatuses.loading}
                    options={multiselectOptions[STATUSES]}
                    selectedItems={formValues.statuses}
                    placeholder={translate('containers.caseFiles.status')}
                    onFocus={fetchStatuses}
                    onChange={(e, callbackFn) =>
                      handleSelectChange(STATUSES, e, callbackFn)
                    }
                    onSearch={(e, callbackFn) =>
                      handleSearchFilter(STATUSES, e, callbackFn)
                    }
                    notFoundContent={translate(
                      'containers.caseFiles.noStatusesFound'
                    )}
                  />
                )}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label={translate('containers.caseFiles.reasons')}>
                {getFieldDecorator(REASONS)(
                  <Multiselect
                    identifier={REASONS}
                    loading={reasons.loading}
                    options={multiselectOptions[REASONS]}
                    selectedItems={formValues.reasons}
                    placeholder={translate('containers.caseFiles.reasons')}
                    onFocus={fetchReasons}
                    onChange={(e, callbackFn) =>
                      handleSelectChange(REASONS, e, callbackFn)
                    }
                    onSearch={(e, callbackFn) =>
                      handleSearchFilter(REASONS, e, callbackFn)
                    }
                    notFoundContent={translate(
                      'containers.caseFiles.noReasonsFound'
                    )}
                  />
                )}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label={translate('containers.caseFiles.outcomes')}>
                {getFieldDecorator(OUTCOMES)(
                  <Multiselect
                    identifier={OUTCOMES}
                    loading={outcomes.loading}
                    options={multiselectOptions[OUTCOMES]}
                    selectedItems={formValues.outcomes}
                    placeholder={translate('containers.caseFiles.outcomes')}
                    onFocus={fetchOutcomes}
                    onChange={(e, callbackFn) =>
                      handleSelectChange(OUTCOMES, e, callbackFn)
                    }
                    onSearch={(e, callbackFn) =>
                      handleSearchFilter(OUTCOMES, e, callbackFn)
                    }
                    notFoundContent={translate(
                      'containers.caseFiles.noOutcomesFound'
                    )}
                  />
                )}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label={translate('containers.caseFiles.individuals')}>
                {getFieldDecorator(INDIVIDUALS)(
                  <Multiselect
                    identifier={INDIVIDUALS}
                    loading={loadingUsers.individuals}
                    options={multiselectOptions[INDIVIDUALS]}
                    selectedItems={formValues.individuals}
                    placeholder={translate('containers.caseFiles.individuals')}
                    onFocus={() =>
                      fetchUsers(
                        null,
                        true,
                        individuals,
                        setIndividuals,
                        INDIVIDUALS
                      )
                    }
                    onSearch={(value, callbackFn) => {
                      fetchUsers(
                        value,
                        false,
                        individuals,
                        setIndividuals,
                        INDIVIDUALS
                      );
                      callbackFn();
                    }}
                    onBlur={() => handleFieldOnBlur(INDIVIDUALS)}
                    onChange={(e, callbackFn) =>
                      handleSelectChange(INDIVIDUALS, e, callbackFn)
                    }
                    notFoundContent={translate(
                      'containers.caseFiles.noUsersFound'
                    )}
                  />
                )}
              </Item>
            )}
            <FormButtons>
              <Button type="danger" onClick={handleResetFilters}>
                {translate('containers.caseFiles.reset')}
              </Button>
              <Button type="primary" htmlType="submit">
                {translate('containers.caseFiles.apply')}
              </Button>
            </FormButtons>
          </Form>
        </AdvanceFilterWrapper>
      )}
    </div>
  );
};

const WrappedCaseFileAdvancedFilters = Form.create({
  name: 'casefile_filters',
})(CaseFileAdvancedFilters);

export default WrappedCaseFileAdvancedFilters;
