import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { uniq } from 'lodash';
import { Empty, Tooltip } from 'antd';

import { getCurrentUser, getAgencyTZ } from 'APP_ROOT/selectors/session';
import { parseHtmlInput } from 'APP_ROOT/utils/request';

import StatusBadge from './components/StatusBadge';
import EllipsisMenu from '../caseFile/components/EllipsisMenu';
import StyledTable from './ReportsTable.styled';
import {
  COLUMN_REPORT_NUMBER,
  COLUMN_REPORT_TYPE,
  COLUMN_SUBMISSION_DATE,
  COLUMN_SUBMITTED_BY,
  COLUMN_EVENT_DATE,
  COLUMN_CASE_NUMBER,
  COLUMN_MENTIONED_USERS,
  COLUMN_STATUS,
  COLUMN_ACTIVE_PARTICIPANT,
} from './utils/constants';
import getReportsTableData from './utils/getReportsTableData';
import renderReportNumber from './utils/renderReportNumber';

import { translate } from '../../i18next';

const buildTitle = key => {
  const testId = key
    .split('.')
    .slice(-2)
    .join('-');
  return <span data-testid={`${testId}-header`}>{translate(key)}</span>;
};

const renderWithTestId = testId => (text, record, index) => {
  return (
    <span data-testid={`reports-${index}-${testId}`}>
      {parseHtmlInput(text)}
    </span>
  );
};

const COLUMNS = [
  {
    title: buildTitle('containers.reports.reportNumber'),
    dataIndex: COLUMN_REPORT_NUMBER,
    key: COLUMN_REPORT_NUMBER,
    fixed: 'left',
    width: 180,
    render: renderReportNumber,
  },
  {
    title: buildTitle('containers.reports.reportType'),
    dataIndex: COLUMN_REPORT_TYPE,
    key: COLUMN_REPORT_TYPE,
    width: 240,
    render: renderWithTestId('reportType'),
  },
  {
    title: buildTitle('containers.reports.submittedDate'),
    dataIndex: COLUMN_SUBMISSION_DATE,
    key: COLUMN_SUBMISSION_DATE,
    width: 150,
    render: renderWithTestId('submittedDate'),
  },
  {
    title: buildTitle('containers.reports.submittedBy'),
    dataIndex: COLUMN_SUBMITTED_BY,
    key: COLUMN_SUBMITTED_BY,
    width: 220,
    render: (submittedBy, data, index) => {
      const { reportParticipants, organizationalUnitDisplayName } = data;
      const { firstName, lastName } = reportParticipants[submittedBy];
      const fullName = `${firstName} ${lastName}`;
      return (
        <Tooltip
          title={
            <div>
              <b>{translate('containers.reports.submittedFrom')}</b>
              <p>{parseHtmlInput(organizationalUnitDisplayName)}</p>
            </div>
          }
        >
          <span data-testid={`reports-${index}-submittedBy`}>
            {parseHtmlInput(fullName) ||
              translate('containers.reports.notAvailable')}
          </span>
        </Tooltip>
      );
    },
  },
  {
    title: buildTitle('containers.reports.status'),
    dataIndex: COLUMN_STATUS,
    key: COLUMN_STATUS,
    width: 170,
    render: (status, _, index) => (
      <StatusBadge status={status} testId={`reports-${index}-status`} />
    ),
  },
  {
    title: buildTitle('containers.reports.activeParticipant'),
    dataIndex: COLUMN_ACTIVE_PARTICIPANT,
    key: COLUMN_ACTIVE_PARTICIPANT,
    width: 220,
  },
  {
    title: buildTitle('containers.reports.mentionedUsers'),
    dataIndex: COLUMN_MENTIONED_USERS,
    key: COLUMN_MENTIONED_USERS,
    width: 220,
  },
  {
    title: buildTitle('containers.reports.eventDate'),
    dataIndex: COLUMN_EVENT_DATE,
    key: COLUMN_EVENT_DATE,
    width: 155,
    render: renderWithTestId('eventDate'),
  },
  {
    title: buildTitle('containers.reports.caseNumber'),
    dataIndex: COLUMN_CASE_NUMBER,
    key: COLUMN_CASE_NUMBER,
    width: 180,
    render: (data, _, index) => (
      <EllipsisMenu elements={data} testId={`reports-${index}-caseNumber`} />
    ),
  },
];

const ReportsTable = props => {
  const {
    adminPage = false,
    loading = false,
    loadingReports = false,
    reportOrderedList,
    reportList,
    currentUser,
    timezone,
    total,
  } = props;
  const [data, setData] = useState();
  const isLoading = loading || loadingReports;
  const dataSource = total > 0 ? data : [];

  useEffect(() => {
    if (total > 0 && !isLoading) {
      setData(
        getReportsTableData({
          reportOrderedList,
          reportList,
          currentUser,
          timezone,
          adminPage,
        })
      );
    }
  }, [total, isLoading]);

  return (
    <StyledTable
      columns={COLUMNS}
      dataSource={dataSource}
      loading={isLoading}
      pagination={false}
      locale={{
        emptyText: isLoading ? <Empty image="" description="" /> : '',
      }}
      data-testid="reports-table"
      scroll={{ x: '100%' }}
    />
  );
};

const mapStateToProps = state => {
  const {
    report: { data: reportList, list = [], total, loading },
  } = state;
  return {
    total,
    reportList,
    reportOrderedList: uniq(list),
    loadingReports: loading,
    timezone: getAgencyTZ(state),
    currentUser: getCurrentUser(state),
  };
};

export default connect(mapStateToProps)(ReportsTable);
