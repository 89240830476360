export const URL_SEARCH_PARAM_PAGE = 'page';

// Reports table column definition
export const COLUMN_REPORT_NUMBER = 'formNumber';
export const COLUMN_REPORT_TYPE = 'templateName';
export const COLUMN_SUBMISSION_DATE = 'submissionDate';
export const COLUMN_SUBMITTED_BY = 'submitterId';
export const COLUMN_EVENT_DATE = 'eventDate';
export const COLUMN_CASE_NUMBER = 'caseNumber';
export const COLUMN_MENTIONED_USERS = 'mentionedUsers';
export const COLUMN_STATUS = 'status';
export const COLUMN_ACTIVE_PARTICIPANT = 'participant';

// data needed for the report table
export const REPORT_UNIT_DISPLAY_NAME = 'organizationalUnitDisplayName';
export const REPORT_LINKED_FORMS = 'linkedForms';
export const REPORT_HISTORY = 'transactionHistory';

// Report filters
const REPORT_FILTER_PARTICIPANTS = {
  relation: 'participants',
  scope: {
    fields: [
      'fullName',
      'lastName',
      'firstName',
      'starNumber',
      'email',
      'rankId',
    ],
    include: [
      {
        relation: 'rank',
        scope: {
          fields: { name: true, abbreviation: true, id: true },
        },
      },
    ],
  },
};
const REPORT_FILTER_NOTES = {
  relation: 'notes',
  scope: {
    include: ['historyStep'],
    order: 'created ASC',
  },
};
const REPORT_FILTER_ACTIONS = {
  relation: 'actions',
  scope: {
    order: 'created ASC',
  },
};
const REPORT_FILTER_LINKED_FORMS = {
  relation: 'linkedForms',
  scope: {
    include: {
      relation: 'actions',
    },
  },
};
const REPORT_FILTER_SUBMITTED_FORMS = {
  relation: 'submittedForms',
  scope: {
    include: {
      relation: 'actions',
      scope: {
        where: { status: 'pending' },
      },
    },
  },
};
const REPORT_FILTER_FORM_LINKS = {
  relation: 'formLinks',
  scope: {
    include: ['start', 'end'],
  },
};
const REPORT_FILTER_LINKED_SUBMISSIONS = {
  relation: 'linkedSubmissions',
  scope: {
    include: {
      relation: 'actions',
      scope: {
        where: { status: 'pending' },
      },
    },
  },
};

export const REPORT_FILTERS = [
  REPORT_FILTER_PARTICIPANTS,
  REPORT_FILTER_NOTES,
  REPORT_FILTER_ACTIONS,
  REPORT_FILTER_LINKED_FORMS,
  REPORT_FILTER_SUBMITTED_FORMS,
  REPORT_FILTER_FORM_LINKS,
  REPORT_FILTER_LINKED_SUBMISSIONS,
];

// StatusBadge background colors
export const STATUS_BADGE_COLORS = {
  takeAction: '#ffee6e',
  closed: '#7adea4',
  inReview: '#ff9c6e',
  draft: '#e1c8f0',
};
