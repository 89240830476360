import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import parsedDate from '../../utils/parse-date';
import { find, get, isEmpty } from 'lodash';
import { getTimelineUser } from './utils';
import { translate } from '../../i18next';

class ShareItem extends Component {
  state = { isLoading: true, users: [], userShareFrom: [] };

  componentDidMount() {
    this.getShareUsers();
    this.getShareFrom();
  }

  getShareUsers = async () => {
    const { item, agencyId } = this.props;
    const { shareToId } = item;
    const shareTo = await getTimelineUser(shareToId, agencyId);
    this.setState({ shareTo, isLoading: false });
  };

  getParticipant = (participants = [], id) => {
    return find(participants, _item => _item.id === id);
  };

  getShareFrom = async () => {
    const { item, agencyId, participants } = this.props;
    const { shareFromId } = item;
    const participant = await this.getParticipant(participants, shareFromId);
    if (isEmpty(participant)) {
      const shareFrom = await getTimelineUser(shareFromId, agencyId);
      this.setState({ shareFrom, isLoading: false });
    } else {
      this.setState({
        shareFrom: `${participant.rank.name} ${participant.fullName}`,
        isLoading: false,
      });
    }
  };

  render() {
    const { item, index, number, timezone } = this.props;
    const { shareTo, isLoading, shareFrom } = this.state;
    if (isLoading)
      return (
        <ContentLoader
          height={5}
          width={90}
          speed={2}
          primaryColor={'#f3f3f3'}
          secondaryColor={'#ecebeb'}
          className="loader-share-item"
        >
          <rect x="0" y="0" rx="4" ry="4" width="90" height="5" />
        </ContentLoader>
      );

    // Currently we are using 'shared' and 'unshared' as translation keys to translate the verbs that comes from item.action

    return (
      <div className={`timeline-item-date-${index}`}>
        <p className={`timeline-item-date-${index}`}>
          {parsedDate(item.createdAt, timezone)}
        </p>
        <p className={`timeline-item-message-${index}`}>
          <span dangerouslySetInnerHTML={{ __html: shareFrom }} />
          {translate('containers.reports.timeline.hasWith', {
            action: translate(
              `containers.reports.timeline.${`${item.action}d`}`
            ),
            number: get(item, 'formNumber', number),
          })}
          <span dangerouslySetInnerHTML={{ __html: shareTo }} />
        </p>
      </div>
    );
  }
}

export default ShareItem;
