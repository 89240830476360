import React, { useReducer, useCallback } from 'react';
import { debounce } from 'lodash';
import { Select, DatePicker, Form, Spin, Input, Row, Col } from 'antd';
import moment from 'moment';

import {
  setUsersLoading,
  setUsersLoadingError,
  setUsersLoadingSuccessful,
} from './actions';
import { initialState, reducer } from './reducer';
import { BENCHMARK_DATE_FORMAT } from '../../../../utils/parse-date';
import {
  showError,
  EVENT_TYPE_LIST,
  RANGE_DATE_FILTER,
  REPORT_NUMBER_FILTER,
  USER_FILTER,
  EVENT_TYPE_FILTER,
} from './constants';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Item } = Form;

const filterOptionBySearch = (input, option) =>
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const ReportsAuditLogsFilters = ({
  loading,
  agencyId,
  handleFilterChange = () => {},
  form,
}) => {
  const [userState, dispatch] = useReducer(reducer, initialState);
  const { getFieldDecorator } = form;

  const sendFilters = newFilters => {
    for (let [key, value] of Object.entries(newFilters)) {
      if (!value || (typeof value === 'object' && value.length === 0)) {
        delete newFilters[key];
        continue;
      }

      if (key === RANGE_DATE_FILTER) {
        const startDate = value[0].startOf('day').format();
        const endDate = value[1].startOf('day').format();
        delete newFilters[key];
        newFilters['from'] = startDate;
        newFilters['to'] = endDate;
        continue;
      }
    }

    handleFilterChange(newFilters);
  };

  const fetchUsers = (searchValue = null, isOnFocus) => {
    if (userState.data.length > 0 && isOnFocus) {
      return;
    }

    dispatch(setUsersLoading());

    organizationEndpoints
      .getUsersSearch(agencyId, { fullName: searchValue })
      .then(response => {
        const data = response.map(({ integrationId, fullName }) => ({
          id: integrationId,
          label: fullName,
        }));
        dispatch(setUsersLoadingSuccessful(data));
      })
      .catch(e => {
        showError(e);
        dispatch(setUsersLoadingError());
      });
  };

  const renderValuesOptions = values =>
    values.map(({ id, label }) => <Option key={id}>{label}</Option>);

  const sendTextFilter = () => {
    const newFilters = form.getFieldsValue();
    sendFilters(newFilters);
  };

  const handleOnChangeText = e => {
    // clear icon is clicked
    if (e.type === 'click') {
      const newFilters = form.getFieldsValue();
      newFilters[REPORT_NUMBER_FILTER] = '';
      sendFilters(newFilters);
    }
  };

  const handleCalendarChange = e => {
    const newFilters = form.getFieldsValue();
    newFilters[RANGE_DATE_FILTER] = e;
    sendFilters(newFilters);
  };

  const handleSelectChange = useCallback(
    debounce((fieldName, e) => {
      const newFilters = form.getFieldsValue();
      newFilters[fieldName] = e;
      sendFilters(newFilters);
      if (fieldName === USER_FILTER) {
        fetchUsers(null, false);
      }
    }, 800),
    []
  );

  const debouncedfetchUsers = useCallback(debounce(fetchUsers, 800), []);

  const disabledDate = current =>
    current && current.valueOf() < moment('2025-02-01');

  return (
    <Form style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={6}>
          <Item>
            {getFieldDecorator(REPORT_NUMBER_FILTER)(
              <Input
                allowClear
                placeholder="Enter Report Number"
                disabled={loading}
                onPressEnter={sendTextFilter}
                onChange={handleOnChangeText}
              />
            )}
          </Item>
        </Col>
        <Col span={6}>
          <Item>
            {getFieldDecorator(RANGE_DATE_FILTER)(
              <RangePicker
                allowClear
                disabledDate={disabledDate}
                onChange={handleCalendarChange}
                format={BENCHMARK_DATE_FORMAT}
                disabled={loading}
              />
            )}
          </Item>
        </Col>
        <Col span={6}>
          <Item>
            {getFieldDecorator(USER_FILTER)(
              <Select
                allowClear
                showSearch
                placeholder="Users"
                filterOption={false}
                onFocus={() => fetchUsers(null, true)}
                onChange={e => handleSelectChange(USER_FILTER, e)}
                onSearch={value => debouncedfetchUsers(value, false)}
                disabled={loading}
                notFoundContent={
                  userState.loading ? <Spin size="small" /> : 'No users found'
                }
                dropdownStyle={{ position: 'fixed' }}
                style={{ width: '100%' }}
                className="users-select"
              >
                {renderValuesOptions(userState.data)}
              </Select>
            )}
          </Item>
        </Col>
        <Col span={6}>
          <Item>
            {getFieldDecorator(EVENT_TYPE_FILTER)(
              <Select
                style={{ width: '100%' }}
                allowClear
                showSearch
                mode="multiple"
                placeholder="Event Type"
                filterOption={filterOptionBySearch}
                onChange={e => handleSelectChange(EVENT_TYPE_FILTER, e)}
                dropdownStyle={{ position: 'fixed' }}
                disabled={loading}
              >
                {EVENT_TYPE_LIST.map(({ label, value }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedReportsAuditLogFilters = Form.create({
  name: 'reports_audit_logs_filters',
})(ReportsAuditLogsFilters);

export default WrappedReportsAuditLogFilters;
