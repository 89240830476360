import React from 'react';
import { Button } from 'antd';

import getRoute from 'APP_ROOT/utils/get-route';
import { translate } from '../../../i18next';

const CreateCaseFileButton = history => {
  const openCaseFilePage = () => {
    history.push(getRoute('createNewCaseFile'));
  };

  return (
    <Button
      type="primary"
      tooltipMessage={translate('containers.caseFiles.createNewCaseFile')}
      onClick={openCaseFilePage}
    >
      + {translate('containers.caseFiles.createNewCaseFile')}
    </Button>
  );
};

export default CreateCaseFileButton;
