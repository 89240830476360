import React, { Component } from 'react';
import {
  get,
  has,
  omit,
  groupBy,
  find,
  findIndex,
  isEqual,
  some,
} from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import scrollToComponent from 'react-scroll-to-component';

import {
  mapProperties,
  mapFormKeys,
  addButtonTemplate,
} from 'APP_ROOT/utils/form';

import RepeaterWrapper from '../styled/repeater';
import RepeaterNavigation from './repeater-navigation';
import FieldTemplate from 'APP_ROOT/utils/fieldTemplate';
import createRepeaterSource from 'APP_ROOT/actions/create-repeater-source';
import syncRepeaters from 'APP_ROOT/actions/sync-repeaters';
import checkSeedRepeaters from 'APP_ROOT/actions/check-seed-repeaters';
import getOverrides from 'APP_ROOT/utils/get-field-overrides';
import {
  isSymlinked,
  getSymlinkInfo,
  symlinksPropName,
  getSymlinks,
} from 'APP_ROOT/utils/create-symlink';
import onItemsChange from 'APP_ROOT/utils/onItemsChange';
import addFirstItemIfRepeaterIsEmpty from 'APP_ROOT/utils/addFirstItemIfRepeaterIsEmpty';
import formActionDispatcher from 'APP_ROOT/utils/formDispatchEmitter';
import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';
import emitter, { EventTypes } from 'APP_ROOT/utils/eventEmitter';
import { getFormTemplate, getDataEnums } from '../../../selectors/form';
import { getDataSelector } from 'APP_ROOT/utils/renderSchema';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';
import AddButton from './Repeater.AddItemButton';
import RepeaterRemoveItem from './Repeater.RemoveItemButton';
import assertedCondition from '../../../utils/assertedCondition';
import { expectations } from '../../../utils/constants';

class Repeater extends Component {
  items = {};

  saveRef = index => el => {
    this.items[index] = el;
  };
  componentDidMount() {
    const {
      dataKey = '',
      source = '',
      data = {},
      overrides,
      settings,
    } = this.props;

    const fieldOverrides = getOverrides(this.props, overrides);
    const options = fieldOverrides('options', {});
    const { syncTo = [], syncKeys = {}, seedKeys = {} } = options;

    if (source && dataKey) {
      formActionDispatcher(
        settings.formName,
        createRepeaterSource(source, dataKey)
      );
    }

    if (syncTo.length && Object.keys(syncKeys).length) {
      formActionDispatcher(
        settings.formName,
        syncRepeaters(dataKey, syncTo, syncKeys)
      );
    }

    if (Object.keys(seedKeys).length) {
      const fields = get(data, [dataKey], []);
      formActionDispatcher(
        settings.formName,
        checkSeedRepeaters(fields, dataKey, seedKeys)
      );
    }

    addFirstItemIfRepeaterIsEmpty(this.props, this.getItems);
  }

  componentDidUpdate(prevProps) {
    const props = this.props;

    const currentItems = this.getItems(props);
    const prevItems = this.getItems(prevProps);

    if (
      currentItems.length !== prevItems.length ||
      !isEqual(currentItems, prevItems)
    ) {
      onItemsChange(currentItems, prevItems, props, prevProps);
    }

    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  getItems = props => {
    const { data, dataKey: parentKey } = props;
    return data[parentKey] || [];
  };

  onChangeNavigation = activeItem => {
    const onDone = () => {
      const formHeader = document.getElementById('form-header');
      const headerHeight = formHeader ? formHeader.clientHeight + 15 : 200;

      scrollToComponent(this.items[activeItem], {
        align: 'top',
        offset: -headerHeight,
      });
    };

    emitter.emit(EventTypes.OPEN_TRAINING_TASK, { activeItem, onDone });
  };

  onChangeCollapse = value => {
    emitter.emit(EventTypes.OPEN_TRAINING_TASK, { activeAll: value });
  };

  canShowRemove = parentIndex => {
    const { data = {}, dataKey: parentKey = '' } = this.props;
    const { __assignedSections = [] } = data;
    const repeaterData = data[parentKey] || [];
    const { id } = repeaterData[parentIndex] || {};

    const item = __assignedSections.find(
      a => a.repeaterKey === parentKey && a.repeaterItemId === id
    );
    return !item?.userId;
  };

  inARepeaterCTRSection = () => {
    const { isContributeReport, parentKey, parentIndex, data } = this.props;
    const { __assignedSections = [] } = data;

    if (isContributeReport && parentKey) {
      // nested repeater
      const itemId = get(data, [parentKey, parentIndex, 'id']);
      return some(__assignedSections, s =>
        s.isRepeater ? s.repeaterItemId === itemId : false
      );
    }
    return false;
  };

  render() {
    const {
      data = {},
      presentation = {}, // eslint-disable-line
      dataKey: parentKey = '',
      form = {},
      properties = [],
      settings = {},
      tab = 0, // eslint-disable-line
      isReviewer = false,
      conditions = {},
      ...rest
    } = this.props;

    const formDataEnums = get(this.props, 'dataEnums', {});
    const fieldOverrides = getOverrides(this.props, rest.overrides || {});
    const options = fieldOverrides('options', {});

    const total = properties.length;
    const {
      showAddButton = true,
      showRemoveButton = true,
      addButtonLabel = 'Add Item',
      minItems = 1,
      maxItems = null,
      syncTo = [],
      removeKeys = [],
      removeRefKeys = [],
      actions = [],
      syncFrom,
      syncKeys,
      seedKeys,
      group = {},
      deleteModalOptions,
      confirmBeforeDelete = true,
      loopFromEnumRef,
      hasNavigation = false,
    } = options;

    const { formSchema } = settings;

    const repeaterData = data[parentKey] || [];

    const sourceData = maxItems ? data[maxItems] : 0;

    const navigationOptions = get(formDataEnums, loopFromEnumRef, []);
    const sourceDataLength =
      typeof sourceData === 'object' ? sourceData.length : sourceData;
    const canAddMore = maxItems ? sourceDataLength > repeaterData.length : true;
    const itemIdField = new FieldTemplate('text', {
      key: 'id',
      conditions: {
        rules: [
          {
            key: 'isHidden',
            expect: true,
          },
        ],
      },
    });

    const template = {
      ...(get(properties, [0], {}) || { type: 'row' }),
      properties: [
        itemIdField.toJSON(),
        ...get(properties, [0, 'properties'], []),
      ],
    };
    const syncToTemplates = syncTo.length
      ? mapFormKeys(formSchema, syncTo)
      : [];

    const symlinks = get(data, symlinksPropName, []);
    const parentRepeaterAtCurrentIndexData = get(
      data,
      `${rest.parentKey}.${rest.parentIndex}`,
      {}
    );

    let symlinkPath;
    const parentSymlinkNames = getSymlinks(parentRepeaterAtCurrentIndexData);
    const parentPaths = parentSymlinkNames
      .filter(symlinkName => symlinkName === parentKey)
      .map(symlinkName => get(parentRepeaterAtCurrentIndexData, symlinkName));

    const parentSymlinks = parentSymlinkNames
      .filter(symlinkName => symlinkName === parentKey)
      .map(symlinkName => ({
        name: symlinkName,
        path: parentRepeaterAtCurrentIndexData[symlinkName],
      }));

    const repeaterPropertiesSymlinks = repeaterData.map(item => {
      const currentItemIsSymlinked = isSymlinked(item);

      if (currentItemIsSymlinked) {
        const symlinkInfo = getSymlinkInfo(item, symlinks);
        const { from, path } = symlinkInfo;

        return from === rest.parentKey && parentPaths.includes(path);
      }

      return true;
    });

    let counter = 0;
    const repeaterPropertiesTitleIndex = repeaterPropertiesSymlinks.reduce(
      (acc, item, index) => {
        if (item) {
          acc[index] = counter;
          counter++;
        }
        return acc;
      },
      {}
    );

    let repeaterProperties = repeaterData.map(() => template);

    const currentRepeaterParentSymlink = find(
      parentSymlinks,
      _item => _item.name === parentKey
    );

    if (currentRepeaterParentSymlink) {
      symlinkPath = currentRepeaterParentSymlink.path;
    }

    const {
      isContributeReport,
      isContributorAssign,
      contributorAssignmentCanEdit,
    } = this.props;

    const isCtrSectionAssignedUser =
      isContributorAssign && contributorAssignmentCanEdit;

    // when not assigned user, if not in a repeater CTR section,
    // should be ok to enable
    const isRepeaterActionEnabled = isContributeReport
      ? isCtrSectionAssignedUser || !this.inARepeaterCTRSection()
      : !isReviewer;

    if (isRepeaterActionEnabled && showAddButton && canAddMore) {
      repeaterProperties = [
        ...repeaterProperties,
        addButtonTemplate({
          addButtonLabel,
          keys: [parentKey, ...syncTo],
          templates: [template, ...syncToTemplates],
          symlinkPath,
          actions,
        }),
      ];
    }

    const mappedRepeater = (property, parentIndex) => {
      const { type, options } = property;
      const propertyWithClassName = {
        ...property,
        tab,
        dataKey: property.key || property.dataKey || '',
        options: {
          ...options,
          className: `${
            options && options.className ? options.className : ''
          } keep-border`,
        },
      };
      const isRepeaterButton = has(options, 'className');
      const repeaterClassNames = classNames(
        'bdm-form-repeater-item-row',
        `bdm-form-repeater-item-${type}`
      );

      // If this item is not a button and is not part of the current repeater
      if (
        !repeaterPropertiesSymlinks[parentIndex] &&
        !property.isRepeaterAction
      ) {
        return null;
      }

      const addButtonItem = [true];
      // Add add button space so that the indexes match in order
      const currentRepeaterChildrenIndexes = addButtonItem
        .concat(repeaterPropertiesSymlinks)
        .map((isCurrentRepeaterChild, _index) => ({
          isCurrentRepeaterChild,
          index: _index,
        }))
        .filter(item => item.isCurrentRepeaterChild)
        .map(_item => _item.index);

      // Get the visual position of the item on this repeater
      const currentItemIndex = findIndex(
        currentRepeaterChildrenIndexes,
        _index => _index === parentIndex + 1
      );

      const hasConditionals = Object.keys(conditions).length;
      const unallowedList = ['interactionOfficerSubjectUseOfForceSequence'];

      const showRepeaterRemoveItemButton =
        isRepeaterActionEnabled &&
        !isRepeaterButton &&
        this.canShowRemove(parentIndex);
      const repeaterElement = repeaterBehavior => (
        <div
          className={`${repeaterClassNames} ${
            unallowedList.includes(parentKey) ? 'enable' : repeaterBehavior
          }`}
          key={parentIndex}
          ref={this.saveRef(parentIndex)}
        >
          {showRepeaterRemoveItemButton && (
            <div className="repeater-remove-item">
              <RepeaterRemoveItem
                formName={settings.formName}
                keys={[parentKey, ...syncTo]}
                removeKeys={removeKeys}
                removeRefKeys={removeRefKeys}
                show={showRemoveButton && currentItemIndex > minItems}
                item={parentIndex}
                itemData={repeaterData[parentIndex]}
                deleteModalOptions={deleteModalOptions}
                showConfirmation={confirmBeforeDelete}
              />
            </div>
          )}
          {mapProperties({
            ...omit(rest, ['conditions', 'options']),
            data,
            form,
            parentIndex,
            parentKey,
            settings,
            total,
            isReviewer,
            syncFrom,
            syncKeys,
            seedKeys,
            loopFromEnumRef,
            title: null,
            titleIndex: repeaterPropertiesTitleIndex[parentIndex],
          })(propertyWithClassName, parentIndex)}
        </div>
      );

      let fieldMatchConditions = false;
      const { rules = [], behavior = '' } = conditions;

      if (hasConditionals) {
        const verifyConditions = rules.map(({ key, toBe, expect }) => {
          const parsedKey = key.includes('data.') ? key.split('.')[1] : key;
          const dataInKey = get(data, parsedKey, '');
          const asserted = assertedCondition({
            toBe,
            expectations,
            expect,
            dataInKey,
          });

          return asserted;
        });

        /*
         since we are receiving condition.every as boolean (true) or string ('some') I'll retrive a false
         either it comes as false or some to support existing forms
        */
        const sanitizeConditionEvery =
          conditions.every === 'some' || conditions.every === false
            ? false
            : true;

        if (sanitizeConditionEvery) {
          fieldMatchConditions = !verifyConditions.includes(false);
        } else {
          fieldMatchConditions = verifyConditions.includes(true);
        }
      }
      if (hasConditionals && behavior === 'hide') {
        return fieldMatchConditions && repeaterElement(behavior);
      } else {
        return fieldMatchConditions
          ? repeaterElement('enable')
          : repeaterElement(behavior);
      }
    };

    const isGroupedRepeater = Object.keys(group).length;
    if (isGroupedRepeater) {
      const grouped = groupBy(repeaterData, group.by);
      const groups = Object.keys(grouped);
      const addNestedRepeaterItem = gIndex => (keys, templates, id) => {
        const from = get(data, [syncFrom, gIndex], {});
        const extra = Object.keys(syncKeys[parentKey] || {}).reduce(
          (acc, k) => ({
            ...acc,
            [syncKeys[parentKey][k]]: from[k],
            __parentId: from.id,
            __parentIndex: gIndex,
            __parentHumanIndex: gIndex + 1,
          }),
          {}
        );
        emitFormEvent(
          'addRepeaterItem',
          settings.formName,
          keys,
          templates,
          id,
          {},
          extra
        );
      };
      return (
        <RepeaterWrapper>
          {groups.map((k, gIndex) => {
            const baseIndex = groups
              .map((_, gi) => gi)
              .filter(gi => gi < gIndex)
              .reduce((acc, gi) => acc + grouped[groups[gi]].length, 0);
            return (
              <div key={gIndex}>
                {grouped[k].map((item, index) => {
                  const parentIndex = baseIndex + index;
                  const property = repeaterProperties[gIndex];
                  const { type, options } = property;
                  const propertyWithClassName = {
                    ...property,
                    dataKey: property.key || property.dataKey || '',
                    options: {
                      ...options,
                      className: `${
                        options && options.className ? options.className : ''
                      } keep-border`,
                    },
                  };
                  const isRepeaterButton = has(options, 'className');
                  const repeaterClassNames = classNames(
                    'bdm-form-repeater-item-row',
                    `bdm-form-repeater-item-${type}`
                  );
                  const showRepeaterRemoveItemButton =
                    isRepeaterActionEnabled &&
                    !isRepeaterButton &&
                    this.canShowRemove(parentIndex);

                  return (
                    <div className={repeaterClassNames} key={parentIndex}>
                      {showRepeaterRemoveItemButton && (
                        <RepeaterRemoveItem
                          keys={[parentKey]}
                          show={
                            !isReviewer && group.showRemoveButton && index > 0
                          }
                          item={parentIndex}
                          itemData={repeaterData[parentIndex]}
                          deleteModalOptions={deleteModalOptions}
                          showConfirmation={confirmBeforeDelete}
                        />
                      )}
                      {mapProperties({
                        ...omit(rest, ['conditions', 'options']),
                        data,
                        form,
                        parentIndex,
                        parentKey,
                        settings,
                        total,
                        isReviewer,
                        syncFrom,
                        syncKeys,
                        title: null,
                      })(propertyWithClassName, parentIndex)}
                    </div>
                  );
                })}
                {!isReviewer && (
                  <AddButton
                    keys={[parentKey]}
                    title={group.addButtonLabel}
                    settings={{
                      addRepeaterItem: addNestedRepeaterItem(gIndex),
                    }}
                  />
                )}
              </div>
            );
          })}
        </RepeaterWrapper>
      );
    }

    return (
      <RepeaterWrapper>
        {hasNavigation && (
          <RepeaterNavigation
            options={navigationOptions}
            onChangeCollapse={this.onChangeCollapse}
            onChangeNavigation={this.onChangeNavigation}
          />
        )}
        {repeaterProperties.map(mappedRepeater)}
      </RepeaterWrapper>
    );
  }
}

const mapState = (state, props) => {
  const dataSelector = getDataSelector(props);
  const formTemplate = getFormTemplate(state, props);

  return {
    data: dataSelector(state, props),
    dataEnums: getDataEnums(state, props, formTemplate),
  };
};

export { default as RepeaterAddItem } from './Repeater.AddItemButton';
export { default as RepeaterRemoveItem } from './Repeater.RemoveItemButton';
export default connect(mapState)(Repeater);
