import React from 'react';
import { camelCase } from 'lodash';
import Badge from './StatusBadge.styled';
import { STATUS_BADGE_COLORS } from '../utils/constants';
import { translate } from '../../../i18next';

const StatusBadge = ({ status, testId = '' }) => {
  return (
    <Badge
      bgColor={STATUS_BADGE_COLORS[camelCase(status)]}
      data-testid={testId}
    >
      {translate(`containers.reports.statusLabel.${status}`)}
    </Badge>
  );
};

export default StatusBadge;
