import openModal from '../utils/onShareButtonClick';

const ReassignButton = {
  icon: 'export',
  type: 'icon',
  title: 'Reassign',
  tooltipMessage: 'Reassign Report',
  translateKey: 'containers.reports.reportViewer.reassignReport',
  onButtonClick: () => openModal,
};

export default ReassignButton;
