import styled from 'styled-components';

import { Row } from 'antd';

export const StyledBoundaryRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;

  .style-options {
    width: 220px;
  }
`;
