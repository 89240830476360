import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Cascader from 'antd/lib/cascader';
import get from 'lodash/get';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import castArray from 'lodash/castArray';
import debounce from 'lodash/debounce';
import some from 'lodash/some';
import has from 'lodash/has';
import cx from 'classnames';

import getFormActions from '../../actions/get-form-actions';
import getFormTemplate from '../../actions/get-form-template';
import setFormStatus from '../../actions/set-form-status';
import setReviewerId from '../../actions/set-reviewer-id';
import setResolution from '../../actions/set-resolution';

import ModalTitle from '../common/modal/title';
import ModalBody from '../common/modal/body';
import withModal from '../common/modal/base';
import Panel, { PanelTitle } from '../common/panel/index';
import ReviewPanelWrappedStyle from '../form/styled/review-panel';
import FormItem from '../form-viewer/styled/form-item';
import Switch from '../form-viewer/styled/input-switch';
import Section from '../form-viewer/styled/section';
import If from '../utils/ConditionalRender';
import HalfSizeColumn from '../common/HalfSizeColumn';
import ReviewPanelSkeleton from './ReviewPanelSkeleton';

import {
  findReviewerUsingFuzzySearch,
  findReviewer,
} from 'APP_ROOT/utils/filters';
import { FEATURES, hasFeatures } from 'APP_ROOT/utils/features';
import flattenErrors from 'APP_ROOT/utils/flatten-errors';
import syncFormValidation from 'APP_ROOT/actions/sync-form-validations';

import {
  getAgencyTZ,
  getTemplateConfiguration,
  getCurrentUser,
} from '../../selectors/session';
import { getAttachments } from '../../selectors/attachments';

import { FORM_IDENTIFIERS } from '../../constants/form-types';
import getOfficerName from '../../utils/get-officer-name';
import { isFinalLinkedSubmission } from '../../utils/form';
import groupReviewers from '../../utils/group-reviewers';
import getTemplateFields from '../../utils/get-template-fields';
import {
  getParsedResolutions,
  getResolutionsOptions,
  pickValuesFromActions,
  getRequires,
} from '../../utils/reportReviewActionsHelpers';
import {
  getReviewerFromActions,
  filterReviewerById,
} from '../../utils/reportReviewersHelpers';
import { parseAttachmentsWithNotes } from '../../utils/parseAttachmentsWithNotes';

import ResolutionWrapper from './ResolutionWrapper.styled';
import { formItemLayout, initialState } from './ReviewPanel.constants';
import { shouldBlocknavigation } from '../../selectors/application';

import ReviewSchema from '../ReviewPanelSchema';

import { translate } from '../../i18next';

const Option = Select.Option;

export class ReviewPanelWrapper extends PureComponent {
  state = initialState;
  presenation = { hasTabs: false, fields: [] };
  presentationRules = {};
  formFields = [];

  componentDidMount() {
    const {
      dispatch,
      submissionForm: {
        selectedForm: {
          meta: { agencyId = '', id: formSubmissionId = '' } = {},
        } = {},
      } = {},
      form,
    } = this.props;

    this.createModal();
    form.getFieldDecorator('requires');

    dispatch(setFormStatus(false));
    dispatch(
      getFormActions({ agencyId, formSubmissionId }, err => {
        if (!err) {
          this.getReviewNotesTemplate(this.props);
        }
      })
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { hasDefaultReviewer, hasDefaultResolution } = this.state;

    if (hasDefaultReviewer || hasDefaultResolution) {
      return;
    }

    const {
      submissionForm: {
        actions = [],
        selectedForm: { meta: { linkedSubmissions = [] } = {} },
      } = {},
    } = nextProps;

    const pendingReport = this.fetchLinkedPendingReport(linkedSubmissions);

    if (has(pendingReport, 'metadata.workflowData.reviewers')) {
      this.processPendingLinkReport(pendingReport, actions);
    }

    this.getReviewNotesTemplate(nextProps);
  }

  fetchLinkedPendingReport(linkedSubmissions) {
    // by default only one pending report is needed
    const emptyList = {};
    const firstLinkedTemplate = get(
      linkedSubmissions.reduce(
        (acc, { actions = [] }) => [
          ...acc,
          ...actions.filter(({ status }) => status === 'pending'),
        ],
        []
      ),
      [0],
      emptyList
    );
    return firstLinkedTemplate;
  }

  processPendingLinkReport(pendingReport, actions) {
    const reviewerId = first(
      get(pendingReport, 'metadata.workflowData.reviewers')
    );
    const reviewer = getReviewerFromActions(actions, reviewerId);
    const objectKeys = ['id', 'label', 'required', 'reviewer'];
    const reportPath = 'metadata.submissionData.notes.action';

    const legacyActionsState = {
      selectedResolution: pickValuesFromActions(
        pendingReport,
        actions,
        reportPath,
        objectKeys
      ),
    };

    const transformToNewState = oldState => {
      const { selectedResolution: sr = {} } = oldState;
      const {
        id: srId = '',
        label: srLabel = '',
        reviewer: { list: srReviewerList = [] } = {},
        required: srRequired = {},
      } = sr;
      const newSelectedResolution = {
        id: srId,
        value: srId,
        label: srLabel,
        requires: Object.entries(srRequired)
          .filter(([k, v]) => v)
          .map(k => k),
        reviewers: srReviewerList,
      };
      return {
        ...oldState,
        selectedResolution: newSelectedResolution,
      };
    };

    const newState = transformToNewState(legacyActionsState);

    if (Object.keys(newState.selectedResolution).length) {
      newState['hasDefaultResolution'] = true;
    }

    if (reviewer) {
      newState['selectedReviewer'] = reviewer;
      newState['hasDefaultReviewer'] = true;
    }

    this.setState(newState, () => {
      if (newState.hasDefaultReviewer && newState.selectedReviewer) {
        this.setReviewer(castArray(newState.selectedReviewer.id));
      }

      if (newState.hasDefaultResolution && newState.selectedResolution) {
        this.setResolution(newState.selectedResolution);
      }
    });
  }

  getReviewNotesTemplate({ selectedForm }) {
    const meta = get(selectedForm, 'meta');
    const agencyId = get(meta, 'agencyId');

    if (!this.state.reviewNotesFetchStarted) {
      const { templateId, id: versionId } = first(
        get(meta, 'reviewNoteTemplateId', [])
      );
      this.setState({ reviewNotesFetchStarted: true });
      this.fetchReviewNoteTemplate(templateId, versionId, agencyId);
    }
  }

  fetchReviewNoteTemplate(templateId, templateVersionId, agencyId) {
    const { dispatch, timezone } = this.props;
    dispatch(
      getFormTemplate(
        { templateId, templateVersionId, agencyIdFromOptions: agencyId },
        async (err, template) => {
          if (!err) {
            this.getReviewNoteFields(template, timezone);
          }
        }
      )
    );
  }

  async getReviewNoteFields(template, timezone) {
    const { fields, presentation, presentationRules } = await getTemplateFields(
      template,
      timezone
    );
    this.formFields = fields;
    this.presentation = presentation;
    this.presentationRules = presentationRules;
    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    this.hideModal();
    this.deleteModal();
  }

  onSubmit = debounce(() => {
    const {
      form,
      onSubmit,
      attachments = {},
      selectedForm: { id = '', data = {}, meta: { formType = '' } = {} },
      blockNavigation: shouldBlockNavigation = false,
    } = this.props;

    form.validateFields(err => {
      if (!err) {
        const {
          selectedResolution = {},
          selectedReviewer = {},
          reviewers: formReviewers = [],
        } = this.state;

        if (shouldBlockNavigation) {
          this.showErrorConfirmation(
            'One or more files are still being uploaded. Please wait for them to finish and submit again.'
          );
          return false;
        }

        const reviewers = castArray(formReviewers);

        const actionReviewers = this.mapReviwersToCascader(reviewers).map(
          reviewer => reviewer.value
        );
        const notes = form.getFieldsValue(this.formFields);
        const newAttachments = form.getFieldValue('review-attachments');
        const attachmentField =
          formType === 'Use of Force' ? 'narrativeEvidenceFiles' : 'evidence';
        const attachmentOnStorage = get(
          attachments,
          [FORM_IDENTIFIERS[kebabCase(formType)], id, attachmentField],
          {}
        );

        const fields = {};

        if (Array.isArray(newAttachments) && newAttachments.length) {
          newAttachments.forEach((_, idx) =>
            this.formFields.push(`review-attachments[${idx}].notes`)
          );

          fields[attachmentField] = parseAttachmentsWithNotes(
            attachmentOnStorage,
            attachmentField,
            newAttachments,
            data
          );
        }

        // Paramenters in review-form:
        // (selectedResolution, selectedReviewer, notes, fields, reviewers, showLoader = () => { })
        onSubmit(
          selectedResolution,
          selectedReviewer,
          notes,
          fields,
          actionReviewers,
          this.showLoader
        );
      } else {
        const { dispatch } = this.props;
        const flattenedErrors = flattenErrors(err);
        dispatch(syncFormValidation(flattenedErrors));
      }
    });
  }, 500);

  onSelectResolution = resolution => {
    const {
      form,
      submissionForm,
      reviewTemplate,
      reviewTemplate: { formSchema },
    } = this.props;
    const { actions = [] } = submissionForm;
    const { hasDefaultReviewer = false } = this.state;
    const resolutions = actions.length
      ? getParsedResolutions(actions, resolution)
      : [];
    const requires = getRequires(resolutions[0].requires, hasDefaultReviewer);
    // Here we validate if propagateAction and propagateFields are empty we reset the fields value of the form
    if (
      isEmpty(reviewTemplate.propagateAction) &&
      isEmpty(reviewTemplate.propagateFields) &&
      isEmpty(formSchema.propagateAction) &&
      isEmpty(formSchema.propagateFields)
    ) {
      this.formFields
        .filter(field => !['review-attachments', 'action'].includes(field))
        .map(field => form.resetFields([field]));
    }

    form.setFieldsValue({
      action: resolution,
      requires: requires,
      reviewer: undefined,
    });

    this.setState({
      selectedResolution: resolutions[0],
      reviewers: resolutions[0] ? resolutions[0].reviewers : [],
    });

    if (!hasDefaultReviewer) {
      this.setState({
        selectedReviewer: {},
      });
    }

    this.setResolution(resolutions[0]);
  };

  onSelectReviewer = reviewerId => {
    const { form } = this.props;
    const {
      selectedResolution: { reviewers = [] },
    } = this.state;
    const reviewer = reviewers.length
      ? filterReviewerById(reviewers, reviewerId)
      : '';
    form.setFieldsValue({
      reviewer: first(reviewerId) + [],
    });
    this.setState({
      selectedReviewer: { ...reviewer[0], id: reviewer[0].id + [] },
    });

    this.setReviewer(first(reviewerId) + []);
  };

  setResolution = resolution => {
    const { dispatch } = this.props;
    dispatch(setResolution(resolution));
  };

  setReviewer = reviewerId => {
    const { dispatch } = this.props;
    dispatch(setReviewerId(reviewerId));
  };

  clearReviewer = () => {
    const { dispatch } = this.props;
    this.setState({
      selectedReviewer: {},
    });
    dispatch(setReviewerId(''));
  };

  showLoader = value => {
    this.setState({ isLoading: value });
  };

  showErrorConfirmation = (errorMessage = '') => {
    const ErrorTitle = (
      <ModalTitle error>
        <Icon type="exclamation-circle" /> <span>Heads up!</span>
      </ModalTitle>
    );

    const ErrorText = (
      <ModalBody>
        {errorMessage && errorMessage}
        {!errorMessage && (
          <span>
            Some of the required fields are empty or were not answered
            correctly. Please double-check and submit again.
          </span>
        )}
      </ModalBody>
    );

    this.updateModal({
      visible: true,
      title: ErrorTitle,
      children: ErrorText,
    });

    this.showModal();
  };

  onSwitchChange = value => {
    const {
      form: { resetFields },
    } = this.props;

    this.setState(
      {
        hasLockableEnable: value,
        reviewers: [],
        selectedResolution: {},
        selectedReviewer: {},
      },
      resetFields(['action'])
    );
  };

  onCascadeReviewerChange = (value, options = []) => {
    const getChoosableOptions = options.filter(option => option.isChoosable);
    const reviewer = first(getChoosableOptions);
    const isClearing = value.length === 0;

    if (!isClearing) {
      return this.onSelectReviewer(castArray(reviewer.value));
    }

    this.clearReviewer();
  };

  mapReviwersToCascader = (reviewers = []) => groupReviewers(reviewers);

  render() {
    const {
      form,
      submissionForm,
      reviewForm,
      saveScrollToComponent,
    } = this.props;

    const {
      reviewers: formReviewers = [],
      selectedResolution = {},
      selectedReviewer = {},
      hasDefaultReviewer = false,
      isLoading = false,
      hasLockableEnable = false,
      hasDefaultResolution = false,
    } = this.state;

    const { label: selectedResolutionLabel = '' } = selectedResolution;
    const { getFieldDecorator } = form;
    const {
      selectedForm: { id = '' },
      actions: formActions = [],
      resolutionLabel = '',
    } = submissionForm;

    const reviewers = formReviewers.length ? formReviewers : [];

    const { loading } = reviewForm;
    const { meta } = submissionForm.selectedForm;
    const { linkedSubmissions } = meta;
    const isLinked = !isEmpty(linkedSubmissions);
    const isFinalLinked = isFinalLinkedSubmission(id, linkedSubmissions);
    const requires = getRequires(
      selectedResolution.requires,
      hasDefaultReviewer
    );
    const submissionIsLoading = submissionForm.loading;
    const isBusy = isLoading || submissionIsLoading;
    const reportIsBusy = loading || submissionIsLoading;
    const isResolutionEmpty = isEmpty(selectedResolution); //here is verifying if the resolution select is empty

    const resolutions = formActions.length
      ? getResolutionsOptions(formActions, {
          isLinked,
          isFinalLinked,
          hasDefaultReviewer,
          hasLockableEnable,
        })
      : [];

    const { user: { featureFlags = [] } = {} } = this.props;
    const hasReportUserSelectionFuzzySearch = hasFeatures(
      featureFlags,
      FEATURES.reportUserSelectionFuzzySearch
    );

    const switchRequired = some(formActions, [
      'canCloseFromCurrentState',
      true,
    ]);
    return (
      <ReviewPanelWrappedStyle className={cx({ loading: isBusy })}>
        <Panel>
          <PanelTitle>
            <h2>
              {translate('containers.reports.reportViewer.review')}{' '}
              {(isBusy || reportIsBusy) && <Icon type="loading" />}
            </h2>
          </PanelTitle>

          <If condition={!isLoading && reviewForm.error}>
            <h1>Error</h1>
          </If>

          <If condition={!isLoading}>
            <div>
              <ResolutionWrapper>
                <Section className="bdm-form-section--no-padding-top bdm-form-section--no-padding-bottom">
                  <Row gutter={0} className="padding-row-bottom">
                    <HalfSizeColumn>
                      <If
                        condition={
                          switchRequired && !(isLinked && isFinalLinked)
                        }
                      >
                        <FormItem
                          label="I am the final reviewer for this report"
                          colon={false}
                          {...formItemLayout}
                        >
                          <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            size="default"
                            disabled={reportIsBusy}
                            onChange={this.onSwitchChange}
                          />
                        </FormItem>
                      </If>
                    </HalfSizeColumn>
                  </Row>
                  <Row gutter={0}>
                    <HalfSizeColumn>
                      <FormItem
                        label={
                          resolutionLabel ||
                          translate(
                            'containers.reports.reportViewer.resolution'
                          )
                        }
                        colon={false}
                        {...formItemLayout}
                      >
                        {getFieldDecorator('action', {
                          rules: [
                            {
                              required: true,
                              message: 'Select a Resolution',
                            },
                          ],
                          initialValue: selectedResolution.id || undefined,
                        })(
                          <Select
                            className="resolution"
                            placeholder={translate(
                              'containers.reports.reportViewer.selectResolution'
                            )}
                            onChange={this.onSelectResolution}
                            disabled={hasDefaultResolution || reportIsBusy}
                            ref={saveScrollToComponent}
                          >
                            {resolutions.map(resolution => (
                              <Option key={resolution.id} value={resolution.id}>
                                {resolution.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </FormItem>
                    </HalfSizeColumn>

                    <If condition={hasDefaultReviewer || reviewers.length}>
                      <HalfSizeColumn>
                        <FormItem
                          label={
                            hasDefaultReviewer
                              ? get(selectedResolution, 'reviewerLabel') ||
                                'Reviewer'
                              : selectedResolutionLabel ===
                                'Investigate Incident'
                              ? 'Investigator'
                              : get(selectedResolution, 'reviewerLabel') ||
                                'Select Reviewer'
                          }
                          colon={false}
                          {...formItemLayout}
                        >
                          <If condition={hasDefaultReviewer}>
                            <div className="reviewer-field text-field">
                              {getOfficerName(selectedReviewer)}
                            </div>
                          </If>
                          <If condition={!hasDefaultReviewer}>
                            {getFieldDecorator('reviewer', {
                              rules: [
                                {
                                  required: requires.includes('reviewer'),
                                  message: 'Select a Reviewer',
                                },
                              ],
                              initialValue: selectedReviewer.id || undefined,
                            })(
                              <Cascader
                                placeholder="Select person"
                                options={this.mapReviwersToCascader(reviewers)}
                                onChange={this.onCascadeReviewerChange}
                                displayRender={label => label.join(' > ')}
                                disabled={reportIsBusy}
                                showSearch={{
                                  filter: hasReportUserSelectionFuzzySearch
                                    ? findReviewerUsingFuzzySearch
                                    : findReviewer,
                                }}
                              />
                            )}
                          </If>
                        </FormItem>
                      </HalfSizeColumn>
                    </If>
                  </Row>
                </Section>
              </ResolutionWrapper>

              <ReviewSchema
                onSubmit={this.onSubmit}
                {...this.props}
                isReviewPanel
                disabled={hasDefaultResolution || reportIsBusy}
              />

              <Row>
                <Col span={24} className="text-right">
                  <Button
                    type="primary"
                    onClick={this.onSubmit}
                    disabled={isBusy || reportIsBusy || isResolutionEmpty}
                    loading={isBusy || reportIsBusy}
                  >
                    {translate('containers.reports.reportViewer.submit_2')}
                  </Button>
                </Col>
              </Row>
            </div>
          </If>

          <If condition={isLoading}>
            <ReviewPanelSkeleton />
          </If>
        </Panel>
      </ReviewPanelWrappedStyle>
    );
  }
}

const mapState = (state, props) => {
  const { submissionForm = {} } = props;
  const { selectedForm = {} } = submissionForm;
  const templateType = get(selectedForm, 'meta.formType', null);
  const templateConfiguration = getTemplateConfiguration(
    kebabCase(templateType)
  );

  return {
    user: getCurrentUser(state),
    selectedForm,
    attachments: getAttachments(state),
    blockNavigation: shouldBlocknavigation(state),
    timezone: getAgencyTZ(state),
    templateConfiguration: templateConfiguration(state),
  };
};

const ReviewPanel = withModal(ReviewPanelWrapper);

ReviewPanel.displayName = 'ReviewPanel';

export default connect(mapState)(ReviewPanel);
