import React, { Component } from 'react';
import { Row, Col, Tag } from 'antd';
import { get, omit, pick, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import cx from 'classnames';
import { DragSource } from 'react-dnd';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import withModal from 'APP_COMPONENTS/common/modal/base';
import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes';
import {
  SELECT,
  MULTISELECT,
  NUMBER,
  TEXTAREA,
  MASKED,
  AUTOCOMPLETE,
  HYPERLINK,
  STATIC_CONTENT,
  DATE,
  DATE_TIME,
  MATH,
} from 'APP_ROOT/constants/fieldTypes';

import FieldEditorWrapper from './FieldEditor.styled';
import IconButton from '../../../../components/common/buttons/icon-button';
import componentsManager from '../../services/componentsManager';
import getCustomFields from '../../utils/getCustomFields';
import buildOptionsModalContent from '../../utils/buildOptionsModalContent';
import getFieldLabel from '../../utils/getFieldLabel';
import getTitle from '../../utils/getTitle';
import setTitle from '../../utils/setTitle';
import getReviewerCssClass from '../../utils/getReviewerCssClass';
import getFieldMeta from '../../utils/getFieldMeta';
import {
  TAB_VALUES,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
  TAB_DATE_BOUNDARY,
} from '../OptionsModalBody/OptionsModalBody';

import {
  getCommonOptions,
  getNumberOptions,
  getTextareaOptions,
  getSelectOptions,
  getAutocompleteOptions,
  getHyperlinkOptions,
  getDateOptions,
  setRequiredOptions,
  setReadOnlyOptions,
  setMaskedOptions,
  setOptionsByType,
} from './utils';
import { DURATION } from '../../../../constants/fieldTypes';
import {
  SEARCHABLE_BY,
  CASE_NUMBER,
  EVENT_DATE,
} from '../../constants/constants';

const MODAL_OPTIONS = { width: '750px' };
class FieldEditor extends withModal(Component) {
  constructor(props) {
    super(props);

    this.state = {
      field: props.field,
      values: props.field,
      optionsModalUpdateFlag: true, // to make modal update state content
    };
  }

  componentDidMount() {
    this.createModal(MODAL_OPTIONS);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      propsHasChanged(this.props, nextProps) ||
      propsHasChanged(this.state, nextState)
    );
  }

  static getDerivedStateFromProps(props, state) {
    return {
      field: props.field,
      values: props.field,
    };
  }

  changeModalFlag = () =>
    this.setState({
      optionsModalUpdateFlag: !this.state.optionsModalUpdateFlag,
    });

  onSave = (field, values, customFields, setState) => params => {
    const mutations = [];
    const newValues = this.setOptions(params);
    const customFieldNames = customFields.map(({ name }) => name);

    customFields.forEach(customField => {
      mutations.push(customField.onSave);
    });

    const modifiedValues = mutations.reduce(
      (allChanges, mutation) => mutation(allChanges),
      Object.assign(
        {},
        omit(values, ['title', 'options', 'isRequired']),
        pick(params, ['conditions'].concat(customFieldNames)),
        omit(newValues, ['options', 'type', 'message'])
      )
    );

    modifiedValues.options = {
      ...values.options,
      ...modifiedValues.options,
      ...newValues.options,
    };
    const validations = get(modifiedValues, 'validations');

    const key = modifiedValues.sharedKey || values.key;
    const enumRef = modifiedValues.sharedRef || values.enumRef;

    // for some reason required attribute is not triggering
    // reactivity (render), that is why we are saving isRequired
    setState({
      values: {
        ...omit(modifiedValues, [
          'conditions',
          'validations',
          'sharedKey',
          'sharedRef',
        ]),
        key,
        enumRef,
      },
      isRequired: modifiedValues.required,
    });
    componentsManager.editComponent(field.id, {
      ...omit(modifiedValues, ['type']),
      select: params.select,
      parentEnum: field.parentEnum,
      validations: {
        ...validations,
        message: newValues.message,
      },
    });

    this.hideModal();
    this.changeModalFlag();
  };

  onCancel = () => {
    this.hideModal();
    this.changeModalFlag();
  };

  getDisableTab = field_type => {
    const disableTab = [HYPERLINK, STATIC_CONTENT, DURATION, MATH].includes(
      field_type
    )
      ? [TAB_SHARE_KEY]
      : [];
    if ([STATIC_CONTENT, DURATION].includes(field_type)) {
      disableTab.push(TAB_INFORMATION);
    }
    if (![SELECT, MULTISELECT].includes(field_type)) {
      disableTab.push(TAB_VALUES);
    }
    if (![DATE, DATE_TIME].includes(field_type)) {
      disableTab.push(TAB_DATE_BOUNDARY);
    }
    return disableTab;
  };

  getEnum = field => {
    let select;
    if (
      field.type === FIELD &&
      [SELECT, MULTISELECT].includes(field.field_type)
    ) {
      select = componentsManager.getEnum(field.enumRef, field.parentEnum);
    }
    return select;
  };

  updateState = (state, callback) => this.setState(state, callback);

  showOptionsModal = () => {
    const { values, optionsModalUpdateFlag } = this.state;
    const { field } = this.props;
    const fieldMeta = getFieldMeta({
      fields: componentsManager.fields,
      value: field.id,
    });
    const { conditions } = fieldMeta;

    const customFields = getCustomFields(field.type, field.field_type, values);
    const customFieldsInitialValue = customFields.reduce(
      (allInitialValues, { initialValue, name }) => ({
        ...allInitialValues,
        [name]: initialValue,
      }),
      {}
    );

    const fieldsOptions = {
      id: values.id,
      fieldKey: values.key,
      fieldRef: values.enumRef,
      type: values.field_type,
      ...this.getOptions(values),
      select: this.getEnum(field),
      ...customFieldsInitialValue,
      customFields,
      conditions,
      disableTab: this.getDisableTab(field.field_type),
      optionsModalUpdateFlag,
    };

    const modalData = buildOptionsModalContent(
      {
        ...fieldsOptions,
        onCancel: this.onCancel,
        onDelete: () => {
          this.remove();
          this.deleteModal();
        },
        onSave: this.onSave(field, values, customFields, this.updateState),
      },
      this.changeModalFlag
    );

    this.updateModal(modalData);

    this.showModal();
  };

  // to get options definition to capture data from user
  getOptions = values => {
    let fieldOptions;
    switch (values.field_type) {
      case NUMBER: {
        fieldOptions = getNumberOptions(values, this.props);
        break;
      }
      case TEXTAREA: {
        fieldOptions = getTextareaOptions(values);
        break;
      }
      case SELECT: {
        fieldOptions = getSelectOptions(values);
        break;
      }
      case AUTOCOMPLETE: {
        fieldOptions = getAutocompleteOptions(values);
        break;
      }
      case HYPERLINK: {
        fieldOptions = getHyperlinkOptions(values);
        break;
      }
      case DATE:
      case DATE_TIME:
        fieldOptions = getDateOptions(values);
        break;
      default:
        break;
    }

    return {
      ...getCommonOptions(values),
      ...fieldOptions,
    };
  };

  // to set options object based on data caputred from user
  setOptions = values => {
    const { field } = this.props;
    const {
      title = {},
      asReviewer = false,
      encrypt = {},
      extra = {},
      tooltip = {},
      message = {},
      reportingKey = {},
      sharedRef,
      sharedKey,
      information,
      defaultValue,
      dateBoundary,
    } = values;
    const isRequired = setRequiredOptions(values);
    const isReadOnly = setReadOnlyOptions(values, isRequired);
    let isEncrypt = encrypt.value;
    let messageText = message.value;

    if (values.type === MASKED) {
      [isEncrypt, messageText] = setMaskedOptions(
        values,
        isRequired,
        isEncrypt
      );
    }

    // For now, no matter if showTitle is False or True,
    // The title will be saved
    const newTitle = setTitle(field.title, title.value);

    const fieldValues = {
      extra: extra.value,
      title: newTitle,
      message: messageText,
      required: isRequired,
      encrypt: isEncrypt ? true : undefined,
      readOnly: isReadOnly,
      asReviewer: isReadOnly && asReviewer ? true : undefined,
      reportingKey: reportingKey.value,
      options: {
        ...setOptionsByType(values, this.props),
        information,
        tooltipText: tooltip.value ? tooltip.value : undefined,
        defaultValue,
        dateBoundary,
      },
      sharedRef,
      sharedKey,
    };
    return fieldValues;
  };

  getValue = (name, defaultValue) =>
    get(this, `state.values.${name}`, defaultValue) || defaultValue;

  getOptionsValue = (name, defaultValue) =>
    get(this, `state.values.options.${name}`, defaultValue);

  remove = () => {
    const { field } = this.props;

    componentsManager.removeComponent(field.id);
  };

  renderFieldHeader = conditions => {
    const { field, values } = this.state;
    const { fromSource } = values;
    const [
      { required = false, mustExist },
    ] = componentsManager.getValidationRules(field.key);
    const isRequired = required || !!mustExist;
    const searchable = get(values, `options.${SEARCHABLE_BY}`, false);
    const hasCaseNumber = searchable === CASE_NUMBER;
    const hasEventDate = searchable === EVENT_DATE;

    return (
      <div className="field-header">
        <Row>
          <Col className="field-editor__actions">
            <span className="field-editor__btns">
              <IconButton
                icon="setting"
                onClick={this.showOptionsModal}
                className="is-focusable"
              />
            </span>
            <span className="field-editor__type">
              {getFieldLabel(field.field_type)}
            </span>
          </Col>
          <Col className="field-status">
            {!isEmpty(fromSource) && <Tag color="blue">Profile Data</Tag>}
            {isRequired && <Tag color="red">Required</Tag>}
            {!isEmpty(conditions) && <Tag color="gold">Conditional</Tag>}
            {hasCaseNumber && <Tag color="purple">Case Number</Tag>}
            {hasEventDate && <Tag color="purple">Event Date</Tag>}
          </Col>
        </Row>
        <hr />
      </div>
    );
  };

  renderFieldDetail = _fieldMeta => {
    const fieldMeta = _fieldMeta || {};
    const { field, values } = this.state;
    const { reportingKey } = fieldMeta;
    const { extra = '' } = values;

    const showTitle = this.getOptionsValue('showTitle', true);
    const title = getTitle(
      showTitle ? this.getValue('title') : extra,
      reportingKey || field.field_type
    );

    return (
      <Row className="field-detail">
        <Col className="field-editor__input">
          <div className="title" title={title}>
            {title}
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      compact,
      connectDragSource,
      connectDragPreview,
      isDragging,
    } = this.props;
    const { values } = this.state;
    const fieldMeta = getFieldMeta({
      fields: componentsManager.fields,
      value: values.id,
    });
    const { conditions } = fieldMeta || {};

    const reviewerCss = getReviewerCssClass(conditions);

    return connectDragPreview(
      <div>
        <FieldEditorWrapper
          className={cx(
            { 'is-compact': compact, 'is-dragging': isDragging },
            reviewerCss
          )}
        >
          {connectDragSource(this.renderFieldHeader(conditions))}
          {this.renderFieldDetail(fieldMeta)}
        </FieldEditorWrapper>
      </div>
    );
  }
}

export default connect()(
  DragSource(
    params => params.type,
    {
      beginDrag: param => param.field,
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    })
  )(FieldEditor)
);
