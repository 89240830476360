import React from 'react';
import styled from 'styled-components';
import { Button, Menu } from 'antd';
import classNames from 'classnames';

import MenuButton from './MenuButton';
import MenuItem from './menu-item';
import { hasPermissions, PERMISSIONS } from '../../../utils/admin';
import route from '../../../utils/get-route';
import getUsersResetFilters from '../../../actions/get-users-reset-filters';
import { translate } from '../../../i18next';

const NewReportPanel = styled.li`
  margin-top: 10px;
  padding: 20px 0;
  border-top: solid 1px #2f3a4a;
  text-align: center;
`;

const MenuOptionsButtons = ({
  onOpenNewReport: openNewReportFunction,
  permissions = [],
  optionsHistory,
  location,
  match,
  isCollapsed,
  isNewReportLoading: templatesLoading,
  menuOptions,
  dispatch,
}) => {
  //Set class when the menu is collaps
  const sidebarCollapseButtonClassNames = classNames({
    'is-collapsed': isCollapsed,
  });

  //Function that contain the menu options and filter the options depending of user permissions
  const getMainMenu = cb => {
    //Filter options to show depending of the user permissions
    const filtered =
      menuOptions &&
      menuOptions
        .filter(item => {
          return item.visibleFor.find(element =>
            [...permissions, 'everybody'].includes(element)
          );
        })
        .filter(item => {
          const { hiddenFor = [] } = item;
          if (!hiddenFor.length) return true;
          return hiddenFor.find(element => !permissions.includes(element));
        });

    return cb(filtered);
  };

  //Function that redirect to the option selected
  const openRoute = next => {
    const staticOptions = ['logout', 'feed'];

    if (!next) {
      return false;
    }

    if (staticOptions.indexOf(next.key) !== -1) {
      next.item.props.onClick && next.item.props.onClick();
    } else {
      const data = getMainMenu && getMainMenu(menu => menu && menu[next.key]);

      if (data && !data.disabled) {
        if (data.onClick) {
          data.onClick();
        } else {
          const to = data.pathname ? route(data.pathname) : data.href;

          if (data.useBrowserLocation) {
            global.location.href = to;
          } else {
            optionsHistory && optionsHistory.push(to);
          }
        }
      }
    }
  };

  //Funtion that get the MenuKey of the options and build the route.
  const getRouteMenuKeys = (path = '', params = {}, routes = []) => {
    const matchingKeys = [];
    routes.forEach((item, index) => {
      if (Array.isArray(item.relatedRoutes)) {
        item.relatedRoutes.forEach(element => {
          const href = element ? route(element, params) : item.href;
          matchingKeys.push({
            href,
            index,
          });
        });
      }
    });

    return matchingKeys
      .filter(route => route && route.href === path)
      .map(route => {
        return route.index + [];
      });
  };

  //Get the href, pathname and related Routes of the options and build an array.
  const menuRoutes =
    getMainMenu &&
    getMainMenu(
      menu =>
        menu &&
        menu.map(({ href, pathname, relatedRoutes }) => ({
          href,
          pathname,
          relatedRoutes,
        }))
    );

  //Get the index of the Menu options selected.
  const currentMenuKeys = getRouteMenuKeys(
    location && location.pathname,
    match && match.params,
    menuRoutes
  );

  //Get true or false if the user has the permission to see the 'New report' button
  const showNewReport = hasPermissions(permissions, [
    PERMISSIONS.submitUOFReport,
    PERMISSIONS.submitVPReport,
    PERMISSIONS.submitDORReport,
    PERMISSIONS.submitIACReport,
    PERMISSIONS.submitIAIReport,
    PERMISSIONS.submitReport,
  ]);

  return (
    <Menu
      mode="inline"
      selectedKeys={currentMenuKeys}
      selectable={false}
      onClick={openRoute}
    >
      {showNewReport && (
        <NewReportPanel role="menuitem">
          <Button
            type="primary"
            shape="round"
            icon="plus"
            onClick={openNewReportFunction}
          >
            {!isCollapsed && translate('sidebar.newReport')}
          </Button>
        </NewReportPanel>
      )}
      {getMainMenu &&
        getMainMenu(
          menu =>
            menu &&
            menu.map((item, index) => {
              if (!!item.isDivider) {
                return (
                  <div className="ant-menu-item-divider" key={index}></div>
                );
              }

              if (item.children) {
                return <MenuItem key={index}>{item.children}</MenuItem>;
              }

              return (
                <MenuItem key={index}>
                  <span>
                    {item.icon && !item.anticon && (
                      <MenuButton
                        iconClassName={
                          item.icon.includes('anticon')
                            ? item.icon
                            : `${item.icon} bdm-icon`
                        }
                        isLoading={item.isLoading || false}
                        iconType={item.iconType || ''}
                        text={
                          item.localeKey
                            ? translate(item.localeKey)
                            : item.label
                        }
                        onClick={() => dispatch(getUsersResetFilters())}
                      />
                    )}
                    {item.anticon && !item.icon && (
                      <MenuButton
                        iconType={item.anticon}
                        text={
                          item.localeKey
                            ? translate(item.localeKey)
                            : item.label
                        }
                      />
                    )}
                  </span>
                </MenuItem>
              );
            })
        )}
    </Menu>
  );
};

export default MenuOptionsButtons;
