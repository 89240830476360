import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Badge,
  Button,
  Col,
  notification,
  Input,
  Row,
  Modal,
  Tooltip,
  Form,
  DatePicker,
  Select,
} from 'antd';
import { chain, debounce, union, get, isEmpty, castArray } from 'lodash';
import moment from 'moment-timezone';

import { parseHtmlInput } from 'APP_ROOT/utils/request';
import { getAgencyId, getAgencyTZ } from '../../../selectors/session';
import { getTemplatesLoading, getTemplates } from '../../../selectors/form';

import { FORM_TEMPLATE_IDENTIFIERS } from 'APP_ROOT/constants/form-types';
import AdvanceFilter from '../../../components/common/form-filter/advance-filter';
import { formTypesFromTemplates } from '../../../components/common/form-filter';
import FilterStatus from '../../../components/common/form-filter/filters/status';
import FilterForceApplied from '../../../components/common/form-filter/filters/force-applied';
import FilterAllegationsType from '../../../components/common/form-filter/filters/allegation-filter';
import AdvanceFilterWrapper from '../../../components/common/form-filter/advance-filter.styled';
import {
  StyledRow,
  StyledRowAdvance,
  StyledFilterCol,
} from '../../../components/common/form-filter/form-filter.styled';
import { BLUE } from '../../../config/theme';

import StyledModal from './AddReportModal.styled';
import { StyledTable } from 'APP_COMPONENTS/custom-sections/tables/CustomSectionTable.styled';

import caseFileEndpoints from '../../../api/caseFileEndpoints/caseFileEndpoints';
import apiEndpoints from '../../../modules/FormBuilder/services/apiEndpoints';
import fetchReadableStream from '../utils/fetchReadableStream';

import getRoute from 'APP_ROOT/utils/get-route';
import { translate } from '../../../i18next';

import retrieveAgencyTemplates from '../../../actions/utils/get-form/retrieveAgencyTemplates';
import getOfficersAndCivilians from '../../../actions/get-officers-and-civilians';
import normalizeFilter from '../../../components/form-viewer/object-types/FormField/normalizeFilter';

import {
  FILTER_SEARCH,
  FILTER_TYPE,
  FILTER_RANGE,
  FILTER_EVENT_DATE,
  FILTER_VIEW,
  FILTER_STATUS,
  FILTER_FORCE,
  FILTER_ALLEGATION,
  FILTER_LAYOUT_OPTIONS,
  FILTER_MENTIONED_USERS,
  FILTER_REPORT_SUBMITTER,
} from '../../../components/common/form-filter/utils/constants';

const PAGE_SIZE = 10;
const SEARCH_WARNING_TITLE = 'Warning';
const SEARCH_WARNING =
  'You will lose your current selections by initiating a new search';

const { Search } = Input;
const { confirm } = Modal;
const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

const FORM_FITLERS = ['status', 'formType'];

const columns = [
  {
    title: translate('containers.caseFiles.reportNumber'),
    dataIndex: 'reportNumber',
    key: 'reportNumber',
    render: (text, record) => {
      const location = getRoute('report', { id: record.reportId });
      return (
        <Button type="link" href={location} target="_blank" title={text}>
          {text}
        </Button>
      );
    },
  },
  {
    title: translate('containers.caseFiles.reportType'),
    dataIndex: 'reportType',
    key: 'reportType',
    render: (text, record) => {
      return <span>{parseHtmlInput(text)}</span>;
    },
  },
];

const USER_LIST = 'userList';

let formTypes = {};
let allStatuses = [];

const defaultFilters = [];

const templateFilters = {
  [FORM_TEMPLATE_IDENTIFIERS.UOF]: [
    ...defaultFilters,
    {
      label: 'Officer Force Applied',
      component: FilterForceApplied,
      filterType: FILTER_FORCE,
    },
  ],
  [FORM_TEMPLATE_IDENTIFIERS.IAI]: [
    ...defaultFilters,
    {
      label: 'Allegation Type',
      component: FilterAllegationsType,
      filterType: FILTER_ALLEGATION,
    },
  ],
  [FORM_TEMPLATE_IDENTIFIERS.IAC]: [
    {
      label: 'Allegation Type',
      component: FilterAllegationsType,
      filterType: FILTER_ALLEGATION,
    },
  ],
};

function getTemplateFilters(templateKey) {
  const keys = Object.keys(templateFilters);
  for (let key in keys) {
    if (templateKey.includes(keys[key])) {
      return templateFilters[keys[key]];
    } else {
      return defaultFilters;
    }
  }
}

function createFormTypesFilters(templates) {
  return Object.keys(templates).map(templateKey => {
    const label = get(templates, [templateKey, 'label'], '');
    const type = get(templates, [templateKey, 'type'], '');
    formTypes = {
      ...formTypes,
      [type]: {
        templateKey,
        title: label,
        filters: getTemplateFilters(templateKey),
      },
    };
  });
}

function getAllStatuses(templates) {
  allStatuses = chain(templates)
    .flatMap(template => get(template, 'statuses', []))
    .uniqBy('id')
    .value();
}

const getPopupContainer = trigger => trigger.parentNode;

const PRE_FILTERS_DEFAULT = {
  [FILTER_TYPE]: '',
  [FILTER_RANGE]: '',
  [FILTER_EVENT_DATE]: '',
  [FILTER_STATUS]: [],
  [FILTER_FORCE]: [],
  [FILTER_ALLEGATION]: [],
  [FILTER_MENTIONED_USERS]: [],
  [FILTER_REPORT_SUBMITTER]: [],
};

class AddReportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      search: false,
      reports: [],
      pagination: {},
      selectedRowKeys: [],
      alreadySelectedRowKeys: [],
      keywords: '',
      selectedRowObjects: [],
      filters: {
        [FILTER_VIEW]: '',
        [FILTER_SEARCH]: '',
        ...PRE_FILTERS_DEFAULT,
      },
      preFilters: {
        ...PRE_FILTERS_DEFAULT,
      },
      selectedTemplate: undefined,
      filtersVisible: false,
      users: [],
    };
    this.onSearchUsers = debounce(this.onSearchUsers, 800);
  }

  async componentDidMount() {
    const { dispatch, formTemplates, agencyId } = this.props;

    this.setState({ search: true, applyDefault: true });
    window.addEventListener('popstate', this.popState);
    this.onSearchUsers();

    await retrieveAgencyTemplates({
      dispatch,
      fallback: formTemplates,
      shouldFetch: () => true,
      agencyId,
    });
    this.setState({ loadingTemplates: false });

    await this.waitAtLeastPromise();
    this.setState({ delayCompleted: true });
  }

  waitAtLeastPromise = (ms = 1000) =>
    new Promise(resolve => {
      global.setTimeout(resolve, ms);
    });

  componentWillUnmount() {
    window.removeEventListener('popstate', this.popState);
  }

  static getDerivedStateFromProps(props, state) {
    const { userList } = props;
    const { users } = state;
    if (isEmpty(users)) {
      return { users: userList };
    }
    return { users: AddReportModal.getUsers(users, userList) };
  }

  static getUsers = (users, userList) => {
    const newList = userList.reduce((list, user) => {
      if (users.find(u => u.data.id === user.data.id) === undefined) {
        list.push(user);
      }
      return list;
    }, []);
    return users.concat(newList);
  };

  componentDidUpdate(prevProps) {
    const {
      agencyId,
      hierarchyKey,
      organizationalUnitId,
      casefileId,
      visible,
      formTemplates,
    } = this.props;
    const { search, keywords } = this.state;
    if (formTemplates !== prevProps.formTemplates) {
      createFormTypesFilters(formTemplates);
      getAllStatuses(formTemplates);
    }
    if (visible && search) {
      const { form, data } = this.getAdvancedFilters();
      this.setState({ search: false, loading: true }, () => {
        caseFileEndpoints
          .searchReports(
            agencyId,
            hierarchyKey,
            organizationalUnitId,
            casefileId,
            PAGE_SIZE,
            0,
            keywords,
            form,
            data
          )
          .then(this.fetch);
      });
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const {
      agencyId,
      hierarchyKey,
      organizationalUnitId,
      casefileId,
    } = this.props;
    const { pagination: pager, keywords } = this.state;
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    const skip = (pager.current - 1) * PAGE_SIZE;
    const { form, data } = this.getAdvancedFilters();
    caseFileEndpoints
      .searchReports(
        agencyId,
        hierarchyKey,
        organizationalUnitId,
        casefileId,
        PAGE_SIZE,
        skip,
        keywords,
        form,
        data
      )
      .then(this.fetch)
      .catch(error => {
        if (error?.response?.body instanceof ReadableStream) {
          fetchReadableStream(error.response.body, this.fetch);
        } else {
          this.showNotification(
            error,
            'Something went wrong',
            error?.error || error?.message || ''
          );
        }
      });
  };

  fetch = (reportsData = {}) => {
    const { selectedRowKeys, alreadySelectedRowKeys, pagination } = this.state;
    const reports = this.getReportList(reportsData.data);
    const selectedKeys = reports
      .filter(r => r.isAssociated)
      .map(r => r.reportId);
    this.setState({
      loading: false,
      reports,
      selectedRowKeys: union(selectedRowKeys, selectedKeys),
      alreadySelectedRowKeys: union(alreadySelectedRowKeys, selectedKeys),
      pagination: {
        ...pagination,
        total: reportsData.totalRecords,
      },
    });
  };

  getReportList = (reportList = []) => {
    const reports = reportList.map(r => ({
      key: r.reportId,
      reportNumber: r.reportNumber,
      reportType: r.reportType,
      reportId: r.reportId,
      isAssociated: r.isAssociated,
    }));
    return reports;
  };

  showNotification = (type, message, description) =>
    notification[type]({ message, description });

  initData = () =>
    this.setState({
      selectedRowKeys: [],
      selectedRowObjects: [],
      alreadySelectedRowKeys: [],
      search: true,
      reports: [],
      keywords: '',
      pagination: { current: 1 },
      filters: {
        [FILTER_VIEW]: '',
        [FILTER_SEARCH]: '',
        ...PRE_FILTERS_DEFAULT,
      },
      preFilters: {
        ...PRE_FILTERS_DEFAULT,
      },
      selectedTemplate: undefined,
      filtersVisible: false,
      users: [],
    });

  resetPreFilters = () => {
    this.setState(
      {
        preFilters: PRE_FILTERS_DEFAULT,
      },
      this.applyFilters
    );
  };

  getAdvancedFilters = () => {
    const { formTemplates } = this.props;
    const { filters, preFilters } = this.state;
    const newFilters = { ...filters, ...preFilters };
    const reducedFilters = Object.entries(newFilters).reduce(
      (prev, [key, value]) => {
        if (!value || isEmpty(value)) return prev;
        const data = { column: key, value };
        switch (key) {
          case FILTER_RANGE:
          case FILTER_EVENT_DATE:
            data.operator = 'between';
            if (key === FILTER_RANGE) {
              data.column = 'submitted_at';
            }
            break;
          case FILTER_TYPE:
            const { label } = Object.keys(formTemplates)
              .map(templateKey => ({
                value: formTemplates[templateKey].type,
                label: formTemplates[templateKey].label,
              }))
              .find(template => template.value === value);
            data.column = 'formType';
            data.operator = 'eq';
            data.value = label;
            data.type = value;
            break;
          case FILTER_REPORT_SUBMITTER:
            data.column = 'submittedBy';
            data.operator = 'inq';
            break;
          case FILTER_FORCE:
            data.column = 'incidentOfficerForceApplied';
            data.operator = 'inq';
            break;
          case FILTER_STATUS:
            const [, ...statuses] = value;
            data.value = statuses;
            data.operator = 'inq';
            break;
          default:
            data.operator = 'inq';
            break;
        }
        return [...prev, data];
      },
      []
    );

    const form = [];
    const data = [];
    reducedFilters.forEach(filter => {
      if (FORM_FITLERS.includes(filter.column)) {
        form.push(filter);
      } else {
        data.push(filter);
      }
    });

    const formString = encodeURIComponent(JSON.stringify(form));
    const dataString = encodeURIComponent(JSON.stringify(data));

    return { form: formString, data: dataString };
  };

  applyFilters = () => {
    const {
      agencyId,
      hierarchyKey,
      organizationalUnitId,
      casefileId,
    } = this.props;
    const { keywords } = this.state;
    const { form, data } = this.getAdvancedFilters();
    this.setState(
      {
        filtersVisible: false,
        search: false,
        loading: true,
        selectedRowKeys: [],
        alreadySelectedRowKeys: [],
        pagination: { current: 1 },
      },
      () => {
        caseFileEndpoints
          .searchReports(
            agencyId,
            hierarchyKey,
            organizationalUnitId,
            casefileId,
            PAGE_SIZE,
            0,
            keywords,
            form,
            data
          )
          .then(this.fetch);
      }
    );
  };

  getDateRangeValue = filterType => {
    const { timezone } = this.props;
    const { preFilters } = this.state;

    return isEmpty(preFilters[filterType])
      ? ''
      : preFilters[filterType].map(d => moment.tz(d, timezone));
  };

  setPreFilters = (filter, value) => {
    const { preFilters } = this.state;
    let newPreFilters = { ...preFilters, [filter]: value };
    if (preFilters[FILTER_TYPE] !== newPreFilters[FILTER_TYPE]) {
      newPreFilters[FILTER_FORCE] = [];
      newPreFilters[FILTER_ALLEGATION] = [];
    }

    this.setState({ preFilters: newPreFilters });
  };

  onModalOk = e => {
    const { onOk } = this.props;
    const {
      selectedRowKeys,
      alreadySelectedRowKeys,
      selectedRowObjects,
    } = this.state;
    const selectedReportObjects = selectedRowObjects.filter(
      ({ key }) =>
        !alreadySelectedRowKeys.includes(key) && selectedRowKeys.includes(key)
    );

    onOk && onOk(e, selectedReportObjects);
    this.initData();
  };

  onModalCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
    this.initData();
  };

  onRowSelectionChange = (selectedRowKeys, selectedRows) => {
    const { selectedRowObjects } = this.state;
    this.setState({
      selectedRowKeys,
      selectedRowObjects: union(selectedRowObjects, selectedRows),
    });
  };

  onSearch = value => {
    const { selectedRowKeys, alreadySelectedRowKeys } = this.state;
    if (selectedRowKeys.length === alreadySelectedRowKeys.length) {
      this.setState({ search: true, pagination: { current: 1 } });
    } else {
      confirm({
        title: SEARCH_WARNING_TITLE,
        content: SEARCH_WARNING,
        onOk: this.onConfirmSearch,
      });
    }
  };

  onConfirmSearch = () =>
    this.setState({
      search: true,
      selectedRowKeys: [],
      alreadySelectedRowKeys: [],
      pagination: { current: 1 },
    });

  onKeywordsChange = e => {
    this.setState({ keywords: e.target.value });
  };

  onClickAdvancedFilters = () => {
    const { filtersVisible } = this.state;
    this.setState({
      filtersVisible: !filtersVisible,
    });
  };

  onDatePickerChange = filterType => (dates, dateStrings) => {
    const { timezone } = this.props;
    const dateFrom = moment.tz(dateStrings[0], timezone).format();
    const dateTo = moment.tz(dateStrings[1], timezone).format();
    const range = dates.length ? [dateFrom, dateTo] : [];

    this.setPreFilters(filterType, range);
  };

  onFormTypeSelect = (value = '') => {
    const { agencyId, formTemplates } = this.props;

    this.setPreFilters(FILTER_TYPE, value);
    if (value) {
      const templateId = formTemplates[value].id;
      apiEndpoints.getForm(agencyId, templateId).then(template => {
        this.setState({ selectedTemplate: template });
      });
    }
  };

  clearFilterColumn = (filterName, columnNames) => {
    const { formType } = this.state;

    const columns = castArray(columnNames);
    const currentFilter = castArray(get(this.state, filterName, []));

    const cleanedFilter = currentFilter.filter(
      filter => !columns.includes(filter.column)
    );

    this.setState({
      [filterName]: cleanedFilter,
      formType: columns.includes('formType') ? '' : formType,
    });
  };

  renderSearch = () => {
    const { loading, keywords } = this.state;

    return (
      <Tooltip
        placement="topLeft"
        title={translate('containers.caseFiles.searchByReport')}
      >
        <Search
          placeholder={translate('containers.caseFiles.search')}
          onSearch={this.onSearch}
          loading={loading}
          value={keywords}
          onChange={this.onKeywordsChange}
          allowClear
        />
      </Tooltip>
    );
  };

  renderStatusFilter = () => {
    const { loading } = this.props;
    const { preFilters } = this.state;
    const template = { statuses: allStatuses };
    return (
      <Item
        label={translate('containers.reports.reportStatus')}
        {...FILTER_LAYOUT_OPTIONS}
      >
        <FilterStatus
          label={translate('containers.reports.status')}
          size="default"
          className="full-width"
          template={template}
          disabled={loading}
          setFilters={this.setPreFilters}
          filterType={FILTER_STATUS}
          filterValues={preFilters}
          getPopupContainer={getPopupContainer}
        />
      </Item>
    );
  };

  renderReportTypeFilter = () => {
    const { formTemplates, loading } = this.props;
    const { preFilters } = this.state;

    return (
      <Item
        label={translate('containers.reports.reportType')}
        {...FILTER_LAYOUT_OPTIONS}
      >
        <Select
          optionFilterProp="children"
          style={{ width: '100%' }}
          placeholder="Type"
          size="default"
          onChange={this.onFormTypeSelect}
          showSearch={true}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          disabled={loading}
          value={preFilters[FILTER_TYPE]}
          getPopupContainer={getPopupContainer}
        >
          {formTypesFromTemplates(formTemplates)}
        </Select>
      </Item>
    );
  };

  renderEventDateFilter = () => {
    const { loading } = this.props;

    return (
      <Item
        label={translate('containers.reports.eventDate')}
        {...FILTER_LAYOUT_OPTIONS}
      >
        <RangePicker
          onChange={this.onDatePickerChange(FILTER_EVENT_DATE)}
          size="default"
          className="full-width"
          disabled={loading}
          value={this.getDateRangeValue(FILTER_EVENT_DATE)}
          getCalendarContainer={getPopupContainer}
        />
      </Item>
    );
  };

  renderSubmittedDateFilter = () => {
    const { loading } = this.props;

    return (
      <Item
        label={translate('containers.reports.submittedDate')}
        {...FILTER_LAYOUT_OPTIONS}
      >
        <RangePicker
          onChange={this.onDatePickerChange(FILTER_RANGE)}
          disabledDate={this.disabledDate}
          size="default"
          className="full-width"
          disabled={loading}
          value={this.getDateRangeValue(FILTER_RANGE)}
          getCalendarContainer={getPopupContainer}
        />
      </Item>
    );
  };

  onMentionedUsersChange = value => {
    this.setPreFilters(FILTER_MENTIONED_USERS, value);
  };

  onReportSubmitterChange = value => {
    this.setPreFilters(FILTER_REPORT_SUBMITTER, value);
  };

  onSearchUsers = (value = '', onComplete) => {
    const { dispatch, agencyId } = this.props;
    const { preFilters } = this.state;
    // currentValue should be in the state
    const currentValue = [
      ...preFilters[FILTER_MENTIONED_USERS],
      ...preFilters[FILTER_REPORT_SUBMITTER],
    ];
    const options = {
      filter: { where: {} },
      source: 'users',
      orCriteria: ['starNumber', 'employeeId', 'benchmarkId'],
    };
    const next = (err, response) => {
      onComplete && typeof onComplete === 'function' && onComplete(response);
    };

    const ilike = encodeURIComponent(`%${value}%`);
    const searchByFilter = [
      {
        fullName: { ilike },
      },
      {
        starNumber: { ilike },
      },
      {
        employeeId: { ilike },
      },
      {
        id: { inq: currentValue },
      },
    ];

    const whereFilter = {
      or: searchByFilter,
    };

    const payload = {
      filter: {
        fullName: value,
        where: whereFilter,
        include: 'rank',
      },
      options,
      enumRef: USER_LIST,
    };

    dispatch(
      getOfficersAndCivilians(
        normalizeFilter(this.props, payload, undefined, agencyId),
        next,
        false // onlyTrainees
      )
    );
  };

  filterOption = (inputValue = '', option) => {
    const {
      benchmarkId = '',
      employeeId = '',
      starNumber = '',
      children = '',
    } = option.props;
    const lowerCaseValue = inputValue.toLowerCase();
    return (
      (children != null &&
        children.toLowerCase().indexOf(lowerCaseValue) >= 0) ||
      (benchmarkId !== null &&
        benchmarkId.toLowerCase().indexOf(lowerCaseValue) >= 0) ||
      (employeeId !== null &&
        employeeId.toLowerCase().indexOf(lowerCaseValue) >= 0) ||
      (starNumber !== null &&
        starNumber.toLowerCase().indexOf(lowerCaseValue) >= 0)
    );
  };

  renderMentionedUsersFilter = () => {
    const { loading } = this.props;
    const { preFilters, users } = this.state;

    return (
      <Item
        label={translate('containers.reports.mentionedUsers')}
        {...FILTER_LAYOUT_OPTIONS}
      >
        <Select
          onChange={this.onMentionedUsersChange}
          onSearch={keyworkds => this.onSearchUsers(keyworkds)}
          filterOption={this.filterOption}
          allowClear={true}
          placeholder={translate('containers.reports.mentionedUsers')}
          size="default"
          className="full-width"
          mode="multiple"
          disabled={loading}
          value={preFilters[FILTER_MENTIONED_USERS]}
          getPopupContainer={getPopupContainer}
        >
          {users.map(d => (
            <Option
              key={d.value}
              starNumber={d.data.starNumber}
              employeeId={d.data.employeeId}
              benchmarkId={d.data.benchmarkId}
            >
              {d.label}
            </Option>
          ))}
        </Select>
      </Item>
    );
  };

  renderReportSubmitterFilter = () => {
    const { loading } = this.props;
    const { preFilters, users } = this.state;

    return (
      <Item
        label={translate('containers.reports.reportSubmitter')}
        {...FILTER_LAYOUT_OPTIONS}
      >
        <Select
          onChange={this.onReportSubmitterChange}
          onSearch={keyworkds => this.onSearchUsers(keyworkds)}
          filterOption={this.filterOption}
          allowClear={true}
          placeholder={translate('containers.reports.reportSubmitter')}
          size="default"
          className="full-width"
          mode="multiple"
          disabled={loading}
          value={preFilters[FILTER_REPORT_SUBMITTER]}
          getPopupContainer={getPopupContainer}
        >
          {users.map(d => (
            <Option
              key={d.value}
              starNumber={d.data.starNumber}
              employeeId={d.data.employeeId}
              benchmarkId={d.data.benchmarkId}
            >
              {d.label}
            </Option>
          ))}
        </Select>
      </Item>
    );
  };

  renderAdvancedFilters = () => {
    const { preFilters, filtersVisible, selectedTemplate } = this.state;
    const type = preFilters[FILTER_TYPE];
    const selectedFormType = formTypes[type];

    return (
      filtersVisible && (
        <AdvanceFilterWrapper>
          <div className="scrollable">
            {this.renderReportTypeFilter()}
            {selectedFormType && (
              <AdvanceFilter
                formType={selectedFormType}
                template={selectedTemplate}
                clearFilterColumn={this.clearFilterColumn}
                setFilters={this.setPreFilters}
                filterValues={preFilters}
                getPopupContainer={getPopupContainer}
              />
            )}
            {this.renderStatusFilter()}
            {this.renderEventDateFilter()}
            {this.renderSubmittedDateFilter()}
            {this.renderMentionedUsersFilter()}
            {this.renderReportSubmitterFilter()}
          </div>
          <StyledRow>
            <Col span={5}>
              <Button
                type="danger"
                size="default"
                className="btn-reset"
                onClick={this.resetPreFilters}
              >
                {translate('containers.reports.reset')}
              </Button>
            </Col>
            <Col span={5} offset={14}>
              <Button
                type="primary"
                size="default"
                className="btn-apply"
                onClick={this.applyFilters}
              >
                {translate('containers.reports.apply')}
              </Button>
            </Col>
          </StyledRow>
        </AdvanceFilterWrapper>
      )
    );
  };

  getBadgeCount = () => {
    const { preFilters } = this.state;
    return Object.values(preFilters).reduce(
      (count, value) => (isEmpty(value) ? count : count + 1),
      0
    );
  };

  render() {
    const { title, visible } = this.props;
    const {
      reports,
      pagination,
      loading,
      selectedRowKeys,
      alreadySelectedRowKeys,
      // filtersVisible,
    } = this.state;
    // const filtersIcon = filtersVisible ? 'minus' : 'plus';
    // const count = this.getBadgeCount();

    const disableSave =
      selectedRowKeys.length === alreadySelectedRowKeys.length;
    const rowSelection = {
      onChange: this.onRowSelectionChange,
      selectedRowKeys,
      getCheckboxProps: record => ({
        disabled: record.isAssociated,
      }),
    };

    return (
      <StyledModal
        title={title}
        width={1000}
        className="add-report-modal"
        centered
        visible={visible}
        onOk={this.onModalOk}
        onCancel={this.onModalCancel}
        footer={[
          <Button key="cancel" onClick={this.onModalCancel}>
            {translate('containers.caseFiles.cancel')}
          </Button>,
          <Button
            key="Save"
            type="primary"
            onClick={this.onModalOk}
            disabled={disableSave}
          >
            {translate('containers.caseFiles.save')}
          </Button>,
        ]}
      >
        <Row gutter={[8, 8]}>
          <Col span={6} offset={18}>
            {this.renderSearch()}
          </Col>
        </Row>
        {/* <Row type="flex" justify="space-between">
          <Col span={6}>{this.renderSearch()}</Col>
          <Col justify="end">
            <Badge count={count} style={{ backgroundColor: BLUE }}>
              <Button
                icon={filtersIcon}
                size="default"
                disabled={loading}
                onClick={this.onClickAdvancedFilters}
              >
                {translate('containers.reports.advancedFilters')}
              </Button>
            </Badge>
            <StyledRowAdvance justify="end">
              <StyledFilterCol xs={24}>
                {this.renderAdvancedFilters()}
              </StyledFilterCol>
            </StyledRowAdvance>
          </Col>
        </Row> */}
        <Row gutter={[8, 8]}>
          <StyledTable
            className="add-reports-list"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={reports}
            pagination={pagination}
            loading={loading}
            onChange={this.handleTableChange}
          ></StyledTable>
        </Row>
      </StyledModal>
    );
  }
}

const mapState = state => {
  return {
    agencyId: getAgencyId(state),
    formTemplates: getTemplates(state),
    hierarchyKey: get(state, 'session.currentUser.hierarchyKey'),
    organizationalUnitId: get(
      state,
      'session.currentUser.organizationalUnitId'
    ),
    templatesLoading: getTemplatesLoading(state),
    timezone: getAgencyTZ(state),
    userList: get(state, `form.selectedForm.enums.${USER_LIST}`, []),
  };
};

export default connect(mapState)(AddReportModal);
