import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  background: #fff;
  margin: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 16px 0;
`;

export const ImagesDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 16px 0;
`;

export const ImageColumnarDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h3 {
    margin: 16px 0;
  }
`;

export const StyledTitle = styled.span`
  margin-right: auto;
  font-weight: bold;
  font-size: 18px;
`;

export default Content;
