import { SET_LAST_SAVE_DATE, SET_ACTIVE_SECTION } from '.';

export const setLastSaveDate = lastSaveDate => ({
  type: SET_LAST_SAVE_DATE,
  payload: lastSaveDate.toISOString(),
});

export const setActiveSection = activeSection => ({
  type: SET_ACTIVE_SECTION,
  payload: activeSection,
});
