import React, { Component } from 'react';
import { Row, Col, Pagination, Spin } from 'antd';
import styled from 'styled-components';

// Actions
import getFeed from '../../actions/get-feed';
import markNotificationSeen from '../../actions/mark-seen-notification';
// Containers
import DashboardPage from '../../components/dashboard';
// Components
import MainTitle from '../../components/common/main-title';
import ActivitiesPanel from '../../components/dashboard/activities-panel';
// utils
import parseDate from '../../utils/parse-date';
// selectors
import { getAgencyTZ } from '../../selectors/session';
import { translate } from '../../i18next';

class ActivityFeed extends Component {
  static getPageConfig() {
    return {
      title: 'Activity Feed',
    };
  }

  UNSAFE_componentWillMount() {
    const {
      dispatch,
      reportsFetched = false,
      session: { isLoggedIn } = {},
    } = this.props;

    if (!reportsFetched && isLoggedIn) {
      dispatch(getFeed(1, null, true));
    }
  }

  markActivityAsRead = id => {
    const { dispatch } = this.props;

    dispatch(markNotificationSeen(id));
  };

  getActivityFeed = () => {
    const { entries = {}, list = [], timezone } = this.props;

    const activities = list.map(activityId => {
      const {
        title,
        created_at,
        data: { formId } = {},
        id,
        new: bold,
      } = entries[activityId];

      return {
        message: title,
        meta: parseDate(created_at, timezone),
        id,
        formId,
        bold,
      };
    });

    return (
      <ActivitiesPanel
        data={activities}
        showTitle={false}
        onItemClick={this.markActivityAsRead}
      />
    );
  };

  createReport = e => {
    const {
      history: { push },
    } = this.props;

    push('/use-of-force/new');
  };

  changePage = page => {
    const { dispatch } = this.props;

    window.scrollTo(0, 0);

    dispatch(getFeed(page));
  };

  render() {
    const { total = 0, per_page = 8, page = 1, loading = false } = this.props;

    const PaginationWrapper = props => {
      if (loading) {
        return <Spin>{props.children}</Spin>;
      }

      return <div>{props.children}</div>;
    };

    return (
      <div className="section-container">
        <Row>
          <Col span={24} className="text-left">
            <MainTitle>{translate('containers.home.activityFeed')}</MainTitle>
          </Col>
        </Row>

        <StyledRow>
          <Col span={24}>{this.getActivityFeed()}</Col>
        </StyledRow>

        {total > 0 && (
          <StyledRowPagination>
            <Col span={24} className="text-right">
              <PaginationWrapper>
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  total={total}
                  pageSize={per_page}
                  onChange={this.changePage}
                />
              </PaginationWrapper>
            </Col>
          </StyledRowPagination>
        )}
      </div>
    );
  }
}

const StyledRow = styled(Row)`
  margin-top: 16px;
`;
const StyledRowPagination = styled(Row)`
  margin-top: 16px;
`;

const mapStateToProps = state => {
  const {
    activity: {
      activityFeed: {
        data: list = [],
        entries,
        fetched,
        total,
        per_page,
        page,
        loading,
      } = {},
    } = {},
  } = state;
  return {
    fetched,
    list,
    entries,
    total,
    per_page,
    page,
    loading,
    timezone: getAgencyTZ(state),
  };
};

export default DashboardPage(mapStateToProps, null)(ActivityFeed);
