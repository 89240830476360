import { FEATURES, hasFeatures } from '../../../../../utils/features';
import { hasPermissions, PERMISSIONS } from '../../../../../utils/admin';
import { userMergeTrainingTableColumns } from './UserMergeTrainingTableColumns';
import { userTrainingTableColumns } from './UserTrainingTableColumns';

const generateTrainingConfigs = session => {
  const { featureFlags = [], permissions = [] } = session.currentUser || {};

  const userCanViewTraining = hasPermissions(permissions, [
    PERMISSIONS.accessTraining,
  ]);

  const agencyHasMergeFeatureTraining = hasFeatures(
    featureFlags,
    FEATURES.userMergeTrainingSection
  );

  const showTrainingSection =
    (agencyHasMergeFeatureTraining ||
      hasFeatures(featureFlags, FEATURES.userTrainingSection)) &&
    userCanViewTraining;

  if (!showTrainingSection) return null;

  const showHyperlinks = hasFeatures(
    featureFlags,
    FEATURES.userTrainingSectionHyperlinks
  );

  const trainingTableColumns = agencyHasMergeFeatureTraining
    ? userMergeTrainingTableColumns(showHyperlinks)
    : userTrainingTableColumns(showHyperlinks);

  const source = agencyHasMergeFeatureTraining ? 'kmi-merge' : 'all';

  const configs = {
    columns: trainingTableColumns,
    source: source,
  };

  return configs;
};

export default generateTrainingConfigs;
