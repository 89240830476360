import openModal from '../utils/onShareButtonClick';

const ShareButton = {
  icon: 'user-add',
  type: 'icon',
  title: 'Share',
  tooltipMessage: 'Share Report',
  translateKey: 'containers.reports.reportViewer.shareReport',
  onButtonClick: () => openModal,
};

export default ShareButton;
