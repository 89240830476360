import downloadReportWithAttachmentsEndpoint from '../../../api/forms/downloadReportWithAttachmentsEndpoint';
import {
  ButtonGroupStyled,
  DividerStyled,
  RadioGroupStyled,
  RadioStyled,
} from '../../administrator/agency-audit-view-logs/components/AuditLogsOptions.style';
import { Button } from 'antd';
import React from 'react';
import ModalTitle from '../../../components/common/modal/title';
import { translate } from '../../../i18next';

export default instance => () => {
  const PrintReportText = translate(
    'containers.reports.reportViewer.printReport'
  );
  const PrintAttachmentsText = translate(
    'containers.reports.reportViewer.printAttachments'
  );

  if (instance.props.allowPrintWithAttachments === false) {
    window.open(instance.getPrintLink());
    return;
  }

  const onOkClick = async value => {
    instance.updateModal({
      visible: true,
      title: title,
      children: body,
      destroyOnClose: true,
    });

    switch (value) {
      case PrintAttachmentsText:
        //add call for print with attachments here
        const {
          selectedForm: { meta: { id: formsId, agencyId } = {} },
        } = instance.props;
        const pdfBlob = await downloadReportWithAttachmentsEndpoint(
          agencyId,
          formsId
        );
        const blobURL = URL.createObjectURL(pdfBlob);
        window.open(blobURL);
        break;
      default:
        window.open(instance.getPrintLink());
    }
    instance.deleteModal();
  };

  const onOptionClick = e => {
    instance.setState({ printOptions: e.target.value });
  };

  const title = (
    <ModalTitle>
      {translate('containers.reports.reportViewer.printOptions')}
    </ModalTitle>
  );

  let body = (
    <>
      <RadioGroupStyled defaultValue={PrintReportText}>
        <RadioStyled onClick={e => onOptionClick(e)} value={PrintReportText}>
          {PrintReportText}
        </RadioStyled>
        <RadioStyled
          selected={PrintAttachmentsText === instance.state.printOptions}
          onClick={e => onOptionClick(e)}
          value={PrintAttachmentsText}
        >
          {PrintAttachmentsText}
        </RadioStyled>
      </RadioGroupStyled>
      <DividerStyled />
      <ButtonGroupStyled>
        <Button
          type="primary"
          onClick={() => onOkClick(instance.state.printOptions)}
        >
          OK
        </Button>
        <Button onClick={() => instance.deleteModal()}>
          {translate('containers.reports.reportViewer.cancel')}
        </Button>
      </ButtonGroupStyled>
    </>
  );

  instance.createModal({
    visible: true,
    title: title,
    children: body,
    destroyOnClose: true,
  });

  instance.showModal();
};
