import React from 'react';
import Button from './ButtonDefinition.styled';
import { translate } from '../../../i18next.js';

const CASE_FILE_REPORTS_SECTION = 'reports';
const CASE_FILE_DOCUMENTS_SECTION = 'documents';

export default (
  callback,
  showIconAction,
  onAction,
  sectionId,
  isSectionReadOnly,
  buttonParameters,
  parentContext,
  disabled = false,
  isContributor = false,
  currentUser = null,
  createdBy = []
) => {
  const { altText, keyNaming, actionName, targetIcon } = buttonParameters;

  const isDisabled = index => {
    let _disabled = disabled;
    if (
      isContributor &&
      (sectionId === CASE_FILE_REPORTS_SECTION ||
        sectionId === CASE_FILE_DOCUMENTS_SECTION)
    ) {
      const _createdBy = createdBy[index];
      const { userIntegrationId } = currentUser;
      if (_createdBy && _createdBy !== userIntegrationId) {
        _disabled = true;
      }
    }
    return _disabled;
  };

  const handleClick = (event, record) => {
    event.stopPropagation();
    if (!isDisabled(record.index)) {
      onAction
        ? onAction(
            record,
            record.index,
            actionName,
            () => callback(sectionId, record.index, record, isSectionReadOnly),
            parentContext
          )
        : callback(sectionId, record.index, record, isSectionReadOnly);
    }
  };

  return {
    title: showIconAction
      ? translate('containers.profile.employment.action')
      : altText,
    dataIndex: '',
    key: keyNaming,
    width: 100,
    render: (text, record, index) => {
      const _disabled = isDisabled(index);
      return (
        <Button
          className={_disabled ? 'disabled' : ''}
          aria-disabled={isSectionReadOnly}
          onClick={event => handleClick(event, record)}
        >
          {targetIcon}
        </Button>
      );
    },
  };
};
