import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'antd';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import getRoute from '../../utils/get-route';
import { getFormValues } from '../../containers/administrator/agency-users/AgencyUsers.selectors';
import { NEW_REPORT_ON_CASE_FILE_REDUX_PROP } from '../../containers/caseFile/components/newReportButton/constants';

const PageHeaderInfo = ({ casefileForNewReport }) => {
  const shouldAlertRender = !isEmpty(casefileForNewReport?.values?.caseFile);
  const { casefileTitle = null, casefileId = null } =
    casefileForNewReport?.values?.caseFile || {};

  return (
    shouldAlertRender && (
      <Alert
        message={
          <span>
            This new report was created from case file:{' '}
            <Link
              to={getRoute('openCaseFile', {
                id: casefileId,
              })}
            >
              {casefileTitle}
            </Link>
          </span>
        }
        type="info"
        showIcon
      />
    )
  );
};

function mapStateToProps(state) {
  return {
    casefileForNewReport: getFormValues(
      state,
      NEW_REPORT_ON_CASE_FILE_REDUX_PROP
    ),
  };
}

export default connect(mapStateToProps)(PageHeaderInfo);
