import React from 'react';
import { get } from 'lodash';

import store from 'APP_ROOT/store';
import createModal from 'APP_ROOT/actions/create-modal';
import showModal from 'APP_ROOT/actions/show-modal';
import { getAgencyTZ } from 'APP_ROOT/selectors/session';
import { parseHtmlInput } from 'APP_ROOT/utils/request';

import ModalTitle from '../../../components/common/modal/title';
import ModalBody from '../../../components/common/modal/body';
import FormHistoryTimeline from '../../../components/form-history-timeline';

import transformFormToNormalizedTimeline from '../../../utils/transform-form-to-normalized-timeline';
import getOnBehalfUsers from '../../../components/utils/getOnBehalfUsers';
import getReportUsers from '../../../components/utils/getReportUsers';

import retrieveReport from '../../../../src/actions/utils/edit-form/retrieveReport';

import { REPORT_FILTERS } from './constants';

import { translate } from '../../../i18next';

const getCustomMeta = (participants, usersOnBehalf, report) => {
  const {
    meta = {},
    id,
    formType,
    formNumber,
    transactionHistory = [],
    participantsId = [],
    linkedSubmissions = [],
    notes = [],
    number,
    agencyId,
    data: { __assignedSectionsHistory = [] },
  } = report;
  return {
    ...meta,
    id,
    formType,
    formNumber,
    transactionHistory,
    participantsId,
    linkedSubmissions,
    notes,
    number,
    agencyId,
    participants,
    usersOnBehalf,
    __assignedSectionsHistory,
  };
};

const getTemplateName = report => {
  const { formType, templateName } = report;
  const {
    form: { templates },
  } = store.getState();
  return templateName || get(templates, `${formType}.name`, formType);
};

const viewHistory = props => e => {
  e.stopPropagation();

  const { dispatch, getState } = store;
  const { report } = props;
  const { formNumber } = report;
  const state = getState();
  const timezone = getAgencyTZ(state);
  const templateName = getTemplateName(report);

  const Title = (
    <ModalTitle>
      <span>
        {parseHtmlInput(templateName)} {formNumber}
        {' - '}
        {translate('containers.reports.timeline.timeline')}
      </span>
    </ModalTitle>
  );

  retrieveReport({
    id: report.id,
    agencyId: report.agencyId,
    filter: REPORT_FILTERS,
  }).then(result => {
    const report = result[1];
    const usersOnBehalf = getOnBehalfUsers(report);
    const users = getReportUsers(report);
    const snapshotUsers = Object.values(users);
    const metaCustom = getCustomMeta(snapshotUsers, usersOnBehalf, report);
    const normalizedRecord = transformFormToNormalizedTimeline(
      metaCustom,
      timezone
    );

    const recordWithReport = { ...normalizedRecord, report };

    const Body = (
      <ModalBody>
        <FormHistoryTimeline {...recordWithReport} />
      </ModalBody>
    );
    const modalOptions = {
      id: 'timeline-modal',
      visible: true,
      title: Title,
      children: Body,
    };
    dispatch(createModal(modalOptions));
    dispatch(showModal(modalOptions.id));
  });
};

export default viewHistory;
