import React, { memo } from 'react';
import { Form, Col, InputNumber } from 'antd';
import FieldSelect from '../../../components/FieldSelect';
import { StyledBoundaryRow } from './RangeBoundaryRow.styled';
import {
  DECIMAL_REGEX,
  DAY_LABEL,
  DAY_VALUE,
  YEAR_LABEL,
  YEAR_VALUE,
} from '../constants';

const { Item } = Form;

const unitOptions = [
  { label: DAY_LABEL, value: DAY_VALUE },
  { label: YEAR_LABEL, value: YEAR_VALUE },
];

const validateLimitValue = limit => String(limit).replace(DECIMAL_REGEX, '');

const RangeBoundaryRow = memo(
  ({
    typeValue,
    onTypeChange,
    typeOptions,
    limitValue,
    onLimitChange,
    unitValue,
    onUnitChange,
  }) => (
    <StyledBoundaryRow gutter={8}>
      <Col flex="1">
        <Item>
          <FieldSelect
            value={typeValue}
            onChange={onTypeChange}
            selectOptions={typeOptions}
            placeholder="Select Boundary"
            className="style-options"
            allowClear
          />
        </Item>
      </Col>
      <Col flex="1">
        <Item>
          <InputNumber
            min={0}
            value={limitValue}
            onChange={onLimitChange}
            formatter={validateLimitValue}
            parser={validateLimitValue}
            placeholder="Limit"
            step={1}
          />
        </Item>
      </Col>
      <Col flex="1">
        <Item>
          <FieldSelect
            value={unitValue}
            onChange={onUnitChange}
            selectOptions={unitOptions}
            placeholder="Select Unit"
            className="style-options"
          />
        </Item>
      </Col>
    </StyledBoundaryRow>
  )
);

export default RangeBoundaryRow;
