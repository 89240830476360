import React, { PureComponent } from 'react';
import { Button } from 'antd';
import Block from 'react-blocks';
import styled from 'styled-components';
import omit from 'lodash/omit';
import { connect } from 'react-redux';

import FormWrapper from '../styled/form-wrapper';
import TabsNavigation from '../../form/tabs-navigation';
import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';
import { getFormValidationState } from 'APP_ROOT/selectors/form';
import {
  setTabsWithErrors,
  updateTabsWithError,
  validateCurrentTabFields,
} from '../../utils/tabs';
import { translate } from '../../../i18next';

class Tabs extends PureComponent {
  constructor(props) {
    const { options = {}, properties = [] } = props;
    const { active: current = 0 } = options;

    super(props);
    const initialTabs = properties.map((_, index) => index);
    updateTabsWithError(initialTabs);
    this.state = {
      current,
    };
  }

  syncTab = current => {
    const {
      settings: { syncTab, validateFormFields },
      validationState: {
        form: { submitRequested },
      },
      tab = 0,
    } = this.props;

    if (tab === current) {
      return false;
    }

    window.scrollTo(0, 0);

    if (submitRequested) {
      syncTab(current);
      validateFormFields(errors => this.setTabsWithErrors(tab, errors));
    } else {
      syncTab(current);
      this.validateCurrentTabFields(tab);
    }
  };

  validateCurrentTabFields = current => {
    const {
      form: { validateFields },
      validationState: { tabsWithErrors },
    } = this.props;
    validateCurrentTabFields(current, validateFields, tabsWithErrors);
  };

  setTabsWithErrors = (current, errors = []) => {
    const {
      validationState: { tabsWithErrors },
    } = this.props;
    setTabsWithErrors(current, errors, tabsWithErrors);
  };

  setStep = (e, current) => {
    this.syncTab(current);
  };

  next() {
    const { tab = 0 } = this.props;
    const current = tab + 1;

    this.syncTab(current);
  }

  prev() {
    const { tab = 0 } = this.props;
    const current = tab - 1;

    this.syncTab(current);
  }

  onSaveDraft = () => {
    const { settings } = this.props;

    emitFormEvent('onSaveDraft', settings.formName);
  };

  render() {
    const {
      isReviewer = false,
      loading = false, // eslint-disable-line
      tab: current = 0,
      properties = [],
      showSaveButton = true,
    } = this.props;

    if (isReviewer) {
      return null;
    }

    return (
      <FormWrapper>
        <Block className="steps-actions" layout horizontal el={BlockRow}>
          <div>
            {showSaveButton && (
              <Button
                ghost
                type="primary"
                htmlType="button"
                disabled={loading}
                loading={loading}
                onClick={this.onSaveDraft}
              >
                {translate('containers.reports.saveDraft')}
              </Button>
            )}
          </div>
          <NavigationButtons>
            <TabsNavigation
              current={current}
              onNext={e => this.next()}
              onPrev={e => this.prev()}
              totalSteps={properties.length}
              loading={loading}
            />
          </NavigationButtons>
        </Block>
      </FormWrapper>
    );
  }
}

const NavigationButtons = styled.div`
  display: flex;
  flex: 1;
  text-align: right;

  .btm-form-steps-navigation {
    width: 100%;
  }
`;

const blacklistesUnknownProps = [
  'layout',
  'inline',
  'flex',
  'vertical',
  'horizontal',
  'reverse',
  'start',
  'center',
  'end',
  'stretch',
  'centered',
  'selfStart',
  'selfCenter',
  'selfEnd',
  'selfStretch',
  'justifyStart',
  'justifyCenter',
  'justifyEnd',
  'justifyBetween',
  'justifyAround',
  'el',
];

const BlockRow = props => (
  <div {...omit(props, ['children', ...blacklistesUnknownProps])}>
    {props.children}
  </div>
);

const mapState = (state, props) => {
  return {
    validationState: getFormValidationState(state, props),
  };
};

export default connect(mapState)(Tabs);
