import React, { Component } from 'react';
import { get, omit, assign, find, pick } from 'lodash';
import { Button, message } from 'antd';
import { initialize, submit, change } from 'redux-form';
import DashboardPage from 'APP_COMPONENTS/dashboard';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import OrganizationInformation from 'APP_COMPONENTS/organization-profile/organization-information/OrganizationInformation';
import OrganizationSections from 'APP_COMPONENTS/organization-profile/organization-sections/OrganizationSections';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import TextActions from 'APP_COMPONENTS/organization-profile/common/TextActions.styled';
import withModal from 'APP_COMPONENTS/common/modal/base';
import getRoute from 'APP_ROOT/utils/get-route';
import { getFormValues } from '../administrator/agency-users/AgencyUsers.selectors';
import connectForm from '../administrator/components/connected-forms';
import { hasPermissions, PERMISSIONS } from '../../utils/admin';
import parseDate, { BENCHMARK_DATE_TIMEZ_FORMAT } from '../../utils/parse-date';
import OrganizationAddress from 'APP_COMPONENTS/organization-profile/organization-address/OrganizationAddress';
import createModal from '../../actions/create-modal';
import showModal from '../../actions/show-modal';
import getOuAddress from './actions/address/get-ou-address';
import getOuData from './actions/get-ou-data';
import getOuDocuments from './actions/get-ou-documents';
import createOuAddress from './actions/address/create-ou-address';
import updateOuAddress from './actions/address/update-ou-address';
import saveOuDocuments from './actions/save-ou-documents';
import updateOuAttributes from './actions/update-ou-attributes';
import saveOuDocumentsThenUpdateAttributes from './actions/save-ou-documents-then-update-attributes';
import { registerNewDocument } from '../../api/ou-documents';
import { Icon } from 'antd';
import ModalTitle from 'APP_COMPONENTS/common/modal/title';
import ModalBody from 'APP_COMPONENTS/common/modal/body';

import OrganizationProfileEmployeeTable from './OrganizationProfileEmployeeTable';
import OrgProfilePointContactTable from './PointContactTable';
import setOuListFromOrgProfile from '../organizations/actions/set-ou-list-from-org-profile';
import { translate } from '../../i18next';

const FORM_NAME = 'organizationProfileForm';

class OrganizationProfile extends withModal(Component) {
  state = {
    loading: true,
    organizationProfileForm: {},
    addressCheckBox: false,
    tenantSlug: '',
  };

  onSubmit = values => {
    const { organizationalUnitId } = this.props;
    const documents = this.getOuDocumentsValues(
      get(values, 'documentsData.documents')
    );
    const attributes = pick(values, ['phoneNumber', 'website']);

    this.sendData(organizationalUnitId, documents, attributes);
    this.sendAddressData();
  };

  getOuDocumentsValues(documents = []) {
    const { documentsData } = this.state;
    let finalUserDocuments = [];
    let ouDocuments = [];
    let changedRecords = [];
    let newRecords = [];

    if (documents) {
      const keys = Object.keys(omit(documents, ['uploaded']));
      keys.forEach(key => {
        let documentRecord = {};
        documents[key].forEach((value, index) => {
          const savedDocument = documentsData.find(
            doc => doc.id == documents.id[index]
          );
          if (savedDocument) {
            if (savedDocument[key] !== value) {
              changedRecords.push(documents.id[index]);
            }
          } else {
            changedRecords.push(documents.id[index]);
            newRecords.push(documents.id[index]);
          }
          documentRecord[key] = value;
          ouDocuments[index] = assign(ouDocuments[index], documentRecord);
        });
      });

      //Remove items that were not changed when the record was edited
      //and remove the id from new records
      finalUserDocuments = ouDocuments
        .filter(doc => find(changedRecords, id => id == doc.id))
        .map(record =>
          find(newRecords, id => id == record.id)
            ? omit(record, ['id'])
            : record
        );
    }

    return finalUserDocuments.length ? { documents: finalUserDocuments } : null;
  }

  sendAddressData = () => {
    const { dispatch, organizationProfileForm } = this.props;

    let addressFields = this.getAddressFieldsForSave(organizationProfileForm);

    if (addressFields.primaryId == undefined) {
      dispatch(
        createOuAddress(addressFields.primary, error => {
          if (error) {
            const errorMessage = 'Error creating primary address.';
            message.error(errorMessage);
            return false;
          }
        })
      );
    } else {
      dispatch(
        updateOuAddress(
          addressFields.primaryId,
          addressFields.primary,
          error => {
            if (error) {
              const errorMessage = 'Error updating primary address.';
              message.error(errorMessage);
              return false;
            }
          }
        )
      );
    }
    if (addressFields.mailingId == undefined) {
      dispatch(
        createOuAddress(addressFields.mailing, error => {
          if (error) {
            const errorMessage = 'Error creating mailing address.';
            message.error(errorMessage);
            return false;
          }
        })
      );
    } else {
      dispatch(
        updateOuAddress(
          addressFields.mailingId,
          addressFields.mailing,
          error => {
            if (error) {
              const errorMessage = 'Error updating mailing address.';
              message.error(errorMessage);
              return false;
            }
          }
        )
      );
    }
  };

  sendData = (organizationalUnitId, documents, attributes) => {
    const { dispatch } = this.props;

    if (this.canChangeAttributes && this.canSave && documents) {
      dispatch(
        saveOuDocumentsThenUpdateAttributes(
          organizationalUnitId,
          documents,
          attributes,
          this.afterSendingData
        )
      );
    } else if (this.canSave && documents) {
      dispatch(
        saveOuDocuments(organizationalUnitId, documents, this.afterSendingData)
      );
    } else if (this.canChangeAttributes) {
      dispatch(
        updateOuAttributes(
          organizationalUnitId,
          attributes,
          this.afterSendingData
        )
      );
    }
  };

  afterSendingData = error => {
    if (error) {
      this.showErrorMessage();
    } else {
      this.setState({ loading: true });
      this.getData();
      this.showSuccessMessage();
    }
  };

  showErrorMessage = () => {
    const { dispatch } = this.props;
    const errorTitle = (
      <ModalTitle error>
        <Icon type="close-circle" /> <span>Heads Up!</span>
      </ModalTitle>
    );
    const successFields = (
      <ModalBody>
        <p>Something wrong occurred, please try again later.</p>
      </ModalBody>
    );
    const options = {
      id: 'ou-error-modal',
      title: errorTitle,
      children: successFields,
    };
    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  showSuccessMessage = () => {
    const { dispatch } = this.props;
    const successTitle = (
      <ModalTitle success>
        <Icon type="check-circle" /> <span>Success!</span>
      </ModalTitle>
    );
    const successFields = (
      <ModalBody>
        <p>The information has been saved successfully.</p>
      </ModalBody>
    );
    const options = {
      id: 'ou-modal',
      title: successTitle,
      children: successFields,
    };
    dispatch(createModal(options));

    dispatch(showModal(options.id));
  };

  getHeaderActions = () => {
    const {
      organizationProfileForm,
      session: {
        currentUser: {
          agency: { timezone = '' },
        },
      },
    } = this.props;

    const lastUpdatedDate =
      organizationProfileForm?.initial?.updatedAt ||
      organizationProfileForm?.initial?.createdAt;
    const lastUpdatedInfo =
      this.isEditing && lastUpdatedDate ? (
        <TextActions key="1">
          {translate('containers.organizationProfile.lastUpdated')}:{' '}
          <span>
            {parseDate(lastUpdatedDate, timezone, BENCHMARK_DATE_TIMEZ_FORMAT)}
          </span>
        </TextActions>
      ) : null;

    return [
      lastUpdatedInfo,
      <Button
        type="primary"
        size="default"
        key="2"
        onClick={this.triggerSubmit}
        disabled={
          this.isLoading || (!this.canSave && !this.canChangeAttributes)
        }
        loading={this.isLoading}
      >
        {translate('containers.organizationProfile.save')}
      </Button>,
    ];
  };

  getGoBackTo = () => {
    return this.props.location?.state?.previousPath || getRoute('dashboard');
  };

  get isEditing() {
    return true;
  }

  get isLoading() {
    return this.state.loading;
  }

  get canSave() {
    const {
      session: {
        currentUser: { permissions = [] },
      },
    } = this.props;
    return hasPermissions(permissions, [PERMISSIONS.manageOUDocuments]);
  }

  get canChangeAttributes() {
    const {
      session: {
        currentUser: { permissions = [] },
      },
    } = this.props;
    return hasPermissions(permissions, [PERMISSIONS.manageOUAttributes]);
  }

  triggerSubmit = () => {
    const { dispatch } = this.props;

    dispatch(submit(FORM_NAME));
  };

  setDoneLoading = () => {
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.getData();
  }

  registerDocumentSpace = ouIntegrationId => {
    const {
      dispatch,
      session: {
        currentUser: { permissions = [] },
      },
    } = this.props;
    if (hasPermissions(permissions, [PERMISSIONS.manageOUDocuments])) {
      registerNewDocument(ouIntegrationId).then(data => {
        const { id } = data;
        dispatch(change(FORM_NAME, 'documentsData.registeredDocumentId', id));
      });
    }
  };

  getAddressData(addressFields) {
    const { dispatch, organizationalUnitId } = this.props;
    dispatch(
      getOuAddress(organizationalUnitId, (error, data) => {
        if (error) {
          const errorMessage = 'Error loading Organization Address.';
          message.error(errorMessage);
          return false;
        }
        this.setAddressFields(data.content, addressFields);
      })
    );
  }

  syncAddressFields = () => {
    let { dispatch, organizationProfileForm } = this.props;
    const { values } = organizationProfileForm;
    dispatch(change(FORM_NAME, 'mailing_address1', values.primary_address1));
    dispatch(change(FORM_NAME, 'mailing_address2', values.primary_address2));
    dispatch(change(FORM_NAME, 'mailing_city', values.primary_city));
    dispatch(change(FORM_NAME, 'mailing_state', values.primary_state));
    dispatch(change(FORM_NAME, 'mailing_zip', values.primary_zip));
    dispatch(change(FORM_NAME, 'mailing_county', values.primary_county));
  };

  getAddressFieldsForSave = ({ initial, values }) => {
    let mailingContent = { ...values };
    if (this.state.addressCheckBox == true) {
      mailingContent.mailing_address1 = mailingContent.primary_address1;
      mailingContent.mailing_address2 = mailingContent.primary_address2;
      mailingContent.mailing_city = mailingContent.primary_city;
      mailingContent.mailing_county = mailingContent.primary_county;
      mailingContent.mailing_state = mailingContent.primary_state;
      mailingContent.mailing_zip = mailingContent.primary_zip;
    }

    const { organizationalUnitId } = this.props;
    let addressFields = {};

    const initialValue = Object.keys(initial);
    let changedValues = {};
    initialValue
      .filter((e, index) => {
        if (e.includes('id')) return false;
        if (e.includes('primary')) return initial[e] != values[e];
      })
      .map(key => {
        const newKey = key.split('_')[1];
        if (newKey === 'counties') {
          changedValues[newKey] = values[key].split(',');
        } else {
          changedValues[newKey] = values[key];
        }
      });

    addressFields.primaryId = values.primary_id;
    addressFields['primary'] = changedValues;
    if (addressFields.primaryId == undefined) {
      addressFields.primary.organizationalUnitId = organizationalUnitId;
      addressFields.primary.addressType = 'Primary';
    }

    changedValues = {};

    initialValue
      .filter((e, index) => {
        if (e.includes('id')) return false;
        if (e.includes('mailing')) return initial[e] != mailingContent[e];
      })
      .map(key => {
        const newKey = key.split('_')[1];
        changedValues[newKey] = mailingContent[key];
      });

    addressFields.mailingId = values.mailing_id;
    addressFields['mailing'] = changedValues;
    if (addressFields.mailingId == undefined) {
      addressFields.mailing.organizationalUnitId = organizationalUnitId;
      addressFields.mailing.addressType = 'Mailing';
    }

    return addressFields;
  };

  setAddressFields = (content, addressFields) => {
    const { dispatch } = this.props;
    content.forEach(element => {
      let type = element.addressType.toLowerCase();

      addressFields[type].id = element.id;
      addressFields[type].address1 = element.address1;
      addressFields[type].address2 = element.address2;
      addressFields[type].city = element.city;
      addressFields[type].state = element.state;
      addressFields[type].zip = element.zip;
      addressFields[type].county = element.county;
      addressFields[type].counties = element.counties.join(', ');
    });

    let tempPrimary = { ...addressFields.primary };
    let tempMailing = { ...addressFields.mailing };
    delete tempPrimary.id;
    delete tempPrimary.counties;
    delete tempMailing.id;
    delete tempMailing.counties;

    if (JSON.stringify(tempPrimary) === JSON.stringify(tempMailing))
      this.setState({ addressCheckBox: true });
    else this.setState({ addressCheckBox: false });

    dispatch(change(FORM_NAME, 'primary_id', addressFields.primary.id));
    dispatch(
      change(FORM_NAME, 'primary_address1', addressFields.primary.address1)
    );
    dispatch(
      change(FORM_NAME, 'primary_address2', addressFields.primary.address2)
    );
    dispatch(
      change(
        FORM_NAME,
        'primary_addressType',
        addressFields.primary.addressType
      )
    );
    dispatch(change(FORM_NAME, 'primary_city', addressFields.primary.city));
    dispatch(change(FORM_NAME, 'primary_state', addressFields.primary.state));
    dispatch(change(FORM_NAME, 'primary_zip', addressFields.primary.zip));
    dispatch(change(FORM_NAME, 'primary_county', addressFields.primary.county));
    dispatch(
      change(FORM_NAME, 'primary_counties', addressFields.primary.counties)
    );
    dispatch(change(FORM_NAME, 'mailing_id', addressFields.mailing.id));
    dispatch(
      change(FORM_NAME, 'mailing_address1', addressFields.mailing.address1)
    );
    dispatch(
      change(FORM_NAME, 'mailing_address2', addressFields.mailing.address2)
    );
    dispatch(
      change(
        FORM_NAME,
        'mailing_addressType',
        addressFields.mailing.addressType
      )
    );
    dispatch(change(FORM_NAME, 'mailing_city', addressFields.mailing.city));
    dispatch(change(FORM_NAME, 'mailing_state', addressFields.mailing.state));
    dispatch(change(FORM_NAME, 'mailing_zip', addressFields.mailing.zip));
    dispatch(change(FORM_NAME, 'mailing_county', addressFields.mailing.county));
  };

  getDocuments = (organizationalUnitId, addressFields, organizationalUnit) => {
    const {
      dispatch,
      session: { currentUser: { permissions } = {} } = {},
    } = this.props;
    const { canViewDocuments } = this.validatePermissions(permissions);

    const documentsData = {
      registeredDocumentId: null,
      documents: [],
    };

    if (!canViewDocuments) {
      this.initialize(
        dispatch,
        organizationalUnit,
        documentsData,
        addressFields
      );
      return;
    }

    dispatch(
      getOuDocuments(
        organizationalUnitId,
        (error, data = { documents: [] }) => {
          if (error) {
            const errorMessage =
              'Something wrong occurred, loading user documents please try again later.';
            message.error(errorMessage);
            return false;
          }
          this.setState({
            documentsData: data.documents,
          });
          documentsData.documents = this.getUserDocumentsData(data.documents);
          this.initialize(
            dispatch,
            organizationalUnit,
            documentsData,
            addressFields
          );
        }
      )
    );
  };

  getData = () => {
    const { dispatch, organizationalUnitId } = this.props;

    const organizationalUnit = {
      organizationalUnitId: organizationalUnitId,
    };

    const addressFields = {
      primary: {},
      mailing: {},
    };

    this.getAddressData(addressFields);

    dispatch(
      getOuData(organizationalUnitId, (error, data) => {
        if (error) {
          const errorMessage =
            'Something wrong occurred, please try again later.';
          message.error(errorMessage);
          return false;
        }
        this.setState({ tenantSlug: data.tenantSlug });
        organizationalUnit.organizationName = data.displayName;
        organizationalUnit.website = data.website;
        organizationalUnit.phoneNumber = data.phoneNumber;
        organizationalUnit.parentOU = data.ouParentDisplayName;
        organizationalUnit.parentOUId = data.ouParentId;
        organizationalUnit.createdAt = data.createdAt;
        organizationalUnit.updatedAt = data.updatedAt;
        this.getDocuments(
          organizationalUnitId,
          addressFields,
          organizationalUnit
        );
      })
    );
  };

  initialize = (dispatch, organizationalUnit, documentsData, addressFields) => {
    dispatch(
      initialize(FORM_NAME, {
        organizationName: organizationalUnit.organizationName || '',
        website: organizationalUnit.website || '',
        phoneNumber: organizationalUnit.phoneNumber || '',
        parentOU: organizationalUnit.parentOU || '',
        parentOUId: organizationalUnit.parentOUId || '',
        createdAt: organizationalUnit.createdAt || '',
        updatedAt: organizationalUnit.updatedAt || '',
        documentsData,
        primary_id: addressFields.primary.id,
        primary_address1: addressFields.primary.address1,
        primary_address2: addressFields.primary.address2,
        primary_city: addressFields.primary.city,
        primary_state: addressFields.primary.state,
        primary_addressType: 'Primary',
        primary_zip: addressFields.primary.zip,
        primary_county: addressFields.primary.county,
        primary_counties: addressFields.primary.counties,
        mailing_id: addressFields.mailing.id,
        mailing_address1: addressFields.mailing.address1,
        mailing_address2: addressFields.mailing.address2,
        mailing_addressType: 'Mailing',
        mailing_city: addressFields.mailing.city,
        mailing_state: addressFields.mailing.state,
        mailing_zip: addressFields.mailing.zip,
        mailing_county: addressFields.mailing.county,
        mailing_counties: addressFields.mailing.counties,
      })
    );
    this.setDoneLoading();
    this.registerDocumentSpace(organizationalUnit.organizationalUnitId);
  };

  getUserDocumentsData = (documents = []) => {
    const {
      session: {
        currentUser: {
          agency: { timezone = '' },
        },
      },
    } = this.props;

    const formatedData = documents.map(document => {
      const createdAt = parseDate(
        document.createdAt,
        timezone,
        'MM/DD/YYYY, HH:mm'
      );
      return {
        ...document,
        uploaded: document.createdBy
          ? `${createdAt} by ${document.createdBy} `
          : createdAt,
      };
    });

    const keys = Object.keys(
      omit(formatedData[0], [
        'createdAt',
        'updatedAt',
        'createdBy',
        'lastModifiedBy',
      ])
    );
    return formatedData.reduce(
      (allData, document) => {
        return keys.reduce((data, key) => {
          data[key].push(document[key]);
          return data;
        }, allData);
      },
      keys.reduce((o, key) => ({ ...o, [key]: [] }), {})
    );
  };

  updateAddressCheckBox = () => {
    if (this.state.addressCheckBox == false) this.syncAddressFields();
    this.setState(prevState => ({
      addressCheckBox: !prevState.addressCheckBox,
    }));
  };

  renderOUPointsContact = (canView, canManage, agencyData) => {
    if (!canView && !canManage) return;

    const { organizationalUnitId } = this.props;
    return (
      <OrgProfilePointContactTable
        viewPointOfContact={canView}
        managePointContact={canManage}
        agencyData={agencyData}
        ouId={organizationalUnitId}
      />
    );
  };

  renderEmployeesSection = (
    organizationalUnitId,
    canViewEmployeesSection,
    tenantSlug
  ) => {
    if (canViewEmployeesSection)
      return (
        <OrganizationProfileEmployeeTable
          orgUnitId={organizationalUnitId}
          tenantSlug={tenantSlug}
        />
      );
  };

  validatePermissions = permissions => {
    return {
      canViewEmployeesSection: hasPermissions(permissions, [
        PERMISSIONS.listUsersByHK,
      ]),
      canViewOUPointOfContact: hasPermissions(permissions, [
        PERMISSIONS.viewOUPointOfContact,
      ]),
      canViewDocuments: hasPermissions(permissions, [
        PERMISSIONS.manageOUDocuments,
        PERMISSIONS.viewOUDocuments,
      ]),
      canManageDocuments: hasPermissions(permissions, [
        PERMISSIONS.manageOUDocuments,
      ]),
      canViewCommentsSection: hasPermissions(permissions, [
        PERMISSIONS.viewOUComments,
      ]),
      canManageCommentsSection: hasPermissions(permissions, [
        PERMISSIONS.manageOUComments,
      ]),
      canManageOUPointOfContact: hasPermissions(permissions, [
        PERMISSIONS.manageOUPointOfContact,
      ]),
    };
  };

  render() {
    const {
      organizationProfileForm,
      dispatch,
      session: {
        currentUser: {
          agency: { timezone },
          permissions = [],
        },
      },
      agency: { id },
      organizationalUnitId,
    } = this.props;

    const agencyData = {
      agencyId: id,
      orgUnitId: organizationalUnitId,
    };
    const {
      canViewEmployeesSection,
      canViewDocuments,
      canManageDocuments,
      canViewCommentsSection,
      canManageCommentsSection,
      canViewOUPointOfContact,
      canManageOUPointOfContact,
    } = this.validatePermissions(permissions);

    const OrganizationProfileForm = this.getConnectedForm();
    const title = translate(
      'containers.organizationProfile.organizationProfile'
    );

    return (
      <div>
        <PageHeader
          title={title}
          goBackTo={this.getGoBackTo()}
          actions={this.getHeaderActions()}
          onClick={() => dispatch(setOuListFromOrgProfile(true))}
        />
        <OrganizationProfileForm
          render={({ handleSubmit }) => (
            <form
              className="ant-form ant-form-horizontal"
              onSubmit={handleSubmit(this.onSubmit)}
            >
              <OrganizationInformation
                organizationProfileForm={organizationProfileForm}
                isLoading={this.isLoading}
                canManageAttributes={this.canChangeAttributes}
              />
              <OrganizationAddress
                selectOptions={this.state.stateDropdownOptions}
                checked={this.state.addressCheckBox}
                updateCheckBox={() => this.updateAddressCheckBox()}
              />

              {this.renderOUPointsContact(
                canViewOUPointOfContact,
                canManageOUPointOfContact,
                agencyData
              )}

              {this.renderEmployeesSection(
                this.props.organizationalUnitId,
                canViewEmployeesSection,
                this.state.tenantSlug
              )}

              <OrganizationSections
                dispatch={dispatch}
                organizationProfileForm={organizationProfileForm}
                isLoading={this.isLoading}
                timezone={timezone}
                formName={FORM_NAME}
                ouId={this.props.organizationalUnitId}
                canViewComments={canViewCommentsSection}
                canManageComments={canManageCommentsSection}
                canViewDocuments={canViewDocuments}
                canManageDocuments={canManageDocuments}
              />
            </form>
          )}
        />
      </div>
    );
  }

  getConnectedForm = () => {
    if (!this.connectedForm) {
      this.connectedForm = connectForm(FORM_NAME, this.onSubmit);
    }
    return this.connectedForm;
  };
}

const mapState = (state, props) => {
  const organizationalUnitId = get(props, 'match.params.organizationalUnitId');
  const organizationalUnitIdOnSession = get(
    state,
    'session.currentUser.organizationalUnitId'
  );
  const organizationProfileForm = getFormValues(state, FORM_NAME);
  const timezone = get(props, 'session.currentUser.agency.timezone');
  return {
    organizationalUnitId,
    organizationalUnitIdOnSession: organizationalUnitIdOnSession,
    organizationProfileForm,
    userProfile: {},
    timezone,
  };
};

export default withoutClutter(
  DashboardPage(mapState, null)(OrganizationProfile)
);
