import React, { useState, useEffect } from 'react';

import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from '../../components/custom-sections/sections/Sections.styled';
import { StyledRow } from '../people/People.styled';
import Col from 'antd/lib/col';
import Table from 'antd/lib/table';

import getOuPoc from './actions/get-ou-poc';
import { Button, message } from 'antd';
import PointOfContactModal from './PointOfContact/PointOfContactModal';
import SectionAddButton from '../../components/custom-sections/sections/SectionAddButton';
import { translate } from '../../i18next';

const { Column } = Table;

const OrgProfilePointContactTable = ({
  viewPointOfContact = false,
  managePointContact = false,
  agencyData = {},
  ouId = '',
}) => {
  const [pointContactList, setPointContactList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(50);
  const [contactData, setContactData] = useState({});

  useEffect(() => {
    fetchPointContactList();
  }, []);

  const fetchPointContactList = () => {
    getOuPoc(ouId, pageNumber, pageSize)
      .then(data => {
        setLoading(false);
        setPointContactList(data.data);
      })
      .catch(error => {
        message.error(
          'There was an error loading Point of Contact information'
        );
        setLoading(false);
        setPointContactList([]);
        return;
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const normalizeData = contactObject => {
    const phones = contactObject.phone.split('\n');
    const emails = contactObject.email.split('\n');
    return {
      ...contactObject,
      phone: phones[0],
      additionalPhoneNumber: phones[1],
      email: emails[0],
      addittionalEmail: emails[1],
    };
  };

  const onAddPointOfContactClick = () => {
    setIsModalVisible(true);
    setContactData({});
  };

  const onEditColumnClick = contactObjectData => {
    const contactInfo = normalizeData(contactObjectData);
    setContactData(contactInfo);
    setIsModalVisible(true);
  };

  const renderPointContactButton = () => {
    if (!managePointContact) return;
    return (
      <SectionAddButton
        onClick={() => onAddPointOfContactClick()}
        buttonTitle={translate(
          'containers.organizationProfile.addPointOfContact'
        )}
      />
    );
  };

  const editColumn = {
    render: (_, record) => (
      <a onClick={() => onEditColumnClick(record)}>
        {translate('containers.organizationProfile.edit')}
      </a>
    ),
  };

  return (
    <>
      <StyledCollapse
        bordered={false}
        defaultActiveKey=""
        expandIconPosition="right"
      >
        <StyledPanel
          header={
            <StyledPanelTitle size="large">
              {translate('containers.organizationProfile.pointsOfContact')}
            </StyledPanelTitle>
          }
          key="PointsContact"
          className="section-panel"
          extra={renderPointContactButton()}
        >
          <StyledRow>
            <Col span={24}>
              <div className="responsive-table">
                <Table
                  dataSource={pointContactList}
                  loading={loading}
                  pagination={false}
                  style={{ whiteSpace: 'pre' }}
                >
                  <Column
                    title={translate('containers.organizationProfile.name')}
                    dataIndex="name"
                    key="Name"
                  />
                  <Column
                    title={translate('containers.organizationProfile.title')}
                    dataIndex="title"
                    key="title"
                  />
                  <Column
                    title={translate(
                      'containers.organizationProfile.contactType'
                    )}
                    dataIndex="contactType"
                    key="contactType"
                  />
                  <Column
                    title={translate('containers.organizationProfile.phone')}
                    dataIndex="phone"
                    key="phone"
                  />
                  <Column
                    title={translate('containers.organizationProfile.email')}
                    dataIndex="email"
                    key="email"
                  />
                  <Column
                    title={translate('containers.organizationProfile.notes')}
                    dataIndex="notes"
                    key="notes"
                  />
                  <Column key="update" {...editColumn} />
                </Table>
              </div>
            </Col>
          </StyledRow>
        </StyledPanel>
      </StyledCollapse>
      <PointOfContactModal
        isModalVisible={isModalVisible}
        agencyData={agencyData}
        contactData={contactData}
        viewPointOfContact={viewPointOfContact}
        managePointContact={managePointContact}
        handleCancel={handleCancel}
        resetTableData={fetchPointContactList}
      />
    </>
  );
};

export default OrgProfilePointContactTable;
