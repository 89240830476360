import React, { Component } from 'react';
import moment from 'moment';
import { Modal, Button, Icon, Row, Col, Upload, notification } from 'antd';
import { genOptions, route, fetchRequest } from 'APP_ROOT/utils/request';
import urlBuilder from 'APP_ROOT/utils/url-builder';
import storage from 'APP_ROOT/utils/storage';
import sectionModalCancel from '../../custom-sections/sections/sectionModalCancel';
import SectionAttribute from '../../custom-sections/sections/SectionAttribute';
import FILE_STATUSES from '../enums/file-statuses.constants';
import { get } from 'lodash';
import {
  ATTRIBUTE_TYPE_TEXTAREA,
  ATTRIBUTE_TYPE_STRING,
} from '../../custom-sections/sections/constants';
import { connect } from 'react-redux';
import { translate } from '../../../i18next';
//TODO: Call the deleteDocument function when the delete feature is implemented.
//import { deleteDocument } from '../../../api/ou-documents';

import FileSizeLimit from '../../form-viewer/forms/utils/fileSizeLimit';
import AttachmentsWarningImportant from '../../common/attachments-warning-important';

const documentTypes = [
  'containers.organizationProfile.procedures',
  'containers.organizationProfile.guidelines',
  'containers.organizationProfile.regulations',
  'containers.organizationProfile.other',
];

const [DONE, ERROR] = FILE_STATUSES;

const PREFIX = 'documentsData';
const ATTRIBUTE_ID = 'id';
const uploadErrorMessage = translate(
  'containers.organizationProfile.uploadErrorMessage'
);
class OrganizationDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editForm: null,
      isNew: false,
      currentRecords: [],
      uploadInputKey: 'uploadInput',
      hasUploadedFile: false,
      fileList: [],
      attachmentId: null,
    };
  }

  /**
   * Calls node-api to get S3 ready link to upload a file
   * It also gets attachment information of the upload link
   * @param fileName String of the file name to upload
   * @param fileType String of the data type of the file
   * @param fileSize Number describing byte size of the file
   * @returns {Promise<{postUrl: (string), attachmentId: (number)}>}
   */
  getUploadURL = async (fileName, fileType, fileSize) => {
    const options = genOptions(
      'POST',
      {
        fileName,
        fileType,
        fileSize,
      },
      storage.get('token')
    );
    const { organizationProfileForm } = this.props;
    const { registeredDocumentId: id } = get(
      organizationProfileForm,
      'values.documentsData'
    );
    const request = route(
      urlBuilder('/OrganizationalUnitDocuments/:id/upload/request-url', {
        id,
      })
    );
    const response = await fetchRequest(request, options);
    if (!response || !response.url) {
      throw Error(translate('containers.organizationProfile.unableToUpload'));
    }
    return {
      postUrl: response.url,
      attachmentId: response.attachmentId,
    };
  };
  /**
   * Custom method that sends file to S3.
   * By default, antd (ant design) sends a POST request on their Upload element
   * and S3 needs a PUT request + with no authorization headers
   * @param antdUploadElementData
   */
  uploadOverride = async antdUploadElementData => {
    const { file, onError, onProgress, onSuccess } = antdUploadElementData;
    const { postUrl, attachmentId } = await this.getUploadURL(
      file.name,
      file.type,
      file.size
    );
    onProgress({ percent: 0 });
    const xhr = new XMLHttpRequest();
    const response = await new Promise(resolve => {
      xhr.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        }
      });
      xhr.addEventListener('loadend', () => {
        resolve(xhr.readyState === 4 && xhr.status === 200);
      });
      xhr.open('PUT', postUrl, true);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    });
    if (response) {
      const { organizationProfileForm } = this.props;
      const { registeredDocumentId: id } = get(
        organizationProfileForm,
        'values.documentsData'
      );
      // S3 uploads do not return node-api attachment record
      const options = genOptions('GET', {}, storage.get('token'));
      const getAttachmentRoute = route(
        urlBuilder(
          '/OrganizationalUnitDocuments/:id/attachments/:attachmentId',
          {
            id,
            attachmentId,
          }
        )
      );
      const attachment = await fetchRequest(getAttachmentRoute, options);
      onSuccess(attachment);
    } else {
      onError(
        new Error(
          translate('containers.organizationProfile.unableToGetResponse')
        )
      );
    }
  };

  onBeforeUpload = file => {};

  componentDidUpdate(prevProps) {
    if (!this.props.visible && this.props.visible !== prevProps.visible) {
      this.setState({ hasUploadedFile: false }, this.resetUploadInput);
    }
  }

  getCurrentValue = () => {
    return get(
      this.props.organizationProfileForm,
      `values.documentsData.documents.documentType[${this.props.rowIndex}]`
    );
  };

  resetUploadInput = () => {
    const newKey = `uploadInput-${Math.random() * 10}`;
    this.setState({ uploadInputKey: newKey });
  };

  getURL = () => {
    const { organizationProfileForm } = this.props;
    const { registeredDocumentId: id } = get(
      organizationProfileForm,
      'values.documentsData'
    );
    return route(
      urlBuilder('/OrganizationalUnitDocuments/:id/upload', {
        id,
      })
    );
  };

  getDownloadURL = fileId => {
    const { organizationProfileForm } = this.props;
    const { registeredDocumentId: id } = get(
      organizationProfileForm,
      'values.documentsData'
    );
    return route(
      urlBuilder(
        '/OrganizationalUnitDocuments/:id/download/request-url?id=:fileId',
        {
          id,
          fileId,
        }
      )
    );
  };

  onChange = ({ file = {} }) => {
    const { status, response = {} } = file;
    const { rowIndex, updateSectionField, currentUserFullName } = this.props;
    switch (status) {
      case DONE:
        this.setState({
          hasUploadedFile: true,
          fileList: [file],
          attachmentId: response.id,
        });
        const locationUrl = this.getDownloadURL(response.id);
        updateSectionField(
          `documentsData.documents.locationUrl[${rowIndex}]`,
          locationUrl
        );
        updateSectionField(
          `documentsData.documents.fileName[${rowIndex}]`,
          response.name || response.originalFilename
        );
        updateSectionField(
          `documentsData.documents.uploaded[${rowIndex}]`,
          `${moment().format('MM/DD/YYYY, HH:mm')} by ${currentUserFullName}`
        );
        break;
      case ERROR:
        notification.error({
          message: translate('containers.organizationProfile.uploadError'),
          description: uploadErrorMessage,
        });
        break;
      default:
        break;
    }
  };

  onRemoveFileOnStorage = () => {
    const { rowIndex, updateSectionField } = this.props;
    /* TODO: Call the deleteDocument function when the delete feature is implemented.
    const { attachmentId } = this.state;
    const { registeredDocumentId: id } = get(
      organizationProfileForm,
      "values.documentsData"
    );
    if (attachmentId) {
      deleteDocument(id, attachmentId)
      .then(data => {
      }).catch(err => {
      });
    }
    */
    updateSectionField(`documentsData.documents.fileName[${rowIndex}]`, '');
    this.setState({ hasUploadedFile: false, documentId: null, fileList: [] });
  };

  saveUpload = () => {
    //No action
  };

  getRowIndex = key => {
    const { organizationProfileForm, sectionId } = this.props;
    const keys = get(
      organizationProfileForm,
      `values.${PREFIX}.${sectionId}.${ATTRIBUTE_ID}`,
      []
    );
    return keys.findIndex(k => k === key);
  };

  onCancelEdit = () => {
    const { editForm } = this.state;
    const props = {
      updateSectionField: this.props.updateSectionField,
      removeSectionFieldValue: this.props.removeSectionFieldValue,
      attributes: this.props.sectionAttributes,
      organizationProfileForm: this.props.organizationProfileForm,
      prefix: PREFIX,
      sectionId: this.props.sectionId,
      attributeId: ATTRIBUTE_ID,
    };

    sectionModalCancel(editForm.isNew, editForm.record, props);
    this.setState({ editForm: null });
  };

  onOkEdit = () => {
    this.setState({ editForm: null });
  };

  isValidDocument = () => {
    const { isNew, organizationProfileForm, rowIndex } = this.props;
    const { hasUploadedFile } = this.state;
    const documentType = get(
      organizationProfileForm,
      `values.documentsData.documents.documentType[${rowIndex}]`
    );

    if (isNew && !hasUploadedFile) {
      notification.warning({
        message: translate('containers.organizationProfile.uploadFile'),
        description: translate(
          'containers.organizationProfile.pleaseUploadAFileBeforeApplyChanges'
        ),
      });
      return false;
    }
    if (!documentType) {
      notification.warning({
        message: translate('containers.organizationProfile.missingFileType'),
        description: translate(
          'containers.organizationProfile.pleaseSetTheFileType'
        ),
      });
      return false;
    }
    return true;
  };

  onModalOk = () => {
    const { onOk } = this.props;
    if (this.isValidDocument()) {
      this.setState({ editForm: null, isNew: false });
      onOk && onOk();
    }
  };

  onModalCancel = () => {
    const { onCancel } = this.props;
    this.setState({ editForm: null, isNew: false });
    onCancel && onCancel();
  };

  render = () => {
    const {
      title,
      visible,
      organizationProfileForm,
      rowIndex,
      readOnly,
      isNew,
    } = this.props;

    return (
      <Modal
        title={title}
        width={1000}
        visible={visible}
        onOk={this.onModalOk}
        onCancel={this.onModalCancel}
        footer={
          readOnly
            ? [
                <Button key="Done" type="primary" onClick={this.onModalOk}>
                  {translate('containers.organizationProfile.done')}
                </Button>,
              ]
            : [
                <Button key="cancel" onClick={this.onModalCancel}>
                  {translate('containers.organizationProfile.cancel')}
                </Button>,
                <Button key="Save" type="primary" onClick={this.onModalOk}>
                  {translate('containers.organizationProfile.applyChanges')}
                </Button>,
              ]
        }
      >
        {isNew && (
          <Row
            type="flex"
            justify="center"
            style={{ paddingBottom: '22px', paddingTop: '22px' }}
          >
            <Col>
              <Upload
                showUploadList={true}
                beforeUpload={this.onBeforeUpload}
                name="fileData"
                headers={{
                  Authorization: storage.get('token'),
                }}
                action={this.getURL()}
                onChange={this.onChange}
                onRemove={this.onRemoveFileOnStorage}
                customRequest={this.uploadOverride}
                ref={this.saveUpload}
                multiple={false}
                key={this.state.uploadInputKey}
                disabled={readOnly}
                maxCount={1}
              >
                <Button>
                  {translate('containers.organizationProfile.selectFile')}{' '}
                  <Icon type="upload" />
                </Button>
              </Upload>
            </Col>
          </Row>
        )}
        <Row type="flex" justify="center">
          <Col>
            <FileSizeLimit />
          </Col>
        </Row>
        <Row style={{ paddingBottom: '22px', paddingTop: '22px' }}>
          <Col xs={24}>
            <SectionAttribute
              key="key-ou-document-type"
              name={`documentsData.documents.documentType[${rowIndex}]`}
              title={translate('containers.organizationProfile.documentType')}
              type={ATTRIBUTE_TYPE_STRING}
              validValues={documentTypes.map(item => translate(item))}
              unique={false}
              organizationProfileForm={organizationProfileForm}
              isArray={false}
              readOnly={readOnly}
              data-test="ou-document-type"
            />
          </Col>
          <Col xs={24}>
            <SectionAttribute
              key="key-ou-document"
              name={`documentsData.documents.description[${rowIndex}]`}
              title={translate(
                'containers.organizationProfile.fileDescription'
              )}
              type={ATTRIBUTE_TYPE_TEXTAREA}
              validValues={null}
              unique={true}
              organizationProfileForm={organizationProfileForm}
              isArray={false}
              readOnly={readOnly}
              data-test="ou-document"
            />
          </Col>
        </Row>
        {!readOnly && (
          <Row className="row-important">
            <Col>
              <AttachmentsWarningImportant />
            </Col>
          </Row>
        )}
      </Modal>
    );
  };
}

const mapState = state => {
  const currentUserFullName = get(state, 'session.currentUser.fullName', '');
  return { currentUserFullName };
};

export default connect(mapState)(OrganizationDocumentModal);
