import React from 'react';
import { DatePicker, Form } from 'antd';
import {
  STYLE_OPTIONS,
  ITEM_LAYOUT,
  EXPLICIT_LOWER_BOUNDARY_LABEL,
  EXPLICIT_UPPER_BOUNDARY_LABEL,
  EXPLICIT_LOWER_BOUNDARY_VALUE,
  EXPLICIT_UPPER_BOUNDARY_VALUE,
} from './constants';
import { BENCHMARK_DATE_FORMAT } from '../../../../utils/parse-date';

const { Item } = Form;

const ExplicitDateBoundary = ({
  lowerBoundary,
  upperBoundary,
  onDateChange,
}) => {
  return (
    <>
      <Item label={EXPLICIT_LOWER_BOUNDARY_LABEL} {...ITEM_LAYOUT}>
        <DatePicker
          value={lowerBoundary}
          format={BENCHMARK_DATE_FORMAT}
          style={STYLE_OPTIONS}
          onChange={date => onDateChange(EXPLICIT_LOWER_BOUNDARY_VALUE, date)}
          showToday={false}
        />
      </Item>
      <Item label={EXPLICIT_UPPER_BOUNDARY_LABEL} {...ITEM_LAYOUT}>
        <DatePicker
          value={upperBoundary}
          format={BENCHMARK_DATE_FORMAT}
          style={STYLE_OPTIONS}
          onChange={date => onDateChange(EXPLICIT_UPPER_BOUNDARY_VALUE, date)}
          showToday={false}
        />
      </Item>
    </>
  );
};

export default ExplicitDateBoundary;
