import React, { Component } from 'react';
import { Tag, Row, Col } from 'antd';
import { omit, isEmpty } from 'lodash';
import { connect } from 'react-redux';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import withModal from 'APP_COMPONENTS/common/modal/base';

import Styled from './GroupEditor.styled';
import PickableZone from '../PickableZone';
import IconButton from '../../../../components/common/buttons/icon-button';
import componentsManager from '../../services/componentsManager';
import { GROUP } from 'APP_ROOT/constants/layoutComponentTypes';
import getFieldLabel from '../../utils/getFieldLabel';

import buildOptionsModalContent from '../../utils/buildOptionsModalContent';
import getReviewerCssClass from '../../utils/getReviewerCssClass';

import {
  TAB_VALUES,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
  TAB_DATE_BOUNDARY,
} from '../OptionsModalBody/OptionsModalBody';
class GroupEditor extends withModal(Component) {
  constructor(props) {
    super(props);

    this.state = {
      values: props.field,
    };
  }

  componentDidMount() {
    this.createModal();
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(this.props, nextProps);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      values: props.field,
    };
  }

  remove = () => {
    const { id } = this.props;
    componentsManager.removeComponent(id);
  };

  showOptionsModal = () => {
    const { values } = this.state;
    const { field } = this.props;

    const fieldsOptions = {
      id: values.id,
      fieldKey: values.key,
      reportingKey: {
        type: 'text',
        label: 'Reporting Key',
        value: values.reportingKey,
        maxLength: 100,
        placeholder: 'Reporting key',
      },
      disableTab: [
        TAB_VALUES,
        TAB_SHARE_KEY,
        TAB_INFORMATION,
        TAB_DATE_BOUNDARY,
      ],
      type: values.type,
      conditions: values.conditions,
    };

    const modalData = buildOptionsModalContent({
      ...fieldsOptions,
      onCancel: this.deleteModal,
      onDelete: () => {
        this.remove();
        this.deleteModal();
      },
      onSave: params => {
        const modifiedValues = Object.assign(
          {},
          omit(params, ['disableTab', 'reportingKey']),
          { reportingKey: params.reportingKey.value }
        );

        this.setState({
          values: omit(modifiedValues, ['validations']),
        });

        componentsManager.editComponent(field.id, modifiedValues);

        this.deleteModal();
      },
    });

    this.updateModal(modalData);

    this.showModal();
  };

  render() {
    const {
      children,
      type,
      id,
      field: { reportingKey },
    } = this.props;
    const {
      values: { conditions },
    } = this.state;
    const reviewerCss = getReviewerCssClass(conditions);

    return (
      <Styled className={reviewerCss}>
        <Row type="flex" align="middle">
          <Col>
            <Tag className="uppercase">{getFieldLabel(GROUP)}</Tag>
          </Col>
          <Col>{reportingKey}</Col>
          <Col className="margin-left">
            {!isEmpty(conditions) && <Tag color="gold">Conditional</Tag>}
          </Col>
          <Col className="margin-left">
            <IconButton
              icon="setting"
              onClick={this.showOptionsModal}
              className="is-big is-focusable"
            />
          </Col>
        </Row>
        <PickableZone type={type} id={id}>
          {children}
        </PickableZone>
      </Styled>
    );
  }
}

GroupEditor.displayName = 'FieldEditor.Group';

export default connect()(GroupEditor);
