import React from 'react';
import { Icon } from 'antd';
import { kebabCase, get, template as _template } from 'lodash';
import ContentLoader from 'react-content-loader';
import { BLUE } from '../config/theme';
import { translateExpression } from '../i18next';

const showLoader = () => {
  const loader = (
    <ContentLoader
      height={16}
      width={200}
      speed={2}
      primaryColor={'#f3f3f3'}
      secondaryColor={'#ecebeb'}
    >
      <rect x="0" y="3" rx="3" ry="3" width="70" height="10" />
      <rect x="80" y="3" rx="3" ry="3" width="100" height="10" />
      <rect x="190" y="3" rx="3" ry="3" width="10" height="10" />
    </ContentLoader>
  );

  return <div className="is-loading-animation">{loader}</div>;
};

const getFormLink = (linkedRef, formNumber) => (
  <a
    href={linkedRef}
    style={{
      marginLeft: 4,
      textDecoration: 'underline',
      color: BLUE,
      fontWeight: 'bold',
    }}
  >
    {formNumber}
  </a>
);

const getFormattedMessage = (
  props,
  template,
  linkedFormTemplateType,
  linkedFormTemplate
) => {
  const {
    linkedForm: firstLinkedForm,
    report: { formType = '', templateName = '', templateId },
  } = props;

  let defaultLinkedMessageFormat =
    ':icon: This <% print(templateName) %> involves a <% print(linkedFormName) %> report :linkedFormLink:';

  defaultLinkedMessageFormat = translateExpression(
    defaultLinkedMessageFormat,
    'containers.reports.reportCard'
  );

  const linkedFormTemplateName =
    get(linkedFormTemplate, 'name') || get(linkedFormTemplate, 'label', '');
  const linkedFormTemplateId = get(firstLinkedForm, 'templateId', '');
  const linkedMessageFormat = get(
    template,
    'linkedMessageFormat',
    defaultLinkedMessageFormat
  );
  const linkedFormShortName = get(
    linkedFormTemplate,
    'shortName',
    linkedFormTemplateType
  );

  const formShortName = get(template, 'shortName', formType);

  const messageFormat = _template(linkedMessageFormat);
  return messageFormat({
    formType,
    templateName,
    formTemplateId: templateId,
    formShortName,
    linkedFormType: linkedFormTemplateType,
    linkedFormName: linkedFormTemplateName,
    linkedFormShortName,
    linkedFormTemplateId,
  }).split(' ');
};

const processMessage = (message, linkedFormLink, formLink) => {
  const reservedNames = [':linkedFormLink:', ':formLink', ':icon:'];
  const chainsIcon = (
    <Icon
      type="link"
      style={{
        fontSize: 16,
        paddingRight: 8,
        color: BLUE,
      }}
    />
  );

  return (
    <div>
      {message.map((word, wordIndex) => {
        if (reservedNames.includes(word)) {
          switch (word) {
            case reservedNames[0]:
              return <span key={wordIndex}>{linkedFormLink} </span>;
            case reservedNames[1]:
              return <span key={wordIndex}>{formLink} </span>;
            case reservedNames[2]:
              return <span key={wordIndex}>{chainsIcon} </span>;
            default:
              return <span key={wordIndex}>{word} </span>;
          }
        }

        return <span key={wordIndex}>{word} </span>;
      })}
    </div>
  );
};

const getLinkedFormTemplate = props => {
  const {
    linkedForm: firstLinkedForm,
    report: { formType = '', templateId, ...currentForm },
    templates = {},
    formLinkRef,
    linkedFormLinkRef,
  } = props;

  if (firstLinkedForm) {
    const linkedFormTemplateType = get(firstLinkedForm, 'formType', '');
    const linkedFormTemplate = get(
      templates,
      kebabCase(linkedFormTemplateType),
      null
    );
    const template = get(templates, kebabCase(formType), null);

    if (!template || !linkedFormTemplate) {
      return showLoader();
    }

    const linkedFormLink = getFormLink(
      linkedFormLinkRef,
      firstLinkedForm.formNumber
    );
    const formLink = getFormLink(formLinkRef, currentForm.formNumber);
    const message = getFormattedMessage(
      props,
      template,
      linkedFormTemplateType,
      linkedFormTemplate
    );

    return processMessage(message, linkedFormLink, formLink);
  }

  return <span />;
};

export default getLinkedFormTemplate;
