import {
  hyperlinkRenderCourseTitle,
  hyperLinkRenderRegistration,
  noHyperLink,
} from './UserTrainingHyperlinkRenders';
import UserTrainingStatusFilter from './UserTrainingStatusFilter';

export const userMergeTrainingTableColumns = (hyperlinks = false) => {
  return [
    {
      title: 'Source',
      dataIndex: 'source',
      notFilterable: true,
    },
    {
      title: 'Agency',
      dataIndex: 'agencyName',
    },
    {
      title: 'Course Title',
      dataIndex: 'courseName',
      render: hyperlinks ? hyperlinkRenderCourseTitle : noHyperLink,
    },
    {
      title: 'Category',
      dataIndex: 'categoryNames',
      render: (_, record) =>
        record.categoryNames ? record.categoryNames.join('; ') : '',
    },
    {
      title: 'Registration ID',
      dataIndex: 'kmiRegistrationId',
      render: hyperlinks ? hyperLinkRenderRegistration : noHyperLink,
    },
    UserTrainingStatusFilter,
    {
      title: 'Date Completed',
      dataIndex: 'completionDate',
    },
    {
      title: 'Hours',
      dataIndex: 'totalHours',
    },
    {
      title: 'Score',
      dataIndex: 'courseScore',
      notFilterable: true,
    },
  ];
};
