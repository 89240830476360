import React from 'react';
import { change } from 'redux-form';
import { ReportButton } from './NewReportButton.styled';

import {
  NEW_REPORT_ON_CASE_FILE_REDUX_PROP,
  NEW_REPORT_ON_CASE_FILE_REDUX_VALUE,
} from './constants';

import resetOnBehalf from '../../../../actions/reset-onBehalf';
import emitter, { EventTypes } from '../../../../utils/eventEmitter';

const NewReportButton = ({ dispatch, casefileId, casefileTitle, disabled }) => {
  const handleNewReportClick = e => {
    e.stopPropagation();
    dispatch(
      change(
        NEW_REPORT_ON_CASE_FILE_REDUX_PROP,
        NEW_REPORT_ON_CASE_FILE_REDUX_VALUE,
        { casefileId, casefileTitle }
      )
    );

    dispatch(resetOnBehalf());
    emitter.emit(EventTypes.CREATE_REPORT);
  };

  return (
    <ReportButton
      type="primary"
      disabled={disabled}
      onClick={handleNewReportClick}
      icon="plus"
    >
      New Report
    </ReportButton>
  );
};

export default NewReportButton;
