import { PERMISSIONS } from './admin';

export const listAllAgenciesPermissions = [
  PERMISSIONS.manageAgencies,
  PERMISSIONS.updateAnyUser,
  PERMISSIONS.createAnyUser,
  PERMISSIONS.viewAllReports,
  PERMISSIONS.viewAllAuditLogs,
  PERMISSIONS.accessAnalyticsAdmin,
];

export const displayAgencyPermissions = [
  PERMISSIONS.manageWorkflows,
  PERMISSIONS.accessTrainingAdminPanel,
  PERMISSIONS.accessAnalyticsReports,
  PERMISSIONS.createAgencyUser,
  PERMISSIONS.updateAgencyUser,
  PERMISSIONS.createRole,
  PERMISSIONS.viewAgencyAuditLogs,
  PERMISSIONS.searchDocumentMetadata,
  PERMISSIONS.manageRule28DataExport,
  PERMISSIONS.manageReportDataExport,
  PERMISSIONS.manageTMSLiveEvents,
  PERMISSIONS.manageCaseFileTypes,
];

export const adminPermissionsForSideBarOption = [
  ...listAllAgenciesPermissions,
  ...displayAgencyPermissions,
];
