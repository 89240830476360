import moment from 'moment-timezone';
import { isMoment } from 'moment/moment';

// Default format for all benchmark dates.
export const BENCHMARK_DATE_TIMEZ_FORMAT = 'MM/DD/YYYY, HH:mm:ss z';
export const BENCHMARK_DATE_TIMEZ_FORMAT_WITH_PREPOSITION =
  '[on] MM/DD/YYYY [at] HH:mm:ss z';
export const BENCHMARK_DATE_TIMEZ_FORMAT_ISO = 'YYYY-MM-DDTHH:mm:ss.000Z';
export const BENCHMARK_DATE_TIMEZ_FORMAT_HUMAN = 'MMMM D, YYYY h:mm z';
export const BENCHMARK_DATE_TIME_FORMAT = 'MM/DD/YYYY, HH:mm:ss';
export const BENCHMARK_DATE_TIME_FORMAT_WO_COMMA = 'MM/DD/YYYY HH:mm:ss';
export const BENCHMARK_DATE_FORMAT = 'MM/DD/YYYY';
export const BENCHMARK_DATE_FORMAT_HYPHEN = 'YYYY-MM-DD';
export const BENCHMARK_TIME_FORMAT = 'HH:mm';
export const BENCHMARK_TIMEZ_FORMAT = 'HH:mm z';
export const BENCHMARK_TIMEA_FORMAT = 'hh:mm a';
export const BENCHMARK_BIRTHDAY_FORMAT = 'MM/DD/YY';
export const BENCHMARK_COMPLETE_BIRTHDAY_FORMAT = 'MM/DD/YYYY';
export const BENCHMARK_DATE_FORMAT_HUMAN = 'MMMM D, YYYY';

/**
 * @param date
 * @param {string} timezone
 * @param {string} format
 * @param {boolean} useTimezone
 * @return {object} A moment instance with the applied timezone.
 */
export function momentWithTZ(date, timezone, format, useTimezone = true) {
  const isBirthdayFormat = typeof date === 'string' && date.length === 8;
  const dateStringFormat = format
    ? format
    : isBirthdayFormat
    ? BENCHMARK_DATE_FORMAT
    : undefined;

  if (timezone && useTimezone) {
    return moment(date, dateStringFormat).tz(timezone);
  } else if (!useTimezone) {
    return moment(date, dateStringFormat);
  }

  throw new Error('the timezone argument is required.');
}

/**
 * @param {Date|string} date
 * @param {string} timezone
 * @param {string} format
 * @param {boolean} useTimezone
 * @return {object} A moment instance with an applied timezone and format.
 */
export default function parseDate(
  date,
  timezone = '',
  format = BENCHMARK_DATE_TIMEZ_FORMAT,
  useTimezone = true
) {
  if (!timezone) {
    timezone = 'Etc/UTC';
  }
  return momentWithTZ(date, timezone, '', useTimezone).format(format);
}

/**
 * @param {Date|string} date
 * @param {string} format
 *
 * @return {object} A moment instance with an applied timezone and format.
 */
export function parseDateNoTimeZone(
  date,
  format = BENCHMARK_DATE_TIMEZ_FORMAT
) {
  return moment.utc(date).format(format);
}

/**
 * @param {Date|string} date
 * @param {string} timezone
 * @param {dateTimezone} dateTimezone Optional.
 *
 * @return {object} A moment instance with an applied timezone.
 */
export const parseDateNoFormat = (date, timezone = null) =>
  momentWithTZ(date, timezone, '', true);

/**
 * @param {Date|string} date
 * @param {string} timezone
 * @param {string} format
 *
 * @return {object} A moment instance with an applied timezone.
 */
export const parseDateFromFormat = (date, timezone = null, format) =>
  momentWithTZ(date, timezone, format);

/**
 * @param {Date|string} date
 *
 * @return {object} A moment instance as utc.
 */
export const formatDate = date => {
  return moment(date).utc();
};

/**
 * @param {Date|string} date
 * @param {string} format
 * @return {object} A moment instance of utc string.
 */
export const formatDateString = (date, format) => {
  return moment(date)
    .utc()
    .format(format);
};

export const formatDateStringOrTimestamp = (datetime, format) => {
  if (isMoment(datetime)) {
    return datetime.format(format);
  }

  const dateTimeMoment = moment(datetime, format, true);

  if (dateTimeMoment.isValid()) {
    return dateTimeMoment.format(format);
  } else {
    const parsedIsoTimestamp = moment(datetime, moment.ISO_8601, true);

    if (parsedIsoTimestamp.isValid()) {
      return parsedIsoTimestamp.format(format);
    } else {
      return datetime;
    }
  }
};

/**
 * @param {Date|string} date
 * @param {string} format
 *
 * @return {object} A moment instance of utc with only dates.
 */
export const readOnlyFormatDateNoTimeZone = (date, format) => {
  const dateNoTimeFormat = date?.split('T')[0].replace(/-/g, '') || '';
  return moment(dateNoTimeFormat)
    .utc()
    .format(format);
};
