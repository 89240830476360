import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import DashboardPage from '../../../components/dashboard';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import If from 'APP_COMPONENTS/utils/ConditionalRender';
import withModal from 'APP_COMPONENTS/common/modal/base.js';

import AdministratorWrapper from '../Administrator.styled';
import AdminMenuButton from './AdminMenuButton';

import { hasPermissions } from 'APP_ROOT/utils/admin';
import getRoute from 'APP_ROOT/utils/get-route';

import getAgency from './actions/get-agency-info';
import getLmsUrl from 'APP_ROOT/utils/getLmsUrl';
import withOwnershipGuard from '../components/agency-ownership-guard';
import { PERMISSIONS } from '../../../utils/admin';
import selectModalOptions from '../AgencySelectAuditLogsModal';

export class Component extends withModal(React.Component) {
  componentDidMount() {
    const { dispatch, agencyId } = this.props;

    dispatch(getAgency(agencyId));
    this.createModal();
  }

  getAuditsTile(agencyId, tenantId) {
    const {
      history: { push },
    } = this.props;
    const modalMethods = {
      showModal: this.showModal,
      updateModal: this.updateModal,
      hideModal: this.hideModal,
    };
    return (
      //the <a> is wrapping the button to avoid deforming the tile size
      <a>
        <AdminMenuButton
          icon="audit"
          label="Audit Logs"
          onClick={() =>
            selectModalOptions(modalMethods, push, { agencyId, tenantId })
          }
        />
      </a>
    );
  }

  render() {
    const {
      session: {
        currentUser: {
          permissions = [],
          agency: { name: userAgencyName = '' },
        },
      },
      agencyProfile,
      agencyId,
      isLoadingAgencyProfile,
    } = this.props;
    const isSuperAdmin = hasPermissions(
      permissions,
      PERMISSIONS.manageAgencies
    );
    const agencyName = get(agencyProfile, 'name', '');
    const tenantId = get(agencyProfile, 'integrationId', '');
    const loadingSpan = <span>&nbsp;</span>;

    return (
      <AdministratorWrapper>
        <PageHeader
          title={
            isLoadingAgencyProfile
              ? loadingSpan
              : isSuperAdmin
              ? agencyName
              : userAgencyName
          }
          showBackButton={isSuperAdmin}
          goBackTo="/administrator"
          loading={isLoadingAgencyProfile}
        />
        <div className="admin-container">
          <Row gutter={[20, 20]}>
            <If
              drillProps
              condition={hasPermissions(permissions, [
                PERMISSIONS.createAnyUser,
                PERMISSIONS.createAgencyUser,
                PERMISSIONS.updateAnyUser,
                PERMISSIONS.updateAgencyUser,
              ])}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('administratorAgencyUsers', { agencyId })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton icon="team" label="Users" />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={
                isSuperAdmin ||
                hasPermissions(permissions, PERMISSIONS.accessAnalyticsAdmin)
              }
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link to={'/admin-reporting'}>
                  <AdminMenuButton icon="line-chart" label="Reporting" />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(
                permissions,
                PERMISSIONS.manageWorkflows
              )}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('administratorAgencyWorkflows', { agencyId })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton icon="cluster" label="Workflows" />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(
                permissions,
                PERMISSIONS.accessTrainingAdminPanel
              )}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <a href={getLmsUrl('')} disabled={isLoadingAgencyProfile}>
                  <AdminMenuButton icon="project" label="Training" />
                </a>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(permissions, PERMISSIONS.manageForms)}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('administratorAgencyFormBuilderList', {
                    agencyId,
                  })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton icon="layout" label="Form Builder" />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(permissions, [
                PERMISSIONS.createAnyUser,
                PERMISSIONS.createAgencyUser,
              ])}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('administratorAgencyBulkUsersImport', {
                    agencyId,
                  })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton
                    icon="usergroup-add"
                    label="Bulk User Import"
                  />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(permissions, PERMISSIONS.createRole)}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('administratorAgencyRolesPermissions', {
                    agencyId,
                  })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton icon="key" label="Roles &amp; Permissions" />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(
                permissions,
                PERMISSIONS.viewAllReports
              )}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('administratorAgencyReports', {
                    agencyId,
                  })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton icon="line-chart" label="Reports" />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(permissions, [
                PERMISSIONS.viewAgencyAuditLogs,
                PERMISSIONS.viewAllAuditLogs,
              ])}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('administratorAgencyAuditLogs', {
                    agencyId,
                  })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton icon="solution" label="SSN Audit Logs" />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(permissions, [
                PERMISSIONS.viewAgencyAuditLogs,
                PERMISSIONS.viewAllAuditLogs,
              ])}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                {this.getAuditsTile(agencyId, tenantId)}
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(permissions, [
                PERMISSIONS.searchDocumentMetadata,
              ])}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('administratorAgencyMigratedFiles', {
                    agencyId,
                    tenantId,
                  })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton icon="file-search" label="Migrated Files" />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(permissions, [
                PERMISSIONS.manageRule28DataExport,
                PERMISSIONS.manageReportDataExport,
              ])}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('exportReports', {
                    agencyId,
                  })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton icon="file-search" label="Data Exports" />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(
                permissions,
                PERMISSIONS.manageTMSLiveEvents
              )}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('administratorAgencyLiveEventCheckIn', {
                    agencyId,
                  })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton icon="qrcode" label="Live Event Check-in" />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(permissions, [
                PERMISSIONS.manageCaseFileTypes,
              ])}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('administratorAgencyCasefiles', {
                    agencyId,
                    tenantId,
                  })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton icon="solution" label="Case Files" />
                </Link>
              </Col>
            </If>
            <If
              drillProps
              condition={hasPermissions(permissions, [
                PERMISSIONS.manageAgencyAttributes,
              ])}
            >
              <Col xs={12} md={8} lg={6} xl={4}>
                <Link
                  to={getRoute('administratorAgencyBranding', {
                    agencyId,
                    tenantId,
                  })}
                  disabled={isLoadingAgencyProfile}
                >
                  <AdminMenuButton icon="picture" label="Agency Branding" />
                </Link>
              </Col>
            </If>
          </Row>
        </div>
      </AdministratorWrapper>
    );
  }
}

const mapStateToProps = (state, { match }) => {
  const agencyId = get(match, 'params.agencyId');

  return {
    agencyProfile: get(state, `agencyProfile.${agencyId}.data`, null),
    isLoadingAgencyProfile: get(
      state,
      `agencyProfile.${agencyId}.loading`,
      false
    ),
    agencyId,
  };
};

export default withoutClutter(
  DashboardPage(mapStateToProps)(withOwnershipGuard(Component))
);
