import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { notification } from 'antd';
import { get } from 'lodash';

import getRoute from 'APP_ROOT/utils/get-route';
import ChangesCaseFileDelete from '../services/changesCaseFileDelete';
import ModalBody from 'APP_ROOT/components/common/modal/body';

import caseFileEndpoints from '../../../api/caseFileEndpoints/caseFileEndpoints';
import fetchReadableStream from '../utils/fetchReadableStream';
import { translate } from '../../../i18next';

class Modal extends Component {
  componentDidMount() {
    this.submitSubscription = ChangesCaseFileDelete.getSubmit().subscribe(
      data => data && this.submit()
    );
  }

  componentWillUnmount() {
    this.submitSubscription.unsubscribe();
  }

  closeModal = () => {
    ChangesCaseFileDelete.setSubmit(false);
    ChangesCaseFileDelete.setCloseModal(false);
  };

  catchError = error => {
    if (error?.response?.body instanceof ReadableStream) {
      fetchReadableStream(error.response.body, data => {
        const { errors = [] } = data;
        const description = errors.map(e => e.message).join(', ');
        notification.error({
          message: translate('containers.caseFiles.somethingWentWrong'),
          description,
        });
      });
    } else {
      const description = error?.message || '';
      notification.error({
        message: translate('containers.caseFiles.somethingWentWrong'),
        description,
      });
    }
  };

  submit = async () => {
    const { history, id: caseFileId, title } = this.props;
    caseFileEndpoints
      .deleteCaseFile(caseFileId)
      .then(() => {
        notification.success({
          message: translate('containers.caseFiles.success'),
          description: (
            <p>
              {translate('containers.caseFiles.caseFile')}{' '}
              <strong>{title}</strong>{' '}
              {translate('containers.caseFiles.wasSuccessfullyDeleted')}
            </p>
          ),
        });
        history.push(getRoute('caseFileList'));
        this.closeModal();
      })
      .catch(error => {
        this.catchError(error);
        this.closeModal();
      });
  };

  render() {
    return (
      <ModalBody>
        <p>{translate('containers.caseFiles.deleteWarning')}</p>
        <br />
        <p>{translate('containers.caseFiles.areYouSure')}</p>
      </ModalBody>
    );
  }
}

const mapState = (state, props) => {
  return {
    id: get(state, 'forms.manageCaseFile.values.id', ''),
    title: get(state, 'forms.manageCaseFile.values.title', ''),
  };
};

export default withRouter(connect(mapState)(Modal));
