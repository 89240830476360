import {
  get,
  first,
  kebabCase,
  isPlainObject,
  keys,
  mergeWith,
  pick,
  has,
  omit,
  merge,
  isEmpty,
} from 'lodash';

import {
  FETCH_FORM,
  FETCH_FORM_SUCCESS,
  FETCH_FORM_ERROR,
  GET_FORM_PRESENTATION,
  GET_FORM_TEMPLATES,
  GET_FORM_TEMPLATES_SUCCESS,
  GET_FORM_TEMPLATES_ERROR,
  CREATE_NEW_FORM,
  GET_IZENDA_REPORTS_REQUEST,
  GET_IZENDA_REPORTS_SUCCESS,
  GET_IZENDA_REPORTS_ERROR,
} from '../../actions';

import selectedFormInitialState from './state/selected-form';

import configureTemplatesEvents from '../../containers/administrator/configure-templates/ConfigureTemplates.reducer';

const getEvents = (schema = {}, context) => {
  const getCustomEvents = (item = {}) => {
    const { options: { events = null } = {}, type = '' } = item;
    const { key = '', ...rest } = item;
    const fieldKey = type === 'repeater' ? 'parentKey' : 'dataKey';

    if (key && events) {
      return events.reduce((result, listener) => {
        if (has(listener, 'event') && has(listener, 'trigger')) {
          return {
            ...result,
            [listener.event]: {
              ...get(result, listener.event, {}),
              [listener.trigger]: {
                [fieldKey]: key,
                ...rest,
              },
            },
          };
        }

        return result;
      }, context);
    }

    return {};
  };

  const mapFields = (context = {}, accumulated = {}) => {
    const { properties = [] } = context;

    return properties.reduce((result, property, index) => {
      const combinedResult = merge(
        result,
        getCustomEvents(properties[index], result)
      );

      return {
        ...combinedResult,
        ...mapFields(properties[index], combinedResult),
      };
    }, accumulated);
  };

  return mapFields(get(schema, ['formSchema', 'form'], {}));
};

const events = {
  [FETCH_FORM]: state => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [FETCH_FORM_SUCCESS]: (state, { payload: { selectedForm: template } }) => {
    const type = kebabCase(template.type);

    const selectedForm = {
      ...selectedFormInitialState,
      template: type,
      presentation: type,
      fetched: true,
    };

    const templates = {
      ...get(state, ['templates'], {}),
      [type]: template,
    };

    const templateList = [
      ...get(state, ['templateList'], []).filter(item => item !== type),
      type,
    ];

    return {
      ...state,
      selectedForm,
      templates,
      templateList,
      isLoading: false,
    };
  },
  [FETCH_FORM_ERROR]: (state, { payload: { error, id } }) => {
    return {
      ...state,
      error,
      isLoading: false,
    };
  },
  [GET_FORM_PRESENTATION]: (
    state,
    {
      payload: {
        schema,
        type,
        formFields,
        formData,
        requirementCompliantFields,
      },
    }
  ) => {
    const presentations = {
      ...get(state, ['presentations'], {}),
      [type]: schema,
    };

    const getDefaultData = (fields, fFields) =>
      keys(fields).reduce((result, tab, index) => {
        return {
          ...result,
          ...keys(schema.fields[tab]).reduce((res, field, fieldIndex) => {
            const formField = fFields.fields[tab][field];
            const valRulesType = get(formField, 'validationRules[0].type');
            const schemaField = schema.fields[tab][field];
            res[field] =
              typeof valRulesType !== 'undefined' &&
              valRulesType === 'string' &&
              typeof schemaField !== 'undefined'
                ? schema.fields[tab][field] + []
                : schema.fields[tab][field];

            return res;
          }, {}),
        };
      }, {});

    const defaultData = getDefaultData(schema.fields, formFields);

    const processAssignedSections = (
      objValue = [],
      srcValue = [],
      withStructure = {}
    ) => {
      const assignedSection = srcValue.filter(
        section =>
          section.contributorFields &&
          Object.values(section.contributorFields).some(Boolean)
      );

      return assignedSection.map((section, index) =>
        mergeWith(
          {},
          !isEmpty(objValue) ? objValue[index] : {},
          section,
          withStructure
        )
      );
    };

    const handleArrayStructure = (
      objValue = [],
      srcValue = [],
      withStructure = {}
    ) => {
      const structure = first(objValue);
      const srcValueLength = !isEmpty(srcValue) ? srcValue.length : 1;
      const size =
        objValue.length >= srcValueLength ? objValue.length : srcValueLength;
      const arrayStructure = Array(size).fill(structure);

      return arrayStructure.map((item, index) =>
        mergeWith({}, item, srcValue[index], withStructure)
      );
    };

    const handleObjectStructure = (
      objValue = {},
      srcValue = {},
      withStructure = {}
    ) => {
      return mergeWith({}, objValue, srcValue, withStructure);
    };

    const withStructure = (objValue, srcValue, key, object, source, stack) => {
      if (key === '__assignedSections') {
        return processAssignedSections(objValue, srcValue, withStructure);
      }

      if (
        (Array.isArray(objValue) ||
          (objValue === undefined && Array.isArray(srcValue))) &&
        (isPlainObject(first(objValue)) || isPlainObject(first(srcValue)))
      ) {
        return handleArrayStructure(objValue, srcValue, withStructure);
      }

      if (
        isPlainObject(objValue) ||
        (objValue === undefined && isPlainObject(srcValue))
      ) {
        return handleObjectStructure(objValue, srcValue, withStructure);
      }

      return objValue !== undefined ? srcValue : objValue;
    };
    const currentFormData = get(state, ['selectedForm', 'data'], {});
    const whitelistedKeys = [
      '__users',
      'currentUserId',
      '__reports',
      'linkedFormSubmitted',
      'encrypted',
      '__assignedSections',
      '__assignedSectionsHistory',
    ];
    // Update __assignedSections with default repeater data if available
    const { __assignedSections = [] } = currentFormData;
    const repeaterAssignedSection = __assignedSections.filter(
      v => v.isRepeater
    );
    repeaterAssignedSection.forEach(section => {
      if (
        Object.keys(defaultData).includes(section.repeaterKey) &&
        !section.repeaterItemId
      ) {
        section.repeaterItemId = defaultData[section.repeaterKey][0].id;
      }
    });

    /**
     * Check if the form data has a valid data type in their calculated fields
     * and assign the value with the correct data type from the default value
     * if is needed, due to the default value fields has the correct calculated
     * fields value and type.
     * NOTE: it was included to avoid error when the value type of the
     * calculated field comes wrong.
     */
    const newFormData = keys(formData).reduce((res, field) => {
      const defaultFieldValue = get(defaultData, field);
      const defaultFieldType = typeof defaultFieldValue;
      const formDataValue = formData[field];
      res[field] =
        defaultFieldType === 'string' &&
        Number(defaultFieldValue) === Number(formDataValue)
          ? defaultFieldValue
          : formDataValue;

      return res;
    }, {});

    // when switches are conditional display, they should be undefined,
    // by default, however, at this point some of them have value (in
    // currentData) and not in presentation because currentData is taking
    // conditionals into account.
    // This method validate booleans to have the correct value
    const getNewDefaultData = (data, currentData) => (newData, key) => {
      const value = data[key];
      const currentValue = currentData[key];
      if (Array.isArray(value)) {
        newData[key] =
          currentValue && !isEmpty(currentValue)
            ? value.map((v, i) =>
                isPlainObject(v)
                  ? keys(v).reduce(getNewDefaultData(v, currentValue[i]), {})
                  : v
              )
            : value;
      } else {
        newData[key] = typeof currentValue === 'boolean' ? currentValue : value;
      }
      return newData;
    };

    const newDefaultData = keys(defaultData).reduce(
      getNewDefaultData(defaultData, currentFormData),
      {}
    );

    const data = mergeWith(
      pick(currentFormData, whitelistedKeys),
      newDefaultData,
      newFormData,
      withStructure
    );

    const userList = data.__users;
    const newUsers = Object.keys(userList).reduce((users, userId) => {
      if (
        has(userList[userId], ['height']) &&
        has(userList[userId], ['weight'])
      ) {
        users[userId] = {
          ...userList[userId],
          id: userList[userId].id + [],
          height: userList[userId].height + [],
          weight: userList[userId].weight + [],
        };
      } else {
        users[userId] = {
          ...userList[userId],
          id: userList[userId].id + [],
        };
      }
      return users;
    }, {});

    const templateSchema = get(state.templates, type, {});

    const events = {
      ...get(selectedFormInitialState, 'events', {}),
      ...getEvents(templateSchema),
    };

    const selectedForm = {
      ...get(state, ['selectedForm'], selectedFormInitialState),
      data: {
        ...data,
        __users: newUsers,
      },
      events,
      presentation: type,
      formFields: {
        ...formFields,
        requirementCompliantFields,
      },
    };

    return {
      ...state,
      presentations,
      selectedForm,
    };
  },
  [GET_FORM_TEMPLATES]: state => {
    const templatesMeta = {
      ...state.templatesMeta,
      loading: true,
    };

    return {
      ...state,
      templatesMeta,
    };
  },
  [GET_FORM_TEMPLATES_ERROR]: (state, { payload }) => {
    const templatesMeta = {
      ...state.templatesMeta,
      loading: false,
      error: payload,
    };

    return {
      ...state,
      templatesMeta,
    };
  },
  [GET_FORM_TEMPLATES_SUCCESS]: (state, { payload }) => {
    const templatesMeta = {
      ...state.templatesMeta,
      loading: false,
      fetched: true,
    };

    // Any template object information with the formSchema information will be kept.
    const customPayload = isEmpty(state.templates)
      ? payload
      : payload.map(function(payloadTemplate) {
          let templateKept = {};
          Object.values(state.templates).filter(function(localTemplate) {
            if (payloadTemplate.id === localTemplate.id) {
              localTemplate.versionId !== payloadTemplate.versionId ||
              isEmpty(localTemplate.formSchema)
                ? (templateKept = payloadTemplate)
                : (templateKept = localTemplate);
            }
          });
          return isEmpty(templateKept) ? payloadTemplate : templateKept;
        });

    const templateList = customPayload.map(template =>
      kebabCase(template.type)
    );
    const templates = customPayload.reduce((result, template) => {
      const type = kebabCase(template.type);

      return {
        ...result,
        [type]: template,
      };
    }, {});

    return {
      ...state,
      templates,
      templateList,
      templatesMeta,
    };
  },
  [CREATE_NEW_FORM]: (state, { payload: { template, currentUser: user } }) => {
    const {
      selectedForm: {
        data = {},
        validations = {},
        state: formState = {},
        presentation = {},
        fetched = false,
      } = {},
    } = state;

    const templateName = kebabCase(template);

    const { userId: currentUserId = '', ...currentUser } = user;

    const selectedForm = {
      ...selectedFormInitialState,
      validations,
      state: formState,
      fetched,
      presentation,
      template: templateName,
      data: {
        ...data,
        __users: {
          [currentUserId]: {
            ...omit(currentUser, 'agency'),
            id: currentUser.id + [],
          },
        },
        __reports: {},
      },
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [GET_IZENDA_REPORTS_REQUEST]: state => {
    return {
      ...state,
      analyticsReports: {
        ...state.analyticsReports,
        loading: true,
      },
    };
  },
  [GET_IZENDA_REPORTS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      analyticsReports: {
        list: payload,
        loading: false,
      },
    };
  },
  [GET_IZENDA_REPORTS_ERROR]: state => {
    return {
      ...state,
      analyticsReports: {
        ...state.analyticsReports,
        loading: false,
      },
    };
  },
  ...configureTemplatesEvents,
};

export default events;
