import React from 'react';
import { Icon } from 'antd';
import { translate } from '../../../../i18next.js';

const FileSizeLimit = ({ limit = '5 GB' }) => {
  return (
    <p className="info">
      <Icon type="info-circle" />{' '}
      <em>
        {translate('containers.profile.documents.fileLimit')}{' '}
        <strong>{limit}</strong>
      </em>
    </p>
  );
};

export default FileSizeLimit;
