import React from 'react';
import { Input } from 'antd';
import { isNil } from 'lodash';

class FieldText extends React.Component {
  onBlur = e => {
    const { onBlur } = this.props;

    onBlur && onBlur(e.target.value);
  };

  onChange = e => {
    const { onChange } = this.props;

    onChange && onChange(e.target.value);
  };

  render() {
    const {
      defaultValue,
      value,
      placeholder = '',
      style = { width: 200 },
      maxLength,
      'data-test': dataTest = '',
      initialValue,
    } = this.props;

    return (
      <Input
        type="text"
        className="edit-input"
        defaultValue={defaultValue}
        style={style}
        value={!isNil(value) ? value : initialValue}
        onBlur={this.onBlur}
        onChange={this.onChange}
        placeholder={placeholder}
        data-test={dataTest}
        maxLength={maxLength}
      />
    );
  }
}

export default FieldText;
