import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';

class dataExportEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'https://localhost:3000';
    const apiPrefix = 'Agencies/0'; // agency ID is not currently used.  Reserved for Superuser in the future
    super(process.env.REACT_APP_API_URL || DEV_URI, apiPrefix);
    this.apiPrefix = apiPrefix;
  }

  EXPORT_STATUS_FAILED = 'FAILED';
  EXPORT_STATUS_COMPLETED = 'COMPLETED';

  async getDownloadLink(exportId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(`/downloadDataExport/${exportId}`),
        method: 'GET',
      },
      {},
      {}
    );

    return await fetchRequest(fetchParams.URI, fetchParams.request);
  }

  async getDataExportList(exportId) {
    const fetchParams = this.buildFetchParams(
      {
        path:
          this.withApiPrefix(
            exportId ? `/listDataExports/${exportId}` : '/listDataExports'
          ) + `?timestamp=${Date.now()}`,
        method: 'GET',
      },
      {
        // tenantId, // path params
        // userId,
      },
      {
        // pageNumber: pageNumber, // query params
        // pageSize: pageSize,
        // sort: sort,
        // ...this.parseTrainingFilters(filters || {}),
      }
    );

    return await fetchRequest(fetchParams.URI, fetchParams.request);
  }

  async submitRule28ExportRequest() {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/submitRule28ExportRequest'),
        method: 'POST',
      },
      {},
      {},
      {}
    );

    return await fetchRequest(fetchParams.URI, fetchParams.request);
  }

  async submitReportPrintingRequest(reportIds) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/submitDataExportRequest'),
        method: 'POST',
      },
      {},
      {},
      {
        exportType: 'REPORT_PRINTING',
        baseUrl: window.location.origin,
        reportIds,
      }
    );

    return await fetchRequest(fetchParams.URI, fetchParams.request);
  }

  async submitDataExportRequest(exportType, templateId, dateFrom, dateTo) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/submitDataExportRequest'),
        method: 'POST',
      },
      {},
      {},
      {
        dateFrom,
        dateTo,
        exportType,
        templateId,
      }
    );

    return await fetchRequest(fetchParams.URI, fetchParams.request);
  }
}

export default new dataExportEndpoints();
