import React, { Fragment } from 'react';
import { Icon } from 'antd';

import ModalTitle from 'APP_ROOT/components/common/modal/title';

import { MODAL_TYPE_SUCCESS, MODAL_TYPE_CONTINUE_REVIEW } from './constants';

import { translate } from '../../../i18next';

const getModalTitle = (type, key) => (
  <ModalTitle key={key} success={type === MODAL_TYPE_SUCCESS}>
    {type === MODAL_TYPE_SUCCESS && (
      <Fragment>
        <Icon type="check-circle" />{' '}
        <span>{translate('containers.reports.reportViewer.success')}</span>
      </Fragment>
    )}
    {type === MODAL_TYPE_CONTINUE_REVIEW && <span>Continue Review</span>}
  </ModalTitle>
);

export default getModalTitle;
