import React from 'react';
import { Layout, Col, Row, Menu } from 'antd';
import classNames from 'classnames';

import StyledSider from '../layout/sidebar';
import { AppFooter } from '../layout/footer';
import ContentWrapper from '../layout/content-wrapper';
import Content from '../layout/content';
import NavigationUserCard from '../layout/navigation-user-card';
import CollapseSidebarButton from '../layout/collapse-sidebar-button';
import MenuOptionsButtons from '../common/menu/menu-options-buttons';
import { SidebarFooter } from './page-wrapper.styled';
import { SIDEBAR_FOOTER_BG } from '../../config/theme';
import isMobileDevice from '../../utils/isMobileDevice';
import { LogoutButton } from '../common/menu/menu-item';
import MenuButton from '../common/menu/MenuButton';
import MenuDivider from '../common/menu/menu-divider';
import websiteLogsMessage from '../../utils/websiteLogsMessage';
import postWebsiteLogs from '../../actions/post-website-logs';
import { translate } from '../../i18next';

const isMobile = isMobileDevice();

const SideBar = ({
  sidebarCollapsed,
  onCollapse,
  saveRef,
  sidebarHeight,
  currentUser,
  onOpenNewReport,
  permissions,
  optionsHistory,
  location,
  match,
  onLogOut,
  isNewReportLoading,
  menuOptions,
  sidebarCollapseButtonClassNames,
  onToggleCollapse,
  appConfig,
  currentYear,
  systemVersion,
  className,
  config,
  children,
  history,
  dispatch,
}) => {
  let sidebarFooterClassName = [];
  if (sidebarCollapsed) {
    sidebarFooterClassName.push('is-collapsed');
  }
  if (isMobile) {
    sidebarFooterClassName.push('new-sidebar-footer');
  }

  const triggerLogOut = () => {
    //This dispatch is sending information to an enpoint on the node api to get information about
    //forced logout - for more information about the issue review SW-1059
    dispatch(
      postWebsiteLogs(
        websiteLogsMessage(
          'Logout executed from logout button',
          'menu-options-buttons'
        )
      )
    );
    setTimeout(() => onLogOut(), 500);
  };

  const getFooterImage = () => (
    <img
      className="footer-logo"
      src={require('../../images/benchmark-logo.svg')}
      alt={appConfig.name}
    />
  );

  const getCollapseButton = () =>
    isMobile ? (
      getFooterImage()
    ) : (
      <CollapseSidebarButton
        icon={sidebarCollapsed ? 'double-right' : 'double-left'}
        className={sidebarCollapseButtonClassNames}
        onClick={() => onToggleCollapse()}
      >
        {getFooterImage()}
      </CollapseSidebarButton>
    );

  return (
    <Layout className="ant-layout-has-sider">
      <StyledSider
        collapsible
        collapsedWidth={isMobile ? 0 : 80}
        style={isMobile ? { width: 0 } : {}}
        trigger={null}
        breakpoint="md"
        collapsed={sidebarCollapsed}
        onCollapse={onCollapse}
      >
        <div
          className="sidebar-menu"
          ref={saveRef('sidebarMenu')}
          style={{
            minHeight: sidebarHeight,
          }}
        >
          <NavigationUserCard
            user={currentUser}
            collapsed={sidebarCollapsed}
            history={history}
            dispatch={dispatch}
            logoutTrigger={onLogOut}
          />

          <MenuOptionsButtons
            onOpenNewReport={onOpenNewReport}
            permissions={permissions}
            optionsHistory={optionsHistory}
            location={location}
            match={match}
            isCollapsed={sidebarCollapsed}
            isNewReportLoading={isNewReportLoading}
            menuOptions={menuOptions}
            dispatch={dispatch}
          />
        </div>

        <SidebarFooter
          className={sidebarFooterClassName.join(' ')}
          ref={saveRef('sidebarFooter')}
        >
          <Menu>
            <MenuDivider />
            <LogoutButton
              key="logout"
              className={sidebarCollapseButtonClassNames}
            >
              <MenuButton
                iconType="logout"
                onClick={onLogOut && (() => triggerLogOut())}
                text={translate('sidebar.mainMenu.logOut')}
              />
            </LogoutButton>
            <MenuDivider />
          </Menu>
          {getCollapseButton()}
          <AppFooter
            background={SIDEBAR_FOOTER_BG}
            className={classNames('sidebar-footer', {
              'is-collapsed': sidebarCollapsed,
            })}
          >
            <Row>
              <Col className="col-left text-center" lg={{ span: 24 }}>
                <ul className="footer-quick-links">
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="http://benchmarkanalytics.com/contact.html"
                      className="footer-link"
                    >
                      {translate('sidebar.customerSupportLink')}
                    </a>
                  </li>
                </ul>
              </Col>
              <Col className="col-right text-center" lg={{ span: 24 }}>
                <p>
                  &copy; {currentYear} {appConfig.name}
                </p>
                <p>
                  {translate('sidebar.copyRight')}{' '}
                  {systemVersion ? <>&bull; {systemVersion}</> : ''}
                </p>
              </Col>
            </Row>
          </AppFooter>
        </SidebarFooter>
      </StyledSider>
      <ContentWrapper
        style={{ marginLeft: isMobile ? 0 : sidebarCollapsed ? 70 : 200 }}
      >
        <Content className={className}>
          <div style={{ ...(config.containerStyle || {}) }}>{children}</div>
        </Content>
      </ContentWrapper>
    </Layout>
  );
};

export default SideBar;
