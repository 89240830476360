import React from 'react';
import { StyledTh } from './CaseFileList.style';

const DraggableHeaderColumn = ({
  dataIndex,
  dndFunctions,
  dragOver,
  onDragEnter,
  onDragStart,
  onDrop,
  ...restProps
}) => {
  const handleDragOver = e => e.preventDefault();

  return (
    <StyledTh
      id={dataIndex}
      key={`col-${dataIndex}`}
      draggable={dataIndex !== 'casefileId'}
      onDragOver={handleDragOver}
      dragOver={dragOver}
      onDragEnter={onDragEnter}
      onDragStart={onDragStart}
      onDrop={onDrop}
      {...restProps}
    />
  );
};

export default DraggableHeaderColumn;
