import React from 'react';
import styled from 'styled-components';
import { translate } from '../../i18next';

const AttachmentsWarningImportant = () => (
  <StyledWarning>
    <div>
      <strong>{translate('containers.profile.documents.important_1')}</strong>
      {`:${translate('containers.profile.documents.important_2')} `}{' '}
      <strong>{translate('containers.profile.documents.important_3')}</strong>
      {` ${translate('containers.profile.documents.important_4')} `}
      <strong>{translate('containers.profile.documents.important_5')}</strong>
      {` ${translate('containers.profile.documents.important_6')}.`}
    </div>
    <div>{translate('containers.profile.documents.navigateAway')}</div>
    <div>{translate('containers.profile.documents.uploadTime')}</div>
  </StyledWarning>
);

const StyledWarning = styled.p`
  margin-bottom: 18px;
`;

export default AttachmentsWarningImportant;
