import React, { Component } from 'react';
import { Row, Button, Icon } from 'antd';
import { get } from 'lodash';
import { v4 as uuid } from 'uuid';
import { change, arrayPush } from 'redux-form';
import { connect } from 'react-redux';
import { getFormValues } from '../../agency-users/AgencyUsers.selectors';
import { translate } from '../../../../i18next';

import RepeaterWrapper from './StaticRepeater.styled';

class StaticRepeater extends Component {
  remove = k => {
    const { field, formName, formData, dispatch } = this.props;
    const fields = get(formData, ['values', field], []);
    const newFields = fields.filter(item => item.id !== k);

    dispatch(change(formName, field, newFields));
  };

  add = () => {
    const { field, formName, dispatch } = this.props;
    const newItem = { id: uuid() };

    dispatch(arrayPush(formName, field, newItem));
  };

  render() {
    const { children, field, formData } = this.props;
    const fields = get(formData, ['values', field], []);
    const items = fields
      ? fields.map((item, index) => {
          return (
            <div className="repeater-item" key={item.id}>
              <Button
                type="default"
                shape="circle"
                icon="close"
                className="repeater-delete"
                size="default"
                ghost
                onClick={() => this.remove(item.id)}
              />
              {children(item.id, index)}
            </div>
          );
        })
      : [];
    return (
      <RepeaterWrapper>
        {items}
        <div className="divider" />
        <Row type="flex" justify="end">
          <Button type="primary" ghost onClick={this.add}>
            <Icon type="plus" /> {translate('containers.profile.addEquipment')}
          </Button>
        </Row>
      </RepeaterWrapper>
    );
  }
}

const mapState = (state, props) => {
  const { formName } = props;
  return {
    formData: getFormValues(state, formName),
  };
};

export default connect(mapState, null, null, { withRef: true })(StaticRepeater);
