import React from 'react';
import styled from 'styled-components';
import { translate } from '../../i18next';

const AttachmentsWarning = () => (
  <StyledWarning>
    <div>
      <strong>{translate('containers.caseFiles.important')}</strong>
      {`: ${translate('containers.profile.documents.navigateAway')}`}
    </div>
    <div>{translate('containers.profile.documents.uploadTime')}</div>
  </StyledWarning>
);

const StyledWarning = styled.p`
  margin-bottom: 18px;
`;

export default AttachmentsWarning;
