import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'antd';
import { CSVLink } from 'react-csv';
import { translate } from '../../i18next';

export default function CsvExportLink({
  buttonText,
  headers = [],
  data = [],
  useAsync,
  asyncFetchData,
  getProcessedData,
  filename,
}) {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const linkRef = useRef(null);
  const translatedButtonText = translate('containers.organizations.exportCSV');

  useEffect(() => {
    if (done) {
      triggerAsyncLinkClick();
    }
  }, [done]);

  const fetchData = () => {
    setLoading(true);
    setDone(false);

    asyncFetchData(concludeFetch, resetComponent);
  };

  const concludeFetch = () => {
    setLoading(false);
    setDone(true);
  };

  const resetComponent = _event => {
    setTimeout(() => {
      setLoading(false);
      setDone(false);
    }, 1000);
  };

  const triggerAsyncLinkClick = () => {
    if (done && linkRef.current?.link?.click) {
      linkRef.current.link.click();
    }
  };

  const renderAsyncLink = () => {
    if (done) {
      return (
        <div style={{ display: 'none' }}>
          <CSVLink
            ref={linkRef}
            asyncOnClick
            headers={headers}
            data={getProcessedData()}
            onClick={resetComponent}
            filename={filename}
          >
            {buttonText ? buttonText : translatedButtonText}
          </CSVLink>
        </div>
      );
    }
  };

  if (!useAsync) {
    return (
      <Button>
        <CSVLink headers={headers} data={data}>
          {buttonText ? buttonText : translatedButtonText}
        </CSVLink>
      </Button>
    );
  }

  return (
    <>
      <Button onClick={fetchData}>
        {loading
          ? translate('containers.organizations.loading')
          : translatedButtonText}
      </Button>
      {renderAsyncLink()}
    </>
  );
}
