import { kebabCase, isArray, has, isEmpty, get } from 'lodash';

import { FETCH_FORM_ATTACHMENTS } from './';
import { getFormSubmissionAttachments } from '../api/attachments';
import fetchFormAttachmentsSuccess from './fetch-form-attachments-success';
import fetchFormAttachmentsError from './fetch-form-attachments-error';
import { FORM_IDENTIFIERS } from '../constants/form-types';
import normalizeAttachments from '../utils/normalizeAttachments';

const getUpload = ({ data, initialValue = {} }) => {
  const keys = Object.keys(data);
  // get the list of upload fields checking [{id, notes}]
  // only uploads have that specific structure
  return keys.reduce(
    (acc, key) =>
      isArray(data[key]) &&
      has(data[key][0], 'id') &&
      has(data[key][0], 'notes')
        ? data[key].reduce((upload, { id }) => ({ ...upload, [id]: key }), acc)
        : acc,
    initialValue
  );
};

const getSingleUpload = ({ singleUpload, data, initialValue = {} }) => {
  const { parentKey, parentIndex, id, key } = singleUpload;
  let storeData = data;
  if (parentKey) {
    storeData = get(data, [parentKey, parentIndex], {});
  }
  let values = { ...initialValue };
  if (has(storeData, id)) {
    values = { ...values, [storeData[id]]: key };
  }
  return values;
};

export default (
  { id = '', field = '', filters = {}, settings = {}, singleUpload = {} },
  next = () => {}
) => (dispatch, getState) => {
  const getUploadFields = isEmpty(singleUpload) ? getUpload : getSingleUpload;

  dispatch({ type: FETCH_FORM_ATTACHMENTS });
  Promise.all([getFormSubmissionAttachments({ id, filters })])
    .then(([attachmentsResponse]) => {
      const attachmentList = normalizeAttachments(attachmentsResponse);
      const { noteId } = settings; // settings only exist in review notes
      let uploads;

      const {
        form: { selectedForm },
      } = getState();
      const { data = {}, meta = {} } = selectedForm;
      const { notes = [] } = meta;

      if (noteId) {
        // get uploads from review notes
        notes
          .filter(({ id }) => id === noteId)
          .forEach(
            ({ content }) =>
              (uploads = getUploadFields({
                singleUpload,
                data: content,
                initialValue: uploads,
              }))
          );
      } else {
        // get uploads from data
        uploads = getUploadFields({ singleUpload, data });
      }

      // now filter to only have attachments without owner or
      // attachments that belogns to the current field
      const attachments = attachmentList.filter(
        ({ id }) => uploads[id] === field
      );

      dispatch(
        fetchFormAttachmentsSuccess({
          attachments,
          formId: meta.id,
          field,
          singleUpload,
          kind: FORM_IDENTIFIERS[kebabCase(meta.formType)],
        })
      );
      next(null, [meta, attachments]);
    })
    .catch(error => {
      dispatch(fetchFormAttachmentsError(error));
      next(error);
    });
};
