import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Upload, Button, Icon, notification, Row, Col, Modal } from 'antd';
import { get } from 'lodash';

import PageHeader from 'APP_COMPONENTS/PageHeader';
import DashboardPage from 'APP_COMPONENTS/dashboard';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import AdministratorWrapper from '../Administrator.styled';
import Content, {
  FlexDiv,
  ImagesDiv,
  StyledTitle,
  ImageColumnarDiv,
} from './AgencyBranding.styled';
import documentManagementEndpoints from '../../../api/documentManagement/documentManagementEndpoints';
import organizationEndpoints from '../../../api/organization/organizationEndpoints';

import bannerExample from './assets/banner_example.png';
import logoExample from './assets/logo_example.png';

const { confirm } = Modal;

const AgencyBranding = React.forwardRef(({ tenantId, agencyId }, _) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadInputKey] = useState('uploadInput');
  const [uploadURL, setUploadURL] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [documentId, setDocumentId] = useState(null);
  const [currentLogoName, setCurrentLogoName] = useState('');

  const renderCurrentLogoName = logoName => {
    if (loading && fileList.length === 0) {
      return <Icon type="loading" style={{ fontSize: '24px' }} />;
    }

    if (logoName && fileList.length === 0) {
      return (
        <Row>
          <Col span={3}>
            <Icon type="paper-clip" />
          </Col>
          <Col span={20}>
            <a href={downloadUrl} download>
              {logoName}
            </a>
          </Col>
          <Col span={1}>
            <Icon
              type="delete"
              style={{ marginLeft: '16px' }}
              onClick={() => showConfirm()}
            />
          </Col>
        </Row>
      );
    }

    return null;
  };

  const showConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this document?',
      content: `${currentLogoName} will be permanently deleted and will no longer be available to print in forms.`,
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteCurrentAgencyLogo();
      },
    });
  };

  const onBeforeUpload = file => {
    setLoading(true);
    setFileList([file]);
  };

  const onRemoveFileOnStorage = () => setFileList([]);

  const showNotification = (type, message, description) =>
    notification[type]({ message, description });

  const onChange = ({ file, fileList, event }) => {
    // always sync `fileList` state for controlled `fileList`
    // for more info, check this issue: https://github.com/ant-design/ant-design/issues/2423
    setFileList(fileList.slice());

    if (file.status === 'uploading' && event.percent === 100) {
      documentManagementEndpoints
        .getDocumentUploadUrl('logo', file.name, tenantId)
        .then(response => {
          setCurrentLogoName(fileList[0].name);
          setDocumentId(response.documentId);
          setUploadURL(response.url);
        })
        .catch(e => {
          showNotification('error', 'Error', 'File could not be uploaded.');
        });
    }
  };

  const uploadDocument = async (file, url, documentId) => {
    const xhr = new XMLHttpRequest();
    const response = await new Promise(resolve => {
      xhr.addEventListener('loadend', () => {
        resolve(xhr.readyState === 4 && xhr.status === 200);
      });
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file.originFileObj);
    });
    if (response) {
      await documentManagementEndpoints.completeUpload(documentId, true);
      return true;
    }

    setLoading(false);
    return false;
  };

  const uploadFile = async () => {
    const completed = await uploadDocument(fileList[0], uploadURL, documentId);

    if (!completed) {
      showNotification('error', 'Error', 'Image could not be uploaded.');
      return;
    }

    getImageUrl(documentId);

    organizationEndpoints
      .addAgencyLogo(agencyId, documentId)
      .then(_ => {
        setFileList([]);
        showNotification('success', 'Success', 'Image uploaded successfully!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkPreviousLoadedImage = (logoDocumentId = null) => {
    documentManagementEndpoints
      .getDocumentMetadata(logoDocumentId)
      .then(response => {
        if (!response.name) {
          return;
        }
        setCurrentLogoName(
          response.name
            .split(' ')
            .slice(1)
            .join(' ')
        );
      })
      .finally(() => setLoading(false));
  };

  const getImageUrl = (logoDocumentId = null) => {
    documentManagementEndpoints
      .getDocumentPreSignedUrl(logoDocumentId)
      .then(response => response.text())
      .then(response => {
        setDownloadUrl(response);
      });
  };

  const deleteCurrentAgencyLogo = () => {
    organizationEndpoints
      .deleteAgencyLogo(agencyId)
      .then(response => {
        setCurrentLogoName('');
        setDocumentId(null);
        showNotification('success', 'Success', 'Image deleted successfully!');
      })
      .catch(e => {
        showNotification('error', 'Error', 'Image could not be deleted.');
      });
  };

  useEffect(() => {
    setLoading(true);
    organizationEndpoints
      .getAgencyLogoId(agencyId)
      .then(({ logoDocumentId }) => {
        setDocumentId(logoDocumentId);
        if (logoDocumentId) {
          getImageUrl(logoDocumentId);
          checkPreviousLoadedImage(logoDocumentId);
        }
      })
      .catch(e => {
        if (e.status !== 404) {
          showNotification(
            'error',
            'Error',
            'Logo Information could not be loaded.'
          );
        }
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!uploadURL) {
      return;
    }
    uploadFile();
  }, [uploadURL]);

  return (
    <AdministratorWrapper>
      <PageHeader title="Agency Branding" />
      <Content>
        <StyledTitle>Agency Logo</StyledTitle>
        <p>
          Your agency logo can be uploaded here to be displayed on pronted
          formats. To properly display your logo on a form, we recommend the
          following:
        </p>
        <FlexDiv>
          <div style={{ marginRight: '80px' }}>
            <span style={{ textDecorationLine: 'underline' }}>File Types</span>
            <ul>
              <li style={{ listStyle: 'disc' }}>JPEG, PNG, PDF, TIFF, BMP.</li>
            </ul>
          </div>
          <div style={{ marginLeft: '80px' }}>
            <span style={{ textDecorationLine: 'underline' }}>Dimensions</span>
            <ol type="a">
              <li style={{ listStyle: 'disc' }}>
                Minimum recommendation
                <ol>
                  <li>Inches: 1.25' x 1.25' at 300 DPI</li>
                  <li>Pixels: 375 x 375 pixels</li>
                </ol>
              </li>
              <li style={{ listStyle: 'disc' }}>Maximum recommendation</li>
              <li>
                <ol>
                  <li>The width can be between 1.25' and 8.5'</li>
                  <li>Inches: 1.25' x 8.5' at 300 DPI</li>
                  <li>Pixels: 375 x 2550 pixels</li>
                </ol>
              </li>
            </ol>
          </div>
        </FlexDiv>
        <p>
          Other: If the image resolution is smaller than the above
          recommendation, we can still use it but it will likely be blurry.
        </p>
        <Upload
          disabled={loading}
          beforeUpload={onBeforeUpload}
          customRequest={async ({ file, onProgress, onSuccess }) => {
            onProgress({ percent: 0 });
            const uploadedFile = await new Promise(resolve => {
              setTimeout(() => {
                onProgress({ percent: 100 });
                resolve({ ...file, status: 'done' });
              }, 400);
            });
            onSuccess({ success: true }, uploadedFile);
          }}
          onChange={onChange}
          onRemove={onRemoveFileOnStorage}
          multiple={false}
          fileList={fileList}
          key={uploadInputKey}
          maxCount={1}
          type="upload"
        >
          <Button style={{ margin: '16px 0 42px 0' }}>
            <Icon type="upload" />
            Select File
          </Button>
        </Upload>

        {renderCurrentLogoName(currentLogoName)}
        <StyledTitle>Agency Logo Print Example</StyledTitle>
        <ImagesDiv>
          <ImageColumnarDiv>
            <h3>Square Logo</h3>
            <img src={logoExample} alt="logo example" />
          </ImageColumnarDiv>
          <ImageColumnarDiv>
            <h3>Square Rectangle</h3>
            <img src={bannerExample} alt="banner example" />
          </ImageColumnarDiv>
        </ImagesDiv>
      </Content>
    </AdministratorWrapper>
  );
});

const mapState = (_, props) => {
  const tenantId = get(props, 'match.params.tenantId');
  const agencyId = get(props, 'match.params.agencyId');
  return {
    tenantId,
    agencyId,
  };
};

export default withoutClutter(
  DashboardPage(mapState)(withRouter(AgencyBranding))
);
