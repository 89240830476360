import styled from 'styled-components';

const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
`;

export default StyledDiv;
