import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Cascader } from 'antd';
import { isEmpty } from 'lodash';
import getNormalizedReviewer from 'APP_ROOT/utils/get-reviewer-cascader';
import {
  findReviewer,
  findReviewerUsingFuzzySearch,
} from 'APP_ROOT/utils/filters';
import { FEATURES, hasFeatures } from 'APP_ROOT/utils/features';
import { translate } from '../../../i18next';

const FormItem = Form.Item;
const { TextArea } = Input;

class SubmitConfirmationModal extends Component {
  modalUpdater = (cond, callback, flag = false) => {
    const { updateModal = () => {}, modalButtons = () => {} } = this.props;
    if (flag) {
      callback();
      return;
    }
    if (cond) {
      callback();
      updateModal({
        footer: modalButtons({ isDisable: false }),
      });
    } else {
      updateModal({
        footer: modalButtons({ isDisable: true }),
      });
    }
  };
  handleNoteChange = event => {
    const {
      target: { value },
    } = event;
    const { onNotesReviewer = () => {} } = this.props;

    this.modalUpdater(value, () => onNotesReviewer(value));
  };
  render() {
    const {
      groupedReviewers,
      setReviewer = () => {},
      notes,
      reviewerNoteSubmission = false,
      disableReviewerSelection = false,
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const withSubmitterNotes = !isEmpty(notes) && reviewerNoteSubmission;

    const { currentUser: { featureFlags = [] } = {} } = this.props;

    const hasReportUserSelectionFuzzySearch = hasFeatures(
      featureFlags,
      FEATURES.reportUserSelectionFuzzySearch
    );

    return (
      <Form layout="vertical" hideRequiredMark>
        <div className="reviewer-select">
          <p>{translate('containers.reports.mustSelectReviewer')}</p>
          <Cascader
            style={{ width: '300px', margin: '0 auto' }}
            options={groupedReviewers}
            onChange={(value, options) => {
              this.modalUpdater(
                value.length,
                () => setReviewer(getNormalizedReviewer(value, options)),
                withSubmitterNotes
              );
            }}
            placeholder={translate('containers.reports.pleaseSelect')}
            displayRender={label => label.join(' > ')}
            showSearch={{
              filter: hasReportUserSelectionFuzzySearch
                ? findReviewerUsingFuzzySearch
                : findReviewer,
            }}
            disabled={disableReviewerSelection}
          />
        </div>
        {withSubmitterNotes && (
          <div className="reviewer-note">
            <FormItem label="Notes">
              {getFieldDecorator('note', {
                rules: [{ required: true, message: 'Notes are required' }],
              })(
                <TextArea
                  rows={4}
                  placeholder="Write your notes here"
                  onChange={this.handleNoteChange}
                />
              )}
            </FormItem>
          </div>
        )}
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const {
    app: { loading },
    session: { currentUser } = {},
  } = state;
  return { disableReviewerSelection: loading, currentUser };
};

const WrappedModal = Form.create()(
  connect(mapStateToProps, null)(SubmitConfirmationModal)
);

export default WrappedModal;
