import React, { useState } from 'react';

import { get, castArray, isEmpty } from 'lodash';
import injectTemplateConfig from './injectTemplateConfig';
import FormPickerWrapper from './FormPickerWrapper.styled';
import Loader from './Loader';
import onNewReportClick from './onNewReportClick';
import { hasPermissions, PERMISSIONS } from '../../../utils/admin';
import { Select, Row, Col } from 'antd';
import IntegrationModal from './IntegrationModal';
import FieldText from '../../../../src/modules/FormBuilder/components/FieldText';
import apiEndpoints from '../../../../src/modules/FormBuilder/services/apiEndpoints';
import { Icon } from 'antd';
import createModal from '../../../actions/create-modal';
import ModalBody from '../../../components/common/modal/body';
import ModalTitle from '../../../components/common/modal/title';
import showModal from '../../../actions/show-modal';

import { setIntegrationData } from '../../../containers/report-type/populateIntegration';
import { translate } from '../../../i18next';

const { Option } = Select;
const fetchIntegrationErrorMessage =
  'We are unable to find your CAD/RMS record with the information you provided. Please Enter a different incident number.';
const FormPicker = ({
  push,
  closeModal,
  templates = [],
  user,
  isLoading = false,
  dispatch,
}) => {
  const [isIntegrationModalVisible, setIsIntegrationModalVisible] = useState(
    false
  );
  const [integrationValue, setIntegrationValue] = useState('');
  const [readOnly, setReadOnly] = useState(true);
  const [formNameSelected, setFormNameSelected] = useState(true);
  const configs = get(user, 'agency.configurations', []).find(
    c => c.entity === 'template'
  );
  const templateList = Array.isArray(templates)
    ? templates
    : Object.values(templates);

  const filterIntegrationsTemplates = (templateName = '') => {
    const templateToLowerCase = templateName.toLowerCase();
    return templateToLowerCase.includes('integration');
  };
  const filterByWorkflowAvailabilityAndPerms = template => {
    let hasPermission;
    let permissions = user.permissions;
    if (template.templateType === 'use-of-force') {
      hasPermission = hasPermissions(permissions, PERMISSIONS.submitUOFReport);
    } else if (template.templateType === 'vehicle-pursuit') {
      hasPermission = hasPermissions(permissions, PERMISSIONS.submitVPReport);
    } else if (template.templateType === 'daily-observation-report') {
      hasPermission = hasPermissions(permissions, PERMISSIONS.submitDORReport);
    } else if (template.templateType === 'internal-affairs-investigation') {
      hasPermission = hasPermissions(permissions, PERMISSIONS.submitIAIReport);
    } else if (template.templateType === 'internal-affairs-complaint') {
      hasPermission = hasPermissions(permissions, PERMISSIONS.submitIACReport);
    } else {
      hasPermission = hasPermissions(permissions, PERMISSIONS.submitReport);
    }

    return template.canCreate && hasPermission;
  };

  const formShortcuts = templateList
    .map(injectTemplateConfig(configs))
    .filter(filterByWorkflowAvailabilityAndPerms)
    .reduce((result, template) => {
      const { templateConfig } = template;
      if (templateConfig) {
        return [
          ...result,
          {
            ...templateConfig,
            templateId: template.id,
            templateName: template.name,
          },
        ];
      }
      return [...result];
    }, [])
    .sort((a, b) => a.templateName.localeCompare(b.templateName));

  const commonClickOptions = {
    closeModal,
    push,
  };

  const shortcutMap = new Map(
    formShortcuts.map(shortcut => [shortcut.title, shortcut])
  );

  const onCancelIntegration = () => {
    setIsIntegrationModalVisible(false);
    onNewReportClick({
      report: shortcutMap.get(formNameSelected),
      ...commonClickOptions,
    })();
  };

  const onChangeIntegrationField = (value = '') => {
    setIntegrationValue(value);
    if (value === '') {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  };

  const showErrorMessage = error => {
    const errorTitle = (
      <ModalTitle error>
        <Icon type="close-circle" /> <span>Heads Up!</span>
      </ModalTitle>
    );
    const successFields = (
      <ModalBody>
        <p>{castArray(error).join('< br/>')} </p>
      </ModalBody>
    );
    const options = {
      id: 'integration-error-modal',
      title: errorTitle,
      children: successFields,
    };
    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  const showIntegrationModal = () => {
    setIsIntegrationModalVisible(true);
    setIntegrationData({});
  };

  const onFetchIntegration = async () => {
    const { agencyId } = user;
    const selectedTemplate = templateList.find(
      template => template.name === formNameSelected
    );
    const { id: templateId } = selectedTemplate;
    apiEndpoints
      .getTemplateIntegration(agencyId, templateId, integrationValue)
      .then(({ content, success, errors = [] }) => {
        const errorList = errors && errors.map(e => e.message);
        const hasErrors = !isEmpty(errorList);
        if (success) {
          const { closeModal } = commonClickOptions;
          setIntegrationData(content);
          closeModal();
          if (hasErrors) {
            showErrorMessage(errorList);
          }
          onNewReportClick({
            report: shortcutMap.get(formNameSelected),
            ...commonClickOptions,
          })();
          setIsIntegrationModalVisible(false);
        } else {
          const errorMessage =
            (hasErrors && errorList) || fetchIntegrationErrorMessage;
          showErrorMessage(errorMessage);
        }
      })
      .catch(e => {
        showErrorMessage(e.message);
      });
  };

  const onSelect = formName => {
    setFormNameSelected(formName);
    if (filterIntegrationsTemplates(formName)) {
      showIntegrationModal();
    } else {
      onNewReportClick({
        report: shortcutMap.get(formName),
        ...commonClickOptions,
      })();
    }
  };

  const integrationContent = (integratedWith = '') => {
    return (
      <Row
        type="flex"
        justify="center"
        style={{ padding: '10px' }}
        align="middle"
      >
        <Col span={4}>
          <div className="ant-integration-picker-content-label">
            {integratedWith}
          </div>
        </Col>
        <Col span={12}>
          <div className="ant-integration-picker-content-wrapper">
            <FieldText
              onChange={onChangeIntegrationField}
              value={integrationValue}
              placeholder={'Enter a value'}
              maxLength={50}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const generateOptions = () =>
    [...shortcutMap.keys()].map(name => <Option key={name}>{name}</Option>);

  return (
    <>
      <FormPickerWrapper>
        {isLoading && <Loader />}
        {!isLoading && formShortcuts && (
          <Select
            showSearch
            placeholder={translate('containers.reports.typeFormHere')}
            onSelect={onSelect}
            className="ant-form-picker-select"
          >
            {generateOptions()}
          </Select>
        )}
      </FormPickerWrapper>
      {isIntegrationModalVisible && (
        <IntegrationModal
          onCancel={onCancelIntegration}
          onFetch={onFetchIntegration}
          visible={true}
          title={'Fetch data'}
          okText={'Fetch'}
          cancelText={'Cancel'}
          content={integrationContent}
          readOnly={readOnly}
          integratedWith={'RMS/CAD ID'}
        />
      )}
    </>
  );
};

export default FormPicker;
