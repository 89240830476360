import React, { Component } from 'react';
import Col from 'antd/lib/col';
import Input from 'antd/lib/input';
import Icon from 'antd/lib/icon';
import Pagination from 'antd/lib/pagination';
import Table from 'antd/lib/table';
import { StyledRow, StyledRowPagination } from './People.styled';
import { Link } from 'react-router-dom';
import getRoute from 'APP_ROOT/utils/get-route';
import savePreviousPath from 'APP_ROOT/utils/savePreviousPath';
import Button from 'antd/lib/button';
import EncryptedInput, {
  MASK_TYPES,
} from '../../components/common/encrypted-input/EncryptedInput';

import If from '../../components/utils/ConditionalRender';
// Components
import PageHeader from '../../components/PageHeader/PageHeader';
import { CREATE_FROM_OU } from '../administrator/agency-users/AgencyUsers.constants';
import { translate } from '../../i18next.js';

class PeopleTable extends Component {
  state = {
    search: '',
    page: 1,
    searchClear: false,
    ssn: '',
    isSearchableBySSN: false,
  };
  searchInput = null;

  getParamsFromUrl = () => {
    const params = new URLSearchParams(global.location.search);
    const page = Number(params.get('page') || 1);
    const search = params.get('search') || '';
    this.setState({ search, searchClear: !!search });
    return { page, search };
  };

  setSearchCriteria = (page, search) => {
    const { history } = this.props;
    const searchCriteria = `?page=${page}&search=${search}`;
    history.push({
      pathname: history.pathname,
      search: searchCriteria,
    });
  };

  componentDidMount() {
    this.searchInput.focus();
    this.search();
  }

  changePage = page => {
    window.scrollTo(0, 0);
    const { search } = this.getParamsFromUrl();
    this.setSearchCriteria(page, search);
  };

  search = () => {
    const { page, search } = this.getParamsFromUrl();
    const { ssn } = this.state;
    const { onSearch, agencyId } = this.props;
    onSearch(this.props, { page, search, ssn, agencyId, size: 50 });
  };

  handlePeopleSearchSubmit = searchText => {
    const page = 1;
    const {
      target: { value: search = '' },
    } = searchText;
    this.setSearchCriteria(page, search);
  };

  handlePeopleSearchChange = value => {
    const {
      target: { value: searchValue = '' },
    } = value;

    this.setState({
      search: searchValue,
      searchClear: !!searchValue,
    });
  };

  handlePeopleSearchClear = () => {
    this.setSearchCriteria(1, '');
  };

  handlePeopleSSNSubmit = () => {
    this.search();
  };

  handlePeopleSNNChange = e => {
    const {
      target: { value },
    } = e;

    const ssnValue = value.replace(/[-|_]/g, '');

    this.setState({
      ssn: value,
      isSearchableBySSN: ssnValue.length == 9,
    });
  };

  render() {
    const {
      total = 0,
      per_page = 50,
      page = 1,
      users = [],
      loading = false,
      canCreateUsers,
      agencyId,
      canSearchBySSN = false,
      shouldRenderHeader = true,
    } = this.props;
    const { search, searchClear, ssn, isSearchableBySSN } = this.state;

    const searchSuffix = searchClear ? (
      <Icon type="close-circle" onClick={this.handlePeopleSearchClear} />
    ) : (
      <Icon type="search" />
    );

    return (
      <>
        {shouldRenderHeader && (
          <PageHeader
            title={translate('containers.people.people')}
            actions={[
              <Link
                key={CREATE_FROM_OU}
                to={savePreviousPath(
                  getRoute('administratorAgencyUser', {
                    agencyId,
                    userId: CREATE_FROM_OU,
                  })
                )}
                disabled={!canCreateUsers}
              >
                <Button
                  type="primary"
                  size="default"
                  icon="plus"
                  disabled={!canCreateUsers}
                >
                  {translate('containers.people.addUser')}
                </Button>
              </Link>,
            ]}
          />
        )}
        <div className="section-container">
          <StyledRow type="flex" gutter={[10, 10]} justify="end">
            <If condition={canSearchBySSN}>
              <Col xs={24} md={12} xl={6}>
                <EncryptedInput
                  placeholder={translate('containers.people.searchBySsn')}
                  size="default"
                  searchable={isSearchableBySSN}
                  onPressEnter={this.handlePeopleSSNSubmit}
                  onSearch={this.handlePeopleSSNSubmit}
                  onChange={this.handlePeopleSNNChange}
                  value={ssn}
                  canEdit={canSearchBySSN}
                  canView={canSearchBySSN}
                  mask={MASK_TYPES.SSN}
                  visible={true}
                />
              </Col>
            </If>
            <Col xs={24} md={12} xl={6}>
              <Input
                placeholder={translate('containers.people.search')}
                size="default"
                onPressEnter={this.handlePeopleSearchSubmit}
                onChange={this.handlePeopleSearchChange}
                suffix={searchSuffix}
                value={search}
                ref={input => {
                  this.searchInput = input;
                }}
              />
            </Col>
          </StyledRow>

          <StyledRow>
            <Col span={24}>
              <div className="responsive-table">
                <Table dataSource={users} loading={loading} pagination={false}>
                  {this.props.children}
                </Table>
              </div>
            </Col>
          </StyledRow>

          {total > 0 && (
            <StyledRowPagination>
              <Col span={24} className="text-right">
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  total={total}
                  pageSize={per_page}
                  onChange={this.changePage}
                />
              </Col>
            </StyledRowPagination>
          )}
        </div>
      </>
    );
  }
}

export default PeopleTable;
