import React from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Icon } from 'antd';

import renderTimelineButton from './renderTimelineButton';

import StyledRepNumDiv from './renderReportNumber.styled';

const renderReportNumber = (text, record, index) => {
  const { id, reportPath, linkedReportsPath } = record;
  const hasLinked = !isEmpty(linkedReportsPath);

  return (
    <StyledRepNumDiv data-testid={`reports-${index}-reportNumber`}>
      <div className="report" data-testid={`reports-reportId-${id}`}>
        <span data-testid="reports-report-link">
          <Link to={reportPath}>{text}</Link>
        </span>
        <span className="timeline" data-testid="reports-report-timeline">
          {renderTimelineButton(record)}
        </span>
      </div>
      {hasLinked && (
        <div className="linked-report" data-testid="reports-linked-report-link">
          {linkedReportsPath.map(r => (
            <Link to={r.path} key={r.text}>
              <Icon type="link" /> {r.text}
            </Link>
          ))}
        </div>
      )}
    </StyledRepNumDiv>
  );
};

export default renderReportNumber;
